import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';

export default class Station extends React.Component {
    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp; &nbsp;
                        <Popconfirm
                            title="删除车站"
                            description="是否确定删除?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除' className="actionIcon"/>
                        </Popconfirm>
                        
                    </div>
                )}
        },
    {
        title: '站名',
        dataIndex: 'stationName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.stationName > b.stationName? 1 : -1,
    },
    {
        title: '城市',
        dataIndex: 'city',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.city > b.city? 1 : -1,
    },
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
    };
    allPermissions: any[] = [];
    
    editRecord = this.createStation();
    originalEditRecords: any = {};
    canBeSaved = false;
    
    createStation() {
        return { 
            stationId : 0,
            stationName : "",
            city : "",
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
        };
    }
    
    copyStation(station: any) {
        return { 
            stationId : station.stationId,
            stationName : station.stationName,
            city : station.city,
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
        };
    }

    onSearch = (value: any) => {
        this.setState({searchText: value.target.value})
        this.search(value.target.value);
    }
    search = (searchText: string) => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => ( 
                    item.stationName.toLowerCase().includes(searchText.toLowerCase()))
            )
          })
    }
    onEdit = (record: any) => {
        this.originalEditRecords = this.copyStation(record);
        this.editRecord = this.copyStation(record);
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    onDelete = (record: any) => {
        const Station = {stationId: record.stationId, stationName: record.stationName, bracketType: record.bracketType, unitCost: record.unitCost};
        axiosInstance.post('/logistics/deleteStation', Station).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createStation();
        this.originalEditRecords = this.createStation();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    save = () => {
        const station = this.copyStation(this.editRecord);
        if (station.stationId === 0) {
            axiosInstance.post('/logistics/insertStation', station).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateStation', station).then(
                response => {
                    this.init();
                    message.success('修改成功');
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )

        }
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.stationName !== null && this.editRecord.stationName !== '' ||
        this.editRecord.city !== null && this.editRecord.city !== '' ) {
            if (this.editRecord.stationName !== this.originalEditRecords.stationName ||
                this.editRecord.city !== this.originalEditRecords.city) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllStations();
    }
    getAllStations() {
        axiosInstance.get('/logistics/getAllStations').then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].stationId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
                if (this.state.searchText !== '') {
                    this.search(this.state.searchText);
                }
            },
            error => {
                displayErrorMessage(error, '获取车站失败');
            }
        )

    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 堆场管理</span>
                </div>
                <div className="seperate">
                </div>
                
                 
                {this.state.isEdit? 
                    <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">站名:</label>
                                <Input className="box-input" defaultValue={this.editRecord.stationName} onChange={this.changeInput.bind(this, 'stationName')}/>     
                                <label className="box-label">城市:</label>
                                <Input className="box-input" defaultValue={this.editRecord.city} onChange={this.changeInput.bind(this, 'city')}/>                        
                            </div>
                        </div>
                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                        </div>
                    </div>
                :
                    <div className="content">
                        <Input placeholder="输入要查询的字符" onChange={this.onSearch} className="tableSearch" value={this.state.searchText} /> 
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>
                        
                        <Table columns={this.columns} pagination={{ showSizeChanger: true  }} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}

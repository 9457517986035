import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, Card, Popconfirm, Select, SelectProps } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';

export default class CarSearch extends React.Component {
    
    columns = [      
        {
            title: '批次号',
            dataIndex: 'shipmentNumber',
            width: 300,
            showSorterTooltip: false,            
        },
        {
            title: '班列号',
            dataIndex: 'trainNumber',
            width: 300,
            showSorterTooltip: false,            
        },
        {
            title: '集装箱号',
            dataIndex: 'progress',//借用progress放集装箱号
            width: 300,
            showSorterTooltip: false,            
        },
        {
            title: '发运日',
            dataIndex: 'shipmentDate',
            width: 300,
            showSorterTooltip: false,            
        }, 
    ];
    originalData: any[] = [];
    originalEditRecords: any = {};
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
    };
    id = 0;
    name = "";
    canBeSaved = false;
  
    save = () => {
        axiosInstance.get('/logistics/getShipmentPlanByCar', {params: {carNumber: this.name}}).then(
            response => {
                this.originalData = response.data;                                
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].orderId;                    
                }
                this.setState({dataSource: this.originalData, isLoaded: true}); 
            },
            error => {
                displayErrorMessage(error, '获取数据失败');
            }
        ) 
    }
    
    changeName = (event: any) => {
        this.name = event.target.value;
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.name !== null && this.name !== '') {
            if (this.name !== this.originalEditRecords.name) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: true});
        // this.save();
     
    }
  
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 车架号查询</span>
                </div>
                <div className="seperate">
                </div>
                <Card size="small" style={{ width: '70%' }}>    
                    <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">车架号:</label>
                                <Input style={{ width: '50%' }} onChange={this.changeName} defaultValue={this.name}/>  <br/>    
                                <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>查询</Button>                                    
                            </div>
                            
                        </div>     
                        <div className="row">
                            <div className="box">                                
                                <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>                        
                            </div>
                        </div>    
                    </div>
                </Card>    
            </div>
        )      
    }
}
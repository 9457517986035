import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';

export default class ProfitManagement extends React.Component {    
    permissions = sessionStorage.getItem("permissions") || '';   
   
    columnsForDirector = [
        {
            title: '批次号',
            dataIndex: 'shipmentNumber',
            showSorterTooltip: false,      
            sorter: (a: any, b: any) => a.shipmentNumber > b.shipmentNumber? 1 : -1,
        },
        {
            title: '班列号',
            dataIndex: 'trainNumber',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.trainNumber > b.trainNumber? 1 : -1,
        },
        {
            title: '箱数',
            dataIndex: 'containerCount',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.trainNumber > b.trainNumber? 1 : -1,
        },
        {
            title: '台数',
            dataIndex: 'carCount',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.trainNumber > b.trainNumber? 1 : -1,
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.customerName > b.customerName? 1 : -1,
        },
        {
            title: '发运日',
            dataIndex: 'shipmentDate',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.shipmentDate > b.shipmentDate? 1 : -1,
        },
        {
            title: '应收款',
            dataIndex: 'totalPrice',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.totalPrice > b.totalPrice? 1 : -1,
            render: (_: any, record: any)=> {
                return (
                    <div>                    
                        <a onClick={() => this.onSpending(record)}>{record.totalPrice}</a>                 
                    </div>
                )}
        },
        
        // {
        //     title: '已收款',
        //     dataIndex: 'paid',
        //     showSorterTooltip: false,
        //     sorter: (a: any, b: any) => a.paid > b.paid? 1 : -1,
        //     render: (_: any, record: any)=> {
        //         return (
        //             <div>                    
        //                 <a onClick={() => this.onReceiving(record)}>{record.paid}</a>                 
        //             </div>
        //         )}
        // },
        // {
        //     title: '未收款',
        //     dataIndex: 'notPaid',
        //     showSorterTooltip: false,
        //     sorter: (a: any, b: any) => a.notPaid > b.notPaid? 1 : -1,
        // },
        {
            title: '成本',
            dataIndex: 'cost',
            showSorterTooltip: false,        
            sorter: (a: any, b: any) => a.cost > b.cost? 1 : -1,
            render: (_: any, record: any)=> {
                return (
                    <div>                    
                        <a onClick={() => this.onCost(record)}>{record.cost}</a>                 
                    </div>
                )}
        },
        {
            title: '毛利润',
            dataIndex: 'cost',   
            showSorterTooltip: false,            
            render: (_: any, record: any)=> {
                return (
                    <div>                    
                        {(record.totalPrice - record.cost)?.toFixed(2) }
                    </div>
                )}
        },
        ];

    columnsSpending = [       
    {
        title: '费用类型',
        dataIndex: 'expenseType',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.expenseType > b.expenseType? 1 : -1,
    },
    {
        title: '费用项目', 
        dataIndex: 'subExpense',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.subExpense > b.subExpense? 1 : -1,
    },
    {
        title: '备注',
        dataIndex: 'comments',
    },
    {
        title: '是否客户支付',
        dataIndex: 'payByCustomer',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.payByCustomer > b.payByCustomer? 1 : -1,
        render: (_: any, record: any)=> {
            return (
                <div>
                    {
                        record.payByCustomer? '是' : '否'
                    }
                </div>
            )
        }
    },
    {
        title: '金额',
        dataIndex: 'amount',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.amount > b.amount? 1 : -1,
        render: (_: any, record: any)=> {               
            return (
                <div>
                    {record.amount.toFixed(2)}
                </div>
            )
        }
    },  
    ];
    columnsReceiving = [       
    {
        title: '收款时间',
        dataIndex: 'paymentDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.paymentDate > b.paymentDate? 1 : -1,
    },
    {
        title: '收款金额',
        dataIndex: 'paymentAmount',
        showSorterTooltip: false,
        render: (_: any, record: any)=> {               
            return (
                <div>
                    {record.paymentAmount.toFixed(2)}
                </div>
            )
        }
    },
    {
        title: '备注',
        dataIndex: 'comments',
        showSorterTooltip: false,
    },
    ];
    columnsCost = [       
        {
            title: '项目名称',
            dataIndex: 'item',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.item > b.item? 1 : -1,
        },
        {
            title: '金额',
            dataIndex: 'cost',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                return (
                    <div>
                        {record.cost?.toFixed(2)}
                    </div>
                )
            }
        },
        ];
    originalData: any[] = [];
    originalDataSpending: any[] = [];
    originalDataReceiving: any[] = [];
    originalDataCost: any[] = [];
    shipmentNumberList: any[] = [];
    trainNumberList: any[] = [];
    customerNameList: any[] = [];
    searchText = {
        shipmentNumber: '',
        trainNumber: '',
        customerName: '',
        shipDateStart: '',
        shipDateEnd: '',

    }
    onSpending = (record: any) => {             
        this.getAdditionalExpenseByPlanId(record.planId)        
        this.setState({isSpending: true});
        this.setState({isReceiving: false});
        this.setState({isCost: false});
    }
    onReceiving = (record: any) => {                     
        this.getShipmentReceivingByPlanId(record.planId)
        this.setState({isSpending: false});
        this.setState({isReceiving: true});
        this.setState({isCost: false});
    }
    onCost = (record: any) => {                     
        this.getShipmentCostByPlanId(record.planId)
        this.setState({isSpending: false});
        this.setState({isReceiving: false});
        this.setState({isCost: true});
    }
    goBack =()=>{
        this.setState({isSpending: false});
        this.setState({isCost: false});
        this.setState({isReceiving: false});
    }

    getShipmentReceivingByPlanId(planId:any) {
        this.totalReceiving = 0;
        axiosInstance.get('/logistics/getShipmentPaymentByPlanId', {params: {planId: planId}}).then(
            response => {
                this.originalDataReceiving = response.data.filter((item: any) => (item.paymentAmount != 0));                
                for (let i = 0; i < this.originalDataReceiving.length; i++) {
                    this.originalDataReceiving[i].key = this.originalDataReceiving[i].consumablesId;                    
                    this.totalReceiving = this.totalReceiving + this.originalDataReceiving[i].paymentAmount;
                }
                this.setState({dataSourceReceiving: this.originalDataReceiving, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取收款信息失败');
            }
        )
    }

    getShipmentCostByPlanId(planId:any) {
        this.totalCost = 0;
        axiosInstance.get('/logistics/getShipmentCostByPlanId', {params: {planId: planId}}).then(
            response => {
                this.originalDataCost = response.data.filter((item: any) => (item.cost != 0));
                for (let i = 0; i < this.originalDataCost.length; i++) {                    
                    this.totalCost = this.totalCost + this.originalDataCost[i].cost;
                }
                this.setState({dataSourceCost: this.originalDataCost, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取成本信息失败');
            }
        )

        console.log('this.originalDataCost==',this.originalDataCost)
    }

    getAdditionalExpenseByPlanId(planId:any) {        
        this.totalAmount = 0;
        axiosInstance.get('/logistics/getAdditionalExpenseByPlanId', {params: {planId: planId}}).then(
            response => {
                this.originalDataSpending = response.data.filter((item: any) => (item.amount != 0));
                for (let i = 0; i < this.originalDataSpending.length; i++) {
                    this.originalDataSpending[i].key = this.originalDataSpending[i].id;
                    this.originalDataSpending[i].expenseType === 'baseExpense'? this.originalDataSpending[i].expenseType ='基本费用': this.originalDataSpending[i].expenseType ='异常费用';                                  
                    this.originalDataSpending[i].subTotal = this.originalDataSpending[i].amount;
                    this.totalAmount = this.totalAmount + this.originalDataSpending[i].amount;
                }
                this.setState({dataSourceSpending: this.originalDataSpending, isLoaded: true});                
            },
            error => {
                displayErrorMessage(error, '获取信息失败');
            }
        )

    }
    totalAmount = 0;
    totalReceiving = 0;
    totalCost = 0;
    state = {
        dataSource: this.originalData,
        dataSourceSpending: this.originalDataSpending,
        dataSourceReceiving: this.originalDataReceiving,
        dataSourceCost: this.originalDataCost,
        isLoaded: false,
        isSpending: false,
        isReceiving: false,
        isCost: false,
    };
    allPermissions: any[] = [];

    changeShipDateStart = (date: any, dateString: string) => {
        this.searchText.shipDateStart = dateString;
        this.search();
    }
    changeShipDateEnd = (date: any, dateString: string) => {
        this.searchText.shipDateEnd = dateString;
        this.search();
    }
    changeSelect = (field: string, event: any) => {        
        (this.searchText as any)[field] = event       
    }

    onSearchShipmentNumber = (value: any) => {
        this.searchText.shipmentNumber = value.target.value;
        this.search();
    }

    onSearchTrainNumber = (value: any) => {
        this.searchText.trainNumber = value.target.value;
        this.search();
    }

    onSearchCustomerName = (value: any) => {
        this.searchText.customerName = value.target.value;
        this.search();
    }

    search = () => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => {
                if (this.searchText.shipmentNumber != '' && !item.shipmentNumber?.toLowerCase().includes(this.searchText.shipmentNumber?.toLowerCase())) {
                    return false;
                }
                if (this.searchText.trainNumber != ''  && !item.trainNumber?.toLowerCase().includes(this.searchText.trainNumber?.toLowerCase())) {
                    return false;
                }
                if (this.searchText.customerName != ''  && !item.customerName?.toLowerCase().includes(this.searchText.customerName?.toLowerCase())) {
                    return false;
                }
                if (this.searchText.shipDateStart != '' && item.shipmentDate < this.searchText.shipDateStart) {
                    return false;
                }
                if (this.searchText.shipDateEnd != '' && item.shipmentDate > this.searchText.shipDateEnd) {
                    return false;
                }

                return true;

            }
            )
          })
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false});
        this.getAllShipmentPlanRevenue();        
    }

    getAllShipmentPlanRevenue() {
        axiosInstance.get('/logistics/getAllShipmentPlanRevenue').then(
            response => {
                this.originalData = response.data;
                let shipmentNumberList: any[] = [];
                let trainNumberList: any[] = [];
                let customerNameList: any[] = [];
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].planId;
                    if (!shipmentNumberList.includes(this.originalData[i].shipmentNumber)) {
                        shipmentNumberList.push(this.originalData[i].shipmentNumber);
                    }
                    if (!trainNumberList.includes(this.originalData[i].trainNumber)) {
                        trainNumberList.push(this.originalData[i].trainNumber);
                    }
                    if (!customerNameList.includes(this.originalData[i].customerName)) {
                        customerNameList.push(this.originalData[i].customerName);
                    }
                }
                this.shipmentNumberList = [];
                this.shipmentNumberList.push({label: '', value: ''});
                for(let i = 0; i < shipmentNumberList.length; i++) {
                    this.shipmentNumberList.push({label: shipmentNumberList[i], value: shipmentNumberList[i]});
                }
                this.trainNumberList = [];
                this.trainNumberList.push({label: '', value: ''});
                for(let i = 0; i < trainNumberList.length; i++) {
                    this.trainNumberList.push({label: trainNumberList[i], value: trainNumberList[i]});
                }
                this.customerNameList = [];
                this.customerNameList.push({label: '', value: ''});
                for(let i = 0; i < customerNameList.length; i++) {
                    this.customerNameList.push({label: customerNameList[i], value: customerNameList[i]});
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
                console.log('this.originalData',this.originalData)
            },
            error => {
                displayErrorMessage(error, '获取装箱计划收款信息失败');
            }
        )
    }

    onExport = (record: any) => {
        this.setState({isLoaded: false});
        axiosInstance.get('/logistics/exportShipmentPlanRevenue', {params: {shipmentNumber: this.searchText.shipmentNumber,
            trainNumber: this.searchText.trainNumber, customerName: this.searchText.customerName, shipDateStart: this.searchText.shipDateStart, shipDateEnd: this.searchText.shipDateEnd},responseType: 'blob'}).then(
            response => {
                this.setState({isLoaded: true});
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const fileName = '应收帐款.xlsx';
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                message.success('导出成功!');
            },
            error => {
                displayErrorMessage(error, '导出失败!');
            }
        );

    }

    render() {
        const roles = sessionStorage.getItem("permissions") || '';     
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>                                     
                {this.state.isSpending==false && this.state.isReceiving==false && this.state.isCost ==false ? 
                    <div>                
                    <div className="title">
                        <span> 管理费用</span>
                    </div>
                    <div className="seperate">
                    </div>
                        <div className="content">
                            <label>批次号:</label>                        
                            <Input onChange={this.onSearchShipmentNumber}  className="tableSearch" value={this.searchText.shipmentNumber} /> 
                            <label>&nbsp;&nbsp;班列号:</label>                        
                            <Input onChange={this.onSearchTrainNumber}  className="tableSearch" value={this.searchText.trainNumber} /> 
                            <label>&nbsp;&nbsp;客户名称:</label>                        
                            <Input onChange={this.onSearchCustomerName}  className="tableSearch" value={this.searchText.customerName} /> 
                            <label>&nbsp;&nbsp;发运日:</label>
                            <DatePicker style={{width: '150px'}} onChange={this.changeShipDateStart} />
                            <label>&nbsp;-&nbsp;</label>
                            <DatePicker style={{width: '150px'}} onChange={this.changeShipDateEnd} />
                            <Button type="primary" className="header-right" onClick={this.onExport}>导出</Button>
                            <Table columns={this.columnsForDirector} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}
                             summary={() => {
                                let allPrice = 0;
                                let totalCost = 0;
                                let totalProfit = 0;
                                let profit = 0;
                                let totalContainerCount = 0;
                                let totalCarCount = 0;
                                this.state.dataSource?.forEach(({ totalPrice,paid,notPaid,cost,containerCount,carCount }) => {
                                    totalContainerCount += containerCount
                                    totalCarCount += carCount
                                    allPrice += totalPrice;                                    
                                    totalCost  += cost;    
                                    profit = totalPrice -cost;
                                    totalProfit  += profit;          
                                });
                                
                                return (
                                <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>汇总</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                <Table.Summary.Cell index={2}>{totalContainerCount % 1 === 0 ? totalContainerCount : totalContainerCount.toFixed(2)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={3}>{totalCarCount % 1 === 0 ? totalCarCount : totalCarCount.toFixed(2)}</Table.Summary.Cell>                                
                                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                <Table.Summary.Cell index={6}>{allPrice % 1 === 0 ? allPrice : allPrice.toFixed(2)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={7}>{totalCost % 1 === 0 ? totalCost : totalCost.toFixed(2)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={8}>{totalProfit % 1 === 0 ? totalProfit : totalProfit.toFixed(2)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={9} />
                                </Table.Summary.Row>
                                );
                                }} />
                            
                        </div>
                </div>
                :          
                    this.state.isSpending == true?      
                    <div className="content">     
                    <div className="title">   
                        <span> 费用明细</span>    
                    </div>         
                    <Button type="primary" className="header-right" onClick={this.goBack}>返回</Button>                       
                    <Table  title={() => '合计金额: ' + this.totalAmount?.toFixed(2)} columns={this.columnsSpending} dataSource={this.state.dataSourceSpending} bordered loading={!this.state.isLoaded}/>
                    </div>
                    :
                        this.state.isCost == true?      
                        <div className="content">     
                        <div className="title">
                            <span> 成本明细</span>
                        </div>         
                        <Button type="primary" className="header-right" onClick={this.goBack}>返回</Button>                       
                        <Table  title={() => '合计金额: ' + this.totalCost?.toFixed(2)} columns={this.columnsCost} dataSource={this.state.dataSourceCost} bordered loading={!this.state.isLoaded}/>
                        </div>
                        :
                        <div className="content">     
                        <div className="title">
                        <span> 收款明细</span>
                        </div>         
                        <Button type="primary" className="header-right" onClick={this.goBack}>返回</Button>                       
                        <Table  title={() => '合计金额: ' + this.totalReceiving?.toFixed(2)} columns={this.columnsReceiving} dataSource={this.state.dataSourceReceiving} bordered loading={!this.state.isLoaded}/>
                        </div>
                }
            </div>
        )      
    }

   
}

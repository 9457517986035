import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import TextArea from 'antd/es/input/TextArea';

const dateFormat = 'YYYY-MM-DD';

const YESNOLIST = [
    {
        label: '是',
        value: true,
    },
    {
        label: '否',
        value: false,
    }
]

export default class Car extends React.Component {
    props = {planId: 0};
    constructor(props:any) {
        super(props);
      }
      
    columns = [
    {
        title: '操作',
        key: 'operation',
        width: 100,
        render: (_: any, record: any)=> {
            return (
                <div>
                    <Popconfirm
                        title="删除车架"
                        description="是否确定删除?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => this.onDelete(record)}
                    >
                        <DeleteOutlined title='删除' className="actionIcon"/>&nbsp; &nbsp;
                    </Popconfirm>
                    <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>
                </div>
            )
        }
    },
    {
        title: '序号',
        dataIndex: 'rowNumber',
        showSorterTooltip: true,
    },
    {
        title: '车架号',
        dataIndex: 'carNumber',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.carNumber > b.carNumber? 1 : -1,
    },
    {
        title: '集装箱号',
        dataIndex: 'containerNumber',
        showSorterTooltip: false,
    },
    {
        title: '车型',
        dataIndex: 'carType',
        showSorterTooltip: false,
    },
    {
        title: '公里数',
        dataIndex: 'kilometers',
        showSorterTooltip: false,
    },
    {
        title: '照片拍摄完成',
        dataIndex: 'finished',
        showSorterTooltip: false,
        render: (_: any, record: any)=> {
            return (
                <div>
                    {
                        record.finished? '是' : '否'
                    }
                </div>
            )
        }
    },
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        isUpdate: true,
    };
    allPermissions: any[] = [];
    
    editRecord = this.createCar();
    originalEditRecords: any = {};
    containerList: any[] = [];
    canBeSaved = false;
    
    createCar() {
        return { 
            id: 0,
            planId : this.props.planId,
            containerId : null,
            containerNumber: '',
            carNumber : '',
            carType : '',
            kilometers: 0,
            finished : false,
        };
    }
    
    copyCar(Car: any) {
        return { 
            id : Car.id,
            planId : this.props.planId,
            containerId : Car.containerId,
            containerNumber : Car.containerNumber,
            carNumber : Car.carNumber,
            carType : Car.carType,
            kilometers: Car.kilometers,
            finished : Car.finished,
        };
    }
    onDelete = (record: any) => {
        const Car = {id: record.id};
        axiosInstance.post('/logistics/deleteCarById', Car).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        axiosInstance.get('/logistics/getContainerByPlanId', {params: {planId: this.props.planId}}).then(
            response => {
                this.containerList = [];
                for(let i = 0; i < response.data.length; i++) {
                    this.containerList.push({label:response.data[i].containerNumber, value: response.data[i].containerId});
                }
                this.editRecord = this.createCar();
                this.originalEditRecords = this.createCar();
                this.canBeSaved = false;
                this.setState({isEdit: true});
            },
            error => {
                displayErrorMessage(error, '获取集装箱失败');
            }
        )
    }
    onEdit = (record: any) => {
        axiosInstance.get('/logistics/getContainerByPlanId', {params: {planId: this.props.planId}}).then(
            response => {
                this.containerList = [];
                for(let i = 0; i < response.data.length; i++) {
                    this.containerList.push({label:response.data[i].containerNumber, value: response.data[i].containerId});
                }
                this.originalEditRecords = this.copyCar(record);
                this.editRecord = this.copyCar(record);
                this.canBeSaved = false;
                this.setState({isEdit: true});
                this.setState({isUpdate: true});
            },
            error => {
                displayErrorMessage(error, '获取集装箱失败');
            }
        )
    }
    save = () => {
        const Car= this.copyCar(this.editRecord);        
        var arrays = Car.carNumber.split("\n");
        for(let i in arrays){
            var item = arrays[i];            
            var carNumber = item.split(",")[0];
            if(item.split(",").length != 2){
                message.error('录入数据格式有误，必须由逗号分割的车架号和车型构成,如:LVTDD24B3RD063191,BMWx3');
                return false;
            }
            if(carNumber.length != 17){
                message.error('车架号['+ carNumber +']有误，必须是17位,请检查!');
                return false;        
            }     
            const pattern = /^\d+$/;       
            if(!pattern.test(carNumber.substring(12,17))){                
                message.error('车架号['+ carNumber +']有误，后5位必须是数字,请检查!');
                return false;
            }
        } 

        if (Car.id === 0) {
            axiosInstance.post('/logistics/insertCar', Car).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateCar', Car).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }
    }

    update = () => {
        const car= this.copyCar(this.editRecord);        
        var carNumber = car.carNumber;
        if(carNumber.length != 17){
            message.error('车架号['+ carNumber +']有误，必须是17位,请检查!');
            return false;        
        }     
        const pattern = /^\d+$/;       
        if(!pattern.test(carNumber.substring(12,17))){                
            message.error('车架号['+ carNumber +']有误，后5位必须是数字,请检查!');
            return false;
        }       
        axiosInstance.post('/logistics/updateCar', car).then(
            response => {
                this.init();
                message.success('新增成功');
            },
            error => {
                displayErrorMessage(error, '新增失败');
            }
        )        
    }
    
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.carNumber !== null && this.editRecord.carNumber !== '') {
            if (this.editRecord.carNumber !== this.originalEditRecords.carNumber ||                                
                this.editRecord.kilometers !== this.originalEditRecords.kilometers ||                
                this.editRecord.carType !== this.originalEditRecords.carType) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false, isUpdate: false});
        this.getCarByPlanId();
    }
    getCarByPlanId() {
        axiosInstance.get('/logistics/getCarByPlanId', {params: {planId: this.props.planId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].id;
                    this.originalData[i].rowNumber = i+1;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取车架失败');
            }
        )

    }
    getContainerByPlanId() {
        axiosInstance.get('/logistics/getContainerByPlanId', {params: {planId: this.props.planId}}).then(
            response => {
                this.containerList = [];
                for(let i = 0; i < response.data.length; i++) {
                    this.containerList.push({label:response.data[i].containerNumber, value: response.data[i].containerId});
                }
            },
            error => {
                displayErrorMessage(error, '获取集装箱失败');
            }
        )

    }
    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
        console.log(`selectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.selectedRows = selectedRows;
        this.checkCanBeSaved();
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };

    deleteSelected = () => {        
        this.selectedRows.forEach(element => {
            const Car = {id: element.id};
            axiosInstance.post('/logistics/deleteCarById', Car).then(
                response => {
                    this.init();                    
                },
                error => {
                    displayErrorMessage(error, '删除失败');
                }
            )
        })               
        message.success('删除成功');
    }

    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="seperate">
                </div>
                
                
                {this.state.isEdit? 
                    this.state.isUpdate? 
                        <div className="content">
                                
                        <div className="row">
                            <div className="box">
                                <label className="box-label">车架号:</label>
                                <Input className="box-input"   onChange={this.changeInput.bind(this, 'carNumber')} defaultValue={this.editRecord.carNumber} />
                                <label className="box-label">车型:</label>
                                <Input className="box-input"   onChange={this.changeInput.bind(this, 'carType')} defaultValue={this.editRecord.carType} />
                            </div>                               
                        </div>                        

                        <div className="row">    
                            <div className="box">
                                <label className="box-label">公里数:</label>
                                <Input className="box-input"  onChange={this.changeInput.bind(this, 'kilometers')} defaultValue={this.editRecord.kilometers} />                                    
                            </div>
                        </div>       
                        
                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.update} disabled={!this.canBeSaved}>保存</Button>
                        </div>
                    </div>

                 :
                    <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">请录入车架号和车型:</label>                                
                                <TextArea rows={10} className="box-input"  onChange={this.changeInput.bind(this, 'carNumber')} />
                                <br/>
                                <br/>
                                <label>
                                    格式如下:<br/>
                                    LVTDD24B3RD063191,BMWx3<br/>
                                    LVVDD21BXRD057688,BMWx3<br/>
                                    LVVDD21BXRD067508,BMWx3<br/>
                                </label>                                
                            
                                                            
                            </div>
                        </div>                        
                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                        </div>
                    </div>
                :
                    <div className="content">
                        <Button type="primary" className="header-right" onClick={this.onAdd}>导入车架列表</Button>
                        <Button type="primary" className="header-left" onClick={this.deleteSelected} disabled={this.selectedRows.length==0}>批量删除</Button>
                        <Table title={() => '车架数量: ' + this.originalData?.length} rowSelection={{
                            type: 'checkbox',
                            ...this.rowSelection,
                            }} columns={this.columns} pagination={{ showSizeChanger: true  }} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}


import { axiosInstance } from '@/config/axios-interceptor';
import { Modal, message } from 'antd';
import { RcFile } from 'antd/es/upload';
export function displayErrorMessage(error: any, msg: string) {
	if (error.response?.status !== 401) {
		message.error(error.response?.data?.message);
	}
}

export function modalError(error: any) {
	Modal.error({
		title: 'Error',
		content: error.response?.data?.message == null? 'Internal error happen!': error.response?.data?.message,
		zIndex: 10000,
	});
}

export function emailCheck(email: string) {
	var re = /^[A-Za-z\d]+([-_\.][A-Za-z\d]+)*@([A-Za-z\d]+[-\.])+[A-Za-z\d]{2,4}(,[A-Za-z\d]+([-_\.][A-Za-z\d]+)*@([A-Za-z\d]+[-\.])+[A-Za-z\d]{2,4})*$/;
	// var re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
	if (!re.test(email)) {
		return false;
	}
	return true;
}



export async function upload (filePath: string, file: RcFile) {        
	const formData = new FormData();
	formData.append('file', file);
	formData.append('filePath', filePath);
	return axiosInstance.post('/greenliant/uploadDoc', formData, {
		headers: {
		  'Content-Type': 'multipart/form-data'
		}

	});
}

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { creatUuid } from './utils/creatUuid';
// import ConstantRoutes from '@router/index';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import store from './config/store';
import AppManage from './appManage';
import ScrollToTop from './components/ScrollToTop';

export default function App() {
	// const navigate = useNavigate()
	// useEffect(() => {
	//   store.subscribe(() => {
	// 	if (store.getState().isLogin.value) {
	// 	  navigate('/manage',{replace: true});
	// 	}else if (store.getState().customerIsLogin.value) {
	// 	  navigate('/customer',{replace: true});
	// 	}
	//   })
	// })
	const getRoutes = (routes: any) =>
		routes.map((item: any) => (
			<Route
				key={creatUuid(8, 25)}
				index={Boolean(item.index)}
				path={item.path}
				element={<item.element />}
			>
				{item.children && getRoutes(item.children)}
			</Route>
		));
		return (
			<BrowserRouter>
				<AppManage />
			</BrowserRouter>
		)
}

/*
 * @Author: CK
 * @email: 1298050101@qq.com
 * @Date: 2023-03-12 00:00:05
 * @LastEditTime: 2023-04-02 17:31:53
 * @FilePath: \code\src\config\store\index.ts
 * @Description: 全局状态管理
 */
import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import returnPlanBracketReducer from './slice/returnPlanBracketReducer';

const store: EnhancedStore = configureStore({
	reducer: {
		returnPlanBracket: returnPlanBracketReducer,
	},
});

export default store;

import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker, Tabs } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';
import TabPane from 'antd/es/tabs/TabPane';
import BracketOrderPayment from './BracketOrder/BracketOrderPayment';
import BracketOrderBracketInventory from './BracketOrder/BracketOrderBracketInventory';

const dateFormat = 'YYYY-MM-DD';

export default class BracketOrder extends React.Component {    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 80,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp; &nbsp;
                    </div>
                )}
        },
    {
        title: '供应商',
        dataIndex: 'vendorName',
        showSorterTooltip: false,
    },
    {
        title: '支架使用类型',
        dataIndex: 'recyclable',
        showSorterTooltip: false,        
        render: (_: any, record: any)=> {
            return (
                <div>
                        {
                            record.recyclable == 'recyclable' ? '循环' :  '一次性'
                        }
                        
                    </div>
            )}
    },   
    {
        title: '下单日期',
        dataIndex: 'orderDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.orderDate > b.orderDate? 1 : -1,
    },

    {
        title: '交货日期',
        dataIndex: 'actualShipmentDate',
        showSorterTooltip: false,
    },
    {
        title: '支架型号',
        dataIndex: 'bracketType',
        showSorterTooltip: false,      
    },    
    {
        title: '数量',
        dataIndex: 'qty',
        showSorterTooltip: false,      
    },    
    {
        title: '发往地',
        dataIndex: 'orderCity',
        showSorterTooltip: false,      
    },         
    {
        title: '备注',
        dataIndex: 'comments',
        showSorterTooltip: false,
    },
    {
        title: '订单状态',
        dataIndex: 'status',
        showSorterTooltip: false,      
        render: (_: any, record: any)=> {
            if (record.status == 'New') {
                return (
                    <div>
                        新订单
                    </div>
                )
            }else if (record.status == 'Checked') {
                return (
                    <div>
                        已对账
                    </div>
                )
            }else if (record.status == 'Invoiced') {
                return (
                    <div>
                        已收票
                    </div>
                )
            }else{
                return (
                    <div>
                        已付款
                    </div>
                )
            }
        }
    },  
    {
        title: '是否入库',
        dataIndex: 'status',
        showSorterTooltip: false,      
        render: (_: any, record: any)=> {
            if (record.allInInventory) {
                return (
                    <div>
                        已入库
                    </div>
                )
            }else {
                return (
                    <div>
                        未入库
                    </div>
                )
            }
        }
    },  
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',        
    };
    orderCityList: any[] = [];
    vendorList: any[] = [];
    recyclableList: any[] = [{label:'循环', value: 'recyclable'},{label:'一次性', value: 'onetime'}]; 
    statusList: any[] = [{label:'新订单', value: 'New'},{label:'已对账', value: 'Checked'},{label:'已收票', value: 'Invoiced'},{label:'已付款', value: 'Paid'}]; 
    inventoryList: any[] = [{label:'已入库', value: true},{label:'未入库', value: false}]; 
    editRecord = this.createBracketOrder();
    originalEditRecords: any = {};
    canBeSaved = false;
    
    createBracketOrder() {
        return { 
            orderId : 0,
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            orderDate : "",
            dayJsOrderDate : dayjs(dayjs(new Date()), dateFormat),
            vendorId: 0,
            recyclable: '',
            vendorName : "",
            planedShipmentDate: "",
            dayJsPlanedShipmentDate : dayjs(dayjs(new Date()), dateFormat),
            bracketNumber: "",
            qty: 0,
            unitPrice: 0,
            allInInventory: false,
            allPaid: false,
            orderCity: "",            
            transportType:"",
            bracketType:"",
            usageChennel:"",
            batchNumber:"",
            colour:"",
            inputValueAddedTax:"",
            comments : "",
            status : "New",
            paid: 0,
        };
    }
    
    copyBracketOrder(bracketOrder: any) {
        return { 
            orderId : bracketOrder.orderId,
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            orderDate : bracketOrder.orderDate,
            dayJsOrderDate :  bracketOrder.orderDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(bracketOrder.orderDate, dateFormat),
            vendorId: bracketOrder.vendorId,
            recyclable: bracketOrder.recyclable,
            vendorName : bracketOrder.vendorName,
            planedShipmentDate: bracketOrder.planedShipmentDate,
            dayJsPlanedShipmentDate :  bracketOrder.planedShipmentDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(bracketOrder.planedShipmentDate, dateFormat),
            bracketNumber: bracketOrder.bracketNumber,
            qty: bracketOrder.qty,
            unitPrice: bracketOrder.unitPrice,
            allInInventory: bracketOrder.allInInventory,
            allPaid: bracketOrder.allPaid,
            orderCity: bracketOrder.orderCity,
            transportType:bracketOrder.transportType,
            bracketType:bracketOrder.bracketType,
            usageChennel:bracketOrder.usageChennel,
            batchNumber:bracketOrder.batchNumber,
            colour:bracketOrder.colour,            
            inputValueAddedTax:bracketOrder.inputValueAddedTax,
            comments : bracketOrder.comments,
            status : bracketOrder.status,
            paid : bracketOrder.paid,
        };
    }

    searchText = {
        recyclable: '',
        status: '',
        vendorName:'',    
        orderCity:'',  
        allInInventory:'', 
    }

    changeSelectSearch = (field: string, event: any) => {
        (this.searchText as any)[field] = event
        this.search();
    }
    search = () => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => {
                if (this.searchText.recyclable != '' && item.recyclable !== this.searchText.recyclable) {
                    return false;
                }
                if (this.searchText.status != ''  && item.status !== this.searchText.status) {
                    return false;
                }
                if (this.searchText.vendorName != ''  && !item.vendorName?.toLowerCase().includes(this.searchText.vendorName?.toLowerCase())) {
                    return false;
                } 
                if (this.searchText.orderCity != ''  && !item.orderCity?.toLowerCase().includes(this.searchText.orderCity?.toLowerCase())) {
                    return false;
                }              
                if (this.searchText.allInInventory?.toString()  != ''  && item.allInInventory != this.searchText.allInInventory) {
                    return false;
                } 
                return true;
            }
            )
          })
    }


    onEdit = (record: any) => {
        this.originalEditRecords = this.copyBracketOrder(record);
        this.editRecord = this.copyBracketOrder(record);
       // this.getBracketOrderPaymentByOrderId();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }

    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {        
        this.selectedRows = selectedRows;
        this.checkCanBeSaved();
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };

    deleteSelected = () => {       
        if(this.selectedRows.length==0) {
            alert('请选择要删除的条目!');
            return;
        }
        this.selectedRows.forEach(element => {
            const BracketOrder = {orderId: element.orderId, orderDate: element.orderDate};
            axiosInstance.post('/logistics/deleteBracketOrder', BracketOrder).then(
                response => {
                    this.init();                   
                },
                error => {
                    displayErrorMessage(error, '删除失败');
                }
            )
        })               
        message.success('删除成功');
    }


    onDelete = (record: any) => {
        const BracketOrder = {orderId: record.orderId, orderDate: record.orderDate};
        axiosInstance.post('/logistics/deleteBracketOrder', BracketOrder).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createBracketOrder();
        this.originalEditRecords = this.createBracketOrder();
        this.canBeSaved = false;
        this.setState({isEdit: true, paid: 0});
    }
   
    save = () => {
        const bracketOrder = this.copyBracketOrder(this.editRecord);
        if (bracketOrder.orderId === 0) {
            axiosInstance.post('/logistics/insertBracketOrder', bracketOrder).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateBracketOrder', bracketOrder).then(
                response => {
                    this.init();
                    message.success('修改成功');
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )

        }
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }    
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event
        this.checkCanBeSaved();
    }
    changeOrderDate = (date: any, dateString: string) => {
        this.editRecord.orderDate = dateString;
        this.checkCanBeSaved();
    }
    changePlanedShipmentDate = (date: any, dateString: string) => {
        this.editRecord.planedShipmentDate = dateString;
        this.checkCanBeSaved();
    }

    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.orderDate !== null && this.editRecord.orderDate !== '' &&
            this.editRecord.vendorId !== null && this.editRecord.vendorId !== 0 &&
            this.editRecord.recyclable !== null && this.editRecord.recyclable !== '' &&
            this.editRecord.planedShipmentDate !== null && this.editRecord.planedShipmentDate !== '' &&
            this.editRecord.qty !== null && this.editRecord.qty !== 0 &&
            this.editRecord.unitPrice !== null && this.editRecord.unitPrice !== 0
           // this.editRecord.orderCity !== null && this.editRecord.orderCity !== '' &&
           // this.editRecord.transportType !== null && this.editRecord.transportType !== ''  &&  
           // this.editRecord.status !== null && this.editRecord.status !== ''           
        ) {
            if (this.editRecord.orderDate !== this.originalEditRecords.orderDate ||
                this.editRecord.vendorId !== this.originalEditRecords.vendorId ||
                this.editRecord.recyclable !== this.originalEditRecords.recyclable ||   
                this.editRecord.planedShipmentDate !== this.originalEditRecords.planedShipmentDate ||
                this.editRecord.bracketNumber !== this.originalEditRecords.bracketNumber ||
                this.editRecord.qty !== this.originalEditRecords.qty ||
                this.editRecord.unitPrice !== this.originalEditRecords.unitPrice ||
                this.editRecord.orderCity !== this.originalEditRecords.orderCity ||
                this.editRecord.transportType !== this.originalEditRecords.transportType ||
                this.editRecord.bracketType !== this.originalEditRecords.bracketType ||
                this.editRecord.usageChennel !== this.originalEditRecords.usageChennel ||
                this.editRecord.batchNumber !== this.originalEditRecords.batchNumber ||
                this.editRecord.colour !== this.originalEditRecords.colour ||                
                this.editRecord.comments !== this.originalEditRecords.comments || 
                this.editRecord.status !== this.originalEditRecords.status ) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    componentWillReceiveProps(nextProps: Readonly<{}>, nextContext: any): void {
        this.init();
    }

    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllBracketOrder();
        this.getAllVendors();
        this.getAllCity();
    }
    getAllBracketOrder() {  
        axiosInstance.get('/logistics/getAllBracketOrder').then(
            response => {
                this.originalData = response.data;                                
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].orderId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true}); 
            },
            error => {
                displayErrorMessage(error, '获取支架订单失败');
            }
        )
    }
    getAllVendors() {
        axiosInstance.get('/logistics/getAllVendors').then(
            response => {
                this.vendorList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.vendorList.push({label:response.data[i].vendorName, value: response.data[i].vendorId});               
                }
            },
            error => {
                displayErrorMessage(error, '获取供应商失败');
            }
        )
    }

    onSearchOrderCity = (value: any) => {
        this.searchText.orderCity = value.target.value;
        this.search();
    }

    onSearchVendorName = (value: any) => {
        this.searchText.vendorName = value.target.value;
        this.search();
    }
    getAllCity() {
        axiosInstance.get('/logistics/getAllCommonProperties').then(
            response => {
                this.orderCityList = [];           
                for(let i = 0; i < response.data.length; i++) {
                    if (response.data[i].propertyKey == 'orderCity') {
                        this.orderCityList.push({label:response.data[i].propertyValue, value: response.data[i].propertyValue});
                    }
                }
            },
            error => {
                displayErrorMessage(error, 'Get common properties failed!');
            }
        )       
    }

    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                     <span> 支架采购订单</span>
                </div>
                <div className="seperate">
                </div>
                
                
                {this.state.isEdit? 
                    <div className="content">
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>退出</Button>
                        </div>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab='基本信息' key = '1'>
                                <>
                                    <div className="row">
                                        <div className="box">
                                            <label className="box-label">下单日期:</label>
                                            {(this.editRecord.orderDate + "") == "" ?
                                                <DatePicker className="box-input" onChange={this.changeOrderDate} />
                                                :
                                                <DatePicker className="box-input" onChange={this.changeOrderDate} defaultValue={this.editRecord.dayJsOrderDate} />}
                                            <label className="box-label">供应商:</label>
                                            <Select className="box-input"  
                                             showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={this.vendorList} onChange={this.changeSelect.bind(this, 'vendorId')} defaultValue={this.editRecord.vendorName}/>               
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="box">
                                            <label className="box-label">预计入库日期:</label>
                                            {(this.editRecord.planedShipmentDate + "") == "" ?
                                                <DatePicker className="box-input" onChange={this.changePlanedShipmentDate} />
                                                :
                                                <DatePicker className="box-input" onChange={this.changePlanedShipmentDate} defaultValue={this.editRecord.dayJsPlanedShipmentDate} />}
                                            <label className="box-label">支架使用类型:</label>
                                            <Select className="box-input"  options={this.recyclableList} onChange={this.changeSelect.bind(this, 'recyclable')} defaultValue={this.editRecord.recyclable} />                                                       
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="box">
                                            <label className="box-label">数量:</label>
                                            <Input className="box-input" defaultValue={this.editRecord.qty==0?'':this.editRecord.qty} onChange={this.changeInput.bind(this, 'qty')}/>
                                            <label className="box-label">单价:</label>
                                            {
                                                roles.includes('财务')?
                                                <Input className="box-input" defaultValue={this.editRecord.unitPrice==0?'':this.editRecord.unitPrice} onChange={this.changeInput.bind(this, 'unitPrice')}/>
                                                :  
                                               ''
                                            }                                            
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="box">
                                            <label className="box-label">发往地:</label>
                                            <Select className="box-input"  options={this.orderCityList} onChange={this.changeSelect.bind(this, 'orderCity')} defaultValue={this.editRecord.orderCity}/> 
                                            <label className="box-label">运输类型:</label>
                                            <Input className="box-input" defaultValue={this.editRecord.transportType} onChange={this.changeInput.bind(this, 'transportType')}/>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="box">
                                            <label className="box-label">支架型号:</label>
                                            <Input className="box-input" defaultValue={this.editRecord.bracketType} onChange={this.changeInput.bind(this, 'bracketType')}/>
                                            <label className="box-label">支架号范围:</label>
                                            <Input className="box-input" defaultValue={this.editRecord.bracketNumber} disabled={this.editRecord.recyclable != 'recyclable'} onChange={this.changeInput.bind(this, 'bracketNumber')}/>      
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="box">
                                            <label className="box-label">批次号:</label>
                                            <Input className="box-input" defaultValue={this.editRecord.batchNumber} onChange={this.changeInput.bind(this, 'batchNumber')}/>
                                            <label className="box-label">颜色:</label>
                                            <Input className="box-input" defaultValue={this.editRecord.colour} onChange={this.changeInput.bind(this, 'colour')}/>
                                        </div>
                                    </div>


                                    <div className="row">
                                    <div className="box">
                                            <label className="box-label">订单状态:</label>
                                            <Select className="box-input"  options={this.statusList} onChange={this.changeSelect.bind(this, 'status')} defaultValue={this.editRecord.status} />                                                       
                                            <label className="box-label">备注:</label>                                            
                                            <Input className="box-input" defaultValue={this.editRecord.comments} onChange={this.changeInput.bind(this, 'comments')}/>                                            
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="box">
                                            <label className="box-label">总价:</label>
                                            <Input className="box-input" value={this.editRecord.qty * this.editRecord.unitPrice} disabled/>
                                            <label className="box-label">进项税额:</label>
                                            <Input className="box-input" value={(((this.editRecord.qty * this.editRecord.unitPrice)/1.13)*0.13).toFixed(2)} disabled/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="box">
                                            <label className="box-label">已付:</label>
                                            <Input className="box-input" value={this.editRecord.paid} disabled/>
                                            <label className="box-label">未付:</label>
                                            <Input className="box-input" value={this.editRecord.qty * this.editRecord.unitPrice - this.editRecord.paid} disabled/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="box">
                                            <label className="box-label">是否入库:</label>
                                            <Input className="box-input" value={this.editRecord.allInInventory?"是":"否"} disabled/>                                          
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                                        <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                                    </div>
                                </>
                            </TabPane>
                            <TabPane tab='入库信息' key = '2' disabled={this.editRecord.orderId == 0}>
                                <BracketOrderBracketInventory orderId={this.editRecord.orderId} recyclable={this.editRecord.recyclable} orderQty={this.editRecord.qty}/>
                            </TabPane>
                            <TabPane tab='付款记录' key = '3' disabled={this.editRecord.orderId == 0}>
                                <BracketOrderPayment orderId={this.editRecord.orderId} />
                            </TabPane>
                        </Tabs> 
                    </div>
                :
                    <div className="content">
                        <label className="box-label">支架使用类型:</label>
                        <Select style={{ width: '172px' }} options={[{label:'--全部--', value: ''},...this.recyclableList]} onChange={this.changeSelectSearch.bind(this, 'recyclable')}/> 
                        <label className="box-label">订单状态:</label>
                        <Select style={{ width: '172px' }} options={[{label:'--全部--', value: ''},...this.statusList]} onChange={this.changeSelectSearch.bind(this, 'status')}/>          
                        <label className="box-label">供应商:</label>
                        <Input onChange={this.onSearchVendorName}  className="tableSearch" value={this.searchText.vendorName} />  
                        <label className="box-label">发往地:</label>
                        <Input onChange={this.onSearchOrderCity}  className="tableSearch" value={this.searchText.orderCity} />  
                        <label className="box-label">是否入库:</label>
                        <Select style={{ width: '172px' }} options={[{label:'--全部--', value: ''},...this.inventoryList]} onChange={this.changeSelectSearch.bind(this, 'allInInventory')}/>  
                        
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>    
                        <div className="row">
                            <Button type="primary" className="header-left" onClick={this.deleteSelected} disabled={this.selectedRows.length==0}>批量删除</Button>  
                        </div>                     
                        <Table columns={this.columns} rowSelection={{
                            type: 'checkbox',
                            ...this.rowSelection,
                            }}  dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined,PlusOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import store from '../../config/store';
import {setUnChanged} from '../../config/store/slice/returnPlanBracketReducer'

const dateFormat = 'YYYY-MM-DD';

const FeeType = [
    {label: '出口报关', value: 'exportFee'},
    {label: '暂时进出口担保', value: 'tempGuaranteeCost'},
    {label: '转关费', value: 'customsTransferCost'},   
    {label: '担保核销', value: 'guaranteeWriteOff'},    
]

export default class ReturnBracket extends React.Component {
    props = {planId: 0, isView: false,trainNumber:''};    
    constructor(props:any) {
        super(props);
    }
    
    columns = [      
        {
            title: '集装箱号',
            dataIndex: 'containerNumber',
        },
        {
            title: '支架号',
            dataIndex: 'bracketNumber',
        },       
        {
            title: '出口报关',
            dataIndex: 'exportFee',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.exportFeeVendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.exportFee % 1 === 0 ? record.exportFee : record.exportFee.toFixed(2)}
                        </div>
                    )
                
            }
        },             
        {
            title: '暂时进出口担保',
            dataIndex: 'tempGuaranteeCost',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.tempGuaranteeCostVendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.tempGuaranteeCost % 1 === 0 ? record.tempGuaranteeCost : record.tempGuaranteeCost.toFixed(2)}
                        </div>
                    )
                
            }
        },   
        {
            title: '转关费',
            dataIndex: 'exportFee',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.customsTransferCostVendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.customsTransferCost % 1 === 0 ? record.customsTransferCost : record.customsTransferCost.toFixed(2)}
                        </div>
                    )
                
            }
        }, 
        {
            title: '担保核销',
            dataIndex: 'customsClearanceCost',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.guaranteeWriteOffVendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.guaranteeWriteOff % 1 === 0 ? record.guaranteeWriteOff : record.guaranteeWriteOff.toFixed(2)}
                        </div>
                    )
                
            }
        },         
        {
            title: '合计',
            dataIndex: 'customsClearanceCost',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div>
                            {(record.guaranteeWriteOff+record.customsTransferCost+record.tempGuaranteeCost+record.exportFee).toFixed(2)}
                        </div>
                    )
                
            }
        }     
    ];

    handleMouseEnter = (a: any) => () => {
        this.setState({ tooltipText: a == 'null'?'':a });
      };
     
      handleMouseLeave = () => {
        this.setState({ tooltipText: '' });
      };

    originalData: any[] = [];
    oriTrainNumberStringList: string[] = [];
    oriTrainNumberList: any[] = [];
    containerNumberStringList: string[] = [];
    containerNumberList: any[] = [];
    vendorList: any[] = [];

    originalShipmentNumber: string = '';
    containerNumber: string = '';
    fee = 0;
    feeType = '';
    vendor = '';
    state = {
        isLoaded: false,
        dataSource: this.originalData,
        searchText: '',
        fee: '',
        tooltipText: ''
    };


    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {        
        this.selectedRows = selectedRows;
        this.checkCanBeSaved();
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };
    
    allPermissions: any[] = [];
    
    canBeSaved = false;

    saveSelected = () => { 
        const costManagementUpdateModelList: any[] = [];
        this.state.dataSource.forEach(row => {
            this.selectedRows.forEach(element => {
                if (row.bracketId === element.bracketId) {
                    costManagementUpdateModelList.push({costId: element.costId, bracketId: element.bracketId, originalShipmentNumber: this.props.trainNumber, containerNumber: element.containerNumber, parameter: this.feeType, value: this.fee, vendorName:this.vendor});
                }
            })
        }) 

        axiosInstance.post('/logistics/updateCostManagementForExportPlan', costManagementUpdateModelList).then(
            response => {
                costManagementUpdateModelList.forEach(element => {
                    const newArr = this.selectedRows.filter(item => item.bracketId !== element.bracketId);
                    this.selectedRows = newArr;
                });
                this.init();
                this.checkCanBeSaved();
                message.success('更新成功');
            },
            error => {
                displayErrorMessage(error, '更新失败');
            }
        )
    }
    changeFeeType = (event: any) => {
        this.feeType = event;
        this.checkCanBeSaved();
    }
    changeVendor = (event: any) => {
        console.log('changeVendor====',event)
        this.vendor = event;
        this.checkCanBeSaved();
    }

    changeFee = (event: any) => {
        this.fee = event.target.value;
        this.checkCanBeSaved();
    }
    changeOriginalShipmentNumber = (event: any) => {
        this.originalShipmentNumber = event;
        this.search();
    }
    changeContainerNumber = (event: any) => {
        this.containerNumber = event;
        this.search();
    }   
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.fee > 0 && this.feeType !== '' && this.vendor !== '' && this.selectedRows.length > 0) {
            this.canBeSaved = true;
        }
        this.setState({});
    }
    componentDidMount() {
        store.subscribe(() => {
            if (store.getState().returnPlanBracket.value) {
                store.dispatch(setUnChanged());
                this.init();
            }
        })
        const roles = sessionStorage.getItem("permissions") || '';
        //if (roles.includes('admin')) {
            this.init();
        //}
    }
    init() {
        this.setState({isLoaded: false});
        this.getBracketInventoryByPlanId();
        this.getAllVendors();
    }
    getAllVendors() { 
        axiosInstance.get('/logistics/getAllVendors').then(
            response => {
                this.vendorList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.vendorList.push({label:response.data[i].vendorName, value: response.data[i].vendorName});               
                }                
                this.setState({});
            },
            error => {
                displayErrorMessage(error, '获取供应商失败');
            }
        )
        
    }
    getBracketInventoryByPlanId() {
        axiosInstance.get('/logistics/getCostManagementForExportPlan', {params: {planId: this.props.planId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = i; 
                }
                this.containerNumberStringList = [];
                this.containerNumberList = [];                
                this.containerNumberList.push({label: '', value: ''});
                this.originalData.forEach(element => {                  
                    if (!this.containerNumberStringList.includes(element.containerNumber)) {
                        this.containerNumberStringList.push(element.containerNumber)
                        this.containerNumberList.push({label: element.containerNumber, value: element.containerNumber});
                    }
                })
                this.setState({dataSource: this.originalData, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取支架失败');
            }
        )

    }
    
    search = () => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => (
                (this.originalShipmentNumber === '' || item.originalShipmentNumber === this.originalShipmentNumber) &&
                (this.containerNumber === '' || item.containerNumber === this.containerNumber))
            )
        });
    }

    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="seperate">
                </div>   
                           
                <div className="content">
                    {
                        this.props.isView? '' :
                            <div className = 'header-right'>
                                <label className="box-label">供应商:</label>                                
                                <Select style={{width: '250PX'}} className="box-input"  
                                showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                options={this.vendorList} onChange={this.changeVendor} />                                    
                                <label>费用类型:</label>
                                <Select style={{width: '150PX'}} options={FeeType} onChange={this.changeFeeType}/> 
                                <label>&nbsp;&nbsp;费用:</label>
                                <Input  style={{width: '150PX'}} onChange={this.changeFee}/>  
                                <Button className="header-right" type="primary" onClick={this.saveSelected} disabled={!this.canBeSaved}>更新</Button>  
                            </div>
                    } 
                    {this.props.isView? '' :<br/>}
                    {this.props.isView? '' :<br/>} 
                    <div className='row'>                                                       
                        <label style={{marginLeft: '20PX', width: '60PX'}}>集装箱号:</label>
                        <Select style={{width: '200PX'}} options={this.containerNumberList} onChange={this.changeContainerNumber} defaultValue={this.containerNumber}/>
                    </div>
                    
                    <Table pagination={{ pageSize: 200 }} title={() => '已选择的支架数量: ' + this.selectedRows?.length + '     供应商: ' + this.state.tooltipText} 
                        rowSelection={{
                        type: 'checkbox',
                        ...this.rowSelection,
                        }} columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}
                        
                        summary={() => {
                            let planCount = 0;
                            let exportFeeTotal = 0;
                            let tempGuaranteeCostTotal = 0;
                            let customsTransferCostTotal = 0;
                            let overseasAgencyTotal = 0;
                            let customsClearanceCostTotal = 0;
                            let guaranteeWriteOffTotal = 0;
                            let other01Total = 0;
                            let portHoistingFeeTotal = 0;
                            let devanningFeeTotal = 0;
                            let returnContainerFeeTotal = 0;
                            let releaseChargeTotal = 0;
                            let loadingFeeTotal = 0;
                            let bracketTransferFeeTotal = 0;
                            let protectionBuckleFeeTotal = 0;
                            let screwSupplementFeeTotal = 0;
                            let bracketMaintainFeeTotal = 0;
                            let other02Total = 0;
                            let other03Total = 0;                            
                            this.state.dataSource?.forEach(({ exportFee,tempGuaranteeCost,customsTransferCost,overseasAgency,customsClearanceCost,guaranteeWriteOff,other01,
                                portHoistingFee,devanningFee,returnContainerFee,releaseCharge,loadingFee,bracketTransferFee,protectionBuckleFee,screwSupplementFee,bracketMaintainFee,other02,other03 }) => {
                                exportFeeTotal += exportFee;
                                tempGuaranteeCostTotal += tempGuaranteeCost;
                                customsTransferCostTotal += customsTransferCost;                                    
                                overseasAgencyTotal += overseasAgency;
                                customsClearanceCostTotal += customsClearanceCost;
                                guaranteeWriteOffTotal += guaranteeWriteOff;
                                other01Total += other01;
                                portHoistingFeeTotal += portHoistingFee;
                                devanningFeeTotal += devanningFee;
                                returnContainerFeeTotal += returnContainerFee;
                                releaseChargeTotal += releaseCharge;
                                loadingFeeTotal += loadingFee;
                                bracketTransferFeeTotal += bracketTransferFee;
                                protectionBuckleFeeTotal += protectionBuckleFee;
                                screwSupplementFeeTotal += screwSupplementFee;
                                bracketMaintainFeeTotal += bracketMaintainFee;
                                other02Total += other02;
                                other03Total += other03;                                
                                planCount++;
                            });
                            
                            return (
                            <Table.Summary.Row>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>汇总</Table.Summary.Cell>                            
                            <Table.Summary.Cell index={2}>{planCount % 1 === 0 ? planCount : planCount.toFixed(2)}</Table.Summary.Cell>                            
                            <Table.Summary.Cell index={4}>{exportFeeTotal % 1 === 0 ? exportFeeTotal : exportFeeTotal.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={5}>{tempGuaranteeCostTotal % 1 === 0 ? tempGuaranteeCostTotal : tempGuaranteeCostTotal.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={6}>{customsTransferCostTotal % 1 === 0 ? customsTransferCostTotal : customsTransferCostTotal.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={9}>{guaranteeWriteOffTotal % 1 === 0 ? guaranteeWriteOffTotal : guaranteeWriteOffTotal.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={9}>{(exportFeeTotal + tempGuaranteeCostTotal +  customsTransferCostTotal + guaranteeWriteOffTotal).toFixed(2)}</Table.Summary.Cell>
                            </Table.Summary.Row>
                            );
                            }} 
                            
                            />
                </div>
            </div>
        )      
    }
}

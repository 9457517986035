import axios from "axios";
import { logout } from './store/slice/loginReducer';
import store from './store'

export const axiosInstance = axios.create({
    baseURL: "/api",
  });

axiosInstance.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem('token');
        if (token) {
            config.headers['token'] = token;
        }
        config.withCredentials = true;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('isLogin');
            store.dispatch(logout());
        }
        return Promise.reject(error)
    }
)
import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';

export default class ManageHome extends React.Component {   

    render() {
        return (
            <div 
                style={{ 
                    backgroundColor:'#f0f0ff',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <h4 style={{ margin: 0,fontSize: '40px'}}>
                    欢迎 {sessionStorage.getItem('userName')} 使用本系统！
                </h4>
            </div>
        );
    }
}
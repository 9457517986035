import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, Card, DatePicker, Progress, Tabs,ConfigProvider } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import { EditOutlined, DeleteOutlined, DownloadOutlined, CaretDownOutlined, DownSquareOutlined, BorderBottomOutlined} from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';
import TabPane from 'antd/es/tabs/TabPane';
import ShipmentBracket from './ShipmentPlan/ShipmentBracket';
import ShipmentConsumables from './ShipmentPlan/ShipmentConsumables';
import Container from './ShipmentPlan/Container';
import Car from './ShipmentPlan/Car';
import ShipmentPayment from './ShipmentPlan/ShipmentPayment';
import ShipmentCost from './ShipmentPlan/ShipmentCost';
import ShipmentStatusLog from './ShipmentPlan/ShipmentStatusLog';
import AdditionalExpense from './ShipmentPlan/AdditionalExpense';
import AdditionalCost from './ShipmentPlan/AdditionalCost';
import locale from 'antd/locale/zh_CN';

const dateFormat = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;

export default class ShipmentPlan extends React.Component {
        
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 150,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp;
                        <Popconfirm
                            title="删除装箱计划"
                            description="是否确定删除?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除' className="actionIcon"/>
                        </Popconfirm>&nbsp;
                        
                        <Popconfirm
                            title="导出装箱计划"
                            description="是否确定导出?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onExport(record)}
                        >
                            <DownloadOutlined title='导出' className="actionIcon"/>
                        </Popconfirm>&nbsp;
                        
                        <Popconfirm
                            title="下载收车图片"
                            description="是否确定下载?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDownloadPhoto(record,'car')}
                        >
                            <CaretDownOutlined title='下载收车图片' className="actionIcon"/>
                        </Popconfirm>&nbsp;

                        <Popconfirm
                            title="下载装箱图片"
                            description="是否确定下载?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDownloadPhoto(record,'container')}
                        >
                            <DownSquareOutlined   title='下载装箱图片' className="actionIcon"/>
                        </Popconfirm>&nbsp;
                        <Popconfirm
                            title="下载装箱方案"
                            description="是否确定下载?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDownloadSolution(record)}
                        >
                            <BorderBottomOutlined title='下载装箱方案' className="actionIcon"/>
                        </Popconfirm>
                        
                    </div>
                )}
        },
    {
        title: '客户名',
        dataIndex: 'customerName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.customerName > b.customerName? 1 : -1,
    },
    {
        title: '批次号',
        dataIndex: 'shipmentNumber',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.shipmentNumber > b.shipmentNumber? 1 : -1,
    },
    {
        title: '装箱周期',
        dataIndex: 'shipmentNumber',
        showSorterTooltip: false,        
        render: (_: any, record: any)=> {
            return (
                <div>                        
                   {record.loadingPeriodStart +' 到 '+ record.loadingPeriodEnd} 
                </div>  
            )}
    },    
    {
        title: '支架是否循环',
        dataIndex: 'shipmentNumber',
        showSorterTooltip: false,        
        render: (_: any, record: any)=> {
            return (
                <div>
                        {
                            record.isRecyclable == 1 ? '无支架' : (record.isRecyclable == 2 ? '循环' : '一次性')
                        }
                        
                    </div>
            )}
    },   
    {
        title: '装箱团队',
        dataIndex: 'teamName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.trainNumber > b.trainNumber? 1 : -1,
    },
    {
        title: '照片审核人',
        dataIndex: 'imageAuditorName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.trainNumber > b.trainNumber? 1 : -1,
    },
    {
        title: '审核状态',
        dataIndex: 'planStatus',
        showSorterTooltip: false,
        render: (_: any, record: any)=> {
            if (record.planStatus == 'Finished') {
                return (
                    <div>
                        已完成
                    </div>
                )
            }else{
                return (
                    <div>
                        未完成
                    </div>
                )
            }
        }
    },
    {
        title: '装箱进度(完成数/总数)',
        dataIndex: 'progress',
        showSorterTooltip: false, 
    },
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
        carCount1: 0,
        carCount2: 0,
        carCount3: 0,

    };
    allPermissions: any[] = [];
    
    editRecord = this.createShipmentPlan();
    originalEditRecords: any = {};
    customerList: any[] = []; 
    factoryList: any[] = [];     
    factories = "";        
    allFactory: any[] = [];     
    stationList: any[] = []; 
    solutionList: any[] = []; 
    containerLoadingTypeList: any[] = []; 
    loadingTeamList: any[] = []; 
    kxcdList: any[] = []; 
    zxcdList: any[] = []; 
    auditorList: any[] = []; 
    recyclableList: any[] = [{label:'无支架', value: 1},{label:'循环', value: 2},{label:'一次性', value: 3}]; 
    statusList: any[] = [{label:'已完成', value: 'Finished'},{label:'未完成', value: 'not-Finished'}]; 
    basicInfoCanBeSaved = false;   

    createShipmentPlan() {
        return { 
            planId : 0,
            customerId : 0,
            customerName : "",
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            shipmentNumber: "",
            trainNumber: "",
            shipmentDate: "",  

            dayJsshipmentDate : dayjs(dayjs(new Date()), dateFormat),    
            dayJsreceivePeriodStart: dayjs(dayjs(new Date()), dateFormat),    
            dayJsreceivePeriodEnd: dayjs(dayjs(new Date()), dateFormat),    
            dayJsloadingPeriodStart: dayjs(dayjs(new Date()), dateFormat),    
            dayJsloadingPeriodEnd: dayjs(dayjs(new Date()), dateFormat),    
            dayJstzPeriodStart:  dayjs(dayjs(new Date()), dateFormat),      
            dayJstzPeriodEnd:  dayjs(dayjs(new Date()), dateFormat),      
            dayJstkPeriodStart:  dayjs(dayjs(new Date()), dateFormat),     
            dayJstkPeriodEnd:  dayjs(dayjs(new Date()), dateFormat),     

            containerCount: 0,
            carCount: 0,
            bracketCount: 0,
            isRecyclable: 1,
            factories: "",            
            loadingType1:"",
            loadingType2:"",
            loadingType3:"",
            loadingTypeCount1: 0,
            loadingTypeCount2: 0,
            loadingTypeCount3: 0,
            loadingTypeBracketInfo1:"",
            loadingTypeBracketInfo2:"",
            loadingTypeBracketInfo3:"",
            loadingTeamId: 0,
            stationId : 0,

           receivePeriodStart:"",
           receivePeriodEnd:"",
           loadingPeriodStart:"",
           loadingPeriodEnd:"",

            tkTeamId : 0,
            tkTeamName : "",
            tkPeriodStart: "",   
            tkPeriodEnd: "",   
            tzTeamId : 0,
            tzTeamName : "",
            tzPeriodStart: "",    
            tzPeriodEnd: "",   
            destinationStation: "",            
            reloadingStation: "",           
           
            stationName : "",
            teamName: "",     
            progress:0,            
            imageAuditorId: 0,
            loadingSolutionId: 0
        };
    }
    
    copyShipmentPlan(ShipmentPlan: any) {
        return { 
		    planId : ShipmentPlan.planId,
            customerId : ShipmentPlan.customerId,
            customerName : ShipmentPlan.customerName,
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            shipmentNumber: ShipmentPlan.shipmentNumber,
            trainNumber: ShipmentPlan.trainNumber,
            shipmentDate: ShipmentPlan.shipmentDate,
			dayJsshipmentDate : ShipmentPlan.shipmentDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(ShipmentPlan.shipmentDate, dateFormat),     
            dayJsreceivePeriodStart: dayjs(ShipmentPlan.receivePeriodStart, dateFormat),   
            dayJsreceivePeriodEnd: dayjs(ShipmentPlan.receivePeriodEnd, dateFormat),   
            dayJsloadingPeriodStart: dayjs(ShipmentPlan.loadingPeriodStart, dateFormat),   
            dayJsloadingPeriodEnd: dayjs(ShipmentPlan.loadingPeriodEnd, dateFormat),   
            dayJstzPeriodStart: dayjs(ShipmentPlan.tzPeriodStart, dateFormat),    
            dayJstzPeriodEnd: dayjs(ShipmentPlan.tzPeriodEnd, dateFormat),   
            dayJstkPeriodStart: dayjs(ShipmentPlan.tkPeriodStart, dateFormat),   
            dayJstkPeriodEnd: dayjs(ShipmentPlan.tkPeriodEnd, dateFormat),    
            containerCount: ShipmentPlan.containerCount,
            carCount: ShipmentPlan.carCount,
            bracketCount: ShipmentPlan.bracketCount,
            isRecyclable: ShipmentPlan.isRecyclable,
            factories: ShipmentPlan.factories,            
            loadingType1:ShipmentPlan.loadingType1,
            loadingType2:ShipmentPlan.loadingType2,
            loadingType3:ShipmentPlan.loadingType3,
            loadingTypeCount1: ShipmentPlan.loadingTypeCount1,
            loadingTypeCount2: ShipmentPlan.loadingTypeCount2,
            loadingTypeCount3: ShipmentPlan.loadingTypeCount3,
            loadingTypeBracketInfo1:ShipmentPlan.loadingTypeBracketInfo1,
            loadingTypeBracketInfo2:ShipmentPlan.loadingTypeBracketInfo2,
            loadingTypeBracketInfo3:ShipmentPlan.loadingTypeBracketInfo3,
            loadingTeamId:ShipmentPlan.loadingTeamId,
            receivePeriodStart:ShipmentPlan.receivePeriodStart,
            receivePeriodEnd:ShipmentPlan.receivePeriodEnd,
            loadingPeriodStart:ShipmentPlan.loadingPeriodStart,
            loadingPeriodEnd:ShipmentPlan.loadingPeriodEnd,
            stationId : ShipmentPlan.stationId,
            tkTeamId : ShipmentPlan.tkTeamId,
            tkTeamName : ShipmentPlan.tkTeamName,
            tkPeriodStart: ShipmentPlan.tkPeriodStart,
            tkPeriodEnd: ShipmentPlan.tkPeriodEnd, 
            tzTeamId : ShipmentPlan.tzTeamId,
            tzTeamName : ShipmentPlan.tzTeamName,
            tzPeriodStart: ShipmentPlan.tzPeriodStart,  
            tzPeriodEnd: ShipmentPlan.tzPeriodEnd, 
            destinationStation: ShipmentPlan.destinationStation,         
            reloadingStation: ShipmentPlan.reloadingStation,   
            stationName : ShipmentPlan.stationName,
            teamName: ShipmentPlan.teamName,     
            progress:ShipmentPlan.progress,            
            imageAuditorId: ShipmentPlan.imageAuditorId,
            loadingSolutionId: ShipmentPlan.loadingSolutionId==0?null:ShipmentPlan.loadingSolutionId,
        };
       
    }

    // onSearch = (value: any) => {
    //     this.searchText.text = value.target.value;
    //     this.search();
    // }
    onSearchShipmentNumber = (value: any) => {
        this.searchText.shipmentNumber = value.target.value;
        this.search();
    }

    onSearchCustomerName = (value: any) => {
        this.searchText.customerName = value.target.value;
        this.search();
    }

    onSearchTeamName = (value: any) => {
        this.searchText.teamName = value.target.value;
        this.search();
    }
    
    search = () => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => {
                if (this.searchText.status != ''  && item.planStatus !== this.searchText.status) {
                    return false;
                }
                if (this.searchText.isRecyclable != ''  && item.isRecyclable !== this.searchText.isRecyclable) {
                    return false;
                }
                if (this.searchText.shipmentNumber != '' && !item.shipmentNumber?.toLowerCase().includes(this.searchText.shipmentNumber?.toLowerCase())) {
                    return false;
                }      
                
                if (this.searchText.customerName != ''  && !item.customerName?.toLowerCase().includes(this.searchText.customerName?.toLowerCase())) {
                    return false;
                }               
                if (this.searchText.teamName != ''  && !item.teamName?.toLowerCase().includes(this.searchText.teamName?.toLowerCase())) {
                    return false;
                } 
                return true;
            }
            )
          })
    }

    searchText = {        
        status: '',
        shipmentNumber:'',     
        customerName:'',  
        teamName:'',  
        isRecyclable:'',  
    }


    changeSelectSearch = (field: string, event: any) => {
        (this.searchText as any)[field] = event
        this.search();
    }  

    onEdit = (record: any) => {        
        this.originalEditRecords = this.copyShipmentPlan(record);        
        this.editRecord = this.copyShipmentPlan(record);
        this.countCarNumber();
        this.basicInfoCanBeSaved = false;
        this.setState({isEdit: true});        
        this.factoryList = this.editRecord.factories?.split(',');
    }
    onDelete = (record: any) => {
        const ShipmentPlan = {planId: record.planId, customerName: record.customerName, bracketType: record.bracketType, unitCost: record.unitCost};
        axiosInstance.post('/logistics/deleteShipmentPlan', ShipmentPlan).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createShipmentPlan();
        this.originalEditRecords = this.createShipmentPlan();
        this.basicInfoCanBeSaved = false;
        this.setState({isEdit: true});
    }
    
    onExport = (record: any) => {
        this.setState({isLoaded: false});
        axiosInstance.get('/logistics/exportShipmentPlan', {params: {planId: record.planId},responseType: 'blob'}).then(
            response => {
                this.setState({isLoaded: true});
                const blob = new Blob([response.data], { type: 'application/zip' });
                const fileName = record.shipmentNumber + '明细.zip';
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                message.success('导出成功!');
            },
            error => {
                displayErrorMessage(error, '导出失败!');
            }
        );

    }
    
    onDownloadPhoto = (record: any, fileType:String) => {        
        this.setState({isLoaded: false});
        axiosInstance.get('/logistics/downloadShipmentPlanPhoto', {params: {planId: record.planId, downloadType: fileType },responseType: 'blob'}).then(
            response => {
                this.setState({isLoaded: true});
                const blob = new Blob([response.data], { type: 'application/zip' });
                const fileName = fileType == 'car' ? record.shipmentNumber + '收车照.zip': record.shipmentNumber + '装箱照.zip';
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                message.success('下载图片成功!');
            },
            error => {
                displayErrorMessage(error, '下载图片失败!');
            }
        );

    }

    onDownloadSolution = (record: any) => {              
        if(record.loadingSolutionDocURL==null){
            message.success('该计划没有指定装箱方案，请联系管理员!');
        }else{
            this.setState({isLoaded: false});
            axiosInstance.get('/logistics/downloadShipmentPlanSolution', {params: {planId: record.planId,fileType:'doc'},responseType: 'blob'}).then(
            response => {
                this.setState({isLoaded: true});
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                const fileName = record.shipmentNumber + record.loadingSolutionDocURL;
                const objectUrl = URL.createObjectURL(blob); 
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                message.success('下载成功!');
            },
            error => {
                displayErrorMessage(error, '下载失败!');
            }
        );
        }  
        
    }
    saveBasicInfo = () => {
        const ShipmentPlan = this.copyShipmentPlan(this.editRecord);        
        if (ShipmentPlan.planId === 0) {
            axiosInstance.post('/logistics/insertShipmentPlan', ShipmentPlan).then(
                response => {
                    ShipmentPlan.planId = response.data;
                    this.onEdit(ShipmentPlan);
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateShipmentPlan', ShipmentPlan).then(
                response => {
                    // this.init();
                    this.originalEditRecords = this.editRecord;
                    this.basicInfoCanBeSaved = false;
                    message.success('修改成功');
                    this.setState({});
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )

        }
    }
    cancel = () => {
        this.init();
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkBasicInfoCanBeSaved();
        this.countCarNumber();
    }
    changeSelect = (field: string, event: any) => {       
        (this.editRecord as any)[field] = event      
        this.checkBasicInfoCanBeSaved();
        this.countCarNumber();
    }
    countCarNumber(){
        if(this.editRecord.loadingType1 !== null && this.editRecord.loadingType1 !== '' && this.editRecord.loadingTypeCount1 !== null
        ){                                 
            this.setState({carCount1: Number(this.editRecord.loadingType1?.substring(0,1)) * this.editRecord.loadingTypeCount1})                        
        }
        if(this.editRecord.loadingType2 !== null && this.editRecord.loadingType2 !== '' && this.editRecord.loadingTypeCount3 !== null){                                 
            this.setState({carCount2: Number(this.editRecord.loadingType2?.substring(0,1)) * this.editRecord.loadingTypeCount2})                        
        }        
        if(this.editRecord.loadingType3 !== null && this.editRecord.loadingType3 !== '' && this.editRecord.loadingTypeCount3 !== null){                                 
            this.setState({carCount3: Number(this.editRecord.loadingType3?.substring(0,1)) * this.editRecord.loadingTypeCount3})                        
        } 
    }
    changeFactories = (value: any) => {
        this.factoryList = value;
        this.factories = this.factoryList.toString();
        this.editRecord.factories = this.factories       
        this.checkBasicInfoCanBeSaved();
    }

    changeshipmentDate = (date: any, dateString: string) => {
        this.editRecord.shipmentDate = dateString;
        this.checkBasicInfoCanBeSaved();
    }
    onChangeReceivePeriod: RangePickerProps['onChange'] = (dates, dateStrings) => {
        if (dates) {                    
          this.editRecord.receivePeriodStart = dateStrings[0];
          this.editRecord.receivePeriodEnd = dateStrings[1];         
          this.checkBasicInfoCanBeSaved();
        } 
      };
      
    onChangeLoadingPeriod: RangePickerProps['onChange'] = (dates, dateStrings) => {
        if (dates) {                    
          this.editRecord.loadingPeriodStart = dateStrings[0];
          this.editRecord.loadingPeriodEnd = dateStrings[1];          
          this.checkBasicInfoCanBeSaved();
        } 
      }; 

      onChangetkPeriod: RangePickerProps['onChange'] = (dates, dateStrings) => {
        if (dates) {                    
          this.editRecord.tkPeriodStart = dateStrings[0];
          this.editRecord.tkPeriodEnd = dateStrings[1];
          this.checkBasicInfoCanBeSaved();
        } 
      }; 

      onChangetzPeriod: RangePickerProps['onChange'] = (dates, dateStrings) => {
        if (dates) {                    
          this.editRecord.tzPeriodStart = dateStrings[0];
          this.editRecord.tzPeriodEnd = dateStrings[1];          
          this.checkBasicInfoCanBeSaved();
        } 
      }; 

    checkBasicInfoCanBeSaved() {
        console.log('this.editRecord.', this.editRecord);
        this.basicInfoCanBeSaved = false;
        if (this.editRecord.customerId !== null && this.editRecord.customerId !== 0 &&
            this.editRecord.shipmentNumber !== null && this.editRecord.shipmentNumber !== '' &&
            this.editRecord.trainNumber !== null && this.editRecord.trainNumber !== '' &&
            this.editRecord.shipmentDate !== null && this.editRecord.shipmentDate !== '' &&     
	        this.editRecord.containerCount !== null && this.editRecord.containerCount !== 0 &&    
			this.editRecord.carCount !== null && this.editRecord.carCount !== 0 &&    
			this.editRecord.bracketCount !== null && this.editRecord.bracketCount !== 0 &&    
			this.editRecord.loadingType1 !== null && this.editRecord.loadingType1 !== '' &&    			
			this.editRecord.loadingTypeCount1 !== null && this.editRecord.loadingTypeCount1 !== 0 &&    
			this.editRecord.loadingTypeBracketInfo1 !== null && this.editRecord.loadingTypeBracketInfo1 !== '' &&  
			this.editRecord.receivePeriodStart !== null && this.editRecord.receivePeriodStart !== '' && 
			this.editRecord.loadingPeriodStart !== null && this.editRecord.loadingPeriodStart !== '' && 
            this.editRecord.stationId !== null && this.editRecord.stationId !== 0 &&
            this.editRecord.loadingTeamId !== null && this.editRecord.loadingTeamId !== 0 &&
			this.editRecord.tkTeamId !== null && this.editRecord.tkTeamId !== 0 &&
			this.editRecord.tkPeriodStart !== null && this.editRecord.tkPeriodStart !== '' &&			
			this.editRecord.tzTeamId !== null && this.editRecord.tzTeamId !== 0 &&
			this.editRecord.tzPeriodStart !== null && this.editRecord.tzPeriodStart !== '' &&			
			this.editRecord.destinationStation !== null && this.editRecord.destinationStation !== '' &&
			this.editRecord.reloadingStation !== null && this.editRecord.reloadingStation !== '' &&						
            this.editRecord.imageAuditorId !== null && this.editRecord.imageAuditorId !== 0 &&
            this.editRecord.isRecyclable !== null && this.editRecord.isRecyclable !== 0 &&
            this.editRecord.factories !== null && this.editRecord.factories !== '' 
            ) {
            if (this.editRecord.customerId !== this.originalEditRecords.customerId ||        
                this.editRecord.shipmentNumber !== this.originalEditRecords.shipmentNumber ||        
                this.editRecord.trainNumber !== this.originalEditRecords.trainNumber ||        
                this.editRecord.shipmentDate !== this.originalEditRecords.shipmentDate ||        
                this.editRecord.containerCount !== this.originalEditRecords.containerCount ||        
                this.editRecord.carCount !== this.originalEditRecords.carCount ||        
                this.editRecord.bracketCount !== this.originalEditRecords.bracketCount ||        
                this.editRecord.isRecyclable !== this.originalEditRecords.isRecyclable ||     
                this.editRecord.factories !== this.originalEditRecords.factories ||    
                this.editRecord.loadingType1 !== this.originalEditRecords.loadingType1 ||        
                this.editRecord.loadingType2 !== this.originalEditRecords.loadingType2 ||        
                this.editRecord.loadingType3 !== this.originalEditRecords.loadingType3 ||        
                this.editRecord.loadingTypeCount1 !== this.originalEditRecords.loadingTypeCount1 ||        
                this.editRecord.loadingTypeCount2 !== this.originalEditRecords.loadingTypeCount2 ||        
                this.editRecord.loadingTypeCount3 !== this.originalEditRecords.loadingTypeCount3 ||        
                this.editRecord.loadingTypeBracketInfo1 !== this.originalEditRecords.loadingTypeBracketInfo1 ||        
                this.editRecord.loadingTypeBracketInfo2 !== this.originalEditRecords.loadingTypeBracketInfo2 ||        
                this.editRecord.loadingTypeBracketInfo3 !== this.originalEditRecords.loadingTypeBracketInfo3 ||        
                this.editRecord.loadingTeamId !== this.originalEditRecords.loadingTeamId ||        
                this.editRecord.receivePeriodStart !== this.originalEditRecords.receivePeriodStart ||        
                this.editRecord.receivePeriodEnd !== this.originalEditRecords.receivePeriodEnd ||        
                this.editRecord.loadingPeriodStart !== this.originalEditRecords.loadingPeriodStart ||        
                this.editRecord.loadingPeriodEnd !== this.originalEditRecords.loadingPeriodEnd ||        
                this.editRecord.stationId !== this.originalEditRecords.stationId ||        
                this.editRecord.tkTeamId !== this.originalEditRecords.tkTeamId ||        
                this.editRecord.tkPeriodStart !== this.originalEditRecords.tkPeriodStart ||        
                this.editRecord.tkPeriodEnd !== this.originalEditRecords.tkPeriodEnd ||        
                this.editRecord.tzTeamId !== this.originalEditRecords.tzTeamId ||        
                this.editRecord.tzPeriodStart !== this.originalEditRecords.tzPeriodStart ||        
                this.editRecord.tzPeriodEnd !== this.originalEditRecords.tzPeriodEnd ||        
                this.editRecord.destinationStation !== this.originalEditRecords.destinationStation ||        
                this.editRecord.reloadingStation !== this.originalEditRecords.reloadingStation ||        
                this.editRecord.imageAuditorId !== this.originalEditRecords.imageAuditorId     ||     
                this.editRecord.loadingSolutionId !== this.originalEditRecords.loadingSolutionId     
                ) {
                    this.basicInfoCanBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllShipmentPlans();
        this.getAllCustomers();
        this.getAllStations();
        this.getContainerLoadingTypes()
        this.getAllLoadingTeams();
        this.getAllUsers();        
        this.getAllSolutions();
    }
    getAllShipmentPlans() {   
        axiosInstance.get('/logistics/getAllShipmentPlans').then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].planId;                    
                    if(this.originalData[i].planStatus?.length==0 || this.originalData[i].planStatus==null){
                        this.originalData[i].planStatus = 'not-Finished'                        
                    }
                }
                this.setState({dataSource: this.originalData, isLoaded: true});  
                this.searchText.status = 'not-Finished';
                this.search();
            },
            error => {
                displayErrorMessage(error, '获取装箱计划失败');
            }
        )

    }
    getAllCustomers() {
        axiosInstance.get('/logistics/getAllCustomers').then(
            response => {
                this.customerList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.customerList.push({label:response.data[i].customerName, value: response.data[i].customerId});               
                }
            },
            error => {
                displayErrorMessage(error, 'Get document list failed!');
            }
        )
    }
   
    getAllUsers() {
        axiosInstance.get('/logistics/getAllUsers').then(
            response => {
                this.auditorList= [];
                this.allFactory= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.auditorList.push({label:response.data[i].userName, value: response.data[i].userId});      
                    if(response.data[i].permissions?.includes('主机厂')){
                        this.allFactory.push({label:response.data[i].userName, value: response.data[i].userName});   
                    }
                }
            },
            error => {
                displayErrorMessage(error, 'Get document list failed!');
            }
        )
    }
    getAllStations() {
        axiosInstance.get('/logistics/getAllStations').then(
            response => {
                this.stationList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.stationList.push({label:response.data[i].stationName, value: response.data[i].stationId});
                }
            },
            error => {
                displayErrorMessage(error, 'Get document list failed!');
            }
        )
    }

    getAllSolutions() {
        axiosInstance.get('/logistics/getAllSolutions').then(
            response => {
                this.solutionList = [{label:'请选择', value: 0}];
                for(let i = 0; i < response.data.length; i++) {
                    this.solutionList.push({label:response.data[i].name, value: response.data[i].id});
                }                
            },
            error => {
                displayErrorMessage(error, 'Get solution List failed!');
            }
        )
    }
    getContainerLoadingTypes() {
        axiosInstance.get('/logistics/getContainerLoadingTypes').then(
            response => {
                this.containerLoadingTypeList= [{label:'请选择', value: ''}];
                console.log(response.data)
                for(let i = 0; i < response.data.length; i++) {
                    this.containerLoadingTypeList.push({label:response.data[i], value: response.data[i]});
                }
            },
            error => {
                displayErrorMessage(error, 'Get Container Loading Type failed!');
            }
        )
    }
    getAllLoadingTeams() {
        axiosInstance.get('/logistics/getAllLoadingTeams').then(
            response => {
                this.loadingTeamList= [];
                this.kxcdList= [];
                this.zxcdList= [];
                for(let i = 0; i < response.data.length; i++) {
                    if(response.data[i].teamType.includes('zxtd')){//装箱团队
                        this.loadingTeamList.push({label:response.data[i].teamName, value: response.data[i].loadingTeamId});
                    }                    
                    if(response.data[i].teamType.includes('kxcd')){//空箱车队
                        this.kxcdList.push({label:response.data[i].teamName, value: response.data[i].loadingTeamId});                        
                    }
                    if(response.data[i].teamType.includes('zxcd')){//重箱车队
                        this.zxcdList.push({label:response.data[i].teamName, value: response.data[i].loadingTeamId});
                    }                    
                }                
            },
            error => {
                displayErrorMessage(error, 'Get document list failed!');
            }
        )
    }
    render() {        
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin') && !roles.includes('客户')  && !roles.includes('主机厂') ) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 装箱计划管理</span>
                </div>
                <div className="seperate">
                </div>
                
                
                {this.state.isEdit? 
                    <div className="content">
                    <div>
                        <Button className="header-right" type="primary" onClick={this.cancel}>退出</Button>
                    </div>
                        <Tabs defaultActiveKey="1" >
                            <TabPane tab='基本信息' key = '1'>
                            <>

                            <Card size="small" style={{ width: '60%' }}>    
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">客户名:</label>
                                        <Select className="box-input"  options={this.customerList} onChange={this.changeSelect.bind(this, 'customerId')} defaultValue={this.editRecord.customerName}/> 
                                        <label className="box-label">发运日:</label>
                                        {(this.editRecord.shipmentNumber + "") == "" ?
                                            <DatePicker className="box-input" onChange={this.changeshipmentDate} />
                                            :
                                            <DatePicker className="box-input" onChange={this.changeshipmentDate} defaultValue={this.editRecord.dayJsshipmentDate} />}                
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="box">
                                    <label className="box-label">批次号:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.shipmentNumber} onChange={this.changeInput.bind(this, 'shipmentNumber')} />  
                                        <label className="box-label">班列号:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.trainNumber} onChange={this.changeInput.bind(this, 'trainNumber')} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">集装箱数量:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.containerCount} onChange={this.changeInput.bind(this, 'containerCount')} />
                                        <label className="box-label">车辆数量:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.carCount} onChange={this.changeInput.bind(this, 'carCount')} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">支架数量:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.bracketCount} onChange={this.changeInput.bind(this, 'bracketCount')} />
                                        <label className="box-label">支架可循环:</label>
                                        <Select className="box-input"  options={this.recyclableList} onChange={this.changeSelect.bind(this, 'isRecyclable')} defaultValue={this.editRecord.isRecyclable} />                                 

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="box">                                        
                                        <label className="box-label">主机厂:</label>                                        
                                        <Select mode="multiple" allowClear className="box-input"  placeholder="请选择" defaultValue={this.factoryList} onChange={this.changeFactories} options={this.allFactory}
                                        />                                         
                                    </div>
                                </div>
                                </Card>
                                <Card size="small" style={{ width: '60%' }}>    
                                <div className="row">
                                    <div className="box">                                        
                                        <label className="box-label">装箱规格①:</label>
                                        <Select className="box-input" style={{ width: '172px' }} options={this.containerLoadingTypeList} onChange={this.changeSelect.bind(this, 'loadingType1')} defaultValue={this.editRecord.loadingType1}/> 
                                        <Input className="box-input" style={{ width: '50px' }} defaultValue={this.editRecord.loadingTypeCount1} onChange={this.changeInput.bind(this, 'loadingTypeCount1')} />箱
                                        <Input className="box-input" style={{ width: '50px' }} value={this.state.carCount1}  />台
                                        <label className="box-label">支架信息①:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.loadingTypeBracketInfo1} onChange={this.changeInput.bind(this, 'loadingTypeBracketInfo1')} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="box">                                        
                                        <label className="box-label">装箱规格②:</label>
                                        <Select className="box-input"  style={{ width: '172px' }} options={this.containerLoadingTypeList} onChange={this.changeSelect.bind(this, 'loadingType2')} defaultValue={this.editRecord.loadingType2}/> 
                                        <Input className="box-input" style={{ width: '50px' }} defaultValue={this.editRecord.loadingTypeCount2} onChange={this.changeInput.bind(this, 'loadingTypeCount2')} />箱
                                        <Input className="box-input" style={{ width: '50px' }} value={this.state.carCount2}  />台
                                        <label className="box-label">支架信息②:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.loadingTypeBracketInfo2} onChange={this.changeInput.bind(this, 'loadingTypeBracketInfo2')} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="box">                                        
                                        <label className="box-label">装箱规格③:</label>
                                        <Select className="box-input" style={{ width: '172px' }} options={this.containerLoadingTypeList} onChange={this.changeSelect.bind(this, 'loadingType3')} defaultValue={this.editRecord.loadingType3}/> 
                                        <Input className="box-input" style={{ width: '50px' }} defaultValue={this.editRecord.loadingTypeCount3} onChange={this.changeInput.bind(this, 'loadingTypeCount3')} />箱
                                        <Input className="box-input" style={{ width: '50px' }} value={this.state.carCount3} />台
                                        <label className="box-label">支架信息③:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.loadingTypeBracketInfo3} onChange={this.changeInput.bind(this, 'loadingTypeBracketInfo3')} />
                                    </div>
                                </div>
                                </Card>
                                <Card size="small" style={{ width: '60%' }}>    
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">装箱团队:</label>
                                        <Select className="box-input"  options={this.loadingTeamList} onChange={this.changeSelect.bind(this, 'loadingTeamId')} defaultValue={this.editRecord.teamName}/> 
                                        <label className="box-label">装箱堆场:</label>
                                        <Select className="box-input"  options={this.stationList} onChange={this.changeSelect.bind(this, 'stationId')} defaultValue={this.editRecord.stationName}/> 
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">收验周期:</label>       
                                        {this.editRecord.receivePeriodStart?.length == 0 ?                                            
                                            <RangePicker   onChange={this.onChangeReceivePeriod }  format="YYYY-MM-DD" style={{ width: '300px' }}/>                                            
                                            :                                            
                                            <RangePicker  onChange={this.onChangeReceivePeriod }  defaultValue={[this.editRecord.dayJsreceivePeriodStart, this.editRecord.dayJsreceivePeriodEnd]} style={{ width: '300px' }}/>
                                        }
                                        <label className="box-label">装箱周期:</label>               
                                        {this.editRecord.loadingPeriodStart?.length == 0 ? 
                                            <RangePicker onChange={this.onChangeLoadingPeriod}  style={{ width: '30%' }} />
                                            :                                            
                                            <RangePicker onChange={this.onChangeLoadingPeriod}  defaultValue={[this.editRecord.dayJsloadingPeriodStart, this.editRecord.dayJsloadingPeriodEnd]} style={{ width: '30%' }} />
                                        }
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">空箱车队:</label>                                        
                                        <Select className="box-input"  options={this.kxcdList} onChange={this.changeSelect.bind(this, 'tkTeamId')} defaultValue={this.editRecord.tkTeamName}/> 
                                        <label className="box-label">提空周期:</label>        
                                        {this.editRecord.tkPeriodStart?.length == 0 ?
                                           <RangePicker onChange={this.onChangetkPeriod} style={{ width: '30%' }}   />
                                            :                                            
                                           <RangePicker onChange={this.onChangetkPeriod} defaultValue={[this.editRecord.dayJstkPeriodStart, this.editRecord.dayJstkPeriodEnd]} style={{ width: '30%' }}   />
                                        } 
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">重箱车队:</label>                                        
                                        <Select className="box-input"  options={this.zxcdList} onChange={this.changeSelect.bind(this, 'tzTeamId')} defaultValue={this.editRecord.tzTeamName}/> 
                                        <label className="box-label">提重周期:</label>        
                                        {this.editRecord.tzPeriodStart?.length == 0 ?
                                            <RangePicker onChange={this.onChangetzPeriod}  style={{ width: '30%' }}   />
                                            :                                            
                                            <RangePicker onChange={this.onChangetzPeriod}  defaultValue={[this.editRecord.dayJstzPeriodStart, this.editRecord.dayJstzPeriodEnd]}  style={{ width: '30%' }}   />
                                        }         
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">目的站:</label>                                        
                                        <Input className="box-input" defaultValue={this.editRecord.destinationStation} onChange={this.changeInput.bind(this, 'destinationStation')} />  
                                        <label className="box-label">换装口岸:</label>                                        
                                        <Input className="box-input" defaultValue={this.editRecord.reloadingStation} onChange={this.changeInput.bind(this, 'reloadingStation')} />  
                                    </div>
                                </div>
                                </Card>
                                <Card size="small" style={{ width: '60%' }}>    
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">照片审核人:</label>
                                        <Select className="box-input"  options={this.auditorList} onChange={this.changeSelect.bind(this, 'imageAuditorId')} defaultValue={this.editRecord.imageAuditorId}/>                                        
                                    </div>
                                    <div className="box">
                                        <label className="box-label">装箱方案:</label>
                                        <Select className="box-input"  options={this.solutionList} placeholder="请选择"  onChange={this.changeSelect.bind(this, 'loadingSolutionId')} defaultValue={this.editRecord.loadingSolutionId}/>                                        
                                    </div>                                    
                                </div>                                
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">装箱进度(完成数/总数):</label>
                                        <label >{this.editRecord.progress}</label>                                               
                                    </div>
                                </div>
                                </Card>
                                <br/>
                                <div>
                                    <Button className="header-right" type="primary" onClick={this.saveBasicInfo} disabled={!this.basicInfoCanBeSaved}>保存</Button>
                                </div>
                            </>,
                            </TabPane>
                            <TabPane tab='支架信息' key = '2' disabled={this.editRecord.planId == 0}>
                                <ShipmentBracket planId={this.editRecord.planId} />
                            </TabPane>
                            <TabPane tab='耗材信息' key = '3' disabled={this.editRecord.planId == 0}>
                                <ShipmentConsumables planId={this.editRecord.planId} stationId={this.editRecord.stationId}/>
                            </TabPane>
                            <TabPane tab='集装箱信息' key = '4' disabled={this.editRecord.planId == 0}>
                                <Container planId={this.editRecord.planId} />
                            </TabPane>
                            <TabPane tab='车架信息' key = '5' disabled={this.editRecord.planId == 0}>
                                <Car planId={this.editRecord.planId} />
                            </TabPane>
                            <TabPane tab='状态跟踪信息' key = '7' disabled={this.editRecord.planId == 0}>
                                <ShipmentStatusLog planId={this.editRecord.planId} />
                            </TabPane>                            
                            <TabPane tab='收款记录' key = '6' disabled={this.editRecord.planId == 0}>
                                <ShipmentPayment planId={this.editRecord.planId} />
                            </TabPane>
                            <TabPane tab='费用明细' key = '8' disabled={this.editRecord.planId == 0}>
                                <AdditionalExpense planId={this.editRecord.planId} />
                            </TabPane>
                            <TabPane tab='付款记录' key = '10' disabled={this.editRecord.planId == 0}>
                                <ShipmentCost planId={this.editRecord.planId} />
                            </TabPane>                              
                            <TabPane tab='场地操作' key = '9' disabled={this.editRecord.planId == 0}>
                                <AdditionalCost planId={this.editRecord.planId} />
                            </TabPane>
                        </Tabs>
                    </div>
                :
                    <div className="content">
                        <label className="box-label">完成状态:</label>
                        <Select style={{ width: '172px' }} options={[{label:'--全部--', value: ''},...this.statusList]} defaultValue='not-Finished' onChange={this.changeSelectSearch.bind(this, 'status')}/> 

                        <label>&nbsp;&nbsp;客户名称:</label>                        
                        <Input onChange={this.onSearchCustomerName}  className="tableSearch" value={this.searchText.customerName} /> 

                        <label>批次号:</label>                        
                        <Input onChange={this.onSearchShipmentNumber}  className="tableSearch" value={this.searchText.shipmentNumber} /> 
                        
                        <label>装箱团队:</label>                        
                        <Input onChange={this.onSearchTeamName}  className="tableSearch" value={this.searchText.teamName} /> 

                        <label>支架是否循环:</label>    
                        <Select style={{ width: '172px' }} options={[{label:'--全部--', value: ''},...this.recyclableList]} onChange={this.changeSelectSearch.bind(this, 'isRecyclable')}/> 
                        
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>                        
                        <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}

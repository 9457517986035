import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined, UploadOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';
import Upload, { RcFile } from 'antd/es/upload';


const PHOTOTYPELIST = [
    {
        label: '收箱照',
        value: 'receiveContainer',
    },
    {
        label: '送箱照',
        value: 'sendContainer',
    }
]

const planList: any[] = [];

export default class BatchUpload extends React.Component {
    state = {
        uploadedFileName: '',
    };
    editRecord = this.createUploadPicture();

    file: RcFile | undefined;
    isLoadFile = 'false';
    
    uploadProps = {
        beforeUpload: (file: any) => {
            this.isLoadFile = 'true';
            this.file = file as RcFile;
            this.setState({uploadedFileName: this.file?.name});
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.editRecord.fileData = reader.result + "";
                this.checkCanBeSaved();
            }
            return false;
        },
        showUploadList: false,
    };
    allPermissions: any[] = [];
    
    canBeSaved = false;
    
    createUploadPicture() {
        return { 
            planId : '',
            photoType : 'receiveContainer',        
            fileData: '',
        };
    }
    save = () => {
        
        upload(this.editRecord.planId, this.editRecord.photoType, this.file as RcFile).then(
            response => {
                message.success('Upload picture successful!');
            },
            error => {
                displayErrorMessage(error, 'Upload picture failed!');
            }
        );
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.planId !== null && this.editRecord.planId !== '' &&
            this.editRecord.photoType !== null && this.editRecord.photoType !== '' &&
            this.editRecord.fileData !== null && this.editRecord.fileData !== '') {
            this.canBeSaved = true;
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getActivePlan();
    }

    getActivePlan() {
        axiosInstance.get('/logistics/getActiveShipmentPlans').then(
            response => {
                for(let i = 0; i < response.data.length; i++) {
                    planList.push({label:response.data[i].trainNumber, value: response.data[i].planId});
                }
            },
            error => {
                displayErrorMessage(error, 'Get plan list failed!');
            }
        )
    }

    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 上传图片</span>
                </div>
                <div className="seperate">
                </div>
                
                <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">班列号:</label>
                                <Select className="box-input"  options={planList} onChange={this.changeSelect.bind(this, 'planId')} /> 
                                <label className="box-label">图片类型:</label>
                                <Select className="box-input"  options={PHOTOTYPELIST} onChange={this.changeSelect.bind(this, 'photoType')} defaultValue={this.editRecord.photoType}/> 
                            </div>
                        </div>                      
                        <div className="row">
                            <div className="box">
                                <label className="box-label">*FILE:</label>
                                <Upload {...this.uploadProps}>
                                    <Button icon={<UploadOutlined />}>Select File</Button>
                                </Upload>
                                <Input className="box-input" value={this.state.uploadedFileName} disabled/> 
                            </div>
                        </div>
                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>上传</Button>
                        </div>
                    </div>
            </div>
        )      
    }
}

export async function upload (planId: string, photoType: string, file: RcFile) {        
	const formData = new FormData();
	formData.append('file', file);
	formData.append('planId', planId);
    formData.append('photoType', photoType);
console.log(formData);
	return axiosInstance.post('/logistics/batchUploadPhoto', formData, {
		headers: {
		  'Content-Type': 'multipart/form-data'
		}

	});
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DD';

export default class ExportStatusLog extends React.Component {
    props = {planId: 0, isView: false};
    constructor(props:any) {
        super(props);
      }
    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                    {
                        
                        this.props.isView?
                        '':
                        <div>
                            <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp; &nbsp;
                            <Popconfirm
                                title="删除状态跟踪信息"
                                description="是否确定删除?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => this.onDelete(record)}
                            >
                                <DeleteOutlined title='删除' className="actionIcon"/>
                            </Popconfirm>
                        </div>
                    }
                        
                    </div>
                )
            }
        },
    {
        title: '状态跟踪时间',
        dataIndex: 'logDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.logDate > b.logDate? 1 : -1,
    },
    {
        title: '备注',
        dataIndex: 'logComments',
        showSorterTooltip: false,
    },
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
    };
    allPermissions: any[] = [];
    
    editRecord = this.createExportStatusLog();
    originalEditRecords: any = {};
    canBeSaved = false;
    
    createExportStatusLog() {
        return { 
            id : 0,
            planId : this.props.planId,
            logDate : '',
            dayJsStatusLogDate : dayjs(dayjs(new Date()), dateFormat),
            logComments : '',
        };
    }
    
    copyExportStatusLog(ExportStatusLog: any) {
        return { 
            id : ExportStatusLog.id,
            planId : this.props.planId,
            logDate : ExportStatusLog.logDate,
            dayJsStatusLogDate :  ExportStatusLog.logDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(ExportStatusLog.logDate, dateFormat),
            logComments : ExportStatusLog.logComments,
        };
    }
    onDelete = (record: any) => {
        const ExportStatusLog = {id: record.id, planId: this.props.planId};
        axiosInstance.post('/logistics/deleteExportStatusLogById', ExportStatusLog).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createExportStatusLog();
        this.originalEditRecords = this.createExportStatusLog();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    onEdit = (record: any) => {
        this.originalEditRecords = this.copyExportStatusLog(record);
        this.editRecord = this.copyExportStatusLog(record);
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    save = () => {
        const ExportStatusLog= this.copyExportStatusLog(this.editRecord);
        if (ExportStatusLog.id === 0) {
            axiosInstance.post('/logistics/insertExportStatusLog', ExportStatusLog).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateExportStatusLog', ExportStatusLog).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }
    changeStatusLogDate = (date: any, dateString: string) => {
        this.editRecord.logDate = dateString;
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.logDate !== null && this.editRecord.logDate !== '' &&
            this.editRecord.logComments !== null && this.editRecord.logComments !== '') {
            if (this.editRecord.logDate !== this.originalEditRecords.logDate ||
                this.editRecord.logComments !== this.originalEditRecords.logComments) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getExportStatusLogByPlanId();
    }
    getExportStatusLogByPlanId() {
        axiosInstance.get('/logistics/getExportStatusLogByPlanId', {params: {planId: this.props.planId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].consumablesId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取状态跟踪信息失败');
            }
        )

    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="seperate">
                </div>
                
                
                {this.state.isEdit? 
                    <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">状态跟踪时间:</label>
                                    {(this.editRecord.logDate + "") == "" ?
                                        <DatePicker className="box-input" onChange={this.changeStatusLogDate} />
                                        :
                                        <DatePicker className="box-input" onChange={this.changeStatusLogDate} defaultValue={this.editRecord.dayJsStatusLogDate} />
                                    }
                                <label className="box-label">备注:</label>
                                <Input className="box-input" defaultValue={this.editRecord.logComments} onChange={this.changeInput.bind(this, 'logComments')} />
                            </div>
                        </div>
                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                        </div>
                    </div>
                :
                    <div className="content">
                        {
                            this.props.isView? '' :
                            <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>
                        }
                        
                        <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}

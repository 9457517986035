import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';

export default class Permission extends React.Component {
    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <Popconfirm
                            title="删除角色"
                            description="Are you sure to delete this permission?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除角色' className="actionIcon"/>
                        </Popconfirm>
                        
                    </div>
                )}
        },
        {
            title: '角色名',
            dataIndex: 'name',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.name > b.name? 1 : -1,
        },
    ];
    originalData: any[] = [];
    originalEditRecords: any = {};
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
    };
    id = 0;
    name = "";
    canBeSaved = false;
    onSearch = (value: any) => {
        this.setState({searchText: value.target.value})
        this.search(value.target.value);
    }
    search = (searchText: string) => {
        this.setState({
            dataSource: this.originalData.filter(item => (item.name.toLowerCase().includes(searchText.toLowerCase()))
            )
          })
    }
    onDelete = (record: any) => {
        const permission = {id: record.id, name: record.name};
        axiosInstance.post('/logistics/deletePermission', permission).then(
            response => {
                this.init();
                message.success('Delete permission successful!');
            },
            error => {
                displayErrorMessage(error, 'Delete permission failed: ' + error.response.data.message);
            }
        )
    }
    onAdd = () => {
        this.id = 0;
        this.name = '';
        this.originalEditRecords = {id: 0, name: ''};
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    
    save = () => {
        const permission = {id: this.id, name: this.name};
        
        axiosInstance.post('/logistics/insertPermission', permission).then(
            response => {
                this.init();
                message.success('Insert permission successful!');
            },
            error => {
                displayErrorMessage(error, 'Insert permission failed!');
            }
        )
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeName = (event: any) => {
        this.name = event.target.value;
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.name !== null && this.name !== '') {
            if (this.name !== this.originalEditRecords.name) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllPermissions();
    }
    getAllPermissions() {
        axiosInstance.get('/logistics/getAllPermissions').then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].id;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
                if (this.state.searchText !== '') {
                    this.search(this.state.searchText);
                }
            },
            error => {
                displayErrorMessage(error, 'Get permission list failed!');
            }
        )

    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 用户角色管理</span>
                </div>
                <div className="seperate">
                </div>
                
                
                {this.state.isEdit? 
                    <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">角色名:</label>
                                <Input className="box-input" onChange={this.changeName} defaultValue={this.name}/>                   
                            </div>
                        </div>
                        
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>Cancel</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>Save</Button>
                        </div>
                    </div>
                :
                    <div className="content">
                        <Input placeholder="输入要查询的字符" onChange={this.onSearch} className="tableSearch" value={this.state.searchText} /> 
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>
                        <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}
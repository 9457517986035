import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined,PlusOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';

const dateFormat = 'YYYY-MM-DD';

export default class ShipmentBracket extends React.Component {
    props = {shipmentPlanId: 0};
    constructor(props:any) {
        super(props);
      }
    
    columns = [
        {
            title: '集装箱号',
            dataIndex: 'containerNumber',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.containerNumber > b.containerNumber? 1 : -1,
        },
        {
            title: '支架号',
            dataIndex: 'bracketNumber',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.bracketNumber > b.bracketNumber? 1 : -1,
        },
    ];

    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        searchText: '',        
    };

    
    allPermissions: any[] = [];
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false,bracketInventoryList:[]});
        this.getBracketInventoryByPlanId();
    }
    getBracketInventoryByPlanId() {
        axiosInstance.get('/logistics/getShipmentBracketForExportPlanByPlanId', {params: {shipmentPlanId: this.props.shipmentPlanId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].bracketId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取支架失败');
            }
        )

    }

    onSearch = (value: any) => {
        this.setState({searchText: value.target.value})
        this.search(value.target.value);
    }
    search = (searchText: string) => {
        console.log('searchText',searchText);
        this.setState({
            dataSource: this.originalData.filter((item: any) => (
                    item.bracketNumber.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.containerNumber?.toLowerCase()?.includes(searchText.toLowerCase()) )
            )
          })
    }
    
    onExport = () => {
        this.setState({isLoaded: false});
        axiosInstance.get('/logistics/exportExportPlanBracket', {params: {shipmentPlanId: this.props.shipmentPlanId},responseType: 'blob'}).then(
            response => {
                this.setState({isLoaded: true});
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const fileName = 'exportPlanBracket.xlsx';
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                message.success('导出成功!');
            },
            error => {
                displayErrorMessage(error, '导出失败!');
            }
        );

    }

    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>              
                <div className="content">       
                        <Input placeholder="输入支架号" onChange={this.onSearch} className="tableSearch" value={this.state.searchText} />
                        <Button type="primary" className="header-right" onClick={this.onExport}>导出</Button>   
                        <Table  columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                </div>
            </div>
        )      
    }
}

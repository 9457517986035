import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined,PlusOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import store from '../../config/store';
import {setUnChanged} from '../../config/store/slice/returnPlanBracketReducer'

const dateFormat = 'YYYY-MM-DD';

 const FeeType = [
//     {label: '出口报关', value: 'exportFee'},
//     {label: '暂时进出口担保', value: 'tempGuaranteeCost'},
//     {label: '转关费', value: 'customsTransferCost'},
    {label: '境外代理', value: 'overseasAgency'},
    {label: '进口清关', value: 'customsClearanceCost'},
    // {label: '担保核销', value: 'guaranteeWriteOff'},
    {label: '内贸铁费用', value: 'other01'}, //new 
    {label: '下站吊转费', value: 'portHoistingFee'}, //旧版本是 陆港吊装费
    {label: '拆箱费', value: 'devanningFee'},
    {label: '还箱短驳费', value: 'returnContainerFee'},
    {label: '核放费用', value: 'releaseCharge'},
    {label: '装车费', value: 'loadingFee'},
    {label: '支架转运费', value: 'bracketTransferFee'},
    {label: '二保卡扣', value: 'protectionBuckleFee'},
    {label: '螺丝补充', value: 'screwSupplementFee'},
    {label: '支架维修费', value: 'bracketMaintainFee'},
    {label: '导轮', value: 'other02'}, //new 
    {label: '其他异常费用', value: 'other03'}, //new 
]

export default class ReturnBracket extends React.Component {
    props = {planId: 0, isView: false};
    constructor(props:any) {
        super(props);
    }
    
    columns = [
        {
            title: '原始班列号',
            dataIndex: 'originalShipmentNumber',
        },
        {
            title: '集装箱号',
            dataIndex: 'containerNumber',
        },
        {
            title: '支架号',
            dataIndex: 'bracketNumber',
        },       
        // {
        //     title: '出口报关',
        //     dataIndex: 'exportFee',
        //     showSorterTooltip: false,
        //     render: (_: any, record: any)=> {               
        //             return (
        //                 <div
        //                 onMouseEnter={this.handleMouseEnter(`${record.exportFeeVendorName}`)}
        //                 onMouseLeave={this.handleMouseLeave}>
        //                     {record.exportFee}
        //                 </div>
        //             )
                
        //     }
        // },             
        // {
        //     title: '暂时进出口担保',
        //     dataIndex: 'tempGuaranteeCost',
        //     showSorterTooltip: false,
        //     render: (_: any, record: any)=> {               
        //             return (
        //                 <div
        //                 onMouseEnter={this.handleMouseEnter(`${record.tempGuaranteeCostVendorName}`)}
        //                 onMouseLeave={this.handleMouseLeave}>
        //                     {record.tempGuaranteeCost}
        //                 </div>
        //             )
                
        //     }
        // },   
        // {
        //     title: '转关费',
        //     dataIndex: 'exportFee',
        //     showSorterTooltip: false,
        //     render: (_: any, record: any)=> {               
        //             return (
        //                 <div
        //                 onMouseEnter={this.handleMouseEnter(`${record.customsTransferCostVendorName}`)}
        //                 onMouseLeave={this.handleMouseLeave}>
        //                     {record.customsTransferCost}
        //                 </div>
        //             )
                
        //     }
        // },    
        {
            title: '境外代理',
            dataIndex: 'overseasAgency',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.overseasAgencyVendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.overseasAgency % 1 === 0 ? record.overseasAgency : record.overseasAgency.toFixed(2)}
                        </div>
                    )
                
            }
        },   
        {
            title: '进口清关',
            dataIndex: 'customsClearanceCost',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.customsClearanceCostVendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.customsClearanceCost % 1 === 0 ? record.customsClearanceCost : record.customsClearanceCost.toFixed(2)}
                        </div>
                    )
                
            }
        },   
        // {
        //     title: '担保核销',
        //     dataIndex: 'customsClearanceCost',
        //     showSorterTooltip: false,
        //     render: (_: any, record: any)=> {               
        //             return (
        //                 <div
        //                 onMouseEnter={this.handleMouseEnter(`${record.guaranteeWriteOffVendorName}`)}
        //                 onMouseLeave={this.handleMouseLeave}>
        //                     {record.guaranteeWriteOff}
        //                 </div>
        //             )
                
        //     }
        // }, 
        {
            title: '内贸铁费用',
            dataIndex: 'other01',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.other01VendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.other01 % 1 === 0 ? record.other01 : record.other01.toFixed(2)}
                        </div>
                    )
                
            }
        }, 
        {
            title: '下站吊转费',
            dataIndex: 'portHoistingFee',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.portHoistingFeeVendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.portHoistingFee % 1 === 0 ? record.portHoistingFee : record.portHoistingFee.toFixed(2)}
                        </div>
                    )
                
            }
        }, 
        {
            title: '拆箱费',
            dataIndex: 'devanningFee',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.devanningFeeVendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.devanningFee % 1 === 0 ? record.devanningFee : record.devanningFee.toFixed(2)}
                        </div>
                    )
                
            }
        }, 
        {
            title: '还箱短驳费',
            dataIndex: 'returnContainerFee',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.returnContainerFeeVendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.returnContainerFee % 1 === 0 ? record.returnContainerFee : record.returnContainerFee.toFixed(2)}
                        </div>
                    )
                
            }
        }, 
        {
            title: '核放费用',
            dataIndex: 'releaseCharge',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.releaseChargeVendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.releaseCharge % 1 === 0 ? record.releaseCharge : record.releaseCharge.toFixed(2)}
                        </div>
                    )
                
            }
        }, 
        {
            title: '装车费',
            dataIndex: 'loadingFee',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.loadingFeeVendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.loadingFee % 1 === 0 ? record.loadingFee : record.loadingFee.toFixed(2)}
                        </div>
                    )
                
            }
        }, 
        {
            title: '支架转运费',
            dataIndex: 'bracketTransferFee',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.bracketTransferFeeVendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.bracketTransferFee % 1 === 0 ? record.bracketTransferFee : record.bracketTransferFee.toFixed(2)}
                        </div>
                    )
                
            }
        }, 
        {
            title: '二保卡扣',
            dataIndex: 'protectionBuckleFee',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.protectionBuckleFeeVendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.protectionBuckleFee % 1 === 0 ? record.protectionBuckleFee : record.protectionBuckleFee.toFixed(2)}
                        </div>
                    )
                
            }
        }, 
        {
            title: '螺丝补充',
            dataIndex: 'screwSupplementFee',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.screwSupplementFeeVendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.screwSupplementFee % 1 === 0 ? record.screwSupplementFee : record.screwSupplementFee.toFixed(2)}
                        </div>
                    )
                
            }
        },  
        {
            title: '支架维修费',
            dataIndex: 'bracketMaintainFee',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.bracketMaintainFeeVendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.bracketMaintainFee % 1 === 0 ? record.bracketMaintainFee : record.bracketMaintainFee.toFixed(2)}
                        </div>
                    )
                
            }
        }, 
        {
            title: '导轮',
            dataIndex: 'other02',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.other02VendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.other02 % 1 === 0 ? record.other02 : record.other02.toFixed(2)}
                        </div>
                    )
                
            }
        }, 
        {
            title: '其他异常费用',
            dataIndex: 'other03',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div
                        onMouseEnter={this.handleMouseEnter(`${record.other03VendorName}`)}
                        onMouseLeave={this.handleMouseLeave}>
                            {record.other03 % 1 === 0 ? record.other03 : record.other03.toFixed(2)}
                        </div>
                    )
                
            }
        }, 

        {
            title: '合计',
            dataIndex: 'other03',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {               
                    return (
                        <div>
                            {(record.overseasAgency+record.customsClearanceCost+record.other01+
                                record.portHoistingFee+record.devanningFee+record.returnContainerFee+
                                record.releaseCharge+record.loadingFee+record.bracketTransferFee+
                                record.protectionBuckleFee+record.screwSupplementFee+
                                record.bracketMaintainFee+record.other02+record.other03).toFixed(2)
}
                        </div>
                    )
                
            }
        }, 
        
    ];

    handleMouseEnter = (a: any) => () => {
        this.setState({ tooltipText: a == 'null'?'':a });
      };
     
      handleMouseLeave = () => {
        this.setState({ tooltipText: '' });
      };

    originalData: any[] = [];
    oriTrainNumberStringList: string[] = [];
    oriTrainNumberList: any[] = [];
    containerNumberStringList: string[] = [];
    containerNumberList: any[] = [];
    vendorList: any[] = [];

    originalShipmentNumber: string = '';
    containerNumber: string = '';
    fee = 0;
    feeType = '';
    vendor = '';
    state = {
        isLoaded: false,
        dataSource: this.originalData,
        searchText: '',
        fee: '',
        tooltipText: ''
    };


    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {        
        this.selectedRows = selectedRows;
        this.checkCanBeSaved();
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };
    
    allPermissions: any[] = [];
    
    canBeSaved = false;

    saveSelected = () => {
        const costManagementUpdateModelList: any[] = [];
        this.state.dataSource.forEach(row => {
            this.selectedRows.forEach(element => {
                if (row.costId === element.costId) {
                    costManagementUpdateModelList.push({costId: element.costId, parameter: this.feeType, value: this.fee, vendorName:this.vendor});
                }
            })
        }) 

        axiosInstance.post('/logistics/updateCostManagement', costManagementUpdateModelList).then(
            response => {
                costManagementUpdateModelList.forEach(element => {
                    const newArr = this.selectedRows.filter(item => item.bracketId !== element.bracketId);
                    this.selectedRows = newArr;
                });
                this.init();
                this.checkCanBeSaved();
                message.success('更新成功');
            },
            error => {
                displayErrorMessage(error, '更新失败');
            }
        )
    }
    changeFeeType = (event: any) => {
        this.feeType = event;
        this.checkCanBeSaved();
    }
    changeVendor = (event: any) => {
        this.vendor = event;
        this.checkCanBeSaved();
    }

    changeFee = (event: any) => {
        this.fee = event.target.value;
        this.checkCanBeSaved();
    }
    changeOriginalShipmentNumber = (event: any) => {
        this.originalShipmentNumber = event;
        this.search();
    }
    changeContainerNumber = (event: any) => {
        this.containerNumber = event;
        this.search();
    }   
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.fee > 0 && this.feeType !== '' && this.vendor !== '' && this.selectedRows.length > 0) {
            this.canBeSaved = true;
        }
        this.setState({});
    }
    componentDidMount() {
        store.subscribe(() => {
            if (store.getState().returnPlanBracket.value) {
                store.dispatch(setUnChanged());
                this.init();
            }
        })
        const roles = sessionStorage.getItem("permissions") || '';
        //if (roles.includes('admin')) {
            this.init();
        //}
    }
    init() {
        this.setState({isLoaded: false});
        this.getCostManagementByReturnPlanId();
        this.getAllVendors();
    }
    getAllVendors() { 
        axiosInstance.get('/logistics/getAllVendors').then(
            response => {
                this.vendorList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.vendorList.push({label:response.data[i].vendorName, value: response.data[i].vendorId});               
                }                
                this.setState({});
            },
            error => {
                displayErrorMessage(error, '获取供应商失败');
            }
        )
        
    }

    getCostManagementByReturnPlanId() {
        axiosInstance.get('/logistics/getCostManagementByReturnPlanId', {params: {returnPlanId: this.props.planId}}).then(
            response => {
                this.originalData = response.data;
                for(let i = 0; i < response.data.length; i++) {
                    this.originalData[i].key = this.originalData[i].costId;                    
                }
                this.oriTrainNumberStringList = [];
                this.oriTrainNumberList = [];
                this.containerNumberStringList = [];
                this.containerNumberList = [];
                this.oriTrainNumberList.push({label: '', value: ''});
                this.containerNumberList.push({label: '', value: ''});
                this.originalData.forEach(element => {
                    if (!this.oriTrainNumberStringList.includes(element.originalShipmentNumber)) {
                        this.oriTrainNumberStringList.push(element.originalShipmentNumber)
                        this.oriTrainNumberList.push({label: element.originalShipmentNumber, value: element.originalShipmentNumber});
                    }
                    if (!this.containerNumberStringList.includes(element.containerNumber)) {
                        this.containerNumberStringList.push(element.containerNumber)
                        this.containerNumberList.push({label: element.containerNumber, value: element.containerNumber});
                    }
                })
                this.setState({dataSource: this.originalData,isLoaded: true});                
                this.search();
                this.checkCanBeSaved();
            },
            error => {
                displayErrorMessage(error, '获取支架失败!');
            }
        );
    }
    search = () => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => (
                (this.originalShipmentNumber === '' || item.originalShipmentNumber === this.originalShipmentNumber) &&
                (this.containerNumber === '' || item.containerNumber === this.containerNumber))
            )
        });
    }

    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="seperate">
                </div>   
                           
                <div className="content">
                    {
                        this.props.isView? '' :
                            <div className = 'header-right'>
                                <label className="box-label">供应商:</label>                                
                                <Select style={{width: '250PX'}} className="box-input"  
                                showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                options={this.vendorList} onChange={this.changeVendor} />                                    
                                <label>费用类型:</label>
                                <Select style={{width: '150PX'}} options={FeeType} onChange={this.changeFeeType}/> 
                                <label>&nbsp;&nbsp;费用:</label>
                                <Input  style={{width: '150PX'}} onChange={this.changeFee}/>  
                                <Button className="header-right" type="primary" onClick={this.saveSelected} disabled={!this.canBeSaved}>更新</Button>  
                            </div>
                    } 
                    {this.props.isView? '' :<br/>}
                    {this.props.isView? '' :<br/>} 
                    <div className='row'>                               
                        <label style={{marginLeft: '20PX', width: '75PX'}}>原始班列号:</label>
                        <Select style={{width: '200PX'}} options={this.oriTrainNumberList} onChange={this.changeOriginalShipmentNumber} defaultValue={this.originalShipmentNumber}/> 
                        <label style={{marginLeft: '20PX', width: '60PX'}}>集装箱号:</label>
                        <Select style={{width: '200PX'}} options={this.containerNumberList} onChange={this.changeContainerNumber} defaultValue={this.containerNumber}/>
                    </div>
                    
                    <Table pagination={{ pageSize: 200 }} title={() => '已选择的支架数量: ' + this.selectedRows?.length + '     供应商: ' + this.state.tooltipText} 
                        rowSelection={{
                        type: 'checkbox',
                        ...this.rowSelection,
                        }} columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}
                        
                        summary={() => {
                            let planCount = 0;
                            let exportFeeTotal = 0;
                            let tempGuaranteeCostTotal = 0;
                            let customsTransferCostTotal = 0;
                            let overseasAgencyTotal = 0;
                            let customsClearanceCostTotal = 0;
                            let guaranteeWriteOffTotal = 0;
                            let other01Total = 0;
                            let portHoistingFeeTotal = 0;
                            let devanningFeeTotal = 0;
                            let returnContainerFeeTotal = 0;
                            let releaseChargeTotal = 0;
                            let loadingFeeTotal = 0;
                            let bracketTransferFeeTotal = 0;
                            let protectionBuckleFeeTotal = 0;
                            let screwSupplementFeeTotal = 0;
                            let bracketMaintainFeeTotal = 0;
                            let other02Total = 0;
                            let other03Total = 0;
                            this.state.dataSource?.forEach(({ exportFee,tempGuaranteeCost,customsTransferCost,overseasAgency,customsClearanceCost,guaranteeWriteOff,other01,
                                portHoistingFee,devanningFee,returnContainerFee,releaseCharge,loadingFee,bracketTransferFee,protectionBuckleFee,screwSupplementFee,bracketMaintainFee,other02,other03 }) => {
                                exportFeeTotal += exportFee;
                                tempGuaranteeCostTotal += tempGuaranteeCost;
                                customsTransferCostTotal += customsTransferCost;                                    
                                overseasAgencyTotal += overseasAgency;
                                customsClearanceCostTotal += customsClearanceCost;
                                guaranteeWriteOffTotal += guaranteeWriteOff;
                                other01Total += other01;
                                portHoistingFeeTotal += portHoistingFee;
                                devanningFeeTotal += devanningFee;
                                returnContainerFeeTotal += returnContainerFee;
                                releaseChargeTotal += releaseCharge;
                                loadingFeeTotal += loadingFee;
                                bracketTransferFeeTotal += bracketTransferFee;
                                protectionBuckleFeeTotal += protectionBuckleFee;
                                screwSupplementFeeTotal += screwSupplementFee;
                                bracketMaintainFeeTotal += bracketMaintainFee;
                                other02Total += other02;
                                other03Total += other03;
                                planCount++;
                            });
                            
                            return (
                            <Table.Summary.Row>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>汇总</Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>{planCount % 1 === 0 ? planCount : planCount.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={3}></Table.Summary.Cell>                                                        
                            <Table.Summary.Cell index={7}>{overseasAgencyTotal % 1 === 0 ? overseasAgencyTotal : overseasAgencyTotal.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={8}>{customsClearanceCostTotal % 1 === 0 ? customsClearanceCostTotal : customsClearanceCostTotal.toFixed(2)}</Table.Summary.Cell>                            
                            <Table.Summary.Cell index={10}>{other01Total % 1 === 0 ? other01Total : other01Total.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={11}>{portHoistingFeeTotal % 1 === 0 ? portHoistingFeeTotal : portHoistingFeeTotal.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={12}>{devanningFeeTotal % 1 === 0 ? devanningFeeTotal : devanningFeeTotal.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={13}>{returnContainerFeeTotal % 1 === 0 ? returnContainerFeeTotal : returnContainerFeeTotal.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={14}>{releaseChargeTotal % 1 === 0 ? releaseChargeTotal : releaseChargeTotal.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={15}>{loadingFeeTotal % 1 === 0 ? loadingFeeTotal : loadingFeeTotal.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={16}>{bracketTransferFeeTotal % 1 === 0 ? bracketTransferFeeTotal : bracketTransferFeeTotal.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={17}>{protectionBuckleFeeTotal % 1 === 0 ? protectionBuckleFeeTotal : protectionBuckleFeeTotal.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={18}>{screwSupplementFeeTotal % 1 === 0 ? screwSupplementFeeTotal : screwSupplementFeeTotal.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={19}>{bracketMaintainFeeTotal % 1 === 0 ? bracketMaintainFeeTotal : bracketMaintainFeeTotal.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={20}>{other02Total % 1 === 0 ? other02Total : other02Total.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={21}>{other03Total % 1 === 0 ? other03Total : other03Total.toFixed(2)}</Table.Summary.Cell>                            
                            <Table.Summary.Cell index={21}>{(overseasAgencyTotal+customsClearanceCostTotal+other01Total+portHoistingFeeTotal+devanningFeeTotal+returnContainerFeeTotal
                                +releaseChargeTotal+loadingFeeTotal+bracketTransferFeeTotal+protectionBuckleFeeTotal+screwSupplementFeeTotal+bracketMaintainFeeTotal
                                +other02Total+other03Total).toFixed(2)}</Table.Summary.Cell>    
                            </Table.Summary.Row>
                            );
                            }} 
                            
                            />
                </div>
            </div>
        )      
    }
}

/*
 * @Author: CK
 * @email: 1298050101@qq.com
 * @Date: 2023-03-04 18:02:27
 * @LastEditTime: 2023-03-12 00:31:26
 * @FilePath: \code\src\main.tsx
 * @Description:
 */
import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import 'antd/dist/reset.css';
import '../common/scss/index.scss';
import App from './app';
import 'amfe-flexible/index.min.js';
import { Provider } from 'react-redux';
import store from '@store/index';

const root: Root = createRoot(document.getElementById('root') as Element);
root.render(
	<Provider store={store}>
		<App />
	</Provider>,
);

import React from 'react';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker, TabsProps, Image, Tree, Layout } from 'antd';
import { EditOutlined, DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import { DataNode } from 'antd/es/tree';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import { DownOutlined,CheckCircleOutlined} from '@ant-design/icons';
const contentStyle = {
  height: '1000px',
  overflow: 'auto'
};
const treeStyle = {    
    overflow: 'scroll"'    
};
export default class ReceiveCarPicture extends React.Component {        
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <EditOutlined className="actionIcon" title='审核' onClick={() => this.onEdit(record)}/>&nbsp; &nbsp;                        
                    </div>
                    
                )}
        },
        {
            title: '客户名',
            dataIndex: 'customerName',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.customerName > b.customerName? 1 : -1,
        },
        {
            title: '批次号',
            dataIndex: 'shipmentNumber',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.shipmentNumber > b.shipmentNumber? 1 : -1,
        },
        {
            title: '装箱周期',
            dataIndex: 'shipmentNumber',
            showSorterTooltip: false,        
            render: (_: any, record: any)=> {
                return (
                    <div>                        
                       {record.loadingPeriodStart +' 到 '+ record.loadingPeriodEnd} 
                    </div>  
                )}
        },    
        {
            title: '支架是否循环',
            dataIndex: 'shipmentNumber',
            showSorterTooltip: false,        
            render: (_: any, record: any)=> {
                return (
                    <div>
                            {
                               record.isRecyclable == 1 ? '无支架' : (record.isRecyclable == 2 ? '循环' : '一次性')
                            }
                            
                        </div>
                )}
        },   
        {
            title: '装箱团队',
            dataIndex: 'teamName',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.trainNumber > b.trainNumber? 1 : -1,
        },
        {
            title: '照片审核人',
            dataIndex: 'imageAuditorName',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.trainNumber > b.trainNumber? 1 : -1,
        },
        {
            title: '审核状态',
            dataIndex: 'planStatus',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {
                if (record.planStatus == 'Finished') {
                    return (
                        <div>
                            已完成
                        </div>
                    )
                }else{
                    return (
                        <div>
                            未完成
                        </div>
                    )
                }
            }
        },
        {
            title: '装箱进度(完成数/总数)',
            dataIndex: 'progress',
            showSorterTooltip: false,    
        },
    ];
    originalData: any[] = [];
    expandedKeysOriginal: React.Key[] = []
    oriPictureStatusList: boolean[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isView: false,
        isShowFileNames: false,
        isShowPicture: false,
        pictureNameWithPath: '',
        searchText: '',
        expandedKeys: this.expandedKeysOriginal,
        isCacheFinished: true,
        pictureStatusList: this.oriPictureStatusList,
    };
    allPermissions: any[] = [];
    statusList: any[] = [{label:'已完成', value: 'Finished'},{label:'未完成', value: 'not-Finished'}]; 
    planId = 0;
    shipmentNumber = "";
    trainNumber = "";
    customerName = "";
    planStatus = "";
    containerNumber = "";
    carNumber = "";
    receiveCarList: any[] = [];
    rejectedPhotoList: any[] = [];
    pictureFileNameList: any[] = [];
    pictureName = '';
    selectedContainerOrCar = '';
    onEdit = (record: any) => {
        this.planId = record.planId;
        this.getReceiveCarByPlanId();        
        this.getShipmentPlanPictureTreeByPlanId();
    }
    cancel = () => {
        this.shipmentNumber = '';
        this.init();
    }
    searchText = {        
        status: '',
        text:'',
    }
    onSearch = (value: any) => {
        this.searchText.text = value.target.value;
        this.search();
    }
    search = () => {                
        this.setState({
            dataSource: this.originalData.filter((item: any) => {
                if (this.searchText.status != ''  && item.planStatus !== this.searchText.status) {
                    return false;
                }

                if(this.searchText.text !=''){                    
                    if (!item.customerName.toLowerCase().includes(this.searchText.text.toLowerCase()) &&
                    !item.shipmentNumber.toLowerCase().includes(this.searchText.text.toLowerCase())&&
                    !item.trainNumber.toLowerCase().includes(this.searchText.text.toLowerCase())) {
                        return false;
                    }
                } 
                return true;

            }
            )
          })        
    }
   
    changeSelectSearch = (field: string, event: any) => {
        (this.searchText as any)[field] = event
        this.search();
    }

    refresh = () => {
        this.setState({dataSource: null, isLoaded: false, isView: false});
        this.getReceiveCarByPlanId();        
        this.getShipmentPlanPictureTreeByPlanId();
    }

    verifyPhoto = () =>  { 
        const OnePagePhoto = {planId: this.planId,           
            photoType: 'receiveCar', 
            entityNumber: this.carNumber ,             
            isVerified:true};                      
        axiosInstance.post('/logistics/verifyPhoto', OnePagePhoto).then(
            response => {                
                message.success('操作成功!');                
            },
            error => {
                displayErrorMessage(error, '操作失败!');
            }
        )   
    }

    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        // if (roles.includes('admin')) {
            this.init();
        // }
    }

    init() {
        this.setState({dataSource: null, isLoaded: false, isView: false});
        this.getAllShipmentPlans();            
    }
  
    getShipmentPlanPictureTreeByPlanId() {
        axiosInstance.get('/logistics/getShipmentPlanById', {params: {planId: this.planId}}).then(
            response => {
                this.planId = response.data.planId;
                this.shipmentNumber = response.data.shipmentNumber;
                this.trainNumber = response.data.trainNumber;      
                this.customerName =  response.data.customerName;     
                this.planStatus =    response.data.planStatus;                    
            },
            error => { 
                displayErrorMessage(error, '获取装箱计划图片失败');
            }
        )        
    }
    getReceiveCarByPlanId() {
        axiosInstance.get('/logistics/getReceiveCarByPlanId', {params: {planId: this.planId}}).then(
            response => {
                this.receiveCarList = response.data;     
                this.setState({isView: true});          
            },
            error => {
                displayErrorMessage(error, '获取集装箱失败');
            }
        )

    }
    
    getPicturesForReceiveCar = () => {
        this.selectedContainerOrCar = 'receiveCar';
        this.setState({isShowFileNames: false, isShowPicture: false});
        axiosInstance.get('/logistics/getPicturesForReceiveCar', {params: {planId: this.planId, carNumber: this.carNumber}}).then(
            response => {
                this.pictureFileNameList = response.data;               
                this.setState({isShowFileNames: true});
            },
            error => {
                displayErrorMessage(error, '获取集装箱图片失败');
            }
        )
    }

    getAllShipmentPlans() {
        axiosInstance.get('/logistics/getAllShipmentPlans').then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].planId;                    
                    if(this.originalData[i].planStatus?.length==0 || this.originalData[i].planStatus==null){
                        this.originalData[i].planStatus = 'not-Finished'                        
                    }
                }
                this.setState({dataSource: this.originalData, isLoaded: true});                
                this.searchText.status = 'not-Finished';
                this.search();
            },
            error => {
                displayErrorMessage(error, '获取装箱计划失败');
            }
        )           
    }
   
    onExpand = (newExpandedKeys: React.Key[]) => {
        this.setState({expandedKeys: newExpandedKeys});
    };

    rendertitle = (title:String, count:number) => (
     <div>
         {title}  
         {this.getIcons(count)}   
      </div>
    )

    getIcons = (count:number) => {
        let res = [];      
        for (let j = 0; j < count; j++) {
            res.push(
                <CheckCircleOutlined style={{color:'red'}}/> 
            )
        }
        return res;
    }

   


    listContainers = (receiveCarList: any[]) => {
        var res = [];
        const treeData: DataNode[] = [];
        const onSelect = (selectedKeys: any) => {
            if (selectedKeys.length > 0) {                     
                this.carNumber = selectedKeys[0];
                this.getPicturesForReceiveCar(); 
            }
        };
        for (let i = 0; i < receiveCarList.length; i++) {
            let count = 0;
            const containerDataNode : DataNode[] = [];                 
            treeData.push({ key: receiveCarList[i].carNumber, title: this.rendertitle(receiveCarList[i].carNumber,count),switcherIcon: receiveCarList[i].receiveVerified ? <CheckCircleOutlined style={{color:'green'}}/> : receiveCarList[i].receiveFinished?<CheckCircleOutlined style={{color:'red'}}/>:'' });
        }
        res.push(
            <div className="treeStyle">              
                <Tree
                    onExpand={this.onExpand}
                    expandedKeys={this.state.expandedKeys}
                    autoExpandParent={true}
                    treeData={treeData}
                    onSelect={onSelect}
                    switcherIcon={<DownOutlined />}
                    showLine
                />                
            </div>
        );
        return res;
    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin') && !roles.includes('运营部')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    {this.shipmentNumber?.length>0?
                    <div style={{ margin: '12px' }}>                        
                        <Button className="header-left" type="primary" onClick={this.refresh}>刷新</Button>
                        <Button className="header-left" type="primary" onClick={this.verifyPhoto}>本页通过</Button>    
                        <Button className="header-left" type="primary" onClick={this.cancel}>退出</Button>             
                        {'客户名:'+this.customerName +' 批次号:'+this.shipmentNumber+' 班列号:'+this.trainNumber+' 状态:' } {this.planStatus =='Finished'?'已完成':'未完成'} 收车照审核                    
                    </div>
                    :' 照片审核'
                    }
                </div>
                <div className="seperate">
                </div>
                
                {this.state.isView? 
                    <div className="content">
                    <div>                        
                                          
                    </div>
                        {}
                        <Layout>
                            <Sider trigger={null} collapsible collapsed={false} theme='light' style={{ height: '100vh', overflowY: 'auto' }}>
                                {
                                    this.listContainers(this.receiveCarList)
                                }
                            </Sider>
                            <Content style={contentStyle}>
                                {
                                    this.state.isShowFileNames?
                                    <div  className="imgStyle">
                                                <Image.PreviewGroup  preview={{
                                                        onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                                        }}>
                                        {
                                            this.pictureFileNameList.map((column, index1) => {
                                                var pictureFullPath = '/api/doc/' + this.planId + '/car/' + this.carNumber + '/' + column.fileName;                                              
                                                return (
                                                    <p>  
                                                    <Image width={200} height={200} src={pictureFullPath}/>        
                                                    <p>{column.fileName} <br/>
                                                    {column.createDate}</p>  
                                                    </p>                                                   
                                                )
                                            })
                                        }
                                         </Image.PreviewGroup>
                                    </div>                                   
                                    :
                                    ''
                                }
                               
                            </Content>
                        </Layout>
                    </div>
                :
                    <div className="content">      
                        <label className="box-label">完成状态:</label>
                        <Select style={{ width: '172px' }} options={[{label:'--全部--', value: ''},...this.statusList]} defaultValue='not-Finished' onChange={this.changeSelectSearch.bind(this, 'status')}/> 
                        <label className="box-label">全局搜索:</label>
                        <Input placeholder="输入要查询的字符" onChange={this.onSearch} className="tableSearch" value={this.searchText.text} /> 
                        <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}

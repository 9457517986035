import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';

export default class InvoiceManage extends React.Component {
    permissions = sessionStorage.getItem("permissions") || '';
    columns = [
    {
        title: '发票号',
        dataIndex: 'invoice',        
        showSorterTooltip: false,      
        sorter: (a: any, b: any) => a.invoice > b.invoice? 1 : -1,
    },  
    {
        title: '供应商',
        dataIndex: 'vendorName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.vendorName > b.vendorName? 1 : -1,
    },   
    {
        title: '名称',
        dataIndex: 'name',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.name > b.name? 1 : -1,
    },
    {
        title: '审核',
        dataIndex: 'verified',
        showSorterTooltip: false,        
        sorter: (a: any, b: any) => a.verified > b.verified? 1 : -1,
        render: (_: any, record: any)=> {
            return (
                <div>
                    {
                        record.verified == true ? '是 ' :'否 '
                    }
                </div>
            )}
    },   
    {
        title: '开票日期',
        dataIndex: 'invoiceDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.invoiceDate > b.invoiceDate? 1 : -1,
    },
    {
        title: '收票日期',
        dataIndex: 'receiveInvoiceDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.receiveInvoiceDate > b.receiveInvoiceDate? 1 : -1,
    },
    {
        title: '总金额',
        dataIndex: 'totalPrice',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.totalPrice > b.totalPrice? 1 : -1,
    },
    {
        title: '摘要',
        dataIndex: 'invoiceExpense',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.invoiceExpense > b.invoiceExpense? 1 : -1,
    },
    {
        title: '异常收支', 
        dataIndex: 'invoiceCost',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.invoiceCost > b.invoiceCost? 1 : -1,
    },
    {
        title: '已付款',
        dataIndex: 'paid',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.paid > b.paid? 1 : -1,
    },  
    
    {
        title: '未付款',
        dataIndex: 'notPaid',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.notPaid > b.notPaid? 1 : -1,
        render: (_: any, record: any)=> {
            return (
                <div>                    
                    {record.notPaid}
                </div>
            )}    
    },
    ];

    
    originalData: any[] = [];
    paidData: any[] = [];
    totalData: any[] = [];
    totalCost = 0;    
    receiveInvoiceDate = "";
    canBeSaved = false;
    state = {
        dataSource: this.originalData,
        paidData: this.paidData,
        totalData: this.totalData,
        isLoaded: false,
        isPaid: false,
        isTotal: false,
        isChangeInvoice:false,
        searchText: '',
    };
   
    allPermissions: any[] = [];
    vendorNameList: any[] = [];
    nameList: any[] = [];
    searchText = {
        vendorName: '',
        invoice: '',
        name: '',
        invoiceDateStart: '',
        invoiceDateEnd: '',
        verified: 'all',
    }
    verifiedList: any[] = [{label:'是', value: true},{label:'否', value: false}]; 
    
    changeInvoiceDateStart = (date: any, dateString: string) => {
        this.searchText.invoiceDateStart = dateString;
        this.search();
    }
    changeInvoiceDateEnd = (date: any, dateString: string) => {
        this.searchText.invoiceDateEnd = dateString;
        this.search();
    }

    onSearchVendorName = (value: any) => {        
        this.searchText.vendorName = value.target.value;
        this.search();
    }

    changeSelect = (field: string, event: any) => {
        (this.searchText as any)[field] = event
        this.search();
    }
    invoiceonSearchInvoice = (value: any) => {
        this.searchText.invoice = value.target.value;
        this.search();
    }

    changeSelectSearch = (field: string, event: any) => {
        (this.searchText as any)[field] = event      
        console.log('searchText',this.searchText)  
        this.search();
    }
    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {                       
        this.selectedRows = selectedRows;        
        this.setState({});
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };

    updatedKeys = "";
    search = () => {        
        this.setState({
            dataSource: this.originalData.filter((item: any) => {   
                if (this.searchText.invoice != '' && !item.invoice?.toLowerCase().includes(this.searchText.invoice?.toLowerCase())) {
                    return false;
                }
                if (this.searchText.vendorName != '' && !item.vendorName?.toLowerCase().includes(this.searchText.vendorName?.toLowerCase())) {
                    return false;
                }
                if (this.searchText.name != ''  && item.name !== this.searchText.name) {
                    return false;
                }
                if (this.searchText.invoiceDateStart != '' && item.invoiceDate < this.searchText.invoiceDateStart) {
                    return false;
                }
                if (this.searchText.invoiceDateEnd != '' && item.invoiceDate > this.searchText.invoiceDateEnd) {
                    return false;
                }
                if (this.searchText.verified != 'all') {                 
                    return this.searchText.verified == item.verified;
                }
                return true;
              }
            )
          })
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false});
        this.getAllOrderPayment();
    }
    getAllOrderPayment() {
        axiosInstance.get('/logistics/getAllPaymentInvoice').then(
            response => {
                this.originalData = response.data;   
                let nameList: any[] = [];
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = i;                   
                    if (!nameList.includes(this.originalData[i].name)) { 
                        nameList.push(this.originalData[i].name);
                    }
                }
                this.nameList = [];
                this.nameList.push({label: '', value: ''});
                for(let i = 0; i < nameList.length; i++) {
                    this.nameList.push({label: nameList[i], value: nameList[i]});
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取信息失败');
            }
        )

    }   
        
    onExport = (record: any) => {
        this.setState({isLoaded: false});
        axiosInstance.get('/logistics/exportInvoice', {responseType: 'blob'} ).then(
            response => {
                this.setState({isLoaded: true});
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const fileName = '发票管理.xlsx';
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                message.success('导出成功!');
            },
            error => {
                displayErrorMessage(error, '导出失败!');
            }
        );

    }
    verifySelected = () => {  
        this.updatedKeys = ";";
        for(let i=0; i<this.selectedRows.length; i++)  {
          this.selectedRows[i].isUpdated = false;
          this.updatedKeys = this.updatedKeys + this.selectedRows[i].key + ";";
        }                       
          this.selectedRows.forEach(element => {               
              axiosInstance.post('/logistics/makeInvoiceVerified', element).then(
                  response => {     
                      element.isUpdated = true;     
                      let isAllUpdated = true;   
                      for(let i=0; i<this.selectedRows.length; i++)  {
                          if(!this.selectedRows[i].isUpdated){
                              isAllUpdated = false;
                          }
                        }     
                      if(isAllUpdated){
                          const newDataSource = this.state.dataSource?.map(item => {
                              if (this.updatedKeys.includes(";" + item.key+ ";")) {
                                  return { ...item, verified:true }; 
                              }
                              return item; 
                          });                    
                          this.setState({dataSource:newDataSource});
                      }
                  
                  },
                  error => {
                      displayErrorMessage(error, '操作失败');
                  }
              )
          })               
          message.success('操作成功');
          // this.init();        
          // this.setState({isLoaded: true});    
      }
    changeInvioce = () => { 
        this.setState({isChangeInvoice: !this.state.isChangeInvoice});        
    };
    changeInvoiceDate = (date: any, dateString: string) => {
        this.receiveInvoiceDate = dateString;
        this.checkCanBeSaved();
    }    
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.receiveInvoiceDate !== null && this.receiveInvoiceDate !== '') {
            this.canBeSaved = true;
        }
        this.setState({});
    }
    

    makeInvoice = () => {  
      this.updatedKeys = ";";
      for(let i=0; i<this.selectedRows.length; i++)  {
        this.selectedRows[i].isUpdated = false;
        this.updatedKeys = this.updatedKeys + this.selectedRows[i].key + ";";
      }                       
        this.selectedRows.forEach(element => {                   
           element.receiveInvoiceDate = this.receiveInvoiceDate                      
           axiosInstance.post('/logistics/updateReceiveInvoiceDate', element).then(
                response => {     
                    element.isUpdated = true;     
                    let isAllUpdated = true;   
                    for(let i=0; i<this.selectedRows.length; i++)  {
                        if(!this.selectedRows[i].isUpdated){
                            isAllUpdated = false;
                        }
                      }     
                    if(isAllUpdated){
                        const newDataSource = this.state.dataSource?.map(item => {
                            if (this.updatedKeys.includes(";" + item.key+ ";")) {
                                return { ...item, verified:true }; 
                            }
                            return item; 
                        });                    
                        this.setState({dataSource:newDataSource});
                    }
                
                },
                error => {
                    displayErrorMessage(error, '操作失败');
                }
            )
        })               
        message.success('操作成功');
        // this.init();        
        // this.setState({isLoaded: true});    
    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
               
                    <div>                
                    <div className="title">
                        <span> 发票管理</span>  
                    </div>
                    <div className="seperate">
                    </div>
                        <div className="content">
                            <label>发票号:</label>                        
                            <Input onChange={this.invoiceonSearchInvoice}  className="tableSearch" value={this.searchText.invoice} /> 
                            <label>供应商:</label>                        
                            <Input onChange={this.onSearchVendorName}  className="tableSearch" value={this.searchText.vendorName} />       
                            <label className="box-label">审核:</label>
                            <Select style={{ width: '100px' }} options={[{label:'--全部--', value: 'all'},...this.verifiedList]} defaultValue='all'  onChange={this.changeSelectSearch.bind(this, 'verified')}/>                                                         
                            <label>&nbsp;&nbsp;名称:</label>
                            <Select style={{width: '150px'}} options={this.nameList} onChange={this.changeSelect.bind(this, 'name')} />
                            <label>&nbsp;&nbsp;开票日期:</label>
                            <DatePicker style={{width: '150px'}} onChange={this.changeInvoiceDateStart} />
                            <label>&nbsp;-&nbsp;</label>
                            <DatePicker style={{width: '150px'}} onChange={this.changeInvoiceDateEnd} />                            
                            <br/>
                            <Button type="primary" className="header-left" onClick={this.verifySelected} disabled={this.selectedRows.length==0}>批量审核</Button>  
                            <Button type="primary" className="header-left" onClick={this.changeInvioce} disabled={this.selectedRows.length==0}>批量收票</Button>                              
                            <Button type="primary" className="header-right" onClick={this.onExport}>导出Excel</Button>
                            <br/>
                            {this.state.isChangeInvoice? 
                            <div className="contents">
                                <div className="row">
                                    <div className="box">                                        
                                        <label className="box-label">收票日期:</label>
                                        <DatePicker className="box-input" onChange={this.changeInvoiceDate} />                                
                                        <Button type="primary" className="header-left" onClick={this.makeInvoice}  disabled={!this.canBeSaved}>确定</Button>                                        
                                </div>                                
                                </div> 
                            </div>
                            :''
                            }
                            <Table rowSelection={{ type: 'checkbox', ...this.rowSelection, }} columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}
                            summary={() => {
                                let allPrice = 0;
                                let totalPaid = 0;
                                let totalNotPaid = 0;
                                let allInvoiceCost = 0;

                                if(this.selectedRows.length>0){
                                    this.selectedRows?.forEach(({ invoiceCost,totalPrice,paid,notPaid }) => {
                                        allInvoiceCost += invoiceCost;
                                        allPrice += totalPrice;
                                        totalPaid += paid;
                                        totalNotPaid += notPaid + invoiceCost;                                    
                                    });  
                                }else{
                                    this.state.dataSource?.forEach(({ invoiceCost,totalPrice,paid,notPaid }) => {
                                        allInvoiceCost += invoiceCost;
                                        allPrice += totalPrice;
                                        totalPaid += paid;
                                        totalNotPaid += notPaid;                                    
                                    });    
                                }
                                
                                return (
                                <Table.Summary.Row>
                                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>汇总(所有或选中)</Table.Summary.Cell>
                                <Table.Summary.Cell index={2}></Table.Summary.Cell>                                                   
                                <Table.Summary.Cell index={3}></Table.Summary.Cell>   
                                <Table.Summary.Cell index={4}></Table.Summary.Cell>                                                   
                                <Table.Summary.Cell index={5}></Table.Summary.Cell>       
                                <Table.Summary.Cell index={6}></Table.Summary.Cell>   
                                <Table.Summary.Cell index={7}>{allPrice % 1 === 0 ? allPrice : allPrice.toFixed(2)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={6}></Table.Summary.Cell>                                   
                                <Table.Summary.Cell index={7}>{allInvoiceCost % 1 === 0 ? allInvoiceCost : allInvoiceCost.toFixed(2)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={8}>{totalPaid % 1 === 0 ? totalPaid : totalPaid.toFixed(2)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={9}>{totalNotPaid % 1 === 0 ? totalNotPaid : totalNotPaid.toFixed(2)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={10} />
                                </Table.Summary.Row>
                                );
                                }} />                             
                        </div>
                </div>                
            </div>
        )      
    }
}

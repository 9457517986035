import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import { displayErrorMessage } from '@/utils/utilFunction';

export default class Password extends React.Component {
    
    state = {
        newPasswd: '',
        confirmPasswd: '',
        canBeSaved: false
    }
    newPasswd = '';
    confirmPasswd = ''
    save = () => {
        const user = {loginName: sessionStorage.getItem("loginName")?.replaceAll('"', ''), password: this.state.newPasswd};
        axiosInstance.post('/logistics/changePassword', user).then(
            response => {
                this.init();
                message.success('Change Password successful!');
            },
            error => {
                this.init();
                displayErrorMessage(error, 'Change Password failed!')
            }
        )
    }
    cancel = () => {
        this.init();
    }
    changeNewPasswd = (event: any) => {
        this.newPasswd = event.target.value;
        const canBeSaved = this.checkCanBeSaved();
        this.setState({newPasswd: this.newPasswd, confirmPasswd: this.confirmPasswd, canBeSaved: canBeSaved});
    }
    changeConfirmPasswd = (event: any) => {
        this.confirmPasswd = event.target.value;
        const canBeSaved = this.checkCanBeSaved();
        this.setState({newPasswd: this.newPasswd, confirmPasswd: this.confirmPasswd, canBeSaved: canBeSaved});
    }
    checkCanBeSaved() {
        if (this.newPasswd !== null && this.newPasswd !== '') {
            if (this.newPasswd === this.confirmPasswd) {
                return true;
            }
        }
        return false;
    }
    init() {
        this.newPasswd = '';
        this.confirmPasswd = '';
        this.setState({newPasswd: '', confirmPasswd: '', canBeSaved: false});
    }
    render() {
        return (
            <div>
                <div className="title">
                    <span> Change Password</span>
                </div>
                <div className="seperate">
                </div>
                
                <div className="content">
                    <div className="row">
                        <div className="box">
                            <label className="box-label">新密码:</label>
                            <Input className="box-input" type="password" onChange={this.changeNewPasswd} value={this.state.newPasswd}/>                             
                        </div>
                    </div>
                    <div className="row">
                        <div className="box">
                            <label className="box-label">确认密码:</label>
                            <Input className="box-input" type="password" onChange={this.changeConfirmPasswd} value={this.state.confirmPasswd}/>                             
                        </div>
                    </div>
                    <div>
                        <Button className="header-right" type="primary" onClick={this.cancel}>Cancel</Button>
                        <Button className="header-right" type="primary" onClick={this.save} disabled={!this.state.canBeSaved}>Save</Button>
                    </div>
                </div>
            </div>
        )      
    }
}
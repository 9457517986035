import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import TextArea from 'antd/es/input/TextArea';

const dateFormat = 'YYYY-MM-DD';

const YESNOLIST = [
    {
        label: '是',
        value: true,
    },
    {
        label: '否',
        value: false,
    }
]

export default class Container extends React.Component {
    props = {planId: 0};
    constructor(props:any) {
        super(props);
      }
    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>                        
                        <Popconfirm
                            title="删除集装箱"
                            description="是否确定删除?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除' className="actionIcon"/> &nbsp; &nbsp;
                        </Popconfirm>
                        <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>
                    </div>
                )
            }
        },
    {
        title: '集装箱号',
        dataIndex: 'containerNumber',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.containerNumber > b.containerNumber? 1 : -1,
    },
    {
        title: '空箱重',
        dataIndex: 'containerWeight',
        showSorterTooltip: false,
    },
    {
        title: '铅封号',
        dataIndex: 'containerLeadSealing',
        showSorterTooltip: false,
    },
    {
        title: '照片拍摄完成',
        dataIndex: 'finished',
        showSorterTooltip: false,
        render: (_: any, record: any)=> {
            return (
                <div>
                    {
                        record.finished? '是' : '否'
                    }
                </div>
            )
        }
    },
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        isUpdate: false,
    };
    allPermissions: any[] = [];
    
    editRecord = this.createContainer();
    originalEditRecords: any = {};
    canBeSaved = false;
    
    createContainer() {
        return { 
            containerId : 0,
            planId : this.props.planId,
            containerNumber : 0,
            containerWeight : 0,
            containerLeadSealing : 0,
            finished : false,
        };
    }
    
    copyContainer(Container: any) {
        return { 
            containerId : Container.containerId,
            planId : this.props.planId,
            containerNumber : Container.containerNumber,
            containerWeight : Container.containerWeight,
            containerLeadSealing : Container.containerLeadSealing,
            finished : Container.finished,
        };
    }
    onDelete = (record: any) => {
        const Container = {containerId: record.containerId, planId: this.props.planId};
        axiosInstance.post('/logistics/deleteContainerById', Container).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createContainer();
        this.originalEditRecords = this.createContainer();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    onEdit = (record: any) => {
        this.originalEditRecords = this.copyContainer(record);
        this.editRecord = this.copyContainer(record);
        this.canBeSaved = false;
        this.setState({isEdit: true});
        this.setState({isUpdate: true});
    }
  
    save = () => {
        const Container= this.copyContainer(this.editRecord);
        var arrays = Container.containerNumber.split("\n");
        for(let i in arrays){
            var item = arrays[i];
            if(item.length != 11){
                message.error('集装箱号['+ item +']有误，必须是11位,请检查!');
                return false;        
            }     
            const pattern = /^\d+$/;       
            if(!pattern.test(item.substring(4,11))){                
                message.error('集装箱号['+ item +']有误，后7位必须是数字,请检查!');
                return false;
            }
        } 

        if (Container.containerId === 0) {
            axiosInstance.post('/logistics/insertContainer', Container).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateContainer', Container).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }
    }

    update = () => {
        const Container= this.copyContainer(this.editRecord);
        var item =  Container.containerNumber;
        if(item.length != 11){
            message.error('集装箱号['+ item +']有误，必须是11位,请检查!');
            return false;        
        }     
        const pattern = /^\d+$/;       
        if(!pattern.test(item.substring(4,11))){                
            message.error('集装箱号['+ item +']有误，后7位必须是数字,请检查!');
            return false;
        }    
        axiosInstance.post('/logistics/updateContainer', Container).then(
            response => {
                this.init();
                message.success('新增成功');
            },
            error => {
                displayErrorMessage(error, '新增失败');
            }
        )
    }

    cancel = () => {
        this.setState({isEdit: false});
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.containerNumber !== null && this.editRecord.containerNumber !== 0) {
            if (this.editRecord.containerNumber !== this.originalEditRecords.containerNumber ||
                this.editRecord.containerWeight !== this.originalEditRecords.containerWeight ||
                this.editRecord.containerLeadSealing !== this.originalEditRecords.containerLeadSealing ||
                this.editRecord.finished !== this.originalEditRecords.finished) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getContainerByPlanId();
    }
    getContainerByPlanId() {
        axiosInstance.get('/logistics/getContainerByPlanId', {params: {planId: this.props.planId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].containerId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取集装箱失败');
            }
        )

    }
    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
        console.log(`selectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.selectedRows = selectedRows;
        this.checkCanBeSaved();
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };

    deleteSelected = () => {        
        this.selectedRows.forEach(element => {
            const Container = {containerId: element.containerId, planId: this.props.planId};
            axiosInstance.post('/logistics/deleteContainerById', Container).then(   
                response => {
                    this.init();                   
                },           
                error => {
                    displayErrorMessage(error, '删除失败');
                }
            )
        })               
        message.success('删除成功');
    }

    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="seperate">
                </div>
                                
                {this.state.isEdit? 
                    this.state.isUpdate? 
                            <div className="content">
                            
                            <div className="row">
                                <div className="box">
                                    <label className="box-label">集装箱号:</label>
                                    <Input className="box-input"   onChange={this.changeInput.bind(this, 'containerNumber')} defaultValue={this.editRecord.containerNumber} />
                                    <label className="box-label">空箱重:</label>
                                    <Input className="box-input"   onChange={this.changeInput.bind(this, 'containerWeight')} defaultValue={this.editRecord.containerWeight} />
                                </div>                               
                            </div>                        

                            <div className="row">    
                                <div className="box">
                                    <label className="box-label">铅封号:</label>
                                    <Input className="box-input"  onChange={this.changeInput.bind(this, 'containerLeadSealing')} defaultValue={this.editRecord.containerLeadSealing} />                                    
                                </div>
                            </div>       
                            
                            <br/>
                            <div>
                                <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                                <Button className="header-right" type="primary" onClick={this.update} disabled={!this.canBeSaved}>保存</Button>
                            </div>
                        </div>

                    :
                        <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">请录入集装箱列表:</label>
                                <TextArea rows={4} className="box-input"  onChange={this.changeInput.bind(this, 'containerNumber')} />
                            </div>
                        </div>                        
                        
                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                        </div>
                        </div>                       
                    
                :
                    <div className="content"> <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                        <Button type="primary" className="header-left" onClick={this.deleteSelected} disabled={this.selectedRows.length==0}>批量删除</Button>
                        <Button type="primary" className="header-right" onClick={this.onAdd}>导入集装箱列表</Button>                   
                        <Table pagination={{ showSizeChanger: true  }} title={() => '集装箱数量: ' + this.originalData?.length}
                            rowSelection={{
                            type: 'checkbox',
                            ...this.rowSelection,
                            }} columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>

                    </div>
                }
            </div>
        )      
    }
}

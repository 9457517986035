import React from 'react';
import './Login.scss';
import store from '../../config/store'
import { login } from '../../config/store/slice/loginReducer';
import {axiosInstance} from '../../config/axios-interceptor';
import { Button, Input, message } from 'antd';

export default class Login extends React.Component {
    state = {
        userName: "",
        passWd: "",
    }
    componentDidMount() {        
        window.addEventListener('popstate', function () {
            window.history.pushState(null, '', document.URL);
        })
    }
    changeUserName = (event: any) => {
        this.setState({userName: event.target.value});
    }
    changePasswd = (event: any) => {
        this.setState({passWd: event.target.value});
    }
    
    login = () => {
        axiosInstance.post('/logistics/general/login', {loginName: this.state.userName, password: this.state.passWd}).then(
          response => {
            if (response.data.token) {
              sessionStorage.setItem("token", JSON.stringify(response.data.token));
              sessionStorage.setItem("permissions", JSON.stringify(response.data.role));
              sessionStorage.setItem("userName", JSON.stringify(response.data.userName));
              sessionStorage.setItem("loginName", JSON.stringify(response.data.loginName));
              sessionStorage.setItem("isLogin", 'true');
              sessionStorage.setItem("customerName", JSON.stringify(response.data.customerName));
              console.log('response====',response)
              store.dispatch(login());
            }else {
              message.error('登录失败');
            }
          },
          error => {
            message.error('不能访问服务器');
          }
        )
    }
    keyDown = (e: any) => {
        if (e.keyCode === 13) {
            this.login();
        }
    } 
    render() {
        return (     
           
            <div className="wrapper" style={{backgroundImage:`url(${require("./cover.png").default})`}}>
                <div className='login-title-content'>
                    <p style={{marginTop:'6px',fontSize:'24px',color:'white'}}><b>清 文 智 能 物 流 系 统</b></p>
                    <p style={{marginTop:'-12px',fontSize:'14px',color:'white'}}>Intelligent Logistics Management System</p>
                    
                </div>
                <div className="inner">  
                    <div className="image-holder">                                        
                    < img style = {{width: '100%', height: '100%'}} src={require("./registration-form-1.jpg").default} alt=""/>                    
                    </div>
                    <div className='form'>
                        <div className='form-content'>                            
                            <p style={{fontSize:'16px',marginTop:'15px',marginBottom:60}}>欢迎使用本系统，请登录！</p>
                            <div className="form-wrapper">
                                <Input onChange={this.changeUserName} type="text" name="username" placeholder="用户名" />
                            </div>
                            <div className="form-wrapper">                                
                                <Input  onChange={this.changePasswd} onKeyDown={this.keyDown} type="password" name="password" placeholder="密码" />                                
                            </div>
                            <Button onClick={this.login}> 登录 </Button>
                        </div>
                    </div>
                </div>
            </div>           
        )     
    }
}


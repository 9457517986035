import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined,PlusOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import TextArea from 'antd/es/input/TextArea';

const dateFormat = 'YYYY-MM-DD';

export default class BracketOrderInventory extends React.Component {
    props = {orderId: 0};
    constructor(props:any) {
        super(props);
    }
    
    inventoryColumns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>     
                        <PlusOutlined title='入库' onClick={() => this.insertBracketOrderInInventory(record)} className="actionIcon"/>
                     </div>
                )}
        },
        { 
            title: '商品编码',
            dataIndex: 'productNumber',
            showSorterTooltip: false,
        },
        {
            title: '品名',
            dataIndex: 'productName',
            showSorterTooltip: false,
        },
        {
            title: '规格',
            dataIndex: 'productSpec',
            showSorterTooltip: false,
        },
        {
            title: '支架类型',
            dataIndex: 'bracketType',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {
                return (
                    <div>
                    {
                        record.bracketType == 'recyclable' ? '循环' :  '一次性'
                    }                            
                    </div>
                )}
        },
        {
            title: '编码范围',
            dataIndex: 'bracketNumber',
            showSorterTooltip: false,
        },
        {
            title: '单价',
            dataIndex: 'unitPrice',
            showSorterTooltip: false,
        },
        {
            title: '数量',
            dataIndex: 'qty',
            showSorterTooltip: false,
        },
        {
            title: '剩余数量',
            dataIndex: 'leftQty',
            showSorterTooltip: false,
        },
        {
            title: '本次入库支架号/数量',
            dataIndex: 'inQty',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {
                return (
                    record.bracketType == 'recyclable'? 
                    <div>            
                        <TextArea rows={5} style={{width: "100px"}}  onChange={this.changeInBracketNumber.bind(this, record)} />
                     </div>
                     :
                     <div>                   
                         <Input style={{width: "100px"}} defaultValue={record.leftQty} onChange={this.changeInBracketNumber.bind(this, record)}/>
                      </div>
                )}
        },
        {
            title: '堆场',
            dataIndex: 'stationId',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {
                return (
                    <div>    
                        <Select  style={{width: "150px"}} options={this.stationList} showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} onChange={this.changeStation.bind(this, record)} />
                     </div>
                )}
        },
    ];

    changeInBracketNumber(record: any, event: any) {
        record.inBracketNumber = event.target.value;
    }
    
    
    selectedColumns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>     
                        <Popconfirm
                            title="删除商品"
                            description="是否确定删除?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除' className="actionIcon"/>
                        </Popconfirm>
                     </div>
                )}
        },
        {
            title: '品名',
            dataIndex: 'productName',
            showSorterTooltip: false,
        },
        {
            title: '规格',
            dataIndex: 'productSpec',
            showSorterTooltip: false,
        },
        {
            title: '支架号/数量',
            dataIndex: 'bracketNumber',
            showSorterTooltip: false,
        },
        {
            title: '入库时间',
            dataIndex: 'entryDate',
            showSorterTooltip: false,
        },
        {
            title: '堆场',
            dataIndex: 'stationName',
            showSorterTooltip: false,
        },
    ];

    originalData: any[] = [];
    originalInvData: any[] = [];
    stationList: any[] = [];
    isLoaded1 = false;
    isLoaded2 = false;
    isLoaded3 = false;
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        inventoryList: this.originalInvData,
        searchText: '',        
    };

    onDelete = (record: any) => {
        axiosInstance.post('/logistics/deleteBracketInventory', {bracketId: record.bracketId, orderId: record.orderId, productId: record.productId, stationId: record.stationId, bracketType: record.bracketType}).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    
    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {        
        this.selectedRows = selectedRows;        
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };

    handleSelected = () => {           
        if(this.selectedRows.length==0) {
            alert('请选择要入库的条目!');
            return;
        }
        this.selectedRows.forEach(element => {
            this.insertBracketOrderInInventory(element);            
        })                         
    }
 

    changeStation = (record: any, event: any) => {
        record.stationId = event;
    }

    insertBracketOrderInInventory = (record: any) => {
        if (!record.stationId) {
            alert("请选择堆场再入库！");
            return;
        }
        if (!record.inBracketNumber) {
            alert("请输入入库支架号/数量再入库！");
            return;
        }
        const bracketInventoryList = [];
        const inBracketNumberList = record.inBracketNumber.split('\n');
        for (let i = 0; i < inBracketNumberList.length; i++) {
            if(inBracketNumberList[i]?.trim().length>0){                
                bracketInventoryList.push({orderId: record.id, stationId: record.stationId, bracketNumber: inBracketNumberList[i],unitCost: record.unitPrice, bracketType: record.bracketType, productSpec: record.productSpec,
                    productName: record.productName,comments: record.comments})                
            }
            
        }       
        axiosInstance.post('/logistics/insertBracketInventoryForOrderId', bracketInventoryList).then(
            response => {
                this.init();
                message.success('入库成功');
            },
            error => {
                displayErrorMessage(error, '入库失败');
            }
        )
    }


    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.isLoaded1 = false;
        this.isLoaded2 = false;
        this.isLoaded3 = false;
        this.setState({dataSource: null, isLoaded: false, isEdit: false,inventoryList:[]});
        this.getBracketOrderNotInInventoryByOrderId();
        this.getBracketInventoryByOrderId();
        this.getAllStations();
    }
    getBracketOrderNotInInventoryByOrderId() {
        axiosInstance.get('/logistics/getBracketOrderNotInInventoryByOrderId',{params: {orderId: this.props.orderId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].id;
                    this.originalData[i].inQty = this.originalData[i].leftQty;                    
                    if (this.originalData[i].bracketType == 'onetime') {
                        this.originalData[i].inBracketNumber = this.originalData[i].leftQty + '';
                    }
                }
                this.isLoaded1 = true;
                this.setState({dataSource: this.originalData});
                if (this.isLoaded1 && this.isLoaded2 && this.isLoaded3) {
                    this.setState({isLoaded: true});
                }
            },
            error => {
                displayErrorMessage(error, '获取商品失败');
            }
        )

    }
    getBracketInventoryByOrderId() {
        axiosInstance.get('/logistics/getBracketInventoryByOrderId', {params: {orderId: this.props.orderId}}).then(
            response => {
                const originalData = response.data;
                for (let i = 0; i < originalData.length; i++) {
                    originalData[i].key = i;
                }
                this.isLoaded2 = true;
                this.setState({inventoryList: originalData});
                if (this.isLoaded1 && this.isLoaded2 && this.isLoaded3) {
                    this.setState({isLoaded: true});
                }
            },
            error => {
                displayErrorMessage(error, '获取入库信息失败');
            }
        )

    }
    getAllStations() {
        axiosInstance.get('/logistics/getAllStations').then(
            response => {
                this.stationList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.stationList.push({label:response.data[i].stationName, value: response.data[i].stationId});
                }
                this.isLoaded3 = true;
                if (this.isLoaded1 && this.isLoaded2 && this.isLoaded3) {
                    this.setState({isLoaded: true});
                }
            },
            error => {
                displayErrorMessage(error, 'Get document list failed!');
            }
        )
    }

    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="seperate">
                </div>                
                <div className="content"> 
                        <Table rowSelection={{
                            type: 'checkbox',
                            ...this.rowSelection,
                            }} columns={this.inventoryColumns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                        <Button type="primary" className="header-right" onClick={this.handleSelected} >批量入库</Button>  
                        <br/><br/>
                        入库记录:                     
                        <Table  title={() => '支架数量: ' + this.state.inventoryList?.length }  columns={this.selectedColumns}  dataSource={this.state.inventoryList} bordered loading={!this.state.isLoaded}/>
                </div>
            </div>
        )      
    }
}

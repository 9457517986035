import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker, Tabs } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';
import TabPane from 'antd/es/tabs/TabPane';
import ConsumableOrderPayment from './ConsumableOrder/ConsumableOrderPayment';
import ConsumableOrderConsumableInventory from './ConsumableOrder/ConsumableOrderConsumableInventory';

const dateFormat = 'YYYY-MM-DD';

export default class ConsumableOrder extends React.Component {
    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 80,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp; &nbsp;
                    </div>
                )}
        },
    {
        title: '供应商',
        dataIndex: 'vendorName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.vendorId > b.vendorId? 1 : -1,
    },   
    {
        title: '下单日期',
        dataIndex: 'orderDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.orderDate > b.orderDate? 1 : -1,
    },
    {
        title: '交货日期',
        dataIndex: 'actualShipmentDate',
        showSorterTooltip: false,
    },
    {
        title: '名称',
        dataIndex: 'name',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.orderDate > b.orderDate? 1 : -1,
    },
    {
        title: '型号',
        dataIndex: 'consumablesType',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.orderDate > b.orderDate? 1 : -1,
    },
    {
        title: '发往地',
        dataIndex: 'stationName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.orderDate > b.orderDate? 1 : -1,
    },
   
    {
        title: '备注',
        dataIndex: 'comments',
        showSorterTooltip: false,
    },
    {
        title: '订单状态',
        dataIndex: 'status',
        showSorterTooltip: false,      
        render: (_: any, record: any)=> {
            if (record.status == 'New') {
                return (
                    <div>
                        新订单
                    </div>
                )
            }else if (record.status == 'Checked') {
                return (
                    <div>
                        已对账
                    </div>
                )
            }else if (record.status == 'Invoiced') {
                return (
                    <div>
                        已收票
                    </div>
                )
            }else{
                return (
                    <div>
                        已付款
                    </div>
                )
            }
        }
    }, 
    {
        title: '是否入库',
        dataIndex: 'status',
        showSorterTooltip: false,      
        render: (_: any, record: any)=> {
            if (record.allInInventory) {
                return (
                    <div>
                        已入库
                    </div>
                )
            }else {
                return (
                    <div>
                        未入库
                    </div>
                )
            }
        }
    },  
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
        paid: 0,
    };
    stationList: any[] = [];
    vendorList: any[] = [];
    consumableNameList: any[] = [];
    consumableSubTypeList: any[] = [];
    inventoryList: any[] = [{label:'已入库', value: true},{label:'未入库', value: false}]; 
    statusList: any[] = [{label:'新订单', value: 'New'},{label:'已对账', value: 'Checked'},{label:'已收票', value: 'Invoiced'},{label:'已付款', value: 'Paid'}]; 
    transportTypeList: any[] = [{label:'自提', value: '自提'},{label:'配送', value: '配送'}]; 
    editRecord = this.createConsumableOrder();
    originalEditRecords: any = {};
    canBeSaved = false;
    
    createConsumableOrder() {
        return { 
            orderId : 0,
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            orderDate : "",
            dayJsOrderDate : dayjs(dayjs(new Date()), dateFormat),
            vendorId: 0,
            vendorName : "",
            planedShipmentDate: "",
            dayJsPlanedShipmentDate : dayjs(dayjs(new Date()), dateFormat),
            name: "",
            qty: 0,
            unitPrice: 0,
            allInInventory: false,
            allPaid: false,
            stationId: 0,
            stationName : "",
            transportType:"",
            consumablesType:"",
            actualShipmentDate: "",
            dayJsActualShipmentDate : dayjs(dayjs(new Date()), dateFormat),
            inputValueAddedTax:"",
            comments : "",
            status : ""
        };
    }
    
    copyConsumableOrder(consumableOrder: any) {
        return { 
            orderId : consumableOrder.orderId,
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            orderDate : consumableOrder.orderDate,
            dayJsOrderDate :  consumableOrder.orderDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(consumableOrder.orderDate, dateFormat),
            vendorId: consumableOrder.vendorId,
            vendorName : consumableOrder.vendorName,
            planedShipmentDate: consumableOrder.planedShipmentDate,
            dayJsPlanedShipmentDate :  consumableOrder.planedShipmentDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(consumableOrder.planedShipmentDate, dateFormat),
            name: consumableOrder.name,
            qty: consumableOrder.qty,
            unitPrice: consumableOrder.unitPrice,
            allInInventory: consumableOrder.allInInventory,
            allPaid: consumableOrder.allPaid,
            stationId: consumableOrder.stationId,
            stationName : consumableOrder.stationName,
            transportType:consumableOrder.transportType,
            consumablesType:consumableOrder.consumablesType,
            actualShipmentDate: consumableOrder.actualShipmentDate,
            dayJsActualShipmentDate :  consumableOrder.actualShipmentDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(consumableOrder.actualShipmentDate, dateFormat),
            inputValueAddedTax:consumableOrder.inputValueAddedTax,
            comments : consumableOrder.comments,
            status : consumableOrder.status,
        };
    }
    searchText = {        
        status: '',
        vendorName:'',    
        name:'',  
        consumablesType:'',  
        orderCity:'',  
        allInInventory:'', 
    }
    onSearchOrderCity = (value: any) => {
        this.searchText.orderCity = value.target.value;
        this.search();
    }

    onSearchVendorName = (value: any) => {
        this.searchText.vendorName = value.target.value;
        this.search();
    }
    onSearchName = (value: any) => {
        this.searchText.name = value.target.value;
        this.search();
    }
    onSearchConsumablesType = (value: any) => {
        this.searchText.consumablesType = value.target.value;
        this.search();
    }
    changeSelectSearch = (field: string, event: any) => {
        (this.searchText as any)[field] = event
        this.search();
    }
    search = () => {
        console.log(" this.originalData", this.originalData);
        this.setState({
            dataSource: this.originalData.filter((item: any) => {              
                if (this.searchText.status != ''  && item.status !== this.searchText.status) {
                    return false;
                }
                if (this.searchText.vendorName != ''  && !item.vendorName?.toLowerCase().includes(this.searchText.vendorName?.toLowerCase())) {
                    return false;
                } 
                if (this.searchText.name != ''  && !item.name?.toLowerCase().includes(this.searchText.name?.toLowerCase())) {
                    return false;
                }
                if (this.searchText.consumablesType != ''  && !item.consumablesType?.toLowerCase().includes(this.searchText.consumablesType?.toLowerCase())) {
                    return false;
                }
                if (this.searchText.orderCity != ''  && !item.stationName?.toLowerCase().includes(this.searchText.orderCity?.toLowerCase())) {
                    return false;
                }        
                if (this.searchText.allInInventory?.toString()  != ''  && item.allInInventory!== this.searchText.allInInventory) {
                    return false;
                } 
                return true;
            }
            )
          })
    }
   
    onEdit = (record: any) => {
        this.originalEditRecords = this.copyConsumableOrder(record);
        this.editRecord = this.copyConsumableOrder(record);
        this.getConsumableOrderPaymentByOrderId();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {        
        this.selectedRows = selectedRows;
        this.checkCanBeSaved();
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };

    deleteSelected = () => {        
        if(this.selectedRows.length==0) {
            alert('请选择要删除的条目!');
            return;
        }
        this.selectedRows.forEach(element => {
            const ConsumableOrder = {orderId: element.orderId, orderDate: element.orderDate};
            axiosInstance.post('/logistics/deleteConsumableOrder', ConsumableOrder).then(
                response => {
                    this.init();                    
                },
                error => {
                    displayErrorMessage(error, '删除失败');
                }
            )
        })               
        message.success('删除成功');
    }


    onDelete = (record: any) => {
        const ConsumableOrder = {orderId: record.orderId, orderDate: record.orderDate};
        axiosInstance.post('/logistics/deleteConsumableOrder', ConsumableOrder).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createConsumableOrder();
        this.originalEditRecords = this.createConsumableOrder();
        this.canBeSaved = false;
        this.setState({isEdit: true, qty: 0, unitCost: 0, paid: 0});
    }
    getConsumableOrderPaymentByOrderId() {
        axiosInstance.get('/logistics/getConsumableOrderPaymentByOrderId', {params: {orderId: this.editRecord.orderId}}).then(
            response => {
                let paid = 0;
                for (let i = 0; i < response.data.length; i++) {
                    paid =  paid + response.data[i].paymentAmount;
                }
                this.setState({paid: paid});
            },
            error => {
                displayErrorMessage(error, '获取收款信息失败');
            }
        )

    }
    save = () => {
        const consumableOrder = this.copyConsumableOrder(this.editRecord);
        if (consumableOrder.orderId === 0) {
            axiosInstance.post('/logistics/insertConsumableOrder', consumableOrder).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateConsumableOrder', consumableOrder).then(
                response => {
                    this.init();
                    message.success('修改成功');
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )

        }
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }    
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event
        this.checkCanBeSaved();
    }
    changeOrderDate = (date: any, dateString: string) => {
        this.editRecord.orderDate = dateString;
        this.checkCanBeSaved();
    }
    changePlanedShipmentDate = (date: any, dateString: string) => {
        this.editRecord.planedShipmentDate = dateString;
        this.checkCanBeSaved();
    }
    changeActualShipmentDate = (date: any, dateString: string) => {
        this.editRecord.actualShipmentDate = dateString;
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        console.log('this.editRecord',this.editRecord.status);
        console.log('this.originalEditRecords',this.originalEditRecords.status);
        console.log('this.editRecord.status !== this.originalEditRecords.status ',this.editRecord.status !== this.originalEditRecords.status );
        if (this.editRecord.orderDate !== null && this.editRecord.orderDate !== '' &&
            this.editRecord.vendorId !== null && this.editRecord.vendorId !== 0 &&
            this.editRecord.planedShipmentDate !== null && this.editRecord.planedShipmentDate !== '' &&
            this.editRecord.name !== null && this.editRecord.name !== '' &&
            this.editRecord.qty !== null && this.editRecord.qty !== 0 &&
            this.editRecord.unitPrice !== null && this.editRecord.unitPrice !== 0 &&
            this.editRecord.stationId !== null && this.editRecord.stationId !== 0 &&
            this.editRecord.consumablesType !== null && this.editRecord.consumablesType !== '' &&
            this.editRecord.status !== null && this.editRecord.status !== ''
        ) {
            if (this.editRecord.orderDate !== this.originalEditRecords.orderDate ||
                this.editRecord.vendorId !== this.originalEditRecords.vendorId ||
                this.editRecord.planedShipmentDate !== this.originalEditRecords.planedShipmentDate ||
                this.editRecord.name !== this.originalEditRecords.name ||
                this.editRecord.qty !== this.originalEditRecords.qty ||
                this.editRecord.unitPrice !== this.originalEditRecords.unitPrice ||
                this.editRecord.stationId !== this.originalEditRecords.stationId ||
                this.editRecord.transportType !== this.originalEditRecords.transportType ||
                this.editRecord.consumablesType !== this.originalEditRecords.consumablesType ||
                this.editRecord.actualShipmentDate !== this.originalEditRecords.actualShipmentDate ||
                this.editRecord.comments !== this.originalEditRecords.comments ||
                this.editRecord.status !== this.originalEditRecords.status ) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllConsumableOrder();
        this.getAllConsumableNames();
        this.getAllConsumableSubTypes();
        this.getAllVendors();
        this.getAllStations();
    }
    getAllConsumableOrder() {
        axiosInstance.get('/logistics/getAllConsumableOrder').then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].orderId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
                // if (this.state.searchText !== '') {
                //     this.search(this.state.searchText);
                // }
            },
            error => {
                displayErrorMessage(error, '获取支架订单失败');
            }
        )

    }
    getAllVendors() {
        axiosInstance.get('/logistics/getAllVendors').then(
            response => {
                this.vendorList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.vendorList.push({label:response.data[i].vendorName, value: response.data[i].vendorId});               
                }
            },
            error => {
                displayErrorMessage(error, '获取供应商失败');
            }
        )
    }
  
  getAllConsumableSubTypes() {
        axiosInstance.get('/logistics/getCommonPropertiesByKey',{params: {key: 'consumableSubType'}}).then(
            response => {
                this.consumableSubTypeList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.consumableSubTypeList.push({label:response.data[i].propertyValue, value: response.data[i].propertyValue});               
                }
            },
            error => {
                displayErrorMessage(error, '获取数据失败');
            }
        )  
    }

    getAllConsumableNames() {
        axiosInstance.get('/logistics/getCommonPropertiesByKey',{params: {key: 'consumableType'}}).then(
            response => {
                this.consumableNameList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.consumableNameList.push({label:response.data[i].propertyValue, value: response.data[i].propertyValue});               
                }
            },
            error => {
                displayErrorMessage(error, '获取数据失败');
            }
        )  
    }   

    getAllStations() {
        axiosInstance.get('/logistics/getAllStations').then(
            response => {
                this.stationList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.stationList.push({label:response.data[i].stationName, value: response.data[i].stationId});
                }
            },
            error => {
                displayErrorMessage(error, 'Get document list failed!');
            }
        )
    }
    onTabChange = (activeKey:string) => {
        if (activeKey == '1') {
            this.getConsumableOrderPaymentByOrderId();            
        }
    };
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 耗材订单管理</span>
                </div>
                <div className="seperate">
                </div>
                {this.state.isEdit? 
                    <div className="content">
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>退出</Button>
                        </div>
                        <Tabs defaultActiveKey="1"  onChange={this.onTabChange}>
                            <TabPane tab='基本信息' key = '1'>
                                <>
                                    <div className="row">
                                        <div className="box">
                                            <label className="box-label">下单日期:</label>
                                            {(this.editRecord.orderDate + "") == "" ?
                                                <DatePicker className="box-input" onChange={this.changeOrderDate} />
                                                :
                                                <DatePicker className="box-input" onChange={this.changeOrderDate} defaultValue={this.editRecord.dayJsOrderDate} />}
                                            <label className="box-label">供应商:</label>
                                            <Select className="box-input"  
                                            showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={this.vendorList} onChange={this.changeSelect.bind(this, 'vendorId')} defaultValue={this.editRecord.vendorName}/>               
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="box">
                                            <label className="box-label">名称:</label> 
                                            <Select className="box-input"  options={this.consumableNameList} onChange={this.changeSelect.bind(this, 'name')} defaultValue={this.editRecord.name}/>                         
                                            <label className="box-label">型号:</label>

                                            <Select className="box-input"  
                                            showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={this.consumableSubTypeList} onChange={this.changeSelect.bind(this, 'consumablesType')} defaultValue={this.editRecord.consumablesType}/>  
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="box">
                                            <label className="box-label">数量:</label>
                                            <Input className="box-input" defaultValue={this.editRecord.qty==0?'':this.editRecord.qty} onChange={this.changeInput.bind(this, 'qty')}/>
                                            <label className="box-label">单价:</label>
                                            {
                                                roles.includes('财务')?
                                                <Input className="box-input" defaultValue={this.editRecord.unitPrice==0?'':this.editRecord.unitPrice} onChange={this.changeInput.bind(this, 'unitPrice')}/>
                                                :  
                                               ''
                                            }                                                    
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="box">
                                            <label className="box-label">发往地:</label>
                                            <Select className="box-input" 
                                             showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                             options={this.stationList} onChange={this.changeSelect.bind(this, 'stationId')} defaultValue={this.editRecord.stationName}/> 
                                            <label className="box-label">运输类型:</label>                                            
                                            <Select className="box-input"  options={this.transportTypeList} onChange={this.changeSelect.bind(this, 'transportType')} defaultValue={this.editRecord.transportType} />
                                        </div>
                                    </div>
                                    <div className="row">
                                    <div className="box">
                                            <label className="box-label">订单状态:</label>
                                            <Select className="box-input"  options={this.statusList} onChange={this.changeSelect.bind(this, 'status')} defaultValue={this.editRecord.status} />                                                       
                                            <label className="box-label">备注:</label>                                            
                                            <Input className="box-input" defaultValue={this.editRecord.comments} onChange={this.changeInput.bind(this, 'comments')}/>                                            
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="box">
                                        <label className="box-label">预计入库日期:</label>
                                            {(this.editRecord.planedShipmentDate + "") == "" ?
                                                <DatePicker className="box-input" onChange={this.changePlanedShipmentDate} />
                                                :
                                                <DatePicker className="box-input" onChange={this.changePlanedShipmentDate} defaultValue={this.editRecord.dayJsPlanedShipmentDate} />}
                                        </div>
                                    </div>                                    

                                    <div className="row">
                                        <div className="box">
                                            <label className="box-label">总价:</label>
                                            <Input className="box-input" value={(this.editRecord.qty * this.editRecord.unitPrice).toFixed(2)} disabled/>
                                            <label className="box-label">进项税额:</label>
                                            <Input className="box-input" value={(((this.editRecord.qty * this.editRecord.unitPrice)/1.13)*0.13).toFixed(2)} disabled/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="box">
                                            <label className="box-label">已付:</label>
                                            <Input className="box-input" value={this.state.paid} disabled/>
                                            <label className="box-label">未付:</label>
                                            <Input className="box-input" value={this.editRecord.qty * this.editRecord.unitPrice - this.state.paid} disabled/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="box">
                                            <label className="box-label">是否入库:</label>
                                            <Input className="box-input" value={this.editRecord.allInInventory?"是":"否"} disabled/>                                          
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                                        <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                                    </div>
                                </>
                            </TabPane>
                            <TabPane tab='入库信息' key = '2' disabled={this.editRecord.orderId == 0}>
                                <ConsumableOrderConsumableInventory orderId={this.editRecord.orderId} orderQty={this.editRecord.qty} /> 
                            </TabPane>
                            <TabPane tab='付款记录' key = '3' disabled={this.editRecord.orderId == 0}>
                                <ConsumableOrderPayment orderId={this.editRecord.orderId} />
                            </TabPane>
                        </Tabs>
                    </div>
                :
                    <div className="content">
                        <label className="box-label">订单状态:</label>
                        <Select style={{ width: '172px' }} options={[{label:'--全部--', value: ''},...this.statusList]} onChange={this.changeSelectSearch.bind(this, 'status')}/>   
                        <label className="box-label">供应商:</label>
                        <Input onChange={this.onSearchVendorName}  className="tableSearch" value={this.searchText.vendorName} />  
                        <label className="box-label">名称:</label>
                        <Input onChange={this.onSearchName}  className="tableSearch" value={this.searchText.name} />  
                        <label className="box-label">型号:</label>
                        <Input onChange={this.onSearchConsumablesType}  className="tableSearch" value={this.searchText.consumablesType} />  
                        <label className="box-label">发往地:</label>
                        <Input onChange={this.onSearchOrderCity}  className="tableSearch" value={this.searchText.orderCity} />  
                        <label className="box-label">是否入库:</label>
                        <Select style={{ width: '172px' }} options={[{label:'--全部--', value: ''},...this.inventoryList]} onChange={this.changeSelectSearch.bind(this, 'allInInventory')}/>  

                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>
                        <div className="row">
                            <Button type="primary" className="header-left" onClick={this.deleteSelected} disabled={this.selectedRows.length==0}>批量删除</Button>  
                        </div>    
                        <Table pagination={{ pageSize: 20 }}  columns={this.columns} rowSelection={{
                            type: 'checkbox',
                            ...this.rowSelection,
                            }} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined,PlusOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';

const dateFormat = 'YYYY-MM-DD';

export default class ShipmentBracket extends React.Component {
    props = {planId: 0, isBracketSelected:0};
    constructor(props:any) {
        super(props);
      }
    
    inventoryColumns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>                   
                        <PlusOutlined title='绑定' onClick={() => this.save(record)} className="actionIcon"/>
                     </div>
                )}
        },
    {
        title: '支架号',
        dataIndex: 'bracketNumber',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.bracketNumber > b.bracketNumber? 1 : -1,
    },
    {
        title: '支架类型',
        dataIndex: 'bracketType',
        showSorterTooltip: false,
        render: (_: any, record: any)=> {
            return (
                <div>
                {
                    record.bracketType == 'recyclable' ? '循环' :  '一次性'
                }                            
                </div>
            )}
    }, 
    {
        title: '入库时间',
        dataIndex: 'entryDate',
        showSorterTooltip: false,
    },
    // {
    //     title: '单位成本',
    //     dataIndex: 'unitCost',
    //     showSorterTooltip: false,
    // },
    { 
        title: '堆场',
        dataIndex: 'stationName',
        showSorterTooltip: false,
    },
    ];

    selectedColumns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <Popconfirm
                            title="删除支架"
                            description="是否确定删除?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除' className="actionIcon"/>
                        </Popconfirm>
                        
                    </div>
                )}
        },
    {
        title: '支架号',
        dataIndex: 'bracketNumber',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.bracketNumber > b.bracketNumber? 1 : -1,
    },
    {
        title: '集装箱号',
        dataIndex: 'containerNumber',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.bracketNumber > b.bracketNumber? 1 : -1,
    },
    {
        title: '支架类型',
        dataIndex: 'bracketType',
        showSorterTooltip: false,
        render: (_: any, record: any)=> {
            return (
                <div>
                {
                    record.bracketType == 'recyclable' ? '循环' :  '一次性'
                }                            
                </div>
            )}
    },
  
    ];

    originalData: any[] = [];
    originalInvData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        bracketInventoryList: this.originalInvData,
        searchText: '',        
    };

    
    allPermissions: any[] = [];
    
    editRecord = this.createShipmentBracket();
    originalEditRecords: any = {};    
    canBeSaved = false;
    
    createShipmentBracket() {
        return { 
            id: 0,
            bracketId : 0,
            stationId : 0,
            vendorId : 0,
            bracketNumber : "",
            bracketType : "",
            entryDate : "",
            unitCost : 0,
            stationName : "",
            customerName : "",
        };
    }
    onDelete = (record: any) => {
        const ShipmentBracket = {id: record.id, planId: this.props.planId, bracketId: record.bracketId, bracketNumber: record.bracketNumber, bracketType: record.bracketType, unitCost: record.unitCost};
        axiosInstance.post('/logistics/deleteShipmentBracketById', ShipmentBracket).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }

    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
        console.log(`selectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.selectedRows = selectedRows;
        this.checkCanBeSaved();
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };

    deleteSelected = () => {        
        this.selectedRows.forEach(element => {
            const ShipmentBracket = {id: element.id, planId: this.props.planId, bracketId: element.bracketId, bracketNumber: element.bracketNumber};
            axiosInstance.post('/logistics/deleteShipmentBracketById', ShipmentBracket).then(
                response => {
                    this.init();
                   // message.success('删除成功');
                },
                error => {
                    displayErrorMessage(error, '删除失败');
                }
            )
        })       
        this.init();
        message.success('删除成功');
    }

    onAdd = () => {
        this.editRecord = this.createShipmentBracket();
        this.originalEditRecords = this.createShipmentBracket();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    save = (record: any) => {
        axiosInstance.post('/logistics/insertShipmentBracket', {planId: this.props.planId, bracketId: record.bracketId}).then(
            response => {
                this.init();
                message.success('新增成功');
            },
            error => {
                displayErrorMessage(error, '新增失败');
            }
        )
    } 

    markResourceSelected = (record: any) => {
        axiosInstance.post('/logistics/markResourceSelected', {planId: this.props.planId, isBracketSelected: 1}).then(
            response => {
                this.init();
                message.success('操作成功');
            },
            error => {
                displayErrorMessage(error, '操作失败');
            }
        )
    }

    cancel = () => {
        this.setState({isEdit: false});
    }
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.bracketId !== null && this.editRecord.bracketId !== 0) {
            if (this.editRecord.bracketId !== this.originalEditRecords.bracketId) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false,bracketInventoryList:[]});
        this.getBracketInventoryByPlanId();
        this.getAvaiableBrackets();
    }
    getBracketInventoryByPlanId() {
        axiosInstance.get('/logistics/getShipmentBracketByPlanId', {params: {planId: this.props.planId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].bracketId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取支架失败');
            }
        )

    }
    getAvaiableBrackets() {        
        axiosInstance.get('/logistics/getAvaiableBracketForPlan', {params: {planId: this.props.planId}}).then(
            response => {
                this.originalInvData = response.data;
                for(let i = 0; i < response.data.length; i++) {
                    this.originalInvData[i].key = this.originalInvData[i].bracketId;                    
                }
                this.setState({bracketInventoryList: this.originalInvData});
            },
            error => {
                displayErrorMessage(error, '获取支架失败!');
            }
        );
      
    }

    onSearch = (value: any) => {
        this.setState({searchText: value.target.value})
        this.search(value.target.value);
    }
    search = (searchText: string) => {
        this.setState({
            bracketInventoryList: this.originalInvData.filter((item: any) => (
                    item.bracketNumber.toLowerCase().includes(searchText.toLowerCase()) )
            )
          })
    }

    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="seperate">
                </div>                
                <div className="content">       
                        <Input placeholder="输入支架号" onChange={this.onSearch} className="tableSearch" value={this.state.searchText} />       
                        <Button className="header-right" type="primary" onClick={this.markResourceSelected} disabled={this.props.isBracketSelected==1}>已完成</Button>                                        
                        <Table columns={this.inventoryColumns} pagination={{ showSizeChanger: true  }} dataSource={this.state.bracketInventoryList} bordered loading={!this.state.isLoaded}/>
                        已绑定的支架:                     
                        <Table  columns={this.selectedColumns} pagination={{ showSizeChanger: true  }} title={() => '支架数量: ' + this.state.dataSource?.length}  dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                </div>
            </div>
        )      
    }
}

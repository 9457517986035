import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined, PlusOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DD';

export default class ComsumableOrderProduct extends React.Component {
    emptyProductCodeList: any[] = [];
    props = {orderId: 0, productCodeList:this.emptyProductCodeList};
    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp; &nbsp;
                        <Popconfirm
                            title="删除商品"
                            description="是否确定删除?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除' className="actionIcon"/>
                        </Popconfirm>
                        
                    </div>
                )}
        },
        {
            title: '商品编码',
            dataIndex: 'productNumber',
            showSorterTooltip: false,
        },
        {
            title: '品名',
            dataIndex: 'productName',
            showSorterTooltip: false,
        },
        {
            title: '规格',
            dataIndex: 'productSpec',
            showSorterTooltip: false,
        },
        {
            title: '单价',
            dataIndex: 'unitPrice',
            showSorterTooltip: false,
        },
        {
            title: '数量',
            dataIndex: 'qty',
            showSorterTooltip: false,
        },
        {
            title: '合计金额',
            dataIndex: 'amount',
            showSorterTooltip: false,
        },
        {
            title: '备注',
            dataIndex: 'comments',
            showSorterTooltip: false,
        },
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
        unitPriceList: [],
        amount: 0,
    };
    allUsers: any[] = [];
    
    editRecord = this.createConsumableOrderProduct();
    originalEditRecords: any = {};
    canBeSaved = false;
    
    createConsumableOrderProduct() {
        return { 
            id : 0,
            orderId: this.props.orderId,
            productId: "",
            productName : "",
            productSpec: "",
            unitPrice: 0,
            qty: 0,
            amount: 0,
            comments: ""
        };
    }
    
    copyConsumableOrderProduct(consumableOrderProduct: any) {
        return { 
            id : consumableOrderProduct.id,
            orderId: this.props.orderId,
            productId: consumableOrderProduct.productId,
            productName : consumableOrderProduct.productName,
            productSpec: consumableOrderProduct.productSpec,
            unitPrice: consumableOrderProduct.unitPrice,
            qty: consumableOrderProduct.qty,
            amount: consumableOrderProduct.unitPrice * consumableOrderProduct.qty,
            comments: consumableOrderProduct.comments,
        };
    }

    onSearch = (value: any) => {
        this.setState({searchText: value.target.value})
        this.search(value.target.value);
    }
    search = (searchText: string) => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => ( item.productSpec.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.productName.toLowerCase().includes(searchText.toLowerCase()))
            )
          })
    }
    onEdit = (record: any) => {
        this.originalEditRecords = this.copyConsumableOrderProduct(record);
        this.editRecord = this.copyConsumableOrderProduct(record);
        for (let i = 0; i < this.props.productCodeList.length; i++) {
            let value = this.props.productCodeList[i].value.split("*");
            if (value[0] == this.editRecord.productId) {
                this.generateUnitPriceSelectList(value[3]);
            }
        }
        this.canBeSaved = false;

        this.setState({isEdit: true, amount: this.editRecord.amount});
    }
    onDelete = (record: any) => {
        const ConsumableOrderProduct = {id: record.id, productName: record.productName};
        axiosInstance.post('/logistics/deleteConsumableOrderProduct', ConsumableOrderProduct).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createConsumableOrderProduct();
        this.originalEditRecords = this.createConsumableOrderProduct();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    save = () => {
        const consumableOrderProduct = this.copyConsumableOrderProduct(this.editRecord);
        let sameProductIdCount = 0;
        for (let i = 0; i < this.state.dataSource.length; i++) {
            if (consumableOrderProduct.productId == this.state.dataSource[i].productId) {
                sameProductIdCount++;
            }
        }
        // if ((consumableOrderProduct.id === 0 && sameProductIdCount > 0) ||
        //     (consumableOrderProduct.id !== 0 && sameProductIdCount > 1)) {
        //         message.error("已经存在相同的产品了！");
        //         return;
        // }
        if (consumableOrderProduct.id === 0) {
            axiosInstance.post('/logistics/insertConsumableOrderProduct', consumableOrderProduct).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateConsumableOrderProduct', consumableOrderProduct).then(
                response => {
                    this.init();
                    message.success('修改成功');
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )

        }
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.editRecord.amount = this.editRecord.unitPrice * this.editRecord.qty;
        this.setState({amount: this.editRecord.amount});
        this.checkCanBeSaved();
    }
    changeUnitPrice = (event: any) => {
        this.editRecord.unitPrice = event;
        this.editRecord.amount = this.editRecord.unitPrice * this.editRecord.qty;
        this.setState({amount: this.editRecord.amount});
        this.checkCanBeSaved();
    }
    changeProductCode = (event: any) => {
        let valueList: string[] = event.split("*");
        this.editRecord.productId = valueList[0];
        this.editRecord.productName = valueList[1];
        this.editRecord.productSpec = valueList[2];
        this.generateUnitPriceSelectList(valueList[3]);
        this.checkCanBeSaved();
    }
    generateUnitPriceSelectList = (unitPriceValue: string) => {
        let unitPriceListValue = unitPriceValue.split(",");
        let unitPriceList: any[] = [];
        for (let i = 0; i < unitPriceListValue.length; i++) {
            unitPriceList.push({label: unitPriceListValue[i], value: unitPriceListValue[i]});
        }
        this.setState({unitPriceList: unitPriceList});

    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.productName !== null && this.editRecord.productName !== ''
            && this.editRecord.qty >= 0 && this.editRecord.unitPrice >= 0
        ) {
            if (this.editRecord.productName !== this.originalEditRecords.productName ||
                this.editRecord.productSpec !== this.originalEditRecords.productSpec ||
                this.editRecord.unitPrice != this.originalEditRecords.unitPrice ||
                this.editRecord.qty != this.originalEditRecords.qty ||
                this.editRecord.comments !== this.originalEditRecords.comments) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllConsumableOrderProducts();
    }
    getAllConsumableOrderProducts() {
        axiosInstance.get('/logistics/getAllConsumableOrderProducts',{params: {orderId: this.props.orderId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].id;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
                if (this.state.searchText !== '') {
                    this.search(this.state.searchText);
                }                
            },
            error => {
                displayErrorMessage(error, '获取商品失败');
            }
        )

    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div style={{width: '1300PX'}}>              
                {this.state.isEdit? 
                    <Modal title="修改" onCancel={this.cancel} maskClosable={false} forceRender open={true} mask={false} transitionName={''} maskTransitionName={''}
                        footer={[
                            <div className="content">
                                <div>
                                    <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                                    <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                                </div>
                            </div>,
                            <br/>,
                        ]}
                    >
                        <div className="content">
                            <div className="row">
                                <div className="box">
                                    <label className="box-label">商品编号-品名-规格:</label>
                                    <Select className="box-input" disabled={this.editRecord.id !== 0} 
                                     showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                     options={this.props.productCodeList} onChange={this.changeProductCode} defaultValue={this.editRecord.productId + "-" + this.editRecord.productName + "-" + this.editRecord.productSpec} />                                                       
                                </div>
                            </div>
                            <div className="row">
                                <div className="box">   
                                    <label className="box-label">单价:</label>
                                    <Select className="box-input" defaultValue={this.editRecord.unitPrice==0?'':this.editRecord.unitPrice}  options={this.state.unitPriceList} onChange={this.changeUnitPrice} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="box">   
                                    <label className="box-label">数量:</label>
                                    <Input className="box-input" defaultValue={this.editRecord.qty==0?'':this.editRecord.qty} onChange={this.changeInput.bind(this, 'qty')}/>                      
                                </div>
                            </div>
                            <div className="row">
                                <div className="box">   
                                    <label className="box-label">合计金额:</label>
                                    <Input className="box-input" disabled value={this.state.amount.toFixed(2)}/>                      
                                </div>
                            </div>
                            <div className="row">
                                <div className="box">   
                                    <label className="box-label">备注:</label>
                                    <Input className="box-input" defaultValue={this.editRecord.comments} onChange={this.changeInput.bind(this, 'comments')}/>                      
                                </div>
                            </div>
                        </div>
                    </Modal>
                :
                    <div className="content">
                        <PlusOutlined className="actionIcon header-right" title='新增' onClick={this.onAdd}/>
                        
                        <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}

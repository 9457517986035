import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined,PlusOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import store from '../../config/store';
import {setChanged} from '@/config/store/slice/returnPlanBracketReducer';

const dateFormat = 'YYYY-MM-DD';

export default class ReturnBracket extends React.Component {
    props = {planId: 0, isView: false};
    constructor(props:any) {
        super(props);
    }
    
    bracketColumns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>  
                        {
                            this.props.isView?
                            '':         
                            <Popconfirm
                                title="删除支架"
                                description="是否确定删除?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => this.onDelete(record)}
                            >
                                <DeleteOutlined title='删除' className="actionIcon"/>
                            </Popconfirm>
                        }        
                     </div>
                )}
        },
        {
            title: '支架号',
            dataIndex: 'bracketNumber',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.bracketNumber > b.bracketNumber? 1 : -1,
        },
        {
            title: '集装箱号',
            dataIndex: 'containerNumber',
            showSorterTooltip: false,
        },
        {
            title: '原始班列号',
            dataIndex: 'oriTrainNumber',
            showSorterTooltip: false,
        },
        {
            title: '支架类型',
            dataIndex: 'bracketType',
            showSorterTooltip: false,
        },       
        {
            title: '单位成本',
            dataIndex: 'unitCost',
            showSorterTooltip: false,
        },
        {
            title: '是否入库',
            dataIndex: 'status',
            showSorterTooltip: false,        
            sorter: (a: any, b: any) => a.status > b.status? 1 : -1,
            render: (_: any, record: any)=> {
                return (
                    <div>
                            {                            
                                record.status === 'Available' ? '已入库' : '未入库'     
                            }
                            
                        </div>
                )}
        },   
    ];
    
    selectableBracketColumns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <PlusOutlined title='新增' onClick={() => this.saveOne(record)} className="actionIcon"/> 
                     </div>
                )}
        },
        {
            title: '支架号',
            dataIndex: 'bracketNumber',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.bracketNumber > b.bracketNumber? 1 : -1,
        },
        {
            title: '支架类型',
            dataIndex: 'bracketType',
            showSorterTooltip: false,
        },
        {
            title: '入库时间',
            dataIndex: 'entryDate',
            showSorterTooltip: false,
        },
        {
            title: '单位成本',
            dataIndex: 'unitCost',
            showSorterTooltip: false,
        },
    ];

    originalData: any[] = [];
    originalInvData: any[] = [];
    oriShipmentNumberList: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        bracketInventoryList: this.originalInvData,
        isChangeStation:false,
        searchText: '',      
    };
    bracketInventoryList: any[] = [];


    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
        console.log(`selectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.selectedRows = selectedRows;
        this.checkCanBeSaved();
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };
    
    allPermissions: any[] = [];
    sectionList: any[] = [];
    
    editRecord = this.createReturnBracket();
    canBeSaved = false;
    
    getAllStations() {
        axiosInstance.get('/logistics/getAllStations').then(
            response => {
                for(let i = 0; i < response.data.length; i++) {
                    this.sectionList.push({label:response.data[i].stationName, value: response.data[i].stationId});
                }
            },
            error => {
                displayErrorMessage(error, 'Get document list failed!');
            }
        )
    }

    createReturnBracket() {
        return { 
            id: 0,
            bracketId : 0,
            planId : this.props.planId,
            vendorId : 0,
            stationId : 0,
            isChangeStation:false,
            stationName : "",
            oriTrainNumber : "",
            shipmentPlanId: 0,
            containerNumber : "",
        };
    }
    onDelete = (record: any) => {
        const ReturnBracket = {id: record.id};
        axiosInstance.post('/logistics/deleteReturnBracketById', ReturnBracket).then(
            response => {
                this.init();
                store.dispatch(setChanged());
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    saveOne = (record: any) => {
        axiosInstance.post('/logistics/insertReturnBracket', {planId: this.props.planId, bracketId: record.bracketId, oriTrainNumber: this.editRecord.oriTrainNumber, containerNumber: this.editRecord.containerNumber}).then(
            response => {
                const newArr = this.selectedRows.filter(item => item.bracketId !== record.bracketId);
                this.selectedRows = newArr;
                this.initAndComeToEdit();
                store.dispatch(setChanged());
                message.success('新增成功');
            },
            error => {
                displayErrorMessage(error, '新增失败');
            }
        )
    }
    saveSelected = () => {
        const ReturnBracketList: any[] = [];
        this.selectedRows.forEach(element => {
            ReturnBracketList.push({planId: this.props.planId, bracketId: element.bracketId, oriTrainNumber: this.editRecord.oriTrainNumber, containerNumber: this.editRecord.containerNumber});
        })

        axiosInstance.post('/logistics/insertReturnBracketList', ReturnBracketList).then(
            response => {
                ReturnBracketList.forEach(element => {
                    const newArr = this.selectedRows.filter(item => item.bracketId !== element.bracketId);
                    this.selectedRows = newArr;
                });
                this.initAndComeToEdit();
                store.dispatch(setChanged());
                this.checkCanBeSaved();
                message.success('新增成功');
            },
            error => {
                displayErrorMessage(error, '新增失败');
            }
        )
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeoriShipmentNumber = (event: any) => {        
        this.editRecord.shipmentPlanId = event;
        this.oriShipmentNumberList.forEach(element => {
            if (this.editRecord.shipmentPlanId == element.value) {
                this.editRecord.oriTrainNumber = element.label;
            }
        })
        this.getReturnBracketByShipmentPlanId();
    }
    changecontainerNumber = (event: any) => {
        this.editRecord.containerNumber = event.target.value
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.containerNumber !== null && this.editRecord.containerNumber !== '') {
            if (this.editRecord.oriTrainNumber !== null && this.editRecord.oriTrainNumber !== '' &&
                this.selectedRows.length > 0
            ) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.bracketInventoryList = [];
        this.setState({dataSource: null, isLoaded: false, isEdit: false,bracketInventoryList:[]});
        this.getBracketInventoryByPlanId();
        this.getReturnBracketByShipmentPlanId();
        this.getAllTrainNumber();
        this.getAllStations();
    }
    initAndComeToEdit() {
        this.init();
        this.setState({isEdit: true});
    }
    getBracketInventoryByPlanId() {
        axiosInstance.get('/logistics/getReturnBracketByPlanId', {params: {planId: this.props.planId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].bracketId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取支架失败');
            }
        )

    }
    getReturnBracketByShipmentPlanId() {
        if (this.editRecord.shipmentPlanId !== 0) {
            axiosInstance.get('/logistics/getReturnBracketByShipmentPlanId', {params: {planId: this.editRecord.shipmentPlanId}}).then(
                response => {
                    this.originalInvData = response.data;
                    for(let i = 0; i < response.data.length; i++) {
                        this.originalInvData[i].key = this.originalInvData[i].bracketId;                    
                    }
                    this.setState({bracketInventoryList: this.originalInvData});
                    this.bracketInventoryList = this.originalInvData;
                    this.checkCanBeSaved();
                },
                error => {
                    displayErrorMessage(error, '获取支架失败!');
                }
            );
        }   
      
    }
    getAllTrainNumber() {
        axiosInstance.get('/logistics/getAllExportPlans').then(
            response => {
                this.oriShipmentNumberList = [];
                for(let i = 0; i < response.data.length; i++) {
                    this.oriShipmentNumberList.push({label: response.data[i].trainNumber, value: response.data[i].shipmentPlanId});                    
                }
                console.log('oriShipmentNumberList',this.oriShipmentNumberList);
            },
            error => {
                displayErrorMessage(error, '获取班列号失败!');
            }
        );
      
    }

    onSearch = (value: any) => {
        this.setState({searchText: value.target.value})
        this.search(value.target.value);
    }
    search = (searchText: string) => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => (
                    item.bracketNumber.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.oriTrainNumber.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.containerNumber.toLowerCase().includes(searchText.toLowerCase()))
            )
        });
    }

    
    changeStation = () => { 
        this.setState({isChangeStation: !this.state.isChangeStation});        
    };

    changeStationId = (event: any) => {
        this.editRecord.stationId = event;
        this.checkCanBeSaved();
    }
    
    putBracketToInventory = () => {        
        this.selectedRows.forEach(element => {            
            const bracketInventory = {bracketId: element.bracketId,vendorId:element.vendorId, bracketNumber: element.bracketNumber,bracketType:element.bracketType,
                entryDate:element.entryDate,unitCost:element.unitCost,status:element.status, stationId: this.editRecord.stationId};
            axiosInstance.post('/logistics/putBracketToInventory', bracketInventory).then(
                response => {
                    this.init();                   
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )
        })          
        message.success('修改成功');          
        this.setState({isChangeStation: !this.state.isChangeStation});        
    }

    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="seperate">
                </div>   
                {this.state.isEdit? 
                           
                    <div className="content"> 
                        <label>集装箱号:</label>
                        <Input style={{width: '200PX'}} defaultValue={this.editRecord.containerNumber} onChange={this.changecontainerNumber}/>   
                        <label style={{marginLeft: '20PX'}}>原始班列号:</label>
                        <Select style={{width: '200PX'}} options={this.oriShipmentNumberList} onChange={this.changeoriShipmentNumber} /> 
                        <Table  
                            rowSelection={{
                            type: 'checkbox',
                            ...this.rowSelection,
                            }} columns={this.selectableBracketColumns} dataSource={this.state.bracketInventoryList} bordered loading={!this.state.isLoaded}/>
                        
                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>退出</Button>
                            <Button className="header-right" type="primary" onClick={this.saveSelected} disabled={!this.canBeSaved}>增加</Button>
                        </div>
                    </div>
                    : 
                    <div className="content">    
                        <label className="box-label">查询支架:</label>
                        <Input placeholder="输入支架号" onChange={this.onSearch} className="tableSearch" value={this.state.searchText} />   
                        {
                            this.props.isView?
                            '':
                            <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button> 
                        }
                        <br/>
                        <Button type="primary" className="header-left" onClick={this.changeStation} disabled={this.selectedRows.length==0}>批量入库</Button>                              
                        {this.state.isChangeStation? 
                        <div className="box">
                            <label className="box-label">堆场:</label>
                            <Select className="box-input"  options={this.sectionList} onChange={this.changeStationId} defaultValue={this.editRecord.stationName}/> 
                            <Button type="primary" className="header-left" onClick={this.putBracketToInventory}>确定</Button>  
                        </div>
                        :''
                        }                        
                      
                        <Table  pagination={{ pageSize: 200 }}  rowSelection={{ type: 'checkbox',  ...this.rowSelection, }} columns={this.bracketColumns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>

                }  
            </div>
        )      
    }
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';
const dateFormat = 'YYYY-MM-DD';

export default class ExportRecycleInvoice extends React.Component {
    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp; &nbsp;                       
                        <Popconfirm
                            title="删除"
                            description="Are you sure to delete this record?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除' className="actionIcon"/>
                        </Popconfirm>
                        
                    </div>
                )}
        },
    {
        title: '班列号',
        dataIndex: 'trainNumber',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.loginName > b.loginName? 1 : -1,
    },
    // {
    //     title: '箱号',
    //     dataIndex: 'containerNumber',
    //     showSorterTooltip: false,
    //     sorter: (a: any, b: any) => a.userName > b.userName? 1 : -1,
    // },
    {
        title: '供应商',
        dataIndex: 'vendorName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.address > b.address? 1 : -1,
    },
    {
        title: '发票号',
        dataIndex: 'invoice',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.contactPhone > b.contactPhone? 1 : -1,
    },
    {
        title: '开票日期',
        dataIndex: 'invoiceDate',
        showSorterTooltip: false,
    },
    {
        title: '审核',
        dataIndex: 'verified',
        showSorterTooltip: false,        
        sorter: (a: any, b: any) => a.verified > b.verified? 1 : -1,
        render: (_: any, record: any)=> {
            return (
                <div>
                    {
                        record.verified == true ? '是 ' :'否 '
                    }
                </div>
            )}
    },  
    {
        title: '金额',
        dataIndex: 'totalPrice',
        showSorterTooltip: false,
    },
    
    {
        title: '是否付款',
        dataIndex: 'paid',
        showSorterTooltip: false,
        render: (_: any, record: any)=> {
            return (
                <div>
                        {
                            record.totalPrice == record.paid ? '是' : '否'
                        }
                        
                    </div>
            )}
    },
    {
        title: '付款日期',
        dataIndex: 'paymentDate',
        showSorterTooltip: false,
    }
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
    };
    vendorList: any[] = [];
    allPermissions: any[] = [];
    id = 0;
    trainNumber = "";
    containerNumber = "";
    vendorId = 0;
    vendorName = "";
    invoice = "";
    invoiceDate = "";
    dayJsInvoiceDate = dayjs(dayjs(new Date()), dateFormat);
    dayJsPaymentDateDate = dayjs(dayjs(new Date()), dateFormat);
    totalPrice = 0;
    paid = 0;
    paymentDate = "";
    shipmentPlanList: any[] = [];
    permissionList: string[] = [];
    originalEditRecords: any = {};
    canBeSaved = false;
    yesNoList: any[] = [{label:'是', value: 'YES'},{label:'否', value: 'NO'}]; 
    onSearch = (value: any) => {
        this.setState({searchText: value.target.value})    
        this.search(value.target.value);
    }
    search = (searchText: string) => {       
        this.setState({
            dataSource: this.originalData.filter((item: any) => (item.trainNumber.toLowerCase().includes(searchText.toLowerCase())  ||
                        item.containerNumber.toLowerCase().includes(searchText.toLowerCase() ) ||
                        item.vendorName.toLowerCase().includes(searchText.toLowerCase() ) ||
                        item.invoice.toLowerCase().includes(searchText.toLowerCase() )    
                    )
            )
          })
    }

    onEdit = (record: any) => {
        this.id = record.id;
        this.trainNumber = record.trainNumber;
        this.containerNumber = record.containerNumber;
        this.vendorId = record.vendorId;
        this.vendorName = record.vendorName;
        this.invoice = record.invoice;
        this.invoiceDate = record.invoiceDate;
        this.totalPrice = record.totalPrice;
        this.paid = record.paid;
        this.paymentDate = record.paymentDate;        
        this.originalEditRecords = {id: record.id, trainNumber: record.trainNumber, containerNumber: record.containerNumber, vendorId: record.vendorId, 
            vendorName: record.vendorName, invoice: record.invoice, invoiceDate: record.invoiceDate, totalPrice: record.totalPrice, paid: record.paid, paymentDate: record.paymentDate};
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    onDelete = (record: any) => {
        const data = {id: record.id};
        axiosInstance.post('/logistics/deleteRecycleInvoiceById', data).then(
            response => {
                this.init();
                message.success('操作成功!');
            },
            error => {
                displayErrorMessage(error, 'Delete user failed!');
            }
        )
    }

    getAllExportPlan() {
        axiosInstance.get('/logistics/getAllExportPlans').then(
            response => {
                this.shipmentPlanList= [];                
                for(let i = 0; i < response.data.length; i++) {
                    this.shipmentPlanList.push({label:response.data[i].trainNumber, value: response.data[i].trainNumber});                    
                }                
            },
            error => {
                displayErrorMessage(error, '获取计划失败!');
            }
        )
    }
  
    onAdd = () => {
        this.trainNumber = '';
        this.containerNumber = '';
        this.dayJsInvoiceDate = dayjs(dayjs(new Date()), dateFormat);
        this.dayJsPaymentDateDate = dayjs(dayjs(new Date()), dateFormat);
        this.vendorId = 0;
        this.vendorName = '';
        this.invoice = '';
        this.invoiceDate = '';
        this.totalPrice = 0;
        this.paid = 0;
        this.paymentDate = '';
        this.permissionList = [];
        this.originalEditRecords = {id: 0, trainNumber: '', containerNumber: '', vendorId: 0, vendorName: '',invoice: '', invoiceDate: '', totalPrice: 0, paid: 0, paymentDate: ''};
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    
    onExport = () => {
        this.setState({isLoaded: false});
        axiosInstance.get('/logistics/exportUser', {responseType: 'blob'}).then(
            response => {
                this.setState({isLoaded: true});
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const fileName = 'user.xlsx';
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                message.success('Export successful!');
            },
            error => {
                displayErrorMessage(error, 'Export failed!');
            }
        );

    }
    save = () => {
        const invoice = {id: this.id, trainNumber: this.trainNumber, containerNumber: this.containerNumber, vendorId: this.vendorId, vendorName: this.vendorName,
            invoice: this.invoice,invoiceDate: this.invoiceDate,totalPrice: this.totalPrice,paid: this.paid,paymentDate: this.paymentDate,dataType: 'Export'};            
        if (this.id === 0) {            
            axiosInstance.post('/logistics/insertRecycleInvoice', invoice).then(
                response => {
                    this.init();
                    message.success('操作成功!');
                },
                error => {
                    displayErrorMessage(error, 'Insert failed!');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateRecycleInvoice', invoice).then(
                response => {
                    this.init();
                    message.success('操作成功!');
                },
                error => {
                    displayErrorMessage(error, 'Update failed!');
                }
            )

        }
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeTrainNumber = (event: any) => {
        this.trainNumber = event.target.value;
        this.checkCanBeSaved();
    }
    changeContainerNumber = (event: any) => {
        this.containerNumber = event.target.value;
        this.checkCanBeSaved();
    }
    changeInvoice = (event: any) => {
        this.invoice = event.target.value;
        this.checkCanBeSaved();
    }
    changeInvoiceDate = (date: any, dateString: string) => {
        this.invoiceDate = dateString;
        this.checkCanBeSaved();
    }
    changeTotalPrice = (value: any) => {
        this.totalPrice = value.target.value;;        
        this.checkCanBeSaved();
    }
    changePaymentDate = (date: any, dateString: string) => {    
        this.paymentDate = dateString;        
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.trainNumber !== null && this.trainNumber !== '' ||
        this.vendorId !== null && this.vendorId !== 0||
        this.invoice !== null && this.invoice !== '' ) {
            if (this.trainNumber !== this.originalEditRecords.trainNumber ||
                this.containerNumber !== this.originalEditRecords.containerNumber ||
                this.vendorId !== this.originalEditRecords.vendorId ||
                this.invoice !== this.originalEditRecords.invoice ||
                this.totalPrice !== this.originalEditRecords.totalPrice ||
                this.invoiceDate !== this.originalEditRecords.invoiceDate||
                this.paymentDate !== this.originalEditRecords.paymentDate) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllRecycleInvoice();
        this.getAllVendors();
        this.getAllExportPlan();
    }

    getAllVendors() {
        axiosInstance.get('/logistics/getAllVendors').then(
            response => {
                this.vendorList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.vendorList.push({label:response.data[i].vendorName, value: response.data[i].vendorId});               
                }
            },
            error => {
                displayErrorMessage(error, '获取供应商失败');
            }
        )
    }

    changeVendorSelect = (field: string, event: any) => {               
        this.vendorId = event;
        this.checkCanBeSaved();        
    }

    changeIsPaidSelect = (field: string, event: any) => {        
        if(event==='YES'){
            this.paid = this.totalPrice;
        }else{
            this.paid = 0;
        }
        this.canBeSaved = true; 
        this.setState({});
    }

    changeTrainSelect = (field: string, event: any) => {                
        this.trainNumber = event;
        this.checkCanBeSaved();        
    }

    getAllRecycleInvoice() {
        axiosInstance.get('/logistics/getAllRecycleInvoice').then(
            response => {
                this.originalData = response.data.filter((item: any) => (item.dataType.toLowerCase().includes('export')))
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].id;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
                if (this.state.searchText !== '') {
                    this.search(this.state.searchText);
                }                
            },
            error => {
                displayErrorMessage(error, 'Get list failed!');
            }
        )

    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 出口发票管理</span>
                </div>
                <div className="seperate">
                </div>
                
                
                {this.state.isEdit? 
                    <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">班列号:</label>                                
                                <Select className="box-input"  options={this.shipmentPlanList} onChange={this.changeTrainSelect.bind(this, 'trainNumber')} defaultValue={this.trainNumber} /> 
                                                                   
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <label className="box-label">供应商:</label>                                
                                <Select className="box-input"  
                                showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                options={this.vendorList} onChange={this.changeVendorSelect.bind(this, 'vendorId')} defaultValue={this.vendorName}/>  
                                <label className="box-label">发票号:</label> 
                                <Input className="box-input" onChange={this.changeInvoice} defaultValue={this.invoice}/>                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <label className="box-label">开票日期:</label>
                                {(this.invoiceDate + "") == ""?
                                    <DatePicker className="box-input" onChange={this.changeInvoiceDate }/>   
                                    :
                                    <DatePicker className="box-input" onChange={this.changeInvoiceDate} defaultValue={this.dayJsInvoiceDate}/>   
                                }                                    
                                <label className="box-label">金额:</label> 
                                <Input className="box-input" onChange={this.changeTotalPrice} defaultValue={this.totalPrice==0?'':this.totalPrice}/>                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <label className="box-label">是否付款:</label> 
                                <Select className="box-input"  
                                options={this.yesNoList} onChange={this.changeIsPaidSelect.bind(this, 'paid')} defaultValue={this.totalPrice!=this.paid?'NO':'YES'}/>                                                                   
                                <label className="box-label">付款日期:</label>
                                {(this.invoiceDate + "") == ""?
                                    <DatePicker className="box-input" onChange={this.changePaymentDate }/>   
                                    :
                                    <DatePicker className="box-input" onChange={this.changePaymentDate} defaultValue={this.dayJsPaymentDateDate}/>   
                                }                                    
                                                       
                            </div>
                        </div>
                     
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                        </div>
                    </div>
                :
                    <div className="content">
                        <Input placeholder="输入要查询的字符" onChange={this.onSearch} className="tableSearch" value={this.state.searchText} /> 
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>
                        
                        <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}
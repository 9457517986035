import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker, Progress, Tabs, Card} from 'antd';
import { EditOutlined, DeleteOutlined, DownloadOutlined, CaretDownOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';
import TabPane from 'antd/es/tabs/TabPane';
import ShipmentBracket from './ExportPlan/ShipmentBracket';
import CostManagement from './ExportPlan/CostManagement';
import ExportStatusLog from './ExportPlan/ExportStatusLog';

const dateFormat = 'YYYY-MM-DD';

export default class ExportPlan extends React.Component {
        
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 80,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp;
                        <Popconfirm
                            title="删除出口计划"
                            description="是否确定删除?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除' className="actionIcon"/>
                        </Popconfirm>
                        
                    </div>
                )}
        },
    {
        title: '客户名(Клиенты)',
        dataIndex: 'customerName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.customerName > b.customerName? 1 : -1,
    },
    {
        title: '批次号',
        dataIndex: 'shipmentNumber',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.shipmentNumber > b.shipmentNumber? 1 : -1,
    },
    {
        title: '支架数',
        dataIndex: 'bracketQTY',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.bracketQTY > b.bracketQTY? 1 : -1,
    },
    {
        title: '班列号(номер поездов)',
        dataIndex: 'trainNumber',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.trainNumber > b.trainNumber? 1 : -1,
    },
    {
        title: '发运日(Дата отправления)',
        dataIndex: 'shipmentDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.shipmentDate > b.shipmentDate? 1 : -1,
    },
    {
        title: '始发站(город отправдения)',
        dataIndex: 'deparStation',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.deparStation > b.deparStation? 1 : -1,
    },
    {
        title: '出境口岸(Погранпереход)',
        dataIndex: 'exitPort',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.exitPort > b.exitPort? 1 : -1,
    },
    {
        title: '状态',
        dataIndex: 'status',        
    },
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
    };
    allPermissions: any[] = [];
    isView = false;
    editRecord = this.createExportPlan();
    originalEditRecords: any = {};
    basicInfoCanBeSaved = false;

    deparStationList: any[] = [];
    destinationStationList: any[] = [];
    carBrandList: any[] = [];
    exitPortList: any[] = [];
    shipmentPlanList: any[] = [];
    bracketQTYList: any[]= [];
    statusList: any[] = [{label:'待发运', value: '待发运'},{label:'在途', value: '在途'},{label:'已到站', value: '已到站'},{label:'已复进口', value: '已复进口'}]; 
    
    createExportPlan() {
        return { 
            planId : 0,
            shipmentPlanId : null,
            trainNumber: '',
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            expectedArriveDate: "",
            dayJsexpectedArriveDate : dayjs(dayjs(new Date()), dateFormat),
            deparStation: "",
            destinationStation: "",
            carBrand: "",
            exitPort: "",
            bracketQTY : 0,
            bracketWeight: 0,
            guideWheelInfo : "",
            contractNumber : "",
            status : "",
            customsDeclareNumber: "",
            declareDate: "",
            dayJsdeclareDate : dayjs(dayjs(new Date()), dateFormat),
            guaranteePeriodStart: "",
            dayJsguaranteePeriodStart : dayjs(dayjs(new Date()), dateFormat),
            guaranteePeriodEnd: "",
            dayJsguaranteePeriodEnd : dayjs(dayjs(new Date()), dateFormat),
        };
    }
    
    copyExportPlan(ExportPlan: any) {
        return { 
            planId : ExportPlan.planId,
            shipmentPlanId : ExportPlan.shipmentPlanId,
            trainNumber: ExportPlan.trainNumber,
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            expectedArriveDate: ExportPlan.expectedArriveDate,
            dayJsexpectedArriveDate : ExportPlan.expectedArriveDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(ExportPlan.expectedArriveDate, dateFormat),
            deparStation: ExportPlan.deparStation,
            destinationStation: ExportPlan.destinationStation,
            carBrand: ExportPlan.carBrand,
            exitPort: ExportPlan.exitPort,
            bracketQTY : ExportPlan.bracketQTY,
            bracketWeight: ExportPlan.bracketWeight,
            guideWheelInfo : ExportPlan.guideWheelInfo,
            contractNumber : ExportPlan.contractNumber,
            status : ExportPlan.status,
            customsDeclareNumber: ExportPlan.customsDeclareNumber,
            declareDate: ExportPlan.declareDate,
            dayJsdeclareDate : ExportPlan.declareDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(ExportPlan.declareDate, dateFormat),
            guaranteePeriodStart: ExportPlan.guaranteePeriodStart,
            dayJsguaranteePeriodStart : ExportPlan.guaranteePeriodStart === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(ExportPlan.guaranteePeriodStart, dateFormat),
            guaranteePeriodEnd: ExportPlan.guaranteePeriodEnd,
            dayJsguaranteePeriodEnd : ExportPlan.guaranteePeriodEnd === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(ExportPlan.guaranteePeriodEnd, dateFormat),
        };
    }

    onSearch = (value: any) => {
        this.setState({searchText: value.target.value})
        this.search(value.target.value);
    }
    search = (searchText: string) => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => ( 
                    item.customerName.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.shipmentNumber.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.trainNumber.toLowerCase().includes(searchText.toLowerCase()))
            )
          })
    }
    onEdit = (record: any) => {        
        this.originalEditRecords = this.copyExportPlan(record);
        this.editRecord = this.copyExportPlan(record);
        this.basicInfoCanBeSaved = false;
        this.setState({isEdit: true});
    }
    onDelete = (record: any) => {
        const ExportPlan = {planId: record.planId};
        axiosInstance.post('/logistics/deleteExportPlan', ExportPlan).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createExportPlan();
        this.originalEditRecords = this.createExportPlan();
        this.basicInfoCanBeSaved = false;
        this.setState({isEdit: true});
    }
    
    onExport = () => {
        this.setState({isLoaded: false});
        axiosInstance.get('/logistics/exportExportPlan', {responseType: 'blob'}).then(
            response => {
                this.setState({isLoaded: true});
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const fileName = 'exportPlan.xlsx';
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                message.success('导出成功!');
            },
            error => {
                displayErrorMessage(error, '导出失败!');
            }
        );

    }
    
    saveBasicInfo = () => {
        const ExportPlan = this.copyExportPlan(this.editRecord);
        if (ExportPlan.planId === 0) {
            axiosInstance.post('/logistics/insertExportPlan', ExportPlan).then(
                response => {
                    ExportPlan.planId = response.data;
                    this.onEdit(ExportPlan);
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateExportPlan', ExportPlan).then(
                response => {
                    // this.init();
                    this.originalEditRecords = this.editRecord;
                    this.basicInfoCanBeSaved = false;
                    message.success('修改成功');
                    this.setState({});
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )

        }
    }
    cancel = () => {
        this.init();
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkBasicInfoCanBeSaved();
    }
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event;
        if (field == 'shipmentPlanId') {
            this.bracketQTYList.forEach
            for (let i = 0; i < this.bracketQTYList.length; i++) {
                if (this.bracketQTYList[i].planId == this.editRecord.shipmentPlanId) {
                    this.editRecord.bracketQTY = this.bracketQTYList[i].bracketQTY;
                }
            }
        }
        this.checkBasicInfoCanBeSaved();
    }
    changeDate = (field: string, date: any, dateString: string) => {
        (this.editRecord as any)[field] = dateString
        this.checkBasicInfoCanBeSaved();
    }
    checkBasicInfoCanBeSaved() {
        this.basicInfoCanBeSaved = false;
        if (this.editRecord.shipmentPlanId !== null && this.editRecord.shipmentPlanId !== 0 &&
            this.editRecord.expectedArriveDate !== null && this.editRecord.expectedArriveDate !== '' &&
            this.editRecord.guaranteePeriodStart !== null && this.editRecord.guaranteePeriodStart !== '' && 
            this.editRecord.declareDate !== null && this.editRecord.declareDate !== '' && 
            this.editRecord.guaranteePeriodEnd !== null && this.editRecord.guaranteePeriodEnd !== '' 
            ) {
            if (this.editRecord.shipmentPlanId !== this.originalEditRecords.shipmentPlanId ||
                this.editRecord.expectedArriveDate !== this.originalEditRecords.expectedArriveDate ||
                this.editRecord.destinationStation !== this.originalEditRecords.destinationStation ||
                this.editRecord.carBrand !== this.originalEditRecords.carBrand ||
                this.editRecord.exitPort !== this.originalEditRecords.exitPort ||
                this.editRecord.bracketQTY !== this.originalEditRecords.bracketQTY ||
                this.editRecord.deparStation !== this.originalEditRecords.deparStation ||
                this.editRecord.bracketWeight !== this.originalEditRecords.bracketWeight ||
                this.editRecord.guideWheelInfo !== this.originalEditRecords.guideWheelInfo ||
                this.editRecord.contractNumber !== this.originalEditRecords.contractNumber ||
                this.editRecord.customsDeclareNumber !== this.originalEditRecords.customsDeclareNumber ||
                this.editRecord.declareDate !== this.originalEditRecords.declareDate ||
                this.editRecord.guaranteePeriodStart !== this.originalEditRecords.guaranteePeriodStart ||
                this.editRecord.guaranteePeriodEnd !== this.originalEditRecords.guaranteePeriodEnd ||
                this.editRecord.status !== this.originalEditRecords.status
                ) {
                    this.basicInfoCanBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllExportPlans();
        this.getAllShipmentPlansForExportPlan();
        this.getAllCommonProperties();
    }
    getAllExportPlans() {
        axiosInstance.get('/logistics/getAllExportPlans').then(
            response => {
                this.originalData = response.data;
                console.log('aaaa',response.data);
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].planId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
                if (this.state.searchText !== '') {
                    this.search(this.state.searchText);
                }
            },
            error => {
                displayErrorMessage(error, '获取出口计划失败');
            }
        )

    }
    getAllShipmentPlansForExportPlan() {
        axiosInstance.get('/logistics/getAllShipmentPlansForExportPlan').then(
            response => {
                this.shipmentPlanList= [];
                this.bracketQTYList = [];
                for(let i = 0; i < response.data.length; i++) {
                    this.shipmentPlanList.push({label:response.data[i].trainNumber, value: response.data[i].planId});
                    this.bracketQTYList.push({planId: response.data[i].planId, bracketQTY: response.data[i].bracketQTY})
                }                
            },
            error => {
                displayErrorMessage(error, '获取装箱计划失败!');
            }
        )
    }
    getAllCommonProperties() {
        axiosInstance.get('/logistics/getAllCommonProperties').then(
            response => {
                this.deparStationList = [];
                this.destinationStationList = [];
                this.carBrandList = [];
                this.exitPortList = [];
                for(let i = 0; i < response.data.length; i++) {
                    if (response.data[i].propertyKey == 'deparStation') {
                        this.deparStationList.push({label:response.data[i].propertyValue, value: response.data[i].propertyValue});
                    }else if (response.data[i].propertyKey == 'destinationStation') {
                        this.destinationStationList.push({label:response.data[i].propertyValue, value: response.data[i].propertyValue});
                    }else if (response.data[i].propertyKey == 'carBrand') {
                        this.carBrandList.push({label:response.data[i].propertyValue, value: response.data[i].propertyValue});
                    }else if (response.data[i].propertyKey == 'exitPort') {
                        this.exitPortList.push({label:response.data[i].propertyValue, value: response.data[i].propertyValue});
                    }
                }
            },
            error => {
                displayErrorMessage(error, 'Get common properties failed!');
            }
        )
    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 出口计划管理</span>
                </div>
                <div className="seperate">
                </div>
                
                
                {this.state.isEdit? 
                    <div className="content">
                    <div>
                        <Button className="header-right" type="primary" onClick={this.cancel}>退出</Button>
                    </div>
                        <Tabs defaultActiveKey="1" >
                            <TabPane tab='基本信息' key = '1'>
                            <>
                            <Card size="small" style={{ width: '60%' }}>    

                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">装箱计划班列号:<br/>номер поездов:</label>
                                        <Select className="box-input"  options={this.shipmentPlanList} onChange={this.changeSelect.bind(this, 'shipmentPlanId')} defaultValue={this.editRecord.trainNumber} disabled={this.editRecord.planId != 0}/> 
                                        <label className="box-label">预计到达日:<br/>дата прибытия:</label>
                                        {(this.editRecord.expectedArriveDate + "") == "" ?
                                            <DatePicker className="box-input" onChange={this.changeDate.bind(this, 'expectedArriveDate')} />
                                            :
                                            <DatePicker className="box-input" onChange={this.changeDate.bind(this, 'expectedArriveDate')} defaultValue={this.editRecord.dayJsexpectedArriveDate} />}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">始发站:<br/>город отправдения:</label>
                                        <Select className="box-input"  options={this.deparStationList} onChange={this.changeSelect.bind(this, 'deparStation')} defaultValue={this.editRecord.deparStation}/> 
                                        <label className="box-label">目的站:<br/>станция назначения:</label>
                                        <Select className="box-input"  options={this.destinationStationList} onChange={this.changeSelect.bind(this, 'destinationStation')} defaultValue={this.editRecord.destinationStation}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="box"> 
                                        <label className="box-label">汽车品牌:<br/>Брэнд:</label>
                                        <Select className="box-input"  options={this.carBrandList} onChange={this.changeSelect.bind(this, 'carBrand')} defaultValue={this.editRecord.carBrand}/> 
                                        <label className="box-label">出境口岸:<br/>Погранпереход: </label>
                                        <Select className="box-input"  options={this.exitPortList} onChange={this.changeSelect.bind(this, 'exitPort')} defaultValue={this.editRecord.exitPort}/> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">支架数量:<br/>количество:</label>
                                        <Input className="box-input" value={this.editRecord.bracketQTY} onChange={this.changeInput.bind(this, 'bracketQTY')} />
                                        <label className="box-label">支架重量(KG/套）:<br/>вес:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.bracketWeight} onChange={this.changeInput.bind(this, 'bracketWeight')} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">导轮信息:<br/>направляющее колесо:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.guideWheelInfo} onChange={this.changeInput.bind(this, 'guideWheelInfo')} />
                                        <label className="box-label">支架报关材料合同号:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.contractNumber} onChange={this.changeInput.bind(this, 'contractNumber')} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">报关单号:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.customsDeclareNumber} onChange={this.changeInput.bind(this, 'customsDeclareNumber')} />
                                        <label className="box-label">报关日期:</label>
                                        {(this.editRecord.declareDate + "") == "" ?
                                            <DatePicker className="box-input" onChange={this.changeDate.bind(this, 'declareDate')} />
                                            :
                                            <DatePicker className="box-input" onChange={this.changeDate.bind(this, 'declareDate')} defaultValue={this.editRecord.dayJsdeclareDate} />}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">担保期限起始日期:<br/>Срок Временного Ввоза:</label>
                                        {(this.editRecord.guaranteePeriodStart + "") == "" ?
                                            <DatePicker className="box-input" onChange={this.changeDate.bind(this, 'guaranteePeriodStart')} />
                                            :
                                            <DatePicker className="box-input" onChange={this.changeDate.bind(this, 'guaranteePeriodStart')} defaultValue={this.editRecord.dayJsguaranteePeriodStart} />}
                                            <label className="box-label">担保期限起始日期:<br/>Срок Временного Ввоза:</label>
                                        {(this.editRecord.guaranteePeriodEnd + "") == "" ?
                                            <DatePicker className="box-input" onChange={this.changeDate.bind(this, 'guaranteePeriodEnd')} />
                                            :
                                            <DatePicker className="box-input" onChange={this.changeDate.bind(this, 'guaranteePeriodEnd')} defaultValue={this.editRecord.dayJsguaranteePeriodEnd} />}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="box">
                                            <label className="box-label">状态:</label>
                                            <Select className="box-input"  options={this.statusList} onChange={this.changeSelect.bind(this, 'status')} defaultValue={this.editRecord.status} />                                                       
                                            
                                        </div>
                                    </div>
                                </Card>
                                <br/>
                                <div>
                                    <Button className="header-right" type="primary" onClick={this.saveBasicInfo} disabled={!this.basicInfoCanBeSaved}>保存</Button>
                                </div>
                            </>
                            </TabPane>
                            <TabPane tab='支架信息' key = '2' disabled={this.editRecord.planId == 0}>
                                <ShipmentBracket shipmentPlanId={this.editRecord.shipmentPlanId?this.editRecord.shipmentPlanId : 0} />
                            </TabPane>                 
                            <TabPane tab='成本管理' key = '4' disabled={this.editRecord.planId == 0}>
                                <CostManagement planId={this.editRecord.shipmentPlanId?this.editRecord.shipmentPlanId : 0} trainNumber={this.editRecord.trainNumber} isView={this.isView} />
                            </TabPane>         
                            <TabPane tab='状态跟踪管理' key = '5' disabled={this.editRecord.planId == 0}>
                                <ExportStatusLog planId={this.editRecord.planId} isView={this.isView} />
                            </TabPane>
                        </Tabs>
                    </div>
                :
                    <div className="content">
                        <Input placeholder="输入要查询的字符" onChange={this.onSearch} className="tableSearch" value={this.state.searchText} /> 
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>
                        <Button type="primary" className="header-right" onClick={this.onExport}>导出</Button>  
                        
                        <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}
                        summary={() => {
                            let planCount = 0;
                            let totalBracketQTY = 0;                            
                            this.state.dataSource?.forEach(({ bracketQTY }) => {
                                planCount++;
                                totalBracketQTY += bracketQTY;                               
                            });
                            
                            return (
                            <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>汇总</Table.Summary.Cell>
                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>{planCount % 1 === 0 ? planCount : planCount.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>{totalBracketQTY % 1 === 0 ? totalBracketQTY : totalBracketQTY.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={4}></Table.Summary.Cell>
                            <Table.Summary.Cell index={5}></Table.Summary.Cell>
                            <Table.Summary.Cell index={6}></Table.Summary.Cell>
                            <Table.Summary.Cell index={7}></Table.Summary.Cell>                            
                            <Table.Summary.Cell index={8} />
                            </Table.Summary.Row>
                            );
                            }}
                        />
                    </div>
                }
            </div>
        )      
    }
}

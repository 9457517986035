import { Layout, Menu, Button} from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined, } from '@ant-design/icons';
import { useRoutes, useNavigate } from 'react-router-dom'
import getManageMenus from './manageRouter'
import React, {useState, useEffect} from 'react'
import { Dropdown, Space,message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './app.scss';
import Login from './components/ManageLogin/Login';
import store from './config/store';
import { setState } from './config/store/slice/i18nSlice';
import { axiosInstance } from './config/axios-interceptor';
const { Header, Sider, Content, Footer } = Layout;

const headerStyle = {
  backgroundColor: '#dbd9e9',
};
const contentStyle = {
  height: '1000px',
  overflow: 'auto'
};
const footerStyle = {
  // textAlign: 'center',
  backgroundColor: '#dbd9e9',
};

function AppManage() {
  const routes = getManageMenus();
  let element = useRoutes(routes);
  const navigate = useNavigate()
  
  const [collapsed, setCollapsed] = useState(false);
  const [isLoginFromCache, setIsLoginFromCache] = useState(sessionStorage.getItem('isLogin'));
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onClick = (e: any) => {
    let path = e.keyPath.reverse().join('/')
    navigate(path)
  }
    
  const logout = () => {
    axiosInstance.post('/greenliant/general/manageLogout').then(
        response => {
          sessionStorage.clear();
          sessionStorage.setItem('isLogout', 'true');
          setIsLoginFromCache(sessionStorage.getItem('isLogin'));
        },
        error => {
          sessionStorage.clear();
          sessionStorage.setItem('isLogout', 'true');
          setIsLoginFromCache(sessionStorage.getItem('isLogin'));
        }
    )
  }
  const items = [
    {
      key: '1',
      label: (
        <a rel="noopener noreferrer" onClick={logout}>
          退出
        </a>
      ),
    },
  ];

  store.subscribe(() => {
    setIsLoginFromCache(sessionStorage.getItem('isLogin'));
  });

  return (
    <div style={{width:'100%',height:'100%'}}>
      {
        isLoginFromCache != "true"?
        <Login />:
        
        <Layout>
          <Header style={headerStyle}>
            <Button type="primary" onClick={toggleCollapsed}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
            <Dropdown menu={{items,}} className="manage-header-right">
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <span>{sessionStorage?.getItem("userName")?.replaceAll('"','')}</span>
                  <UserOutlined />
                </Space>
              </a>
            </Dropdown>
          </Header>
          <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed} theme='light'>
              <Menu theme='light' onClick={onClick} mode="inline" items={routes} defaultSelectedKeys={['/manage']} />
            </Sider>
            <Content style={contentStyle}>{element}</Content>
          </Layout>
          <Footer style={footerStyle}>Copyright &copy; 2023 Logistics Ltd.</Footer>
        </Layout>
      }
    </div>
  )
}

export default AppManage
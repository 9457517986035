import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DD';

export default class Vendor extends React.Component {
    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp; &nbsp;
                        <Popconfirm
                            title="删除供应商"
                            description="是否确定删除?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除' className="actionIcon"/>
                        </Popconfirm>
                        
                    </div>
                )}
        },
    {
        title: '供应商名称',
        dataIndex: 'vendorName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.vendorName > b.vendorName? 1 : -1,
    },    

    {
        title: '合同起始日期',
        dataIndex: 'contractDurationStart',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.contactName > b.contactName? 1 : -1,
    },
    {
        title: '合同终止日期',
        dataIndex: 'contractDurationEnd',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.contactName > b.contactName? 1 : -1,
    },

    {
        title: '联系人',
        dataIndex: 'contactName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.contactName > b.contactName? 1 : -1,
    },
    {
        title: '联系电话',
        dataIndex: 'contactPhone',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.contactPhone > b.contactPhone? 1 : -1,
    },
    {
        title: '邮箱',
        dataIndex: 'email',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.email > b.email? 1 : -1,
    },
    {
        title: '服务类型',
        dataIndex: 'serviceType',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.serviceType > b.serviceType? 1 : -1,
    },
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
    };
    allPermissions: any[] = [];
    
    editRecord = this.createVendor();
    originalEditRecords: any = {};
    canBeSaved = false;
    
    createVendor() {
        return { 
            vendorId : 0,
            vendorName : "",
            serviceType : "",
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),                   
            contactName: "",
            contactPhone: "",
            email: "",
            address: "",
            contractDurationStart: "",
            contractDurationEnd: "",
            dayJsContractDurationStart : dayjs(dayjs(new Date()), dateFormat),
            dayJsContractDurationEnd : dayjs(dayjs(new Date()), dateFormat),
        };
    }
    
    copyVendor(vendor: any) {
        return { 
            vendorId : vendor.vendorId,
            vendorName : vendor.vendorName,
            serviceType : vendor.serviceType,
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),   
            contactName: vendor.contactName,
            contactPhone: vendor.contactPhone,
            email: vendor.email,
            address: vendor.address,
            contractDurationStart: vendor.contractDurationStart,
            contractDurationEnd: vendor.contractDurationEnd,
            dayJsContractDurationStart :  vendor.contractDurationStart === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(vendor.contractDurationStart, dateFormat),
            dayJsContractDurationEnd :  vendor.contractDurationEnd === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(vendor.contractDurationEnd, dateFormat),
        };
    }
    changeContractDurationStart = (date: any, dateString: string) => {
        this.editRecord.contractDurationStart = dateString;
        this.checkCanBeSaved();
    }
    changeContractDurationEnd = (date: any, dateString: string) => {
        this.editRecord.contractDurationEnd = dateString;
        this.checkCanBeSaved();
    }
    onSearch = (value: any) => {
        this.setState({searchText: value.target.value})
        this.search(value.target.value);
    }
    search = (searchText: string) => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => ( 
                    item.vendorName.toLowerCase().includes(searchText.toLowerCase()))
            )
          })
    }
    onEdit = (record: any) => {
        this.originalEditRecords = this.copyVendor(record);
        this.editRecord = this.copyVendor(record);
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    onDelete = (record: any) => {
        const vendor = {vendorId: record.vendorId, vendorName: record.vendorName, bracketType: record.bracketType, unitCost: record.unitCost};
        axiosInstance.post('/logistics/deleteVendor', vendor).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createVendor();
        this.originalEditRecords = this.createVendor();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    save = () => {
        const vendor = this.copyVendor(this.editRecord);
        if (vendor.vendorId === 0) {
            axiosInstance.post('/logistics/insertVendor', vendor).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateVendor', vendor).then(
                response => {
                    this.init();
                    message.success('修改成功');
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )

        }
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }    
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.vendorName !== null && this.editRecord.vendorName !== ''&&
        this.editRecord.contractDurationStart !== null && this.editRecord.contractDurationStart !== '' &&
        this.editRecord.contractDurationEnd !== null && this.editRecord.contractDurationEnd !== '' ) {
            if (this.editRecord.vendorName !== this.originalEditRecords.vendorName ||        
                this.editRecord.serviceType !== this.originalEditRecords.serviceType ||  
                this.editRecord.contactName !== this.originalEditRecords.contactName ||
                this.editRecord.contactPhone !== this.originalEditRecords.contactPhone ||
                this.editRecord.email !== this.originalEditRecords.email ||
                this.editRecord.address !== this.originalEditRecords.address ||
                this.editRecord.contractDurationStart !== this.originalEditRecords.contractDurationStart ||
                this.editRecord.contractDurationEnd !== this.originalEditRecords.contractDurationEnd ) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllVendors();
    }
    getAllVendors() {
        axiosInstance.get('/logistics/getAllVendors').then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].vendorId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
                if (this.state.searchText !== '') {
                    this.search(this.state.searchText);
                }
            },
            error => {
                displayErrorMessage(error, '获取供应商失败');
            }
        )

    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 供应商管理</span>
                </div>
                <div className="seperate">
                </div>
                
                
                {this.state.isEdit? 
                    <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">供应商名:</label>
                                <Input className="box-input" defaultValue={this.editRecord.vendorName} onChange={this.changeInput.bind(this, 'vendorName')}/>   
                                <label className="box-label">服务类型:</label>
                                <Input className="box-input" defaultValue={this.editRecord.serviceType} onChange={this.changeInput.bind(this, 'serviceType')}/>                                                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <label className="box-label">合同起始日期:</label>
                                {(this.editRecord.contractDurationStart + "") == ""?
                                    <DatePicker className="box-input" onChange={this.changeContractDurationStart}/>   
                                    :
                                    <DatePicker className="box-input" onChange={this.changeContractDurationStart} defaultValue={this.editRecord.dayJsContractDurationStart}/>   
                                }    
                                <label className="box-label">合同终止期:</label>
                                {(this.editRecord.contractDurationEnd + "") == ""?
                                    <DatePicker className="box-input" onChange={this.changeContractDurationEnd}/>   
                                    :
                                    <DatePicker className="box-input" onChange={this.changeContractDurationEnd} defaultValue={this.editRecord.dayJsContractDurationEnd}/>   
                                }                           
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <label className="box-label">联系人:</label>
                                <Input className="box-input" defaultValue={this.editRecord.contactName} onChange={this.changeInput.bind(this, 'contactName')}/>  
                                <label className="box-label">联系电话:</label>
                                <Input className="box-input" defaultValue={this.editRecord.contactPhone} onChange={this.changeInput.bind(this, 'contactPhone')}/>                             
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <label className="box-label">邮箱:</label>
                                <Input className="box-input" defaultValue={this.editRecord.email} onChange={this.changeInput.bind(this, 'email')}/>  
                                <label className="box-label">收件地址:</label>
                                <Input className="box-input" defaultValue={this.editRecord.address} onChange={this.changeInput.bind(this, 'address')}/>                             
                            </div>
                        </div>
                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                        </div>
                    </div>
                :
                    <div className="content">
                        <Input placeholder="输入要查询的字符" onChange={this.onSearch} className="tableSearch" value={this.state.searchText} /> 
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>
                        
                        <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}

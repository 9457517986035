import { createSlice } from '@reduxjs/toolkit';

export const returnPlanBracketSlice = createSlice({
  name: "isChanged",
  initialState: {
    value: false
  },
  reducers: {
    setChanged: (state) => {
      state.value = true;
    },
    setUnChanged: (state) => {
      state.value = false;
    }
  }
});

export const { setChanged, setUnChanged } = returnPlanBracketSlice.actions;

const returnPlanBracketReducer = returnPlanBracketSlice.reducer;

export default returnPlanBracketReducer

import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DD';

export default class ConsumablesInventoryOneTimeBracket extends React.Component {
    permissions = sessionStorage.getItem("permissions") || '';
    columns = [       
    {
        title: '订单号',
        dataIndex: 'orderId',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.orderId > b.orderId? 1 : -1,
    },   
    {
        title: '供应商',
        dataIndex: 'vendorName',
        showSorterTooltip: false,
    },     
    {
        title: '耗材名称',
        dataIndex: 'consumablesName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.consumablesName > b.consumablesName? 1 : -1,
    },
    {
        title: '规格型号',
        dataIndex: 'consumablesType',
        width: 150,
        showSorterTooltip: false,
    },
    {
        title: '耗材数量',
        dataIndex: 'consumablesAmount',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.consumablesAmount > b.consumablesAmount? 1 : -1,
    },
    {
        title: '入库时间',
        dataIndex: 'entryDate',
        showSorterTooltip: false,
    },
    {
        title: '城市',
        dataIndex: 'city',
        showSorterTooltip: false,
    },
    {
        title: '堆场',
        dataIndex: 'stationName',
        showSorterTooltip: false,
    },
    {
        title: '单位成本',
        dataIndex: 'unitCost',
        showSorterTooltip: false,
    },    
    {
        title: '备注',
        dataIndex: 'comments',
        showSorterTooltip: false,
    },       
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
        isChangeStation:false,
        totalCount: 0,
    };
    allPermissions: any[] = [];
    
    editRecord = this.createConsumablesInventory();
    originalEditRecords: any = {};
    sectionList: any[] = [];
    cityList: any[] = [];
    canBeSaved = false;
    consumableTypeList: any[] = [];     
    consumableSubTypeList: any[] = [];
    vendorList: any[] = [];

    createConsumablesInventory() {
        return { 
            consumablesId : 0,
            stationId : 0,
            consumablesName : "",
            consumablesType : "",
            consumablesAmount: 0,
            entryDate : "",
            dayJsEntryDate : dayjs(dayjs(new Date()), dateFormat),
            unitCost : 0,
            stationName : "",
            vendorName : "",           
        };
    }

    changeStation = () => { 
        this.setState({isChangeStation: !this.state.isChangeStation});        
    };

    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {        
        this.selectedRows = selectedRows;
        this.checkCanBeSaved();
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };


    // getAllConsumableSubTypes() {
    //     axiosInstance.get('/logistics/getCommonPropertiesByKey',{params: {key: 'consumableSubType'}}).then(
    //         response => {
    //             this.consumableSubTypeList= [];
    //             for(let i = 0; i < response.data.length; i++) {
    //                 this.consumableSubTypeList.push({label:response.data[i].propertyValue, value: response.data[i].propertyValue});               
    //             }                
    //         },
    //         error => {
    //             displayErrorMessage(error, '获取数据失败');
    //         }
    //     )  
    // }

    // getAllConsumableTypes() {
    //     axiosInstance.get('/logistics/getAllConsumableTypes').then(
    //         response => {
    //             this.consumableTypeList= [];
    //             for(let i = 0; i < response.data.length; i++) {
    //                 this.consumableTypeList.push({label:response.data[i].propertyValue, value: response.data[i].propertyValue});               
    //             }
    //         },
    //         error => {
    //             displayErrorMessage(error, '获取供应商失败');
    //         }
    //     )
    // }

    copyConsumablesInventory(consumablesInventory: any) {
        return { 
            consumablesId : consumablesInventory.consumablesId,
            stationId : consumablesInventory.stationId,
            consumablesName : consumablesInventory.consumablesName,
            consumablesType : consumablesInventory.consumablesType,
            consumablesAmount : consumablesInventory.consumablesAmount,
            entryDate : consumablesInventory.entryDate,
            dayJsEntryDate :  consumablesInventory.entryDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(consumablesInventory.entryDate, dateFormat),
            unitCost : consumablesInventory.unitCost,
            stationName : consumablesInventory.stationName,
            vendorName : consumablesInventory.vendorName,            
        };
    }
    searchText = {                
        consumablesName:'',    
        consumablesType :'',    
        stationName:'',  
        city:'',  
        vendorName:'',     
        entryDateStart:'',
        entryDateEnd:'',      
        orderId:'', 
    }

    // onSearchConsumablesName = (value: any) => {
    //     this.searchText.consumablesName = value.target.value;
    //     this.search();
    // }

    onSearchConsumablesType = (value: any) => {
        this.searchText.consumablesType = value.target.value;
        this.search();
    }

    onSearchCity = (value: any) => {
        this.searchText.city = value.target.value;
        this.search();
    }

    onSearchStationName = (value: any) => {
        this.searchText.stationName = value.target.value;
        this.search();
    }

    onSearchVendorName = (value: any) => {
        this.searchText.vendorName = value.target.value;
        this.search();
    }
    changeEntryDateStart = (date: any, dateString: string) => {
        this.searchText.entryDateStart = dateString;
        this.search();
    }
    handleVendorChange = (value:any, option:any) => {    
        if(value!=''){
            this.searchText.vendorName = option.label;
        }else{
            this.searchText.vendorName = '';
        }  
        this.search();
    };
        
    handleCityChange = (value:any, option:any) => {     
        if(value!=''){
            this.searchText.city = option.label;
        }else{
            this.searchText.city = '';
        }  
        this.search();
    };

    handleStationChange = (value:any, option:any) => {     
        if(value!=''){
            this.searchText.stationName = option.label;
        }else{
            this.searchText.stationName = '';
        }  
        this.search();
    };
    
    handleTypeChange = (value:any, option:any) => {     
        if(value!=''){
            this.searchText.consumablesType = option.label;
        }else{
            this.searchText.consumablesType = '';
        }          
        this.search();
    };

    handleNameChange = (value:any, option:any) => {     
        if(value!=''){
            this.searchText.consumablesName = option.label;
        }else{
            this.searchText.consumablesName = '';
        }  
        this.search();
    };
    changeEntryDateEnd = (date: any, dateString: string) => {
        this.searchText.entryDateEnd = dateString;
        this.search();
    }     
    search = () => {        
        const temp = this.originalData.filter((item: any) => {
            if (this.searchText.orderId != ''  && !String(item.orderId).includes(this.searchText.orderId)) {
                return false;
            }  
            if (this.searchText.consumablesName != '' && item.consumablesName!=this.searchText.consumablesName) {
                return false;
            }      
            if (this.searchText.consumablesType != '' && item.consumablesType!=this.searchText.consumablesType) {
                return false;
            }    
            if (this.searchText.city != ''  && !item.city?.toLowerCase().includes(this.searchText.city?.toLowerCase())) {
                return false;
            }                     
            if (this.searchText.stationName != ''  && !item.stationName?.toLowerCase().includes(this.searchText.stationName?.toLowerCase())) {
                return false;
            }               
            if (this.searchText.vendorName != ''  && !item.vendorName?.toLowerCase().includes(this.searchText.vendorName?.toLowerCase())) {
                return false;
            } 
            if (this.searchText.entryDateStart != '' && item.entryDate < this.searchText.entryDateStart) {
                return false;
            }
            if (this.searchText.entryDateEnd != '' && item.entryDate > this.searchText.entryDateEnd) {
                return false;
            }
            return true;
            });

        this.setState({dataSource: temp});
        this.updateTotalCount(temp);
    }

    updateTotalCount = (data: any[]) => {    
        let count=0
        for (let i = 0; i < data?.length; i++) {       
            count = count + data[i].consumablesAmount;            
        }
        this.setState({totalCount:count});
    }

    onEdit = (record: any) => {
        this.originalEditRecords = this.copyConsumablesInventory(record);
        this.editRecord = this.copyConsumablesInventory(record);
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    onDelete = (record: any) => {
        const ConsumablesInventory = {consumablesId: record.consumablesId, consumablesName: record.consumablesName, consumablesType: record.consumablesType, unitCost: record.unitCost};
        axiosInstance.post('/logistics/deleteConsumablesInventory', ConsumablesInventory).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createConsumablesInventory();
        this.originalEditRecords = this.createConsumablesInventory();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    save = () => {
        const consumablesInventory = this.copyConsumablesInventory(this.editRecord);
        if (consumablesInventory.consumablesId === 0) {
            axiosInstance.post('/logistics/insertConsumablesInventory', consumablesInventory).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateConsumablesInventory', consumablesInventory).then(
                response => {
                    this.init();
                    message.success('修改成功');
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )

        }
    }
    onSearchOrderId = (value: any) => {
        this.searchText.orderId = value.target.value;
        this.search();
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }
    changeStationId = (event: any) => {
        this.editRecord.stationId = event;
        this.checkCanBeSaved();
    }
    changeEntryDate = (date: any, dateString: string) => {
        this.editRecord.entryDate = dateString;
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.consumablesName !== null && this.editRecord.consumablesType !== '') {
            if (this.editRecord.stationId !== this.originalEditRecords.stationId ||
                this.editRecord.consumablesName !== this.originalEditRecords.consumablesName ||
                this.editRecord.consumablesType !== this.originalEditRecords.consumablesType ||
                this.editRecord.entryDate !== this.originalEditRecords.entryDate ||
                this.editRecord.consumablesAmount !== this.originalEditRecords.consumablesAmount ||                
                this.editRecord.unitCost !== this.originalEditRecords.unitCost ||
                this.editRecord.vendorName !== this.originalEditRecords.vendorName
                ) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';       
        this.init();        
    }

    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        // this.getAllConsumableTypes();
        this.getAllStations();
        this.getAllStationCity();
        this.getAllVendors();
        // this.getAllConsumableSubTypes();  
        this.getAllConsumablesInventorys();      
    }

    getAllVendors() {
        axiosInstance.get('/logistics/getAllVendors').then(
            response => {
                this.vendorList = [];    
                for(let i = 0; i < response.data.length; i++) {
                    this.vendorList.push({label:response.data[i].vendorName, value: response.data[i].vendorName});                                        
                }                
            },
            error => {
                displayErrorMessage(error, 'Get document list failed!');
            }
        )
    }

    getAllConsumablesInventorys() {      
        const consumableTypeList: any[] = [];
        const consumableSubTypeList: any[] = [];  
        axiosInstance.get('/logistics/getAllConsumablesInventorys',{params: {type: 'oneTimeBracket'}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].consumablesId; 
                    const consumablesName = response.data[i].consumablesName;                    
                    
                    const isExist1 = consumableTypeList.some(
                        (item) => item.value === consumablesName
                    );                    
                    if (!isExist1) {
                        consumableTypeList.push({label: consumablesName,value: consumablesName});
                    }
                    this.consumableTypeList = consumableTypeList;

                    const consumablesType = response.data[i].consumablesType;      
                    const isExist2 = consumableSubTypeList.some(
                        (item) => item.value === consumablesType
                    );                    
                    if (!isExist2) {
                        consumableSubTypeList.push({label: consumablesType,value: consumablesType});
                    }
                    this.consumableSubTypeList = consumableSubTypeList;                    
                }
                this.setState({dataSource: this.originalData, isLoaded: true});   
                this.updateTotalCount(this.originalData);
            },
            error => {
                displayErrorMessage(error, '获取支架失败');
            }
        )
        
    }

    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event
        this.checkCanBeSaved();
    }

    updateStation = () => {   
        let success = true;     
        this.selectedRows.forEach(element => {          
            if(element.consumablesAmount< this.editRecord.consumablesAmount) {
                alert('转场数量不能大于原数量！');     
                success = false;           
                return;
            }
            if(this.editRecord.stationId==0) {
                alert('请选择堆场！');
                success = false;
                return;
            }
            const consumablesInventory = {consumablesId: element.consumablesId,consumablesAmount: this.editRecord.consumablesAmount, stationId: this.editRecord.stationId};
            axiosInstance.post('/logistics/changeConsumablesInventory', consumablesInventory).then(
                response => {
                    this.init();                   
                },
                error => {
                    displayErrorMessage(error, '转场失败');
                    success = false;
                }
            )
        })          
        if(success){
            message.success('操作成功');          
        }        
        this.setState({isChangeStation: !this.state.isChangeStation});        
    }
    getAllStationCity() {
        axiosInstance.get('/logistics/getAllStationCity').then(
            response => {
                for(let i = 0; i < response.data.length; i++) {
                    this.cityList.push({label:response.data[i].city, value: response.data[i].city});                    
                }
            },  
            error => {
                displayErrorMessage(error, 'Get document list failed!');
            }
        )
    }
    getAllStations() {
        axiosInstance.get('/logistics/getAllStations').then(
            response => {
                for(let i = 0; i < response.data.length; i++) {
                    this.sectionList.push({label:response.data[i].stationName, value: response.data[i].stationId});                    
                }
            },  
            error => {
                displayErrorMessage(error, 'Get document list failed!');
            }
        )
    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 一次性支架管理</span> 
                </div>
                <div className="seperate">
                </div>
                
                
                {this.state.isEdit? 
                    <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">耗材名称:</label> 
                                <Select className="box-input"  options={this.consumableTypeList} onChange={this.changeSelect.bind(this, 'consumablesName')} defaultValue={this.editRecord.consumablesName}/>                                                         
                                <label className="box-label">规格型号:</label>                               
                                <Select className="box-input"  
                                showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                options={this.consumableSubTypeList} onChange={this.changeSelect.bind(this, 'consumablesType')} defaultValue={this.editRecord.consumablesType}/> 

                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <label className="box-label">堆场:</label>
                                <Select className="box-input"  options={this.sectionList} onChange={this.changeStationId} defaultValue={this.editRecord.stationName}/> 
                                <label className="box-label">供应商:</label>                                   
                                <Select className="box-input"  
                                showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                options={this.vendorList}  onChange={this.changeSelect.bind(this, 'vendorName')} defaultValue={this.editRecord.vendorName}/>  
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <label className="box-label">入库时间:</label>
                                {(this.editRecord.entryDate + "") == ""?
                                    <DatePicker className="box-input" onChange={this.changeEntryDate}/>   
                                    :
                                    <DatePicker className="box-input" onChange={this.changeEntryDate} defaultValue={this.editRecord.dayJsEntryDate}/>   
                                }  
                                <label className="box-label">单位成本:</label>
                                <Input className="box-input" onChange={this.changeInput.bind(this, 'unitCost')} defaultValue={this.editRecord.unitCost}/>                                    
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <label className="box-label">耗材数量:</label>
                                <Input className="box-input" defaultValue={this.editRecord.consumablesAmount} onChange={this.changeInput.bind(this, 'consumablesAmount')}/>                          
                            </div>
                        </div>
                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                        </div>
                    </div>
                ://<Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>
                    <div className="content">
                        <label className="box-label">订单号:</label>     
                        <Input style={{ width: '130px' }} onChange={this.onSearchOrderId}  className="tableSearch" value={this.searchText.orderId} />                                                                      
                        <label className="box-label">耗材名称:</label>                                                                        
                        <Select style={{ width: '160px' }} options={[{label:'--全部--', value: ''},...this.consumableTypeList]} 
                         showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        onChange={this.handleNameChange}/>   

                        <label className="box-label">耗材型号:</label>                                                
                        <Select style={{ width: '160px' }} options={[{label:'--全部--', value: ''},...this.consumableSubTypeList]} 
                         showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        onChange={this.handleTypeChange}/>    

                        <label className="box-label">城市:</label>                                                                                            
                        <Select style={{ width: '130px' }} options={[{label:'--全部--', value: ''},...this.cityList]} 
                         showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        onChange={this.handleCityChange}/>  

                        <label className="box-label">堆场:</label>                                                                                            
                        <Select style={{ width: '130px' }} options={[{label:'--全部--', value: ''},...this.sectionList]} 
                         showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        onChange={this.handleStationChange}/>  

                        <label className="box-label">供应商:</label>                         
                        <Select style={{ width: '180px' }} options={[{label:'--全部--', value: ''},...this.vendorList]} 
                         showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        onChange={this.handleVendorChange}/> 

                        <label>&nbsp;入库时间:</label>
                        <DatePicker style={{width: '150px'}} onChange={this.changeEntryDateStart} />
                        <label>&nbsp;-&nbsp;</label>
                        <DatePicker style={{width: '150px'}} onChange={this.changeEntryDateEnd} />
                        <br/>
                        
                        <Button type="primary" className="header-left" onClick={this.changeStation} disabled={this.selectedRows.length==0}>批量转场</Button>                              
                        {this.state.isChangeStation? 
                        <div className="box">
                            <label className="box-label">转场数量:</label>
                            <Input style={{width: '200px'}}  defaultValue={this.editRecord.consumablesAmount==0?'':this.editRecord.consumablesAmount} onChange={this.changeInput.bind(this, 'consumablesAmount')}/> 
                            <label className="box-label" >堆场:</label>
                            <Select  style={{width: '200px'}}  options={this.sectionList} onChange={this.changeStationId} defaultValue={this.editRecord.stationName}/> 
                            <Button type="primary" className="header-left" onClick={this.updateStation}>确定</Button>  
                        </div>
                        :''
                        }
                        <Table rowSelection={{
                            type: 'checkbox',
                            ...this.rowSelection,
                            }}  title={() => '合计数量: ' + this.state.totalCount}  columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}

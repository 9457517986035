import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DD';

export default class ConsumableOrderPayment extends React.Component {
    props = {orderId: 0};
    constructor(props:any) {
        super(props);
      }
    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp; &nbsp;
                        <Popconfirm
                            title="删除收款信息"
                            description="是否确定删除?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除' className="actionIcon"/>
                        </Popconfirm>
                        
                    </div>
                )
            }
        },
    {
        title: '收款时间',
        dataIndex: 'paymentDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.paymentDate > b.paymentDate? 1 : -1,
    },
    {
        title: '付款金额',
        dataIndex: 'paymentAmount',
        showSorterTooltip: false,
    },
    {
        title: '备注',
        dataIndex: 'comments',
        showSorterTooltip: false,
    },
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        allPaid: true,
    };
    allPermissions: any[] = [];
    
    editRecord = this.createConsumableOrderPayment();
    originalEditRecords: any = {};
    canBeSaved = false;
    
    createConsumableOrderPayment() {
        return { 
            id : 0,
            orderId : this.props.orderId,
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            paymentDate : '',
            dayJsPaymentDate : dayjs(dayjs(new Date()), dateFormat),
            paymentAmount : 0,
            comments : '',
        };
    }
    
    copyConsumableOrderPayment(ConsumableOrderPayment: any) {
        return { 
            id : ConsumableOrderPayment.id,
            orderId : this.props.orderId,
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            paymentDate : ConsumableOrderPayment.paymentDate,
            dayJsPaymentDate :  ConsumableOrderPayment.paymentDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(ConsumableOrderPayment.paymentDate, dateFormat),
            paymentAmount : ConsumableOrderPayment.paymentAmount,
            comments : ConsumableOrderPayment.comments,
        };
    }
    onDelete = (record: any) => {
        const ConsumableOrderPayment = {id: record.id, orderId: this.props.orderId};
        axiosInstance.post('/logistics/deleteConsumableOrderPaymentById', ConsumableOrderPayment).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createConsumableOrderPayment();
        this.originalEditRecords = this.createConsumableOrderPayment();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    onEdit = (record: any) => {
        this.originalEditRecords = this.copyConsumableOrderPayment(record);
        this.editRecord = this.copyConsumableOrderPayment(record);
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    save = () => {
        const ConsumableOrderPayment= this.copyConsumableOrderPayment(this.editRecord);
        if (ConsumableOrderPayment.id === 0) {
            axiosInstance.post('/logistics/insertConsumableOrderPayment', ConsumableOrderPayment).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateConsumableOrderPayment', ConsumableOrderPayment).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }
    }
    finish = () => {
        axiosInstance.post('/logistics/finishConsumableOrderPaid', {orderId: this.props.orderId}).then(
            response => {
                this.init();
                message.success('完成成功');
            },
            error => {
                displayErrorMessage(error, '完成失败');
            }
        )
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event
        this.checkCanBeSaved();
    }
    changePaymentDate = (date: any, dateString: string) => {
        this.editRecord.paymentDate = dateString;
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.paymentDate !== null && this.editRecord.paymentDate !== '' &&
            this.editRecord.paymentAmount !== null && this.editRecord.paymentAmount !== 0) {
            if (this.editRecord.paymentDate !== this.originalEditRecords.paymentDate ||
                this.editRecord.paymentAmount !== this.originalEditRecords.paymentAmount ||
                this.editRecord.comments !== this.originalEditRecords.comments) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getConsumableOrderPaymentByOrderId();
    }

    getConsumableOrderPaymentByOrderId() {
        axiosInstance.get('/logistics/getConsumableOrderPaymentByOrderId', {params: {orderId: this.props.orderId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].consumablesId;
                }
                axiosInstance.get('/logistics/getConsumableOrderById', {params: {orderId: this.props.orderId}}).then(
                    response => {
                        this.setState({allPaid: response.data.allPaid});
                        this.setState({dataSource: this.originalData, isLoaded: true});
                    },
                    error => {
                        displayErrorMessage(error, '获取消耗品订单失败');
                    }
                )
            },
            error => {
                displayErrorMessage(error, '获取收款信息失败');
            }
        )

    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="seperate">
                </div>
                
                
                {this.state.isEdit? 
                    <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">收款时间:</label>
                                    {(this.editRecord.paymentDate + "") == "" ?
                                        <DatePicker className="box-input" onChange={this.changePaymentDate} />
                                        :
                                        <DatePicker className="box-input" onChange={this.changePaymentDate} defaultValue={this.editRecord.dayJsPaymentDate} />
                                    }
                                <label className="box-label">付款金额:</label>
                                <Input className="box-input" defaultValue={this.editRecord.paymentAmount==0?'':this.editRecord.paymentAmount} onChange={this.changeInput.bind(this, 'paymentAmount')} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <label className="box-label">备注:</label>
                                <Input className="box-input" defaultValue={this.editRecord.comments} onChange={this.changeInput.bind(this, 'comments')} />
                            </div>
                        </div>
                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                        </div>
                    </div>
                :
                    <div className="content">
                        {this.state.allPaid?
                        ''
                        :
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>
                        }
                        

                        {this.state.allPaid?
                        ''
                        :
                        <Popconfirm
                            title="完成"
                            description="是否确定所有支付都已经完成?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.finish()}
                        >
                            <Button type="primary" className="header-right">完成</Button>
                        </Popconfirm> 
                        }
                        
                        <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}

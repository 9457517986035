import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DD';

export default class LoadingTeam extends React.Component {
    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp; &nbsp;
                        <Popconfirm
                            title="删除装箱团队"
                            description="是否确定删除?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除' className="actionIcon"/>
                        </Popconfirm>
                        
                    </div>
                )}
        },
    {
        title: '团队名称',
        dataIndex: 'teamName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.teamName > b.teamName? 1 : -1,
    },
    {
        title: '负责人',
        dataIndex: 'teamLeader',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.teamLeader > b.teamLeader? 1 : -1,
    },
    {
        title: '联系电话',
        dataIndex: 'contactPhone',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.contactPhone > b.contactPhone? 1 : -1,
    },
    {
        title: '地址',
        dataIndex: 'address',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.address > b.address? 1 : -1,
    },
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
    };
    allUsers: any[] = [];
    
    allTeamTypeList: any[] = [{label:'装箱团队', value: 'zxtd'},{label:'空箱车队', value: 'kxcd'},{label:'重箱车队', value: 'zxcd'}]; 
    editRecord = this.createLoadingTeam();
    originalEditRecords: any = {};
    canBeSaved = false;
    
    createLoadingTeam() {
        return { 
            loadingTeamId : 0,
            teamName : "",
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            teamLeader: "",
            contactPhone: "",
            address: "",
            photographers:"",
            teamType:"",
            teamTypeList : [],
            userList : [],
        };
    }
    
    copyLoadingTeam(loadingTeam: any) {
        return { 
            loadingTeamId : loadingTeam.loadingTeamId,
            teamName : loadingTeam.teamName,
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            teamLeader: loadingTeam.teamLeader,
            contactPhone: loadingTeam.contactPhone,
            address: loadingTeam.address,
            photographers: loadingTeam.photographers,
            teamType: loadingTeam.teamType,
            teamTypeList:loadingTeam.teamType?.split(','),
            userList:loadingTeam.photographers?.split(','),
        };
    }

    onSearch = (value: any) => {
        this.setState({searchText: value.target.value})
        this.search(value.target.value);
    }
    search = (searchText: string) => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => ( item.teamLeader.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.teamName.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.address.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.contactPhone.toLowerCase().includes(searchText.toLowerCase()))
            )
          })
    }
    onEdit = (record: any) => {
        this.originalEditRecords = this.copyLoadingTeam(record);
        this.editRecord = this.copyLoadingTeam(record);
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    onDelete = (record: any) => {
        const LoadingTeam = {loadingTeamId: record.loadingTeamId, teamName: record.teamName};
        axiosInstance.post('/logistics/deleteLoadingTeam', LoadingTeam).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createLoadingTeam();
        this.originalEditRecords = this.createLoadingTeam();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    save = () => {
        const loadingTeam = this.copyLoadingTeam(this.editRecord);
        console.log(loadingTeam);
        if (loadingTeam.loadingTeamId === 0) {
            axiosInstance.post('/logistics/insertLoadingTeam', loadingTeam).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateLoadingTeam', loadingTeam).then(
                response => {
                    this.init();
                    message.success('修改成功');
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )

        }
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.teamName !== null && this.editRecord.teamName !== '') {
            if (this.editRecord.teamName !== this.originalEditRecords.teamName ||
                this.editRecord.teamLeader !== this.originalEditRecords.teamLeader ||
                this.editRecord.contactPhone !== this.originalEditRecords.contactPhone ||
                this.editRecord.address !== this.originalEditRecords.address ||
                this.editRecord.photographers !== this.originalEditRecords.photographers ||
                this.editRecord.teamType !== this.originalEditRecords.teamType) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    changePhotographers = (value: any) => {
        this.editRecord.userList = value;
        this.editRecord.photographers = this.editRecord.userList.toString();        
        this.checkCanBeSaved();
    }
    changeTeamType = (value: any) => {
        this.editRecord.teamTypeList = value;
        this.editRecord.teamType = this.editRecord.teamTypeList.toString();
        // console.log(value)
        this.checkCanBeSaved();
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllLoadingTeams();
        this.getAllPhotographers();
    }
    getAllLoadingTeams() {
        axiosInstance.get('/logistics/getAllLoadingTeams').then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].loadingTeamId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
                if (this.state.searchText !== '') {
                    this.search(this.state.searchText);
                }                
            },
            error => {
                displayErrorMessage(error, '获取装箱团队失败');
            }
        )

    }

    getAllPhotographers() {
        axiosInstance.get('/logistics/getUsersByRole', {params: {role: '摄影师'}}).then(
            response1 => {
                this.allUsers = [];
                for (let i = 0; i < response1.data.length; i++) {
                    this.allUsers.push({
                      label: response1.data[i].userName,
                      value: response1.data[i].userId+'',
                    });
                }                          
            },
            error => {
                displayErrorMessage(error, 'Get users failed!');
            }
            
        )

    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 装箱团队管理</span>
                </div>
                <div className="seperate">
                </div>                
                {this.state.isEdit? 
                    <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">团队名称:</label>
                                <Input className="box-input" defaultValue={this.editRecord.teamName} onChange={this.changeInput.bind(this, 'teamName')}/> 
                                <label className="box-label">联系电话:</label>
                                <Input className="box-input" defaultValue={this.editRecord.contactPhone} onChange={this.changeInput.bind(this, 'contactPhone')}/>                          
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <label className="box-label">地址:</label>
                                <Input className="box-input" defaultValue={this.editRecord.address} onChange={this.changeInput.bind(this, 'address')}/>  
                                <label className="box-label">负责人:</label>
                                <Input className="box-input" defaultValue={this.editRecord.teamLeader} onChange={this.changeInput.bind(this, 'teamLeader')}/>                           
                            </div>
                        </div>
                       
                        <div className="row">
                            <div className="box">
                                <label className="box-label">摄影师:</label>
                                {/* <Input className="box-input" onChange={this.changePermissions} value={this.permission}/> */}
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="请选择.."
                                    defaultValue={this.editRecord.userList}
                                    onChange={this.changePhotographers}
                                    options={this.allUsers}
                                />                              
              
                                <label className="box-label">团队类型:</label>
                                {/* <Input className="box-input" onChange={this.changePermissions} value={this.permission}/> */}
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="请选择.."
                                    defaultValue={this.editRecord.teamTypeList}
                                    onChange={this.changeTeamType}
                                    options={this.allTeamTypeList}
                                />                              
                            </div>
                        </div>

                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                        </div>
                    </div>
                :
                    <div className="content">
                        <Input placeholder="输入要查询的字符" onChange={this.onSearch} className="tableSearch" value={this.state.searchText} /> 
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>
                        
                        <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker, Card, Tabs } from 'antd';
import { EditOutlined, DeleteOutlined, DownloadOutlined, CaretDownOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';
import TabPane from 'antd/es/tabs/TabPane';
import ReturnBracket from './ReturnPlan/ReturnBracket';
import ReturnConsumables from './ReturnPlan/ReturnConsumables';
import CostManagement from './ReturnPlan/CostManagement';
import ReturnStatusLog from './ReturnPlan/ReturnStatusLog';

const dateFormat = 'YYYY-MM-DD';

export default class ReturnPlan extends React.Component {
        
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 120,
            render: (_: any, record: any)=> {
                return (                            
                    <div>
                        <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp;
                        <Popconfirm
                            title="删除回程计划"
                            description="是否确定删除?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除' className="actionIcon"/>
                        </Popconfirm>&nbsp;
                    
                    </div>
                )}
        },
    {
        title: '班列号',
        dataIndex: 'trainNumber',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.trainNumber > b.trainNumber? 1 : -1,
        render: (_: any, record: any)=> {
            return (
                <div>                        
                    <a onClick={() => this.onView(record)}>{record.trainNumber}</a>
                </div>  
            )}
    },
    {
        title: '发运日',
        dataIndex: 'departureDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.departureDate > b.departureDate? 1 : -1,
    },
    {
        title: '预计到达日',
        dataIndex: 'expectedArriveDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.expectedArriveDate > b.expectedArriveDate? 1 : -1,
    },
    {
        title: '入境口岸',
        dataIndex: 'inboundEntry',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.inboundEntry > b.inboundEntry? 1 : -1,
    },
    {
        title: '状态',
        dataIndex: 'status',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.status > b.status? 1 : -1,
    },
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
    };
    allPermissions: any[] = [];
    
    isView = false;
    editRecord = this.createReturnPlan();
    originalEditRecords: any = {};
    stationList: any[] = []; 
    basicInfoCanBeSaved = false;
    statusList: any[] = [{label:'待发运', value: '待发运'},{label:'在途', value: '在途'},{label:'已到站', value: '已到站'}]; 
    createReturnPlan() {
        return { 
            planId : 0,
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            updatedBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            departureDate: "",
            dayJsdepartureDate : dayjs(dayjs(new Date()), dateFormat),
            expectedArriveDate: "",
            dayJsexpectedArriveDate : dayjs(dayjs(new Date()), dateFormat),
            trainNumber: "",
            departureStation: "",
            destinationStation: "",
            inboundEntry: "",
            bracketCount: "",
            bracketWeight: "",
            bracketContactNumber: "",      
            containerCount: "",      
            arriveDate: "",
            priceDetail: "",
            status : "",
            comments : "",
        };
    }
    
    copyReturnPlan(ReturnPlan: any) {
        return { 
            planId : ReturnPlan.planId,
            createdBy: ReturnPlan.createdBy,
            updatedBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            departureDate: ReturnPlan.departureDate,
            dayJsdepartureDate :  ReturnPlan.departureDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(ReturnPlan.departureDate, dateFormat),
            expectedArriveDate: ReturnPlan.expectedArriveDate,
            dayJsexpectedArriveDate :  ReturnPlan.expectedArriveDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(ReturnPlan.expectedArriveDate, dateFormat),
            trainNumber: ReturnPlan.trainNumber,
            departureStation: ReturnPlan.departureStation,
            destinationStation: ReturnPlan.destinationStation,
            inboundEntry: ReturnPlan.inboundEntry,
            bracketCount: ReturnPlan.bracketCount,
            bracketWeight: ReturnPlan.bracketWeight,
            bracketContactNumber: ReturnPlan.bracketContactNumber,
            containerCount: ReturnPlan.containerCount,
            arriveDate: ReturnPlan.arriveDate,
            priceDetail: ReturnPlan.priceDetail,
            status: ReturnPlan.status,
            comments : ReturnPlan.comments,
        };
    }

    onSearch = (value: any) => {
        this.setState({searchText: value.target.value})
        this.search(value.target.value);
    }
    search = (searchText: string) => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => ( 
                    item.expectedArriveDate.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.inboundEntry.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.trainNumber.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.comments.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.status.toLowerCase().includes(searchText.toLowerCase()))
            )
          })
    }
    onView = (record: any) => {
        this.onEdit(record);
        this.isView = true;
    }
    onEdit = (record: any) => {
        this.isView = false;
        this.originalEditRecords = this.copyReturnPlan(record);
        this.editRecord = this.copyReturnPlan(record);
        this.basicInfoCanBeSaved = false;
        this.setState({isEdit: true});
    }
    onDelete = (record: any) => {
        const ReturnPlan = {planId: record.planId};
        axiosInstance.post('/logistics/deleteReturnPlan', ReturnPlan).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createReturnPlan();
        this.originalEditRecords = this.createReturnPlan();
        this.basicInfoCanBeSaved = false;
        this.setState({isEdit: true});
    }
    saveBasicInfo = () => {
        const ReturnPlan = this.copyReturnPlan(this.editRecord);
        if (ReturnPlan.planId === 0) {
            axiosInstance.post('/logistics/insertReturnPlan', ReturnPlan).then(
                response => {
                    ReturnPlan.planId = response.data;
                    this.onEdit(ReturnPlan);
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateReturnPlan', ReturnPlan).then(
                response => {
                    // this.init();
                    this.originalEditRecords = this.editRecord;
                    this.basicInfoCanBeSaved = false;
                    message.success('修改成功');
                    this.setState({});
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )

        }
    }
    finishReturnPlan = () => {
        const ReturnPlan = {planId: this.editRecord.planId};
        axiosInstance.post('/logistics/finishReturnPlan', ReturnPlan).then(
            response => {
                this.init();
                message.success('完成成功');
            },
            error => {
                displayErrorMessage(error, '完成失败');
            }
        )
    }
    cancel = () => {
        this.init();
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkBasicInfoCanBeSaved();
    }
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event
        this.checkBasicInfoCanBeSaved();
    }
    changedepartureDate = (date: any, dateString: string) => {
        this.editRecord.departureDate = dateString;
        this.checkBasicInfoCanBeSaved();
    }
    changeexpectedArriveDate = (date: any, dateString: string) => {
        this.editRecord.expectedArriveDate = dateString;
        this.checkBasicInfoCanBeSaved();
    }
    checkBasicInfoCanBeSaved() {
        this.basicInfoCanBeSaved = false;
        if (this.editRecord.trainNumber !== null && this.editRecord.trainNumber !== '' &&
            this.editRecord.departureDate !== null && this.editRecord.departureDate !== '' &&
            this.editRecord.expectedArriveDate !== null && this.editRecord.expectedArriveDate !== '' 
            //this.editRecord.departureStation !== null && this.editRecord.departureStation !== '' &&
            //this.editRecord.destinationStation !== null && this.editRecord.destinationStation !== '' &&
            //this.editRecord.inboundEntry !== null && this.editRecord.inboundEntry !== '' &&
            //this.editRecord.bracketCount !== null && this.editRecord.bracketCount !== '' &&
            //this.editRecord.bracketWeight !== null && this.editRecord.bracketWeight !== '' &&
            //this.editRecord.bracketContactNumber !== null && this.editRecord.bracketContactNumber !== '' &&            
            //this.editRecord.containerCount !== null && this.editRecord.containerCount !== '' &&        
            //this.editRecord.comments !== null && this.editRecord.comments !== ''
            ) {
            if (this.editRecord.trainNumber !== this.originalEditRecords.trainNumber ||
                this.editRecord.departureDate !== this.originalEditRecords.departureDate ||
                this.editRecord.expectedArriveDate !== this.originalEditRecords.expectedArriveDate ||
                this.editRecord.departureStation !== this.originalEditRecords.departureStation ||
                this.editRecord.destinationStation !== this.originalEditRecords.destinationStation ||
                this.editRecord.inboundEntry !== this.originalEditRecords.inboundEntry ||
                this.editRecord.bracketCount !== this.originalEditRecords.bracketCount ||
                this.editRecord.bracketWeight !== this.originalEditRecords.bracketWeight ||
                this.editRecord.bracketContactNumber !== this.originalEditRecords.bracketContactNumber ||
                this.editRecord.containerCount !== this.originalEditRecords.containerCount ||
                this.editRecord.status !== this.originalEditRecords.status ||
                this.editRecord.comments !== this.originalEditRecords.comments 
                ) {
                    this.basicInfoCanBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllReturnPlans();
        this.getAllStations();
    }
    getAllReturnPlans() {
        axiosInstance.get('/logistics/getAllReturnPlans').then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].planId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
                if (this.state.searchText !== '') {
                    this.search(this.state.searchText);
                }
            },
            error => {
                displayErrorMessage(error, '获取回程计划失败');
            }
        )

    }
    getAllStations() {
        axiosInstance.get('/logistics/getAllStations').then(
            response => {
                this.stationList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.stationList.push({label:response.data[i].stationName, value: response.data[i].stationName});
                }
            },
            error => {
                displayErrorMessage(error, 'Get document list failed!');
            }
        )
    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 回程计划管理</span>
                </div>
                <div className="seperate">
                </div>
                
                
                {this.state.isEdit? 
                    <div className="content">
                    <div>
                        <Button className="header-right" type="primary" onClick={this.cancel}>退出</Button>
                        {
                            this.isView?
                            '':
                            ''//<Button className="header-right" type="primary" onClick={this.finishReturnPlan} disabled = {this.editRecord.planId == 0}>完成</Button>
                        }
                    </div>
                        <Tabs defaultActiveKey="1" >
                            <TabPane tab='基本信息' key = '1'>
                            <>
                            <Card size="small" style={{ width: '60%' }}>    
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">班列号:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.trainNumber} onChange={this.changeInput.bind(this, 'trainNumber')} />
                                        <label className="box-label">发运日:</label>
                                        {(this.editRecord.departureDate == null || this.editRecord.departureDate == "") ?
                                            <DatePicker className="box-input" onChange={this.changedepartureDate} />
                                            :
                                            <DatePicker className="box-input" onChange={this.changedepartureDate} defaultValue={this.editRecord.dayJsdepartureDate} />
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">预计到达日:</label>
                                        {(this.editRecord.expectedArriveDate == null || this.editRecord.expectedArriveDate == "") ?
                                            <DatePicker className="box-input" onChange={this.changeexpectedArriveDate} />
                                            :
                                            <DatePicker className="box-input" onChange={this.changeexpectedArriveDate} defaultValue={this.editRecord.dayJsexpectedArriveDate} />
                                        }
                                        <label className="box-label">始发站:</label>
                                        <Select className="box-input"  options={this.stationList} onChange={this.changeSelect.bind(this, 'departureStation')} defaultValue={this.editRecord.departureStation}/> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">目的站:</label>
                                        <Select className="box-input"  options={this.stationList} onChange={this.changeSelect.bind(this, 'destinationStation')} defaultValue={this.editRecord.destinationStation}/> 
                                        <label className="box-label">入境口岸:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.inboundEntry} onChange={this.changeInput.bind(this, 'inboundEntry')} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">支架数量(套):</label>
                                        <Input className="box-input" defaultValue={this.editRecord.bracketCount} onChange={this.changeInput.bind(this, 'bracketCount')} />
                                        <label className="box-label">支架重量(KG/套）:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.bracketWeight} onChange={this.changeInput.bind(this, 'bracketWeight')} />
                                    </div>
                                </div>                                
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">支架报关材料合同号:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.bracketContactNumber} onChange={this.changeInput.bind(this, 'bracketContactNumber')} />
                                        <label className="box-label">箱数:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.containerCount} onChange={this.changeInput.bind(this, 'containerCount')} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label">备注:</label>
                                        <Input className="box-input" defaultValue={this.editRecord.comments} onChange={this.changeInput.bind(this, 'comments')} />
                                        <label className="box-label">状态:</label>
                                        <Select className="box-input"  options={this.statusList} onChange={this.changeSelect.bind(this, 'status')} defaultValue={this.editRecord.status} />                                                       

                                    </div>
                                </div>
                                </Card>
                                <br/>
                                <div>
                                    {
                                        this.isView?
                                        '':
                                        <Button className="header-right" type="primary" onClick={this.saveBasicInfo} disabled={!this.basicInfoCanBeSaved}>保存</Button>
                                    }
                                </div>
                            </>,
                            </TabPane>
                            <TabPane tab='支架信息' key = '2' disabled={this.editRecord.planId == 0}>
                                <ReturnBracket planId={this.editRecord.planId} isView={this.isView} />
                            </TabPane>
                            <TabPane tab='耗材信息' key = '3' disabled={this.editRecord.planId == 0}>
                                <ReturnConsumables planId={this.editRecord.planId} isView={this.isView} />
                            </TabPane>
                            <TabPane tab='成本管理' key = '4' disabled={this.editRecord.planId == 0}>
                                <CostManagement planId={this.editRecord.planId} isView={this.isView} />
                            </TabPane>
                            <TabPane tab='状态跟踪管理' key = '5' disabled={this.editRecord.planId == 0}>
                                <ReturnStatusLog planId={this.editRecord.planId} isView={this.isView} />
                            </TabPane>
                        </Tabs>
                    </div>
                :
                    <div className="content">
                        <Input placeholder="输入要查询的字符" onChange={this.onSearch} className="tableSearch" value={this.state.searchText} /> 
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>
                        
                        <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}

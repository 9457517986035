import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';

export default class OrderPayment extends React.Component {
    
    columns = [
    {
        title: '供应商',
        dataIndex: 'vendorName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.vendorName > b.vendorName? 1 : -1,
    },
    {
        title: '名称',
        dataIndex: 'name',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.name > b.name? 1 : -1,
    },
    {
        title: '下单日期',
        dataIndex: 'orderDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.orderDate > b.orderDate? 1 : -1,
    },
    {
        title: '总金额',
        dataIndex: 'totalPrice',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.totalPrice > b.totalPrice? 1 : -1,
    },
    {
        title: '已付款',
        dataIndex: 'paid',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.paid > b.paid? 1 : -1,
    },
    {
        title: '未付款',
        dataIndex: 'notPaid',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.notPaid > b.notPaid? 1 : -1,
    },
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        searchText: '',
    };
    allPermissions: any[] = [];
    vendorNameList: any[] = [];
    nameList: any[] = [];
    searchText = {
        vendorName: '',
        name: '',
        orderDateStart: '',
        orderDateEnd: '',
    }
    
    changeOrderDateStart = (date: any, dateString: string) => {
        this.searchText.orderDateStart = dateString;
        this.search();
    }
    changeOrderDateEnd = (date: any, dateString: string) => {
        this.searchText.orderDateEnd = dateString;
        this.search();
    }

    onSearchVendorName = (value: any) => {        
        this.searchText.vendorName = value.target.value;
        this.search();
    }

    changeSelect = (field: string, event: any) => {
        (this.searchText as any)[field] = event
        this.search();
    }

    search = () => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => {   
                if (this.searchText.vendorName != '' && !item.vendorName?.toLowerCase().includes(this.searchText.vendorName?.toLowerCase())) {
                    return false;
                }
                if (this.searchText.name != ''  && item.name !== this.searchText.name) {
                    return false;
                }
                if (this.searchText.orderDateStart != '' && item.orderDate < this.searchText.orderDateStart) {
                    return false;
                }
                if (this.searchText.orderDateEnd != '' && item.orderDate > this.searchText.orderDateEnd) {
                    return false;
                }

                return true;
            }
            )
          })
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false});
        this.getAllOrderPayment();
    }
    getAllOrderPayment() {
        axiosInstance.get('/logistics/getAllOrderPayment').then(
            response => {
                this.originalData = response.data;
                let vendorNameList: any[] = [];
                let nameList: any[] = [];
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].planId;
                    if (!vendorNameList.includes(this.originalData[i].vendorName)) {
                        vendorNameList.push(this.originalData[i].vendorName);
                    }
                    if (!nameList.includes(this.originalData[i].name)) {
                        nameList.push(this.originalData[i].name);
                    }
                }
                this.vendorNameList = [];
                this.vendorNameList.push({label: '', value: ''});
                for(let i = 0; i < vendorNameList.length; i++) {
                    this.vendorNameList.push({label: vendorNameList[i], value: vendorNameList[i]});
                }
                this.nameList = [];
                this.nameList.push({label: '', value: ''});
                for(let i = 0; i < nameList.length; i++) {
                    this.nameList.push({label: nameList[i], value: nameList[i]});
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取装箱计划收款信息失败');
            }
        )

    }
        
    onExport = (record: any) => {
        this.setState({isLoaded: false});
        axiosInstance.get('/logistics/exportOrderPayment', {params: {vendorName: this.searchText.vendorName,
            name: this.searchText.name, orderDateStart: this.searchText.orderDateStart, orderDateEnd: this.searchText.orderDateEnd},responseType: 'blob'} ).then(
            response => {
                this.setState({isLoaded: true});
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const fileName = '应付帐款.xlsx';
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                message.success('导出成功!');
            },
            error => {
                displayErrorMessage(error, '导出失败!');
            }
        );

    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 应付账款</span>
                </div>
                <div className="seperate">
                </div>
                    <div className="content">
                        <label>供应商:</label>                        
                        <Input onChange={this.onSearchVendorName}  className="tableSearch" value={this.searchText.vendorName} /> 
                        <label>&nbsp;&nbsp;名称:</label>
                        <Select style={{width: '150px'}} options={this.nameList} onChange={this.changeSelect.bind(this, 'name')} />
                        <label>&nbsp;&nbsp;下单日期:</label>
                        <DatePicker style={{width: '150px'}} onChange={this.changeOrderDateStart} />
                        <label>&nbsp;-&nbsp;</label>
                        <DatePicker style={{width: '150px'}} onChange={this.changeOrderDateEnd} />
                        <Button type="primary" className="header-right" onClick={this.onExport}>导出</Button>
                        <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}
                        summary={() => {
                            let allPrice = 0;
                            let totalPaid = 0;
                            let totalNotPaid = 0;
                            this.state.dataSource?.forEach(({ totalPrice,paid,notPaid }) => {
                                allPrice += totalPrice;
                                totalPaid += paid;
                                totalNotPaid += notPaid;                                    
                            });
                            
                            return (
                            <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>汇总</Table.Summary.Cell>
                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2}></Table.Summary.Cell>                                                   
                            <Table.Summary.Cell index={5}>{allPrice % 1 === 0 ? allPrice : allPrice.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={6}>{totalPaid % 1 === 0 ? totalPaid : totalPaid.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={7}>{totalNotPaid % 1 === 0 ? totalNotPaid : totalNotPaid.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={8} />
                            </Table.Summary.Row>
                            );
                            }} />
                    </div>
            </div>
        )      
    }
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined,PlusOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import store from '../../config/store';
import {setUnChanged} from '../../config/store/slice/returnPlanBracketReducer'

const dateFormat = 'YYYY-MM-DD';

const FeeType = [
    {label: '出口报关', value: 'exportFee'},
    {label: '暂时进出口担保', value: 'tempGuaranteeCost'},
    {label: '转关费', value: 'customsTransferCost'},
    {label: '境外代理', value: 'overseasAgency'},
    {label: '进口清关', value: 'customsClearanceCost'},
    {label: '担保核销', value: 'guaranteeWriteOff'},
    {label: '陆港吊装费', value: 'portHoistingFee'},
    {label: '拆箱费', value: 'devanningFee'},
    {label: '还箱短驳费', value: 'returnContainerFee'},
    {label: '核放费用', value: 'releaseCharge'},
    {label: '装车费', value: 'loadingFee'},
    {label: '支架转运费', value: 'bracketTransferFee'},
    {label: '二保卡扣', value: 'protectionBuckleFee'},
    {label: '螺丝补充', value: 'screwSupplementFee'},
    {label: '支架维修费', value: 'bracketMaintainFee'},
]

export default class ReturnBracket extends React.Component {
    props = {planId: 0, isView: false};
    constructor(props:any) {
        super(props);
    }
    
    columns = [
        {
            title: '原始班列号',
            dataIndex: 'originalShipmentNumber',
        },
        {
            title: '集装箱号',
            dataIndex: 'containerNumber',
        },
        {
            title: '支架号',
            dataIndex: 'bracketNumber',
        },
        {
            title: '出口报关',
            dataIndex: 'exportFee',
        },
        {
            title: '暂时进出口担保',
            dataIndex: 'tempGuaranteeCost',
        },
        {
            title: '境外代理',
            dataIndex: 'overseasAgency',
        },
        {
            title: '进口清关',
            dataIndex: 'customsClearanceCost',
        },
        {
            title: '担保核销',
            dataIndex: 'guaranteeWriteOff',
        },
        {
            title: '陆港吊装费',
            dataIndex: 'portHoistingFee',
        },
        {
            title: '拆箱费 ',
            dataIndex: 'devanningFee',
        },
        {
            title: '还箱短驳费',
            dataIndex: 'returnContainerFee',
        },
        {
            title: '核放费用',
            dataIndex: 'releaseCharge',
        },
        {
            title: '装车费',
            dataIndex: 'loadingFee',
        },
        {
            title: '支架转运费',
            dataIndex: 'bracketTransferFee',
        },
        {
            title: '二保卡扣',
            dataIndex: 'protectionBuckleFee',
        },
        {
            title: '螺丝补充',
            dataIndex: 'screwSupplementFee',
        },
        {
            title: '支架维修费',
            dataIndex: 'bracketMaintainFee',
        },
    ];

    originalData: any[] = [];
    oriTrainNumberStringList: string[] = [];
    oriTrainNumberList: any[] = [];
    containerNumberStringList: string[] = [];
    containerNumberList: any[] = [];

    originalShipmentNumber: string = '';
    containerNumber: string = '';
    fee = 0;
    feeType = '';
    state = {
        isLoaded: false,
        dataSource: this.originalData,
        searchText: '',
        fee: '',
    };


    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
        console.log(`selectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.selectedRows = selectedRows;
        this.checkCanBeSaved();
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };
    
    allPermissions: any[] = [];
    
    canBeSaved = false;

    saveSelected = () => {
        const costManagementUpdateModelList: any[] = [];
        this.state.dataSource.forEach(row => {
            this.selectedRows.forEach(element => {
                if (row.costId === element.costId) {
                    costManagementUpdateModelList.push({costId: element.costId, parameter: this.feeType, value: this.fee});
                }
            })
        })

        axiosInstance.post('/logistics/updateCostManagement', costManagementUpdateModelList).then(
            response => {
                costManagementUpdateModelList.forEach(element => {
                    const newArr = this.selectedRows.filter(item => item.bracketId !== element.bracketId);
                    this.selectedRows = newArr;
                });
                this.init();
                this.checkCanBeSaved();
                message.success('更新成功');
            },
            error => {
                displayErrorMessage(error, '更新失败');
            }
        )
    }
    changeFeeType = (event: any) => {
        this.feeType = event;
        this.checkCanBeSaved();
    }
    changeFee = (event: any) => {
        this.fee = event.target.value;
        this.checkCanBeSaved();
    }
    changeOriginalShipmentNumber = (event: any) => {
        this.originalShipmentNumber = event;
        this.search();
    }
    changeContainerNumber = (event: any) => {
        this.containerNumber = event;
        this.search();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.fee > 0 && this.feeType !== '' && this.selectedRows.length > 0) {
            this.canBeSaved = true;
        }
        this.setState({});
    }
    componentDidMount() {
        store.subscribe(() => {
            if (store.getState().returnPlanBracket.value) {
                store.dispatch(setUnChanged());
                this.init();
            }
        })
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({isLoaded: false});
        this.getCostManagementByReturnPlanId();
    }
    getCostManagementByReturnPlanId() {
        axiosInstance.get('/logistics/getCostManagementByReturnPlanId', {params: {returnPlanId: this.props.planId}}).then(
            response => {
                this.originalData = response.data;
                for(let i = 0; i < response.data.length; i++) {
                    this.originalData[i].key = this.originalData[i].costId;                    
                }
                this.oriTrainNumberStringList = [];
                this.oriTrainNumberList = [];
                this.containerNumberStringList = [];
                this.containerNumberList = [];
                this.oriTrainNumberList.push({label: '', value: ''});
                this.containerNumberList.push({label: '', value: ''});
                this.originalData.forEach(element => {
                    if (!this.oriTrainNumberStringList.includes(element.originalShipmentNumber)) {
                        this.oriTrainNumberStringList.push(element.originalShipmentNumber)
                        this.oriTrainNumberList.push({label: element.originalShipmentNumber, value: element.originalShipmentNumber});
                    }
                    if (!this.containerNumberStringList.includes(element.containerNumber)) {
                        this.containerNumberStringList.push(element.containerNumber)
                        this.containerNumberList.push({label: element.containerNumber, value: element.containerNumber});
                    }
                })
                this.setState({dataSource: this.originalData,isLoaded: true});
                this.search();
                this.checkCanBeSaved();
            },
            error => {
                displayErrorMessage(error, '获取支架失败!');
            }
        );
    }
    search = () => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => (
                (this.originalShipmentNumber === '' || item.originalShipmentNumber === this.originalShipmentNumber) &&
                (this.containerNumber === '' || item.containerNumber === this.containerNumber))
            )
        });
    }

    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="seperate">
                </div>   
                           
                <div className="content">
                    {
                        this.props.isView? '' :
                            <div className = 'header-right'>
                                <label>费用类型:</label>
                                <Select style={{width: '150PX'}} options={FeeType} onChange={this.changeFeeType}/> 
                                <label>&nbsp;&nbsp;费用:</label>
                                <Input  style={{width: '150PX'}} onChange={this.changeFee}/>  
                                <Button className="header-right" type="primary" onClick={this.saveSelected} disabled={!this.canBeSaved}>更新</Button>  
                            </div>
                    } 
                    {this.props.isView? '' :<br/>}
                    {this.props.isView? '' :<br/>}
                    <div className='row'>
                        <label style={{marginLeft: '20PX', width: '75PX'}}>原始班列号:</label>
                        <Select style={{width: '200PX'}} options={this.oriTrainNumberList} onChange={this.changeOriginalShipmentNumber} defaultValue={this.originalShipmentNumber}/> 
                        <label style={{marginLeft: '20PX', width: '60PX'}}>集装箱号:</label>
                        <Select style={{width: '200PX'}} options={this.containerNumberList} onChange={this.changeContainerNumber} defaultValue={this.containerNumber}/>
                    </div>
                    <Table  
                        rowSelection={{
                        type: 'checkbox',
                        ...this.rowSelection,
                        }} columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                </div>
            </div>
        )      
    }
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, Card, DatePicker, Tabs } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';
import TabPane from 'antd/es/tabs/TabPane';
import ConsumableOrderPayment from './ConsumableOrder/ConsumableOrderPayment';
import ConsumableOrderConsumableInventory from './ConsumableOrder/ConsumableOrderConsumableInventory';
import ConsumableOrderInventory from './ConsumableOrder/ConsumableOrderInventory';
import ComsumableOrderProduct from './ConsumableOrder/ComsumableOrderProduct';

const dateFormat = 'YYYY-MM-DD';

export default class ConsumableOrder extends React.Component {
    
    columns = [
    {
        title: '操作',
        key: 'operation',
        width: 80,
        render: (_: any, record: any)=> {
            return (
                <div>
                    <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp; &nbsp;
                </div>
            )}
    },
    {
        title: '订单号',
        dataIndex: 'orderId',
        width: 100,
        showSorterTooltip: false,
    },   
    {
        title: '供应商',
        dataIndex: 'vendorName',
        showSorterTooltip: false,
        width: 300,
        sorter: (a: any, b: any) => a.vendorId > b.vendorId? 1 : -1,
    },   
    {
        title: '下单日期',
        dataIndex: 'orderDate',
        width: 150,    
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.orderDate > b.orderDate? 1 : -1,
    },
    {
        title: '交货日期',
        dataIndex: 'planedShipmentDate',
        width: 150,    
        showSorterTooltip: false,
    },
    {
        title: '总数量',
        dataIndex: 'totalqty',
        width: 100,    
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.totalqty > b.totalqty? 1 : -1,
    },  
    {
        title: '总合计金额',
        dataIndex: 'totalPrice', 
        width: 110,    
        showSorterTooltip: false,
    },
    {
        title: '发票号',
        width: 110,  
        dataIndex: 'invoice',
        showSorterTooltip: false,
        render: (_: any, record: any)=> {
            return (
                <div style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>                   
                    {record.invoice}
                </div>
            )}    
    }, 
    {
        title: '开票日期',
        width: 110,  
        dataIndex: 'invoiceDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.invoiceDate > b.invoiceDate? 1 : -1,
    },
    {
        title: '收票日期',
        width: 110,  
        dataIndex: 'receiveInvoiceDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.receiveInvoiceDate > b.receiveInvoiceDate? 1 : -1,
    },
   
   
    {
        title: '发往地',
        dataIndex: 'orderCity',
        width: 100,    
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.orderCity > b.orderCity? 1 : -1,
    },
    {
        title: '订单状态',
        dataIndex: 'status',
        width: 100,    
        showSorterTooltip: false,      
        render: (_: any, record: any)=> {
            if (record.status == 'New') {
                return (
                    <div>
                        待入库
                    </div>
                )
            }else if (record.status == 'Inventoried') {
                return (
                    <div>
                        已入库
                    </div>
                )
            }else if (record.status == 'Checked') {
                return (
                    <div>
                        已对账
                    </div>
                )
            }else if (record.status == 'Invoiced') {
                return (
                    <div>
                        已收票
                    </div>
                )
            }else{
                return (
                    <div>
                        已付款
                    </div>
                )
            }
        }
    },

    {
        title: '备注',
        dataIndex: 'comments',        
        showSorterTooltip: false,
    },
     
    // {
    //     title: '是否入库',
    //     dataIndex: 'status',
    //     showSorterTooltip: false,      
    //     render: (_: any, record: any)=> {
    //         if (record.allInInventory) {
    //             return (
    //                 <div>
    //                     已入库
    //                 </div>
    //             )
    //         }else {
    //             return (
    //                 <div>
    //                     未入库
    //                 </div>
    //             )
    //         }
    //     }
    // },  
    ];
    
    expandColumns = [
        {
            title: '商品编码',
            dataIndex: 'productNumber',
            width: 127,
            showSorterTooltip: false,
        },
        {
            title: '品名',
            width: 97,
            dataIndex: 'productName',
            showSorterTooltip: false,
        },
        {
            title: '规格',
            width: 283,
            dataIndex: 'productSpec',
            showSorterTooltip: false,
        },
        {
            title: '单价',
            width: 140,
            dataIndex: 'unitPrice',            
            showSorterTooltip: false,
        },
        {
            title: '数量',
            width: 143,
            dataIndex: 'qty',
            showSorterTooltip: false,
        },
        {
            title: '合计金额',
            width: 100,
            dataIndex: 'amount',
            showSorterTooltip: false,
        },
        {
            title: '备注',
            dataIndex: 'comments',
            showSorterTooltip: false,
        },
    ];
    originalData: any[] = [];
    invoice = "";    
    invoiceDate = "";
    invoiceExpense = "";    
    invoiceCost = "";	
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
        isChangeStatus:false,
        paid: 0,
        expandDataSource: this.originalData,
        isChangeInvoice:false,
        expandedRowKeys: []
    };
    stationList: any[] = [];
    vendorList: any[] = [];
    consumableNameList: any[] = [];
    consumableSubTypeList: any[] = [];
    inventoryList: any[] = [{label:'已入库', value: true},{label:'未入库', value: false}];                                 
    statusList: any[] = [{label:'待入库', value: 'New'},{label:'已入库', value: 'Inventoried'},{label:'已对账', value: 'Checked'},{label:'已收票', value: 'Invoiced'},{label:'已付款', value: 'Paid'}]; 
    transportTypeList: any[] = [{label:'自提', value: '自提'},{label:'配送', value: '配送'}]; 
    editRecord = this.createConsumableOrder();
    originalEditRecords: any = {};
    canBeSaved = false;
    isNewSaved = false;
    productCodeList: any[] = [];
    
    createConsumableOrder() {
        return { 
            orderId : 0,
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            orderDate : "",
            dayJsOrderDate : dayjs(dayjs(new Date()), dateFormat),
            vendorId: 0,
            vendorName : "",
            planedShipmentDate: "",
            dayJsPlanedShipmentDate : dayjs(dayjs(new Date()), dateFormat),
            name: "",
            qty: 0,
            unitPrice: 0,
            allInInventory: false,
            allPaid: false,
            stationId: 0,
            stationName : "",
            transportType:"",
            consumablesType:"",
            actualShipmentDate: "",
            dayJsActualShipmentDate : dayjs(dayjs(new Date()), dateFormat),
            inputValueAddedTax:"",
            comments : "",
            invoice : "",
            project : "",
            consignee : "",
            orderCity : "",
            status : "",
            totalPrice:0
        };
    }
    
    copyConsumableOrder(consumableOrder: any) {
        return { 
            orderId : consumableOrder.orderId,
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            orderDate : consumableOrder.orderDate,
            dayJsOrderDate :  consumableOrder.orderDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(consumableOrder.orderDate, dateFormat),
            vendorId: consumableOrder.vendorId,
            vendorName : consumableOrder.vendorName,
            planedShipmentDate: consumableOrder.planedShipmentDate,
            dayJsPlanedShipmentDate :  consumableOrder.planedShipmentDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(consumableOrder.planedShipmentDate, dateFormat),
            name: consumableOrder.name,
            qty: consumableOrder.qty,
            unitPrice: consumableOrder.unitPrice,
            allInInventory: consumableOrder.allInInventory,
            allPaid: consumableOrder.allPaid,
            stationId: consumableOrder.stationId,
            stationName : consumableOrder.stationName,
            transportType:consumableOrder.transportType,
            consumablesType:consumableOrder.consumablesType,
            actualShipmentDate: consumableOrder.actualShipmentDate,
            dayJsActualShipmentDate :  consumableOrder.actualShipmentDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(consumableOrder.actualShipmentDate, dateFormat),
            inputValueAddedTax:consumableOrder.inputValueAddedTax,
            comments : consumableOrder.comments,
            invoice : consumableOrder.invoice,
            project : consumableOrder.project,
            consignee : consumableOrder.consignee,
            orderCity : consumableOrder.orderCity,
            status : consumableOrder.status,
            totalPrice:consumableOrder.totalPrice,
        };
    }
    searchText = {        
        status: '',
        vendorName:'',    
        name:'',  
        invoice:'', 
        consumablesType:'',  
        orderCity:'',  
        orderId:'', 
    }
    onSearchInvoice = (value: any) => {
        this.searchText.invoice = value.target.value;
        this.search();
    }
    onSearchOrderCity = (value: any) => {
        this.searchText.orderCity = value.target.value;
        this.search();
    }
    
    onSearchOrderId = (value: any) => {
        this.searchText.orderId = value.target.value;
        this.search();
    }
    onSearchVendorName = (value: any) => {
        this.searchText.vendorName = value.target.value;
        this.search();
    }
    onSearchName = (value: any) => {
        this.searchText.name = value.target.value;
        this.search();
    }
   
    changeSelectSearch = (field: string, event: any) => {
        (this.searchText as any)[field] = event
        this.search();
    }
    search = () => {        
        this.setState({
            dataSource: this.originalData.filter((item: any) => {  
                if (this.searchText.orderId != ''  && !String(item.orderId).includes(this.searchText.orderId)) {
                    return false;
                } 
                if (this.searchText.status != ''  && item.status !== this.searchText.status) {
                    return false;
                }
                if (this.searchText.vendorName != ''  && !item.vendorName?.toLowerCase().includes(this.searchText.vendorName?.toLowerCase())) {
                    return false;
                } 
                if (this.searchText.name != ''  && !item.name?.toLowerCase().includes(this.searchText.name?.toLowerCase())) {
                    return false;
                }    
                if (this.searchText.orderCity != ''  && !item.orderCity?.toLowerCase().includes(this.searchText.orderCity?.toLowerCase())) {
                    return false;
                }  
                if (this.searchText.invoice != ''  && !item.invoice?.toLowerCase().includes(this.searchText.invoice?.toLowerCase())) {
                    return false;
                } 
                return true;
            }
            )
          })
    }
   
    onEdit = (record: any) => {
        this.originalEditRecords = this.copyConsumableOrder(record);
        this.editRecord = this.copyConsumableOrder(record);
        this.getConsumableOrderPaymentByOrderId();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {        
        this.selectedRows = selectedRows;
        this.checkCanBeSaved();
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };

    deleteSelected = () => {        
        if(this.selectedRows.length==0) {
            alert('请选择要删除的条目!');
            return;
        }
        this.selectedRows.forEach(element => {
            const ConsumableOrder = {orderId: element.orderId, orderDate: element.orderDate};
            axiosInstance.post('/logistics/deleteConsumableOrder', ConsumableOrder).then(
                response => {
                    this.init();                    
                },
                error => {
                    displayErrorMessage(error, '删除失败');
                }
            )
        })               
        message.success('删除成功');
    }


    onDelete = (record: any) => {
        const ConsumableOrder = {orderId: record.orderId, orderDate: record.orderDate};
        axiosInstance.post('/logistics/deleteConsumableOrder', ConsumableOrder).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createConsumableOrder();
        this.originalEditRecords = this.createConsumableOrder();
        this.canBeSaved = false;
        this.setState({isEdit: true, qty: 0, unitCost: 0, paid: 0});
    }

    onExport = () => {
        this.setState({isLoaded: false});
        const ids = this.selectedRows.map(element => element.orderId).join(',');        
        axiosInstance.get('/logistics/exportConsumableOrder', {params: {orderIds: ids},responseType: 'blob'}).then(               
            response => {
                this.setState({isLoaded: true});
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const fileName = '耗材订单明细.xlsx';
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                message.success('导出成功!');
            },
            error => {
                displayErrorMessage(error, '导出失败!');
            }
        );

    }

    getConsumableOrderPaymentByOrderId() {
        axiosInstance.get('/logistics/getConsumableOrderPaymentByOrderId', {params: {orderId: this.editRecord.orderId}}).then(
            response => {
                let paid = 0;
                for (let i = 0; i < response.data.length; i++) {
                    paid =  paid + response.data[i].paymentAmount;
                }
                this.setState({paid: paid});
            },
            error => {
                displayErrorMessage(error, '获取收款信息失败');
            }
        )

    }
    save = () => {
        const consumableOrder = this.copyConsumableOrder(this.editRecord);
        if (consumableOrder.orderId === 0) {
            axiosInstance.post('/logistics/insertConsumableOrder', consumableOrder).then(
                response => {
                    this.editRecord.orderId = response.data[0].orderId;
                    this.originalEditRecords = {...this.editRecord};
                    this.getConsumableOrderPaymentByOrderId();
                    this.canBeSaved = false;
                    this.isNewSaved = true;
                    this.setState({isEdit: true});
                    
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateConsumableOrder', consumableOrder).then(
                response => {
                    this.init();
                    message.success('修改成功');
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )

        }
    }
    cancel = () => {
        if (this.isNewSaved) {
            this.init();
        }else {
            this.setState({isEdit: false});
        }
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }    
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event
        this.checkCanBeSaved();
    }
    changeOrderDate = (date: any, dateString: string) => {
        this.editRecord.orderDate = dateString;
        this.checkCanBeSaved();
    }
    changePlanedShipmentDate = (date: any, dateString: string) => {
        this.editRecord.planedShipmentDate = dateString;
        this.checkCanBeSaved();
    }
    changeActualShipmentDate = (date: any, dateString: string) => {
        this.editRecord.actualShipmentDate = dateString;
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;        
        if (this.editRecord.orderDate !== null && this.editRecord.orderDate !== '' &&
            this.editRecord.vendorId !== null && this.editRecord.vendorId !== 0 &&
            this.editRecord.planedShipmentDate !== null && this.editRecord.planedShipmentDate !== '' &&
            // this.editRecord.name !== null && this.editRecord.name !== '' &&
            // this.editRecord.qty !== null && this.editRecord.qty !== 0 &&
            // this.editRecord.unitPrice !== null && this.editRecord.unitPrice !== 0 &&
            // this.editRecord.stationId !== null && this.editRecord.stationId !== 0 &&
            // this.editRecord.consumablesType !== null && this.editRecord.consumablesType !== '' &&
            this.editRecord.status !== null && this.editRecord.status !== ''
        ) {
            if (this.editRecord.orderDate !== this.originalEditRecords.orderDate ||
                this.editRecord.vendorId !== this.originalEditRecords.vendorId ||                                
                // this.editRecord.recyclable !== this.originalEditRecords.recyclable ||   
                this.editRecord.orderDate !== this.originalEditRecords.orderDate ||
                this.editRecord.planedShipmentDate !== this.originalEditRecords.planedShipmentDate ||
                // this.editRecord.bracketNumber !== this.originalEditRecords.bracketNumber ||
                // this.editRecord.qty !== this.originalEditRecords.qty ||
                // this.editRecord.unitPrice !== this.originalEditRecords.unitPrice ||
                this.editRecord.orderCity !== this.originalEditRecords.orderCity ||
                this.editRecord.transportType !== this.originalEditRecords.transportType ||
                // this.editRecord.bracketType !== this.originalEditRecords.bracketType ||
                // this.editRecord.usageChennel !== this.originalEditRecords.usageChennel ||
                // this.editRecord.batchNumber !== this.originalEditRecords.batchNumber ||
                // this.editRecord.colour !== this.originalEditRecords.colour ||                
                this.editRecord.project !== this.originalEditRecords.project ||        
                this.editRecord.consignee !== this.originalEditRecords.consignee ||        
                this.editRecord.comments !== this.originalEditRecords.comments || 
                this.editRecord.invoice !== this.originalEditRecords.invoice || 
                this.editRecord.status !== this.originalEditRecords.status ) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.isNewSaved = false;
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllConsumableOrder();
        this.getAllConsumableNames();
        this.getAllConsumableSubTypes();
        this.getAllVendors();
        this.getAllStations();
        this.getAllProductCodes();
    }

    changeUserName = (event: any) => {
        this.invoice = event.target.value;
        this.checkCanBeUpdated();
    }

    changeInvoiceDate = (date: any, dateString: string) => {
            this.invoiceDate = dateString;
            this.checkCanBeUpdated();
        }   	
        
    changeInvoiceExpense = (event: any) => {
            this.invoiceExpense = event.target.value;
            this.checkCanBeUpdated();
        }

    changeInvoiceCost = (event: any) => {
        this.invoiceCost = event.target.value;
        this.checkCanBeUpdated();
    }	

    checkCanBeUpdated() {
        this.canBeSaved = false;
        if (this.invoice !== null && this.invoice !== '' &&
            this.invoiceDate !== null && this.invoiceDate !== '' ) {
            this.canBeSaved = true;
        }
        this.setState({});
    }	

    getAllConsumableOrder() {
        axiosInstance.get('/logistics/getAllConsumableOrder').then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].orderId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
                // if (this.state.searchText !== '') {
                //     this.search(this.state.searchText);
                // }
            },
            error => {
                displayErrorMessage(error, '获取支架订单失败');
            }
        )

    }
    getAllVendors() {
        axiosInstance.get('/logistics/getAllVendors').then(
            response => {
                this.vendorList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.vendorList.push({label:response.data[i].vendorName, value: response.data[i].vendorId});               
                }
            },
            error => {
                displayErrorMessage(error, '获取供应商失败');
            }
        )
    }
    changeStatus = () => { 
        this.setState({isChangeStatus: !this.state.isChangeStatus});        
    };

    changeInvioce = () => { 
        this.setState({isChangeInvoice: !this.state.isChangeInvoice});        
    };
    
    onStatusChange = () => {        
        this.selectedRows.forEach(element => {            
            const order = {orderId: element.orderId,status:this.editRecord.status};
            axiosInstance.post('/logistics/updateConsumableOrderStatus', order).then(
                response => {
                    this.init();                   
                },
                error => {
                    displayErrorMessage(error, '操作失败');
                }
            )
        })          
        message.success('操作成功');        
        this.setState({isChangeStatus: !this.state.isChangeStatus});         
    }

    changeStatusValue = (event: any) => {
        this.editRecord.status = event;        
    }
  getAllConsumableSubTypes() {
        axiosInstance.get('/logistics/getCommonPropertiesByKey',{params: {key: 'consumableSubType'}}).then(
            response => {
                this.consumableSubTypeList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.consumableSubTypeList.push({label:response.data[i].propertyValue, value: response.data[i].propertyValue});               
                }
            },
            error => {
                displayErrorMessage(error, '获取数据失败');
            }
        )  
    }

    getAllConsumableNames() {
        axiosInstance.get('/logistics/getCommonPropertiesByKey',{params: {key: 'consumableType'}}).then(
            response => {
                this.consumableNameList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.consumableNameList.push({label:response.data[i].propertyValue, value: response.data[i].propertyValue});               
                }
            },
            error => {
                displayErrorMessage(error, '获取数据失败');
            }
        )  
    }   

    getAllStations() {
        axiosInstance.get('/logistics/getAllStations').then(
            response => {
                this.stationList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.stationList.push({label:response.data[i].stationName, value: response.data[i].stationId});
                }
            },
            error => {
                displayErrorMessage(error, 'Get document list failed!');
            }
        )
    }
    getAllProductCodes() {
        axiosInstance.get('/logistics/getAllProductCodes').then(
            response => {                
                for (let i = 0; i < response.data.length; i++) { 
                    let item = response.data[i].productNumber + "-" 
                        + response.data[i].productName + "-" 
                        + response.data[i].productSpec;
                    let itemValue = response.data[i].id + "*" 
                        + response.data[i].productName + "*" 
                        + response.data[i].productSpec + "*"
                        + response.data[i].unitPriceList;            
                    this.productCodeList.push({label:item, value: itemValue});
                }         
            },
            error => {
                displayErrorMessage(error, '获取商品编码失败');
            }
        )

    }
    onTabChange = (activeKey:string) => {
        if (activeKey == '1') {
            this.getConsumableOrderPaymentByOrderId();            
        }
    };
    expandedRowRender = () => (
        <Table
          columns={this.expandColumns}
          dataSource={this.state.expandDataSource}
          pagination={false}
        />
      );

    updatedKeys = "";
    makeInvoice = () => {  
        this.updatedKeys = ";";
        for(let i=0; i<this.selectedRows.length; i++)  {
          this.selectedRows[i].isUpdated = false;
          this.updatedKeys = this.updatedKeys + this.selectedRows[i].key + ";";
        }                       
        this.selectedRows.forEach(element => {        
            element.id = element.orderId
            element.type = 'consumableOrder'
            element.invoice = this.invoice
            element.invoiceDate = this.invoiceDate
            element.invoiceExpense = this.invoiceExpense
            element.invoiceCost = this.invoiceCost
            axiosInstance.post('/logistics/makeInvoice', element).then(
                response => {     
                    element.isUpdated = true;     
                    let isAllUpdated = true;   
                    for(let i=0; i<this.selectedRows.length; i++)  {
                        if(!this.selectedRows[i].isUpdated){
                            isAllUpdated = false;
                        }
                    }     
                    if(isAllUpdated){
                        const newDataSource = this.state.dataSource?.map(item => {
                            if (this.updatedKeys.includes(";" + item.key+ ";")) {
                                return { ...item, verified:true }; 
                            }
                            return item; 
                        });                    
                        this.setState({dataSource:newDataSource});
                    }
                
                },
                error => {
                    displayErrorMessage(error, '操作失败');
                }
            )
        })               
        message.success('操作成功');
        // this.init();        
        // this.setState({isLoaded: true});    
    }
    getAllConsumableOrderProducts(record: any) {
        axiosInstance.get('/logistics/getAllConsumableOrderProducts',{params: {orderId: record.orderId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].id;
                }
                this.setState({expandDataSource: this.originalData});
            },
            error => {
                displayErrorMessage(error, '获取商品失败');
            }
        )

    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 耗材订单管理</span>
                </div>
                <div className="seperate">
                </div>
                {this.state.isEdit? 
                    <div className="content">
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>退出</Button>
                        </div>
                        <Tabs defaultActiveKey="1"  onChange={this.onTabChange}>
                            <TabPane tab='基本信息' key = '1'>
                            <Card size="small" style={{ width: '90%' }}>    
                                <div className="row">
                                <div className="box">
                                <label className="box-label" style={{ width: '100px' }}>订单编号:</label>
                                    <Input className="box-input" style={{ width: '200px' }} disabled defaultValue={this.editRecord.orderId==0?'':this.editRecord.orderId} />

                                <label className="box-label"style={{ width: '100px' }}>供应商:</label>
                                    <Select className="box-input"  style={{ width: '200px' }}
                                        showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    options={this.vendorList} onChange={this.changeSelect.bind(this, 'vendorId')} defaultValue={this.editRecord.vendorName}/>  


                                <label className="box-label" style={{ width: '100px' }}>订购日期:</label>
                                    {(this.editRecord.orderDate + "") == "" ?
                                        <DatePicker style={{ width: '200px' }} className="box-input" onChange={this.changeOrderDate} />
                                        :
                                        <DatePicker style={{ width: '200px' }} className="box-input" onChange={this.changeOrderDate} defaultValue={this.editRecord.dayJsOrderDate} />}

                                <label className="box-label" style={{ width: '100px' }}>交货日期:</label>
                                    {(this.editRecord.planedShipmentDate + "") == "" ?
                                        <DatePicker style={{ width: '200px' }} className="box-input" onChange={this.changePlanedShipmentDate} />
                                        :
                                        <DatePicker style={{ width: '200px' }} className="box-input" onChange={this.changePlanedShipmentDate} defaultValue={this.editRecord.dayJsPlanedShipmentDate} />}             
                                </div>
                            </div>
                            <div className="row">
                                <div className="box">
                                    <label className="box-label" style={{ width: '100px' }}>项目:</label>
                                    <Input className="box-input" style={{ width: '200px' }} defaultValue={this.editRecord.project} onChange={this.changeInput.bind(this, 'project')} />

                                    <label className="box-label" style={{ width: '100px' }}>收货人:</label>
                                    <Input className="box-input" style={{ width: '200px' }}  defaultValue={this.editRecord.consignee} onChange={this.changeInput.bind(this, 'consignee')}/>

                                    <label className="box-label" style={{ width: '100px' }}>收货地址:</label>
                                    <Input className="box-input" style={{ width: '200px' }}  defaultValue={this.editRecord.orderCity} onChange={this.changeInput.bind(this, 'orderCity')}/>                                     

                                    <label className="box-label" style={{ width: '100px' }}>运输类型:</label>                                            
                                            <Select className="box-input"  style={{ width: '200px' }} options={this.transportTypeList} onChange={this.changeSelect.bind(this, 'transportType')} defaultValue={this.editRecord.transportType} />                                        
                                </div>
                            </div>
                           
                            <div className="row">
                            <div className="box">
                                    <label className="box-label" style={{ width: '100px' }} >订单状态:</label>
                                    <Select className="box-input"  style={{ width: '200px' }} options={this.statusList} onChange={this.changeSelect.bind(this, 'status')} defaultValue={this.editRecord.status} />                                                       
                                    <label className="box-label" style={{ width: '100px' }}>发票号:</label>                                            
                                        <Input className="box-input" style={{ width: '200px' }}  defaultValue={this.editRecord.invoice} onChange={this.changeInput.bind(this, 'invoice')}/>                                                                                
                                    <label className="box-label" style={{ width: '100px' }}>备注:</label>                                            
                                    <Input className="box-input" style={{ width: '200px' }}  defaultValue={this.editRecord.comments} onChange={this.changeInput.bind(this, 'comments')}/>                                            
                                </div>
                            </div>
                                    <br/>
                                    {
                                        this.editRecord.orderId == 0? 
                                        '' :
                                        <div>     
                                            <div className="row" >                                     
                                            <ComsumableOrderProduct  orderId={this.editRecord.orderId} productCodeList={this.productCodeList}/>
                                            </div>
                                        </div>
                                    }
                                    <div>
                                        <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                                        <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                                    </div>
                                </Card>
                            </TabPane>
                            <TabPane tab='入库信息' key = '2' disabled={this.editRecord.orderId == 0}>
                                <ConsumableOrderInventory orderId={this.editRecord.orderId}  /> 
                            </TabPane>
                            <TabPane tab='付款记录' key = '3' disabled={this.editRecord.orderId == 0}>
                                <ConsumableOrderPayment orderId={this.editRecord.orderId} totalPrice={this.editRecord.totalPrice}/>
                            </TabPane>
                        </Tabs>
                    </div>
                :
                    <div className="content">                        
                        <label className="box-label">订单号:</label>
                        <Input onChange={this.onSearchOrderId}  className="tableSearch" value={this.searchText.orderId} />                      
                        <label className="box-label">订单状态:</label>
                        <Select style={{ width: '172px' }} options={[{label:'--全部--', value: ''},...this.statusList]} onChange={this.changeSelectSearch.bind(this, 'status')}/>   
                        <label className="box-label">供应商:</label>
                        <Input onChange={this.onSearchVendorName}  className="tableSearch" value={this.searchText.vendorName} />                      
                        <label className="box-label">发往地:</label>
                        <Input onChange={this.onSearchOrderCity}  className="tableSearch" value={this.searchText.orderCity} />                          
                        <label className="box-label">发票号:</label>
                        <Input onChange={this.onSearchInvoice}  className="tableSearch" value={this.searchText.invoice} /> 
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>
                        <Button type="primary" className="header-right" disabled={this.selectedRows.length==0} onClick={this.onExport}>导出Excel</Button>
                        <div className="row">
                            <Button type="primary" className="header-left" onClick={this.deleteSelected} disabled={this.selectedRows.length==0}>批量删除</Button>  
                            <Button type="primary" className="header-left" onClick={this.changeStatus} disabled={this.selectedRows.length==0}>批量改状态</Button>  
                            <Button type="primary" className="header-left" onClick={this.changeInvioce} disabled={this.selectedRows.length==0 } >批量收票</Button>   
                            {this.state.isChangeStatus? 
                            <div className="box">
                                <label className="box-label">状态:</label>
                                <Select className="box-input" style={{width: '150px'}} options={this.statusList} onChange={this.changeStatusValue}/> 
                                <Button type="primary" className="header-left" onClick={this.onStatusChange}>确定</Button>  
                            </div>
                            :''
                            }
                            {this.state.isChangeInvoice? 
                            <div className="box">
                                <label className="box-label">发票号:</label>
                                <Input className="box-input" style={{width: '150px'}} onChange={this.changeUserName} />
                                <label className="box-label">开票日期:</label>
                                <DatePicker className="box-input" style={{width: '150px'}}onChange={this.changeInvoiceDate} /> 
                                <Button type="primary" className="header-left" onClick={this.makeInvoice}  disabled={!this.canBeSaved}>确定</Button>                            
                            </div>
                            :''
                            }
                        </div>     
                        <Table pagination={{ pageSize: 40 }}  columns={this.columns} rowSelection={{
                            type: 'checkbox',
                            ...this.rowSelection,
                            }} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}
                            expandable={{ 
                                expandedRowRender: this.expandedRowRender, 
                                defaultExpandedRowKeys: [],           
                                expandedRowKeys: this.state.expandedRowKeys,                     
                                onExpand: (expanded:boolean, record: any) => {
                                    if (expanded) {
                                        this.setState({expandedRowKeys: [record.key]});
                                        this.getAllConsumableOrderProducts(record);
                                    } else {
                                        this.setState({expandedRowKeys: []});
                                    }
                                },
                                }} 
                                summary={() => {
                                    let allTotalqty = 0;
                                    let allTotalPrice = 0;
                                    if(this.selectedRows.length>0){
                                        this.selectedRows?.forEach(({ totalqty,totalPrice }) => {
                                            allTotalqty += totalqty;
                                            allTotalPrice += totalPrice;                                                                          
                                        });  
                                    }
                                    return (
                                    <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>选中汇总</Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>{allTotalqty % 1 === 0 ? allTotalqty : allTotalqty.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}>{allTotalPrice % 1 === 0 ? allTotalPrice : allTotalPrice.toFixed(2)}</Table.Summary.Cell>                                    
                                    <Table.Summary.Cell index={9}></Table.Summary.Cell>                                    
                                    <Table.Summary.Cell index={10} />
                                    </Table.Summary.Row>
                                    );  
                                    }}/> 
                    </div>
                }
            </div>
        )      
    }
}

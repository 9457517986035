import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';

const dateFormat = 'YYYY-MM-DD';

export default class ReturnConsumables extends React.Component {
    props = {planId: 0, isView: false};
    constructor(props:any) {
        super(props);
      }
    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        {
                            this.props.isView?
                            '':
                            <Popconfirm
                                title="删除耗材"
                                description="是否确定删除?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => this.onDelete(record)}
                            >
                                <DeleteOutlined title='删除' className="actionIcon"/>
                            </Popconfirm>
                        }
                        
                    </div>
                )}
        },
    {
        title: '耗材名称',
        dataIndex: 'consumablesName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.consumablesName > b.consumablesName? 1 : -1,
    },
    {
        title: '耗材类型',
        dataIndex: 'consumablesType',
        showSorterTooltip: false,
    },
    {
        title: '数量',
        dataIndex: 'amount',
        showSorterTooltip: false,
    },    
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
    };
    allPermissions: any[] = [];
    
    editRecord = this.createReturnConsumables();
    originalEditRecords: any = {};
    consumablesInventoryList: any[] = [];
    canBeSaved = false;
    
    createReturnConsumables() {
        return { 
            id: 0,
            consumablesId : 0,
            stationId : 0,
            vendorId : 0,
            consumablesName : "",
            consumablesType : "",
            entryDate : "",
            unitCost : 0,
            stationName : "",
            amount : 0,
        };
    }
    onDelete = (record: any) => {
        const ReturnConsumables = {id: record.id, planId: this.props.planId, consumablesId: record.consumablesId, consumablesName: record.consumablesName, consumablesType: record.consumablesType, unitCost: record.unitCost};
        axiosInstance.post('/logistics/deleteReturnConsumablesById', ReturnConsumables).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createReturnConsumables();
        this.originalEditRecords = this.createReturnConsumables();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    save = () => {  
        axiosInstance.post('/logistics/insertReturnConsumables', {planId: this.props.planId, consumablesId: this.editRecord.consumablesId,amount: this.editRecord.amount}).then(
            response => {
                this.init();
                message.success('操作成功');
            },
            error => {
                displayErrorMessage(error, '操作失败');
            }
        )
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event
        this.checkCanBeSaved();
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.consumablesId !== null && this.editRecord.consumablesId !== 0 &&  this.editRecord.amount !== 0) {
            if (this.editRecord.consumablesId !== this.originalEditRecords.consumablesId) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getReturnConsumablesByPlanId();
        // this.getAllConsumablesInventorys();
    }
    getReturnConsumablesByPlanId() {
        axiosInstance.get('/logistics/getReturnConsumablesByPlanId', {params: {planId: this.props.planId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].consumablesId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取耗材失败');
            }
        )

    }
    getAllConsumablesInventorys() {
        axiosInstance.get('/logistics/getAllConsumablesInventorys').then(
            response => {
                this.consumablesInventoryList = [];
                for(let i = 0; i < response.data.length; i++) {
                    this.consumablesInventoryList.push({label:response.data[i].consumablesName, value: response.data[i].consumablesId});
                }
            },
            error => {
                displayErrorMessage(error, '获取耗材失败!');
            }
        )
    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="seperate">
                </div>
                
                
                {this.state.isEdit? 
                    <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">耗材名称:</label>
                                <Select className="box-input"  options={this.consumablesInventoryList} onChange={this.changeSelect.bind(this, 'consumablesId')} defaultValue={this.editRecord.consumablesName}/> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <label className="box-label">数量:</label>                                
                                <Input className="box-input" defaultValue={this.editRecord.amount} onChange={this.changeInput.bind(this, 'amount')}/>   
                            </div>
                        </div>
                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                        </div>
                    </div>
                :
                    <div className="content">
                        {
                            this.props.isView?
                            '':
                            <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>
                        }
                        
                        <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';

export default class User extends React.Component {
    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <EditOutlined className="actionIcon" title='修改用户' onClick={() => this.onEdit(record)}/>&nbsp; &nbsp;
                        <Popconfirm
                            title="重置密码"
                            description="Are you sure to reset password for this user?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onResetPassword(record)}
                        >
                            <RetweetOutlined title='重置密码' className="actionIcon" />
                        </Popconfirm>&nbsp; &nbsp;
                        <Popconfirm
                            title="删除用户"
                            description="Are you sure to delete this user?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除用户' className="actionIcon"/>
                        </Popconfirm>
                        
                    </div>
                )}
        },
    {
        title: '登录名',
        dataIndex: 'loginName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.loginName > b.loginName? 1 : -1,
    },
    {
        title: '用户名',
        dataIndex: 'userName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.userName > b.userName? 1 : -1,
    },
    {
        title: '地址',
        dataIndex: 'address',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.address > b.address? 1 : -1,
    },
    {
        title: '联系电话',
        dataIndex: 'contactPhone',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.contactPhone > b.contactPhone? 1 : -1,
    },
    {
        title: '角色',
        dataIndex: 'permissions',
        showSorterTooltip: false,
    }
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
    };
    allPermissions: any[] = [];
    userId = 0;
    userName = "";
    address = "";
    loginName = "";
    contactPhone = "";
    permissions = "";
    permissionList: string[] = [];
    originalEditRecords: any = {};
    canBeSaved = false;
    onSearch = (value: any) => {
        this.setState({searchText: value.target.value})
        console.log('searchText==',value.target.value)
        this.search(value.target.value);
    }
    search = (searchText: string) => {       
        this.setState({
            dataSource: this.originalData.filter((item: any) => (item.userName.toLowerCase().includes(searchText.toLowerCase())  ||
                        item.loginName.toLowerCase().includes(searchText.toLowerCase())    
                    )
            )
          })
    }
    onEdit = (record: any) => {
        this.userId = record.userId;
        this.userName = record.userName;
        this.address = record.address;
        this.contactPhone = record.contactPhone;
        this.loginName = record.loginName;
        this.permissions = record.permissions;
        this.permissionList = this.permissions.split(',');
        this.originalEditRecords = {userId: record.userId, userName: record.userName, address: record.address, contactPhone: record.contactPhone, loginName: record.loginName, permissions: record.permissions};
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    onDelete = (record: any) => {
        const user = {userId: record.userId, userName: record.userName, address: record.address, contactPhone: record.contactPhone, loginName: record.loginName, permissions: record.permissions};
        axiosInstance.post('/logistics/deleteUser', user).then(
            response => {
                this.init();
                message.success('Delete user successful!');
            },
            error => {
                displayErrorMessage(error, 'Delete user failed!');
            }
        )
    }
    onResetPassword = (record: any) => {
        const user = {userId: record.userId, userName: record.userName, address: record.address, contactPhone: record.contactPhone, loginName: record.loginName, permissions: record.permissions};
        axiosInstance.post('/logistics/resetPassword', user).then(
            response => {
                this.init();
                message.success('Reset password successful!');
            },
            error => {
                displayErrorMessage(error, 'Reset password failed!');
            }
        )
    }
    onAdd = () => {
        this.userId = 0;
        this.userName = '';
        this.address = '';
        this.contactPhone = '';
        this.loginName = '';
        this.permissions = '';
        this.permissionList = [];
        this.originalEditRecords = {userId: 0, userName: '', address: '', contactPhone: '', loginName: '', permissions: ''};
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    
    onExport = () => {
        this.setState({isLoaded: false});
        axiosInstance.get('/logistics/exportUser', {responseType: 'blob'}).then(
            response => {
                this.setState({isLoaded: true});
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const fileName = 'user.xlsx';
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                message.success('Export successful!');
            },
            error => {
                displayErrorMessage(error, 'Export failed!');
            }
        );

    }
    save = () => {
        const user = {userId: this.userId, userName: this.userName, address: this.address, contactPhone: this.contactPhone, loginName: this.loginName, permissions: this.permissions};
        if (this.userId === 0) {
            axiosInstance.post('/logistics/insertUser', user).then(
                response => {
                    this.init();
                    message.success('Insert user successful!');
                },
                error => {
                    displayErrorMessage(error, 'Insert user failed!');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateUser', user).then(
                response => {
                    this.init();
                    message.success('Update user successful!');
                },
                error => {
                    displayErrorMessage(error, 'Update user failed!');
                }
            )

        }
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeUserName = (event: any) => {
        this.userName = event.target.value;
        this.checkCanBeSaved();
    }
    changeAddress = (event: any) => {
        this.address = event.target.value;
        this.checkCanBeSaved();
    }
    changeContactPhone = (event: any) => {
        this.contactPhone = event.target.value;
        this.checkCanBeSaved();
    }
    changeLoginName = (event: any) => {
        this.loginName = event.target.value;
        this.checkCanBeSaved();
    }
    changePermissions = (value: any) => {
        this.permissionList = value;
        this.permissions = this.permissionList.toString();
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.userName !== null && this.userName !== '') {
            if (this.userName !== this.originalEditRecords.userName ||
                this.address !== this.originalEditRecords.address ||
                this.contactPhone !== this.originalEditRecords.contactPhone ||
                this.loginName !== this.originalEditRecords.loginName ||
                this.permissions !== this.originalEditRecords.permissions) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllUsers();
    }
    getAllUsers() {
        axiosInstance.get('/logistics/getAllUsers').then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].userId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
                if (this.state.searchText !== '') {
                    this.search(this.state.searchText);
                }
                axiosInstance.get('/logistics/getAllPermissions').then(
                    response1 => {
                        this.allPermissions = [];
                        for (let i = 0; i < response1.data.length; i++) {
                            this.allPermissions.push({
                              label: response1.data[i].name,
                              value: response1.data[i].name,
                            });
                        }
                    },
                    error => {
                        displayErrorMessage(error, 'Get users failed!');
                    }
                )
            },
            error => {
                displayErrorMessage(error, 'Get user list failed!');
            }
        )

    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 用户管理</span>
                </div>
                <div className="seperate">
                </div>
                
                
                {this.state.isEdit? 
                    <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">用户名:</label>
                                <Input className="box-input" onChange={this.changeUserName} defaultValue={this.userName}/>
                                <label className="box-label">地址:</label>
                                <Input className="box-input" onChange={this.changeAddress} defaultValue={this.address}/>                                    
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <label className="box-label">联系电话:</label>
                                <Input className="box-input" onChange={this.changeContactPhone} defaultValue={this.contactPhone}/>
                                <label className="box-label">登录名:</label> 
                                <Input className="box-input" onChange={this.changeLoginName} defaultValue={this.loginName}/>                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <label className="box-label">角色:</label>
                                {/* <Input className="box-input" onChange={this.changePermissions} value={this.permission}/> */}
                                <Select
                                    mode="multiple"
                                    allowClear
                                    className="box-input" 
                                    placeholder="Please select"
                                    defaultValue={this.permissionList}
                                    onChange={this.changePermissions}
                                    options={this.allPermissions}
                                />                              
                            </div>
                        </div>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>Cancel</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>Save</Button>
                        </div>
                    </div>
                :
                    <div className="content">
                        <Input placeholder="输入要查询的字符" onChange={this.onSearch} className="tableSearch" value={this.state.searchText} /> 
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>
                        
                        <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';

export default class ReceiveShipmentInvoice extends React.Component {
    permissions = sessionStorage.getItem("permissions") || '';
    columns = [
    {
        title: '批次号',
        dataIndex: 'entityNumber',        
        showSorterTooltip: false,      
        sorter: (a: any, b: any) => a.entityNumber > b.entityNumber? 1 : -1,
    },  
    {
        title: '供应商',
        dataIndex: 'vendorName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.vendorName > b.vendorName? 1 : -1,
    },
    {
        title: '发票号',
        width: 100,  
        dataIndex: 'invoice',
        showSorterTooltip: false,
    }, 
    {
        title: '开票日期',
        width: 110,  
        dataIndex: 'invoiceDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.invoiceDate > b.invoiceDate? 1 : -1,
    },
    {
        title: '收票日期',
        width: 110,  
        dataIndex: 'receiveInvoiceDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.receiveInvoiceDate > b.receiveInvoiceDate? 1 : -1,
    },
    {
        title: '审核',
        dataIndex: 'verified',
        showSorterTooltip: false,        
        sorter: (a: any, b: any) => a.verified > b.verified? 1 : -1,
        render: (_: any, record: any)=> {
            return (
                <div>
                    {
                        record.verified == true ? '是 ' :'否 '
                    }
                </div>
            )}
    },   
    {
        title: '名称',
        dataIndex: 'name',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.name > b.name? 1 : -1,
    },
    {
        title: '下单日期',
        dataIndex: 'orderDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.orderDate > b.orderDate? 1 : -1,
    },
    {
        title: '总金额',
        dataIndex: 'totalPrice',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.totalPrice > b.totalPrice? 1 : -1,
        render: (_: any, record: any)=> {
            return (
                <div>                    
                    <a onClick={() => this.onTotal(record)}>{record.totalPrice}</a>                 
                </div>
            )}    
    },
    {
        title: '已付款',
        dataIndex: 'paid',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.paid > b.paid? 1 : -1,
        render: (_: any, record: any)=> {
        return (
            <div>                    
                <a onClick={() => this.onPaid(record)}>{record.paid}</a>                 
            </div>
        )}        
    },
    {
        title: '未付款',
        dataIndex: 'notPaid',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.notPaid > b.notPaid? 1 : -1,
    },
    ];

    columnsTotal = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div> {                        
                        this.permissions.includes('财务总监') && '基本支出异常支出'.includes(record.expenseType)?
                        <>                        
                        <Popconfirm
                            title="删除该记录" 
                            description="是否确定删除?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除' className="actionIcon"/>
                        </Popconfirm>
                        </>
                        :''
                        }                                 
                    </div>
                )
            }
        },
        {
            title: '供应商',
            dataIndex: 'vendorName',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.vendorName > b.vendorName? 1 : -1,
        },
        {
            title: '费用类型',
            dataIndex: 'expenseType', 
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.expenseType > b.expenseType? 1 : -1,
            render: (_: any, record: any)=> {
                return (
                    <div>                    
                        {record.expenseType=='baseCost'?'基本费用':'异常费用'}
                    </div>
                )}    
        },
        {
            title: '费用项目',
            dataIndex: 'subExpense',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.subExpense > b.subExpense? 1 : -1,
        },
        {
            title: '应付款',
            dataIndex: 'cost',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.amount > b.amount? 1 : -1,
        },  
        {
            title: '备注',
            dataIndex: 'comments',
        },  
       
        ];
  
    columnsPaid = [     
    // {
    //     title: '供应商',
    //     dataIndex: 'vendorName',
    //     showSorterTooltip: false,
    //     sorter: (a: any, b: any) => a.vendorName > b.vendorName? 1 : -1,
    // },  
    {
        title: '付款时间',
        dataIndex: 'paymentDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.paymentDate > b.paymentDate? 1 : -1,
    },
    {
        title: '付款金额',
        dataIndex: 'paymentAmount',
        showSorterTooltip: false,
    },
    {
        title: '备注',
        dataIndex: 'comments',
        showSorterTooltip: false,
    },
    ];
    originalData: any[] = [];
    paidData: any[] = [];
    totalData: any[] = [];
    totalCost = 0;    
    paymentDate = "";
    invoice = "";    
    invoiceDate = "";    
    invoiceExpense = "";    
    invoiceCost = "";
    canBeSaved = false;
    canBePaid = false;
    canInvoiceChanged = true;
    canPayment = true;
    state = {
        dataSource: this.originalData,
        paidData: this.paidData,
        totalData: this.totalData,
        isLoaded: false,
        isPaid: false,
        isTotal: false,
        isChangeInvoice:false,
        isChangePayment:false,
        searchText: '',
    };
    
    allPermissions: any[] = [];
    vendorNameList: any[] = [];
    nameList: any[] = [];
    searchText = {
        vendorName: '',
        entityNumber: '',
        name: '',
        invoice: '',
        orderDateStart: '',
        orderDateEnd: '',
        verified: '',
    }
    changeInvioce = () => { 
        this.setState({isChangeInvoice: !this.state.isChangeInvoice});        
    };

    changePayment = () => { 
        this.setState({isChangePayment: !this.state.isChangePayment});        
    };

    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {                       
        this.selectedRows = selectedRows;     
        this.canInvoiceChanged = true;
        this.canPayment = true;
        for(let i=0; i<this.selectedRows.length; i++)  {
            if(this.selectedRows[i].verified){
                this.canInvoiceChanged = false;
            }
            if(this.selectedRows[i].notPaid<=0){
                this.canPayment = false;
            }
          }       
        this.setState({});
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };

    updatedKeys = "";

      makeInvoice = () => {  
        this.updatedKeys = ";";
        for(let i=0; i<this.selectedRows.length; i++)  {
          this.selectedRows[i].isUpdated = false;
          this.updatedKeys = this.updatedKeys + this.selectedRows[i].key + ";";
        }                       
          this.selectedRows.forEach(element => {   
             element.id=element.planId     
             element.invoice = this.invoice
             element.invoiceDate = this.invoiceDate
             element.invoiceExpense = this.invoiceExpense
             element.invoiceCost = this.invoiceCost         
             console.log('element==',element)    
             axiosInstance.post('/logistics/makeInvoice', element).then(
                  response => {     
                      element.isUpdated = true;     
                      let isAllUpdated = true;   
                      for(let i=0; i<this.selectedRows.length; i++)  {
                          if(!this.selectedRows[i].isUpdated){
                              isAllUpdated = false;
                          }
                        }     
                      if(isAllUpdated){
                          const newDataSource = this.state.dataSource?.map(item => {
                              if (this.updatedKeys.includes(";" + item.key+ ";")) {
                                  return { ...item, verified:true }; 
                              }
                              return item; 
                          });                    
                          this.setState({dataSource:newDataSource});
                      }
                  
                  },
                  error => {
                      displayErrorMessage(error, '操作失败');
                  }
              )
          })               
          message.success('操作成功');
          // this.init();        
          // this.setState({isLoaded: true});    
      }
     
      makePayment = () => {  
        this.updatedKeys = ";";
        for(let i=0; i<this.selectedRows.length; i++)  {
          this.selectedRows[i].isUpdated = false;
          this.updatedKeys = this.updatedKeys + this.selectedRows[i].key + ";";
        }                       
          this.selectedRows.forEach(element => {                                  
             element.id = 0
             element.createdBy = sessionStorage.getItem("loginName")?.replaceAll('"', '')
             element.paymentDate = this.paymentDate
             element.paymentAmount = element.totalPrice             
             axiosInstance.post('/logistics/insertShipmentCost', element).then(
                  response => {     
                      element.isUpdated = true;     
                      let isAllUpdated = true;   
                      for(let i=0; i<this.selectedRows.length; i++)  {
                          if(!this.selectedRows[i].isUpdated){
                              isAllUpdated = false;
                          }
                        }     
                      if(isAllUpdated){
                          const newDataSource = this.state.dataSource?.map(item => {
                              if (this.updatedKeys.includes(";" + item.key+ ";")) {
                                  return { ...item, paid:element.paymentAmount, notPaid:0 }; 
                              }
                              return item; 
                          });                    
                          this.setState({dataSource:newDataSource});
                      }
                  
                  },
                  error => {
                      displayErrorMessage(error, '操作失败');
                  }
              )
          })               
          message.success('操作成功');         
          // this.init();        
          // this.setState({isLoaded: true});    
      }

    getTotalDetail(record: any) {
        this.totalCost = 0;
        console.log('record==',record);
        let url = '/logistics/getAdditionalCostByPlanId'             
        if(record.type=='bracketOrder') {
            url = '/logistics/getBracketOrderProductDetail'                 
        }else if(record.type=='consumableOrder') {
            url = '/logistics/getConsumableOrderProductDetail'
        }
        axiosInstance.get(url, {params: {planId: record.planId, orderId: record.id}}).then(
            response => {
                this.totalData = response.data.filter((item: any) => (item.vendorName === record.vendorName && item.invoice === record.invoice));                        
                    for (let i = 0; i < this.totalData.length; i++) {
                        this.totalData[i].key = this.totalData[i].id;
                        if(record.type=='shipmentPlan') {
                            this.totalData[i].expenseType === 'baseCost'? this.totalData[i].expenseType ='基本支出': this.totalData[i].expenseType ='异常支出';    
                        }                     
                        this.totalData[i].subTotal = this.totalData[i].cost;
                        this.totalCost = this.totalCost + this.totalData[i].cost;
                    }
                          
                this.setState({totalData: this.totalData, isLoaded: true});                
            },
            error => {
                displayErrorMessage(error, '获取信息失败');
            }
        )
    }

    getPaidDetail(record: any) {         
        let url = '/logistics/queryShipmentCostByPlanIdAndVendorId'     
        let paramObj =  {planId: record.planId, vendorId: record.vendorId, orderId: record.id};
        if(record.type=='bracketOrder') {
            url = '/logistics/getBracketOrderPaymentByOrderId'                 
        }else if(record.type=='consumableOrder') {
            url = '/logistics/getConsumableOrderPaymentByOrderId'
        }
        axiosInstance.get(url, {params: paramObj}).then(
            response => {
                this.paidData = response.data;
                for (let i = 0; i < this.paidData.length; i++) {
                    this.paidData[i].key = this.paidData[i].id;
                }
                this.setState({paidData: this.paidData, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取付款信息失败');
            }
        )
    }

    onTotal = (record: any) => {                
        this.getTotalDetail(record)        
        this.setState({isTotal: true});
        this.setState({isPaid: false});
    }

    onPaid = (record: any) => {                
        this.getPaidDetail(record)        
        this.setState({isPaid: true});
        this.setState({isTotal: false});
    }

    onDeletePaid = (record: any) => {
        const ShipmentCost = {id: record.id, planId: record.planId};
        axiosInstance.post('/logistics/deleteShipmentCostById', ShipmentCost).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    
    changeOrderDateStart = (date: any, dateString: string) => {
        this.searchText.orderDateStart = dateString;
        this.search();
    }
    changeOrderDateEnd = (date: any, dateString: string) => {
        this.searchText.orderDateEnd = dateString;
        this.search();
    }

    onSearchVendorName = (value: any) => {        
        this.searchText.vendorName = value.target.value;
        this.search();
    }

    changeSelect = (field: string, event: any) => {
        (this.searchText as any)[field] = event
        this.search();
    }
    onSearchEntityShipmentNumber = (value: any) => {
        this.searchText.entityNumber = value.target.value;
        this.search();
    }

    changeSelectSearch = (field: string, event: any) => {
        (this.searchText as any)[field] = event        
        this.search();
    }

    invoiceonSearchInvoice = (value: any) => {
        this.searchText.invoice = value.target.value;
        this.search();
    }
    
    search = () => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => {   
                if (this.searchText.entityNumber != '' && !item.entityNumber?.toLowerCase().includes(this.searchText.entityNumber?.toLowerCase())) {
                    return false;
                }
                if (this.searchText.vendorName != '' && !item.vendorName?.toLowerCase().includes(this.searchText.vendorName?.toLowerCase())) {
                    return false;
                }               
                if (this.searchText.invoice != '' && !item.invoice?.toLowerCase().includes(this.searchText.invoice?.toLowerCase())) {
                    return false;
                }
                if (this.searchText.orderDateStart != '' && item.orderDate < this.searchText.orderDateStart) {
                    return false;
                }
                if (this.searchText.orderDateEnd != '' && item.orderDate > this.searchText.orderDateEnd) {
                    return false;
                }

                return true;
            }
            )
          })
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false});
        this.getAllOrderPayment();
    }
    getAllOrderPayment() {
        axiosInstance.get('/logistics/getAllOrderPayment').then(
            response => {
                this.originalData = response.data;     
                for (let i = 0; i < this.originalData.length; i++) {
                        this.originalData[i].key = i;
                    }
                
                this.setState({
                    dataSource: this.originalData.filter((item: any) => {   
                        if (item.name !='场地操作') {
                            return false;
                        }               
                        return true;
                    }
                    ), isLoaded: true})          
                // let vendorNameList: any[] = [];                
                // for (let i = 0; i < this.originalData.length; i++) {
                //     this.originalData[i].key = i;
                //     if (!vendorNameList.includes(this.originalData[i].vendorName)) {
                //         vendorNameList.push(this.originalData[i].vendorName);
                //     }                   
                // }
                // this.vendorNameList = [];
                // this.vendorNameList.push({label: '', value: ''});
                // for(let i = 0; i < vendorNameList.length; i++) {
                //     this.vendorNameList.push({label: vendorNameList[i], value: vendorNameList[i]});
                // }               
                // this.setState({dataSource: this.originalData, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取信息失败');
            }
        )

    }

    onDelete = (record: any) => {
        const cost = {id: record.id, planId: record.planId};
        axiosInstance.post('/logistics/deleteAdditionalCostById', cost).then(
            response => {
                this.getTotalDetail({id: record.planId})
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
        
    onExport = (record: any) => {
        this.setState({isLoaded: false});
        axiosInstance.get('/logistics/exportOrderPayment', {params: {vendorName: this.searchText.vendorName,
            name: this.searchText.name, orderDateStart: this.searchText.orderDateStart, orderDateEnd: this.searchText.orderDateEnd},responseType: 'blob'} ).then(
            response => {
                this.setState({isLoaded: true});
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const fileName = '应付帐款.xlsx';
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                message.success('导出成功!');
            },
            error => {
                displayErrorMessage(error, '导出失败!');
            }
        );

    }

    changeUserName = (event: any) => {
        this.invoice = event.target.value;
        this.checkCanBeSaved();
    }

    changeInvoiceExpense = (event: any) => {
        this.invoiceExpense = event.target.value;
        this.checkCanBeSaved();
    }
    changeInvoiceCost = (event: any) => {
        this.invoiceCost = event.target.value;
        this.checkCanBeSaved();
    }


    changeInvoiceDate = (date: any, dateString: string) => {
        this.invoiceDate = dateString;
        this.checkCanBeSaved();
    }    

    changePaymentDate = (date: any, dateString: string) => {
        this.paymentDate = dateString;
        this.checkCanBePaid();
    }  

    checkCanBePaid() {
        this.canBeSaved = false;
        if (this.paymentDate !== null && this.paymentDate !== '') {
            this.canBePaid = true;
        }
        this.setState({});
    }

    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.invoice !== null && this.invoice !== '' &&
            this.invoiceDate !== null && this.invoiceDate !== '') {
            this.canBeSaved = true;
        }
        this.setState({});
    }
    goBack =()=>{
        this.setState({isPaid: false});
        this.setState({isTotal: false});
    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                {this.state.isPaid == false && this.state.isTotal == false? 
                    <div>                
                    <div className="title">
                        <span>应付收票管理</span>  
                    </div>
                    <div className="seperate">
                    </div>
                        <div className="content">
                            <label>批次号</label>                        
                            <Input onChange={this.onSearchEntityShipmentNumber}  className="tableSearch" value={this.searchText.entityNumber} /> 
                            <label>供应商:</label>                        
                            <Input onChange={this.onSearchVendorName}  className="tableSearch" value={this.searchText.vendorName} /> 
                            <label>发票号:</label>                        
                            <Input onChange={this.invoiceonSearchInvoice}  className="tableSearch" value={this.searchText.invoice} />                             
                            <label>&nbsp;&nbsp;日期:</label>
                            <DatePicker style={{width: '150px'}} onChange={this.changeOrderDateStart} />
                            <label>&nbsp;-&nbsp;</label>
                            <DatePicker style={{width: '150px'}} onChange={this.changeOrderDateEnd} />            
                            <br/>                                            
                            <Button type="primary" className="header-left" onClick={this.changeInvioce} disabled={this.selectedRows.length==0 || this.canInvoiceChanged==false } >批量收票</Button>                                                          
                            <Button type="primary" className="header-left" onClick={this.changePayment} disabled={this.selectedRows.length==0 || this.canPayment==false} >批量付款</Button>                                                          
                            {this.state.isChangeInvoice? 
                            <div className="box">
                                <label className="box-label">发票号:</label>
                                <Input className="box-input" style={{width: '150px'}} onChange={this.changeUserName} />
                                <label className="box-label">开票日期:</label>
                                <DatePicker className="box-input" style={{width: '150px'}}onChange={this.changeInvoiceDate} />     
                                <label className="box-label">摘要:</label>
                                <Input className="box-input" style={{width: '150px'}} onChange={this.changeInvoiceExpense}/>
                                <label className="box-label">异常收支:</label> 
                                <Input className="box-input" style={{width: '150px'}} onChange={this.changeInvoiceCost}/>                                
                                <Button type="primary" className="header-left" onClick={this.makeInvoice}  disabled={!this.canBeSaved}>确定</Button>                            
                            </div>
                            :''
                            }

                            {this.state.isChangePayment? 
                            <div className="box">                              
                                <label className="box-label">付款日期:</label>
                                <DatePicker className="box-input" style={{width: '150px'}} onChange={this.changePaymentDate} /> 
                                <Button type="primary" className="header-left" onClick={this.makePayment}  disabled={!this.canBePaid}>确定</Button>                            
                            </div>
                            :''
                            }
                            
                            <Table rowSelection={{ type: 'checkbox', ...this.rowSelection, }} columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}
                            summary={() => {
                                let allPrice = 0;
                                let totalPaid = 0;
                                let totalNotPaid = 0;

                                if(this.selectedRows.length>0){
                                    this.selectedRows?.forEach(({ totalPrice,paid,notPaid }) => {
                                        allPrice += totalPrice;
                                        totalPaid += paid;
                                        totalNotPaid += notPaid;                                    
                                    });  
                                }else{
                                    this.state.dataSource?.forEach(({ totalPrice,paid,notPaid }) => {
                                        allPrice += totalPrice;
                                        totalPaid += paid;
                                        totalNotPaid += notPaid;                                    
                                    });    
                                }
                                
                                return (
                                <Table.Summary.Row>
                                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>汇总(所有或选中)</Table.Summary.Cell>
                                <Table.Summary.Cell index={2}></Table.Summary.Cell>                                                   
                                <Table.Summary.Cell index={3}></Table.Summary.Cell>                     
                                <Table.Summary.Cell index={3}></Table.Summary.Cell>     
                                <Table.Summary.Cell index={3}></Table.Summary.Cell>     
                                <Table.Summary.Cell index={4}></Table.Summary.Cell>       
                                <Table.Summary.Cell index={5}></Table.Summary.Cell>       
                                <Table.Summary.Cell index={6}></Table.Summary.Cell>       
                                <Table.Summary.Cell index={7}>{allPrice % 1 === 0 ? allPrice : allPrice.toFixed(2)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={8}>{totalPaid % 1 === 0 ? totalPaid : totalPaid.toFixed(2)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={9}>{totalNotPaid % 1 === 0 ? totalNotPaid : totalNotPaid.toFixed(2)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={10} />
                                </Table.Summary.Row>
                                );
                                }} />                            
                        </div>
                </div>
                :  this.state.isPaid == true? 
                    <div className="content">
                        <div className="title">
                            <span>付款记录</span>
                        </div>         
                        <Button type="primary" className="header-right" onClick={this.goBack}>返回</Button>   
                        <Table columns={this.columnsPaid} dataSource={this.state.paidData} bordered loading={!this.state.isLoaded}/>
                    </div>
                 :
                    <div className="content">     
                    <div className="title">
                    <span> 应付账款明细</span> 
                    </div>         
                    <Button type="primary" className="header-right" onClick={this.goBack}>返回</Button>                       
                    <Table  title={() => '合计金额: ' + this.totalCost} columns={this.columnsTotal} dataSource={this.state.totalData} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}

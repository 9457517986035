
import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import returnPlanBracketReducer from './slice/returnPlanBracketReducer';

const store: EnhancedStore = configureStore({
	reducer: {
		returnPlanBracket: returnPlanBracketReducer,
	},
});

export default store;

import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, InputNumber, InputNumberProps } from 'antd';
import { EditOutlined,PlusOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import TextArea from 'antd/es/input/TextArea';

const dateFormat = 'YYYY-MM-DD';

export default class ConsumableOrderConsumableInventory extends React.Component {
    props = {orderId: 0, orderQty: 0};
    constructor(props:any) {
        super(props);
    }

    columns = [
    {
        title: '操作',
        key: 'operation',
        width: 120,
        render: (_: any, record: any)=> {
            return (
                <div>
                 {this.state.allInInventory?'':
                    <Popconfirm
                    title="删除入库记录"
                    description="是否确定删除入库记录?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => this.onDelete(record)}
                    >
                    <DeleteOutlined title='删除' className="actionIcon"/>
                    </Popconfirm>
                 }  
                </div>
            )}
    },
    {
        title: '入库时间',
        dataIndex: 'inDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.inDate > b.inDate? 1 : -1,
    },
    {
        title: '耗材名',
        dataIndex: 'name',
        showSorterTooltip: false,
    },
    {
        title: '堆场',
        dataIndex: 'stationName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.stationName > b.stationName? 1 : -1,
    },
    {
        title: '数量',
        dataIndex: 'qty',
    },
    {
        title: '备注',
        dataIndex: 'comments',
    },
  
    ];

    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
        allInInventory: true,     
    };

    
    allPermissions: any[] = [];
    
    stationList: any[] = [];
    editRecord = this.createConsumableOrderConsumableInventory();
    canBeSaved = false;
    
    createConsumableOrderConsumableInventory() {
        return {
            orderId: this.props.orderId,
            stationId : 0,
            qty: 0,
            comments : '',
        };
    }
    onAdd = () => {        
        this.editRecord = this.createConsumableOrderConsumableInventory();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    save = () => {
        if(this.editRecord.qty > this.props.orderQty){
            alert("入库数量不能大于定单数量！");
            return;
        }
        axiosInstance.post('/logistics/insertConsumableOrderInInventory', this.editRecord).then(
            response => {
                this.init();
                message.success('入库成功');
            },
            error => {
                displayErrorMessage(error, '入库失败');
            }
        )
    }
    onDelete = (record: any) => {
        console.log('recordrecord',record);
        const consumable = {id: record.id};
        axiosInstance.post('/logistics/deleteConsumableOrderInInventoryById', consumable).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    finish = () => {
        axiosInstance.post('/logistics/finishConsumableOrderInInventory', {orderId: this.props.orderId}).then(
            response => {
                this.init();
                message.success('完成成功');
            },
            error => {
                displayErrorMessage(error, '完成失败');
            }
        )
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.stationId !== null && this.editRecord.stationId !== 0 &&
            this.editRecord.qty !== null && this.editRecord.qty !== 0
        ) {
            this.canBeSaved = true;
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getConsumableOrderById();
        this.getConsumableOrderInInventoryByOrderId();
        this.getAllStations();
    }
    getConsumableOrderById() {
        axiosInstance.get('/logistics/getConsumableOrderById', {params: {orderId: this.props.orderId}}).then(
            response => {
                this.setState({allInInventory: response.data.allInInventory});
            },
            error => {
                displayErrorMessage(error, '获取耗材订单失败');
            }
        )

    }
    getConsumableOrderInInventoryByOrderId() {
        axiosInstance.get('/logistics/getConsumableOrderInInventoryByOrderId', {params: {orderId: this.props.orderId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = i;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取入库信息失败');
            }
        )

    }
    getAllStations() {
        axiosInstance.get('/logistics/getAllStations').then(
            response => {
                this.stationList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.stationList.push({label:response.data[i].stationName, value: response.data[i].stationId});
                }
            },
            error => {
                displayErrorMessage(error, 'Get document list failed!');
            }
        )
    }

    onSearch = (value: any) => {
        this.setState({searchText: value.target.value})
        this.search(value.target.value);
    }
    search = (searchText: string) => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => (
                    item.inDate.toLowerCase().includes(searchText.toLowerCase()) )
            )
          })
    }
    onChangeQty: InputNumberProps['onChange'] = (value) => {              
        if(value!==null){
            this.editRecord.qty = parseInt(value+'');
            this.checkCanBeSaved();
        }
    };

    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="seperate">
                </div>       
                {this.state.isEdit? 
                    <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">堆场:</label> 
                                <Select className="box-input"  options={this.stationList} onChange={this.changeSelect.bind(this, 'stationId')}/>  
                                <label className="box-label">数量:</label> 
                                <InputNumber className="box-input"  defaultValue={this.editRecord.qty==0?'':this.editRecord.qty} onChange={this.onChangeQty} />
                            </div>
                        </div>  
                        <div className="row">
                            <div className="box">
                                <label className="box-label">备注:</label> 
                                <Input className="box-input" defaultValue={this.editRecord.comments} onChange={this.changeInput.bind(this, 'comments')} />
                            </div>
                        </div>                   
                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>入库</Button>
                        </div>
                    </div>
                :     
                <div className="content">       
                        {this.state.allInInventory?
                        ''
                        :
                        <Button type="primary" className="header-right" onClick={this.onAdd}>入库</Button>
                        }

                        {this.state.allInInventory?
                        ''
                        :
                        <Popconfirm
                            title="完成入库"
                            description="是否确定所有入库都已经完成?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.finish()}
                        >
                            <Button type="primary" className="header-right">完成入库</Button>  
                        </Popconfirm> 
                        }
                         
                        
                        <Table  columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                </div>
                }    
            </div>
        )      
    }
}

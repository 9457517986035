import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, Checkbox, Card  } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import type { CheckboxProps  } from 'antd';

const dateFormat = 'YYYY-MM-DD';

export default class AdditionalExpense extends React.Component {
    props = {planId: 0};
    constructor(props:any) {
        super(props);
      }
    
    columns = [
        // {
        //     title: '操作',
        //     key: 'operation',
        //     width: 100,
        //     render: (_: any, record: any)=> {
        //         return (
        //             <div>                       
        //                 <Popconfirm
        //                     title="删除该记录"
        //                     description="是否确定删除?"
        //                     okText="Yes"
        //                     cancelText="No"
        //                     onConfirm={() => this.onDelete(record)}
        //                 >
        //                     <DeleteOutlined title='删除' className="actionIcon"/>
        //                 </Popconfirm>
                        
        //             </div>
        //         )
        //     }
        // },
    {
        title: '费用类型',
        dataIndex: 'expenseType',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.expenseType > b.expenseType? 1 : -1,
    },
    {
        title: '费用项目',
        dataIndex: 'subExpense',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.subExpense > b.subExpense? 1 : -1,
    },
    {
        title: '备注',
        dataIndex: 'comments',
    },
    {
        title: '是否客户支付',
        dataIndex: 'payByCustomer',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.payByCustomer > b.payByCustomer? 1 : -1,
        render: (_: any, record: any)=> {
            return (
                <div>
                    {
                        record.payByCustomer? '是' : '否'
                    }
                </div>
            )
        }
    },
    {
        title: '应收款',
        dataIndex: 'amount',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.amount > b.amount? 1 : -1,
    },     
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        payByCustomer:'',
        isLoaded: false,
        isEdit: false,
        unitPriceVisible: false,
    };
    allPermissions: any[] = [];
    
    editRecord = this.createAdditionalExpense();
    originalEditRecords: any = {};
    canBeSaved = false;
    containerCount = 0;
    totalAmount = 0;
    yesNoList: any[] = [{label:'是', value: true},{label:'否', value: false}]; 
    yesOnlyList: any[] = [{label:'是', value: true}]; 
    payByCustomerList: any[] = []; 
    vendorList: any[] = [];

    expenseTypeList: any[] = [{label:'基本费用', value: 'baseExpense'},{label:'异常费用', value: 'additionalExpense'}]; 
    baseExpenseList: any[] = []; 
    additionalExpenseList: any[] = []; 
    subExpenseList: any[] = []; 

    createAdditionalExpense() {
        return { 
            id : 0,
            planId : this.props.planId,
            expenseType : '',
            subExpense : '',
            amount : '',           
            payByCustomer : '',
            comments : '',
            vendorName: '',
        };
    }
    
    getAllBaseExpenses() {
        axiosInstance.get('/logistics/getCommonPropertiesByKey',{params: {key: 'baseExpense'}}).then(
            response => {
                this.baseExpenseList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.baseExpenseList.push({label:response.data[i].propertyValue, value: response.data[i].propertyValue});               
                }
            },
            error => {
                displayErrorMessage(error, '获取数据失败');
            }
        )       
    }

    getAllAdditionalExpenses() {
        axiosInstance.get('/logistics/getCommonPropertiesByKey',{params: {key: 'additionalExpense'}}).then(
            response => {
                this.additionalExpenseList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.additionalExpenseList.push({label:response.data[i].propertyValue, value: response.data[i].propertyValue});               
                }
            },
            error => {
                displayErrorMessage(error, '获取数据失败');
            }
        )
    }
    
    
    copyAdditionalExpense(AdditionalExpense: any) {
        return { 
            id : AdditionalExpense.id,
            planId : this.props.planId,
            expenseType : AdditionalExpense.expenseType,
            subExpense : AdditionalExpense.subExpense,
            amount : AdditionalExpense.amount,            
            payByCustomer : AdditionalExpense.payByCustomer,
            comments : AdditionalExpense.comments,
            vendorName : AdditionalExpense.vendorName,
        };
    }

    getContainerCount() {
        axiosInstance.get('/logistics/getShipmentPlanById', {params: {planId: this.props.planId}}).then(
            response => {                
                this.containerCount = response.data.containerCount;
                console.log('this.containerCount',this.containerCount);
            },
            error => {
                displayErrorMessage(error, '获取装箱计划图片失败');
            }
        )

    }

    onDelete = (record: any) => {
        const AdditionalExpense = {id: record.id, planId: this.props.planId};
        axiosInstance.post('/logistics/deleteAdditionalExpenseById', AdditionalExpense).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }

    onExport = () => {
        this.setState({isLoaded: false});
        axiosInstance.get('/logistics/exportExpense', {params: {planId: this.props.planId},responseType: 'blob'}).then(               
            response => {
                this.setState({isLoaded: true});
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const fileName = '费用明细.xlsx';
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                message.success('导出成功!');
            },
            error => {
                displayErrorMessage(error, '导出失败!');
            }
        );

    }
    onAdd = () => {
        this.editRecord = this.createAdditionalExpense();
        this.originalEditRecords = this.createAdditionalExpense();
        this.canBeSaved = false;
        this.setState({isEdit: true, payByCustomer: ''});
    }
    onEdit = (record: any) => {
        this.originalEditRecords = this.copyAdditionalExpense(record);
        this.editRecord = this.copyAdditionalExpense(record);
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    save = () => {
        const AdditionalExpense= this.copyAdditionalExpense(this.editRecord);
        if (AdditionalExpense.id === 0) {
            console.log('AdditionalExpense', AdditionalExpense);
            axiosInstance.post('/logistics/insertAdditionalExpense', AdditionalExpense).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateAdditionalExpense', AdditionalExpense).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    onChangeCheckbox = (event: any) => {
        console.log(`checked = ${event.target.checked}`);
    };
      
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event
        if(field === 'expenseType'){            
            if(event === 'additionalExpense'){
                this.subExpenseList = this.additionalExpenseList
                this.payByCustomerList = this.yesNoList
                this.setState({payByCustomer: ''});
                this.editRecord.payByCustomer = event
                this.setState({unitPriceVisible: false});
            }else{
                this.subExpenseList = this.baseExpenseList                                         
                this.setState({payByCustomer: '是'});
                this.editRecord.payByCustomer = 'true'
                this.setState({unitPriceVisible: true});                
            }       
        }
        this.checkCanBeSaved();
    }

    changeSelectPayByCustomer = (field: string, event: any) => {
        this.editRecord.payByCustomer = event
        this.setState({payByCustomer: this.editRecord.payByCustomer });       
        this.checkCanBeSaved();
    }

    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }   
    changeUnitPrice = (field: string, event: any) => {
        this.editRecord.amount = event.target.value * this.containerCount +'';        
        this.checkCanBeSaved(); 
    }  
 
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.expenseType !== null && this.editRecord.expenseType !== '' &&
            this.editRecord.subExpense !== null && this.editRecord.subExpense !== '' &&
            this.editRecord.amount !== null && this.editRecord.amount !== '' //&&
            // this.editRecord.payByCustomer !== null && this.editRecord.payByCustomer !== '' 
            ) {
            if (this.editRecord.expenseType !== this.originalEditRecords.expenseType ||
                this.editRecord.subExpense !== this.originalEditRecords.subExpense ||
                this.editRecord.amount !== this.originalEditRecords.amount ||
                this.editRecord.payByCustomer !== this.originalEditRecords.payByCustomer ||
                this.editRecord.comments !== this.originalEditRecords.payByCustomer )
                 {
                    this.canBeSaved = true;
            }
        }  
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';        
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false, payByCustomer: ''});
        this.getContainerCount();
        this.getAdditionalExpenseByPlanId();
        this.getAllBaseExpenses();
        this.getAllAdditionalExpenses();        
    }
    getAdditionalExpenseByPlanId() {
        this.totalAmount = 0;
        axiosInstance.get('/logistics/getAdditionalExpenseByPlanId', {params: {planId: this.props.planId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].id;
                    this.originalData[i].expenseType === 'baseExpense'? this.originalData[i].expenseType ='基本费用': this.originalData[i].expenseType ='异常费用';
                    this.originalData[i].containerCount = this.containerCount;
                    this.originalData[i].subTotal = this.originalData[i].amount;
                    this.totalAmount = this.totalAmount + this.originalData[i].amount;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});                
            },
            error => {
                displayErrorMessage(error, '获取信息失败');
            }
        )

    }
    onChange111 = (field: string, event: any) => {                
        (this.editRecord as any)[field] = event?.join(',');
        this.checkCanBeSaved();
    };
    
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="seperate">
                </div>
                {this.state.isEdit? 
                    <div className="content">  
                        <div className="row">
                            <div className="box">
                                <label className="box-label">费用类型:</label>                                
                                <Select className="box-input"  options={this.expenseTypeList} onChange={this.changeSelect.bind(this, 'expenseType')} defaultValue={this.editRecord.expenseType} />                                                            
                                <label className="box-label">费用项目:</label>
                                <Checkbox.Group  options={this.subExpenseList} onChange={this.onChange111.bind(this, 'subExpense')} defaultValue={[this.editRecord.subExpense]} />                                                            
                            </div>
                        </div>
                          
                        <div className="row">
                            <div className="box">
                                <label className="box-label">应收款:</label>
                                <Input className="box-input" value={this.editRecord.amount} onChange={this.changeInput.bind(this, 'amount')} />
                                <label className="box-label">单价(应收):</label>
                                <Input className="box-input" onChange={this.changeUnitPrice.bind(this, 'unitPrice')}  disabled={!this.state.unitPriceVisible}/>  
                                箱数: <p>  {this.containerCount}  </p>                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                            <label className="box-label">是否客户支付:</label>
                                <Select className="box-input"  options={this.payByCustomerList} onChange={this.changeSelectPayByCustomer.bind(this, 'payByCustomer')} value={this.state.payByCustomer} />                                                            
                                <label className="box-label">备注:</label>
                                <Input className="box-input" defaultValue={this.editRecord.comments} onChange={this.changeInput.bind(this, 'comments')} /> 
                            </div>
                        </div>
                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                        </div>
                    </div>
                :
                    <div className="content">
                        <Button type="primary" className="header-right" onClick={this.onExport}>导出Excel</Button>
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>                        
                        <Table  title={() => '合计金额: ' + this.totalAmount} columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}

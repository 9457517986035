import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DD';

export default class BracketInventory extends React.Component {
    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>
                    </div>
                )}
        },
    {
        title: '支架号',
        dataIndex: 'bracketNumber',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.bracketNumber > b.bracketNumber? 1 : -1,
    },
    {
        title: '支架类型',
        dataIndex: 'bracketType',
        showSorterTooltip: false,
    },
    {
        title: '入库时间',
        dataIndex: 'entryDate',
        showSorterTooltip: false,
    },
    {
        title: '堆场',
        dataIndex: 'stationName',
        showSorterTooltip: false,
    },
    {
        title: '供应商',
        dataIndex: 'vendorName',
        showSorterTooltip: false,
    },
    {
        title: '单位成本',
        dataIndex: 'unitCost',
        showSorterTooltip: false,
    },
    {
        title: '是否可用',
        dataIndex: 'status',
        showSorterTooltip: false,        
        sorter: (a: any, b: any) => a.status > b.status? 1 : -1,
        render: (_: any, record: any)=> {
            return (
                <div>
                        {
                            record.status === 'Available' ? '可用' :'不可用'
                        }
                        
                    </div>
            )}
    },   
    ];
    
    historyColumns = [
        {
            title: '状态',
            showSorterTooltip: false,
            dataIndex: 'recyclable',      
            render: (_: any, record: any)=> {
                return (
                    <div>
                            {
                                record.isRecyclable == 1 ? '无支架' : (record.isRecyclable == 2 ? '循环' : '一次性')
                            }
                            
                        </div>
                )}      
        },
        {
            title: '班列号',
            dataIndex: 'trainNumber',
            showSorterTooltip: false,
        },
        {
            title: '批次号',
            dataIndex: 'shipmentNumber',
            showSorterTooltip: false,
        },
        {
            title: '车型',
            dataIndex: 'carTypes',
            showSorterTooltip: false,
        },
        {
            title: '回程时间',
            dataIndex: 'finishDate',
            showSorterTooltip: false,
        },
    ];
    originalData: any[] = [];
    historyOriginalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        isChangeStation:false,
        searchText: '',
        historyDataSource: this.historyOriginalData,
        isHistoryLoaded: false,
    };

    searchText = {                
        bracketNumber:'',     
        stationName:'',  
        vendorName:'',    
        status:'',      
        entryDateStart:'',
        entryDateEnd:'', 
    }

    allPermissions: any[] = [];
    
    editRecord = this.createBracketInventory();
    originalEditRecords: any = {};
    sectionList: any[] = [];
    vendorList: any[] = [];
    canBeSaved = false;
    recyclableList: any[] = [{label:'循环', value: true},{label:'一次性', value: false}]; 
    statusList: any[] = [{label:'可用', value: 'Available'},{label:'不可用', value: 'UnAvailable'}]; 
    createBracketInventory() {
        return { 
            bracketId : 0,
            stationId : 0,
            vendorId : 0,
            bracketNumber : "",
            bracketType : "",
            entryDate : "",
            dayJsEntryDate : dayjs(dayjs(new Date()), dateFormat),
            unitCost : 0,
            stationName : "",
            vendorName : "",
            status : "",
        };
    }
    
    copyBracketInventory(bracketInventory: any) {
        return { 
            bracketId : bracketInventory.bracketId,
            stationId : bracketInventory.stationId,
            vendorId : bracketInventory.vendorId,
            bracketNumber : bracketInventory.bracketNumber,
            bracketType : bracketInventory.bracketType,
            entryDate : bracketInventory.entryDate,
            dayJsEntryDate :  bracketInventory.entryDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(bracketInventory.entryDate, dateFormat),
            unitCost : bracketInventory.unitCost,
            stationName : bracketInventory.stationName,
            vendorName : bracketInventory.vendorName,
            status : bracketInventory.status,
        };
    }

    onSearchBracketNumber = (value: any) => {
        this.searchText.bracketNumber = value.target.value;
        this.search();
    }

    onSearchStationName = (value: any) => {
        this.searchText.stationName = value.target.value;
        this.search();
    }

    onSearchVendorName = (value: any) => {
        this.searchText.vendorName = value.target.value;
        this.search();
    }
    changeSelectSearch = (field: string, event: any) => {
        (this.searchText as any)[field] = event
        this.search();
    }  

    changeEntryDateStart = (date: any, dateString: string) => {
        this.searchText.entryDateStart = dateString;
        this.search();
    }
    changeEntryDateEnd = (date: any, dateString: string) => {
        this.searchText.entryDateEnd = dateString;
        this.search();
    } 

    search = () => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => {
                if (this.searchText.bracketNumber != '' && !item.bracketNumber?.toLowerCase().includes(this.searchText.bracketNumber?.toLowerCase())) {
                    return false;
                }      
                if (this.searchText.status != ''  && item.status !== this.searchText.status) {
                    return false;
                }
                if (this.searchText.stationName != ''  && !item.stationName?.toLowerCase().includes(this.searchText.stationName?.toLowerCase())) {
                    return false;
                }               
                if (this.searchText.vendorName != ''  && !item.vendorName?.toLowerCase().includes(this.searchText.vendorName?.toLowerCase())) {
                    return false;
                } 
                if (this.searchText.entryDateStart != '' && item.entryDate < this.searchText.entryDateStart) {
                    return false;
                }
                if (this.searchText.entryDateEnd != '' && item.entryDate > this.searchText.entryDateEnd) {
                    return false;
                }
                return true;
            }
            )
          })
    }

    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {        
        this.selectedRows = selectedRows;
        this.checkCanBeSaved();
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };

    deleteSelected = () => {        
        this.selectedRows.forEach(element => {
            const bracket = {bracketId: element.bracketId};
            axiosInstance.post('/logistics/deleteBracketInventory', bracket).then(   
                response => {
                    this.init();                   
                },           
                error => {
                    displayErrorMessage(error, '删除失败');
                }
            )
        })               
        message.success('删除成功');
    }

    changeStation = () => { 
        this.setState({isChangeStation: !this.state.isChangeStation});        
    };
    
    updateStation = () => {        
        this.selectedRows.forEach(element => {
            console.log("element==",element);
            const bracketInventory = {bracketId: element.bracketId,vendorId:element.vendorId, bracketNumber: element.bracketNumber,bracketType:element.bracketType,
                entryDate:element.entryDate,unitCost:element.unitCost,status:element.status, stationId: this.editRecord.stationId};
            axiosInstance.post('/logistics/updateBracketInventory', bracketInventory).then(
                response => {
                    this.init();                   
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )
        })          
        message.success('修改成功');             
    }

    onEdit = (record: any) => {
        this.originalEditRecords = this.copyBracketInventory(record);
        this.editRecord = this.copyBracketInventory(record);
        this.canBeSaved = false;
        this.setState({isEdit: true});
        this.getBracketInventoryHistoryById();
    }

    onAdd = () => {
        this.editRecord = this.createBracketInventory();
        this.originalEditRecords = this.createBracketInventory();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    save = () => {
        const bracketInventory = this.copyBracketInventory(this.editRecord);
        if (bracketInventory.bracketId === 0) {
            axiosInstance.post('/logistics/insertBracketInventory', bracketInventory).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateBracketInventory', bracketInventory).then(
                response => {
                    this.init();
                    message.success('修改成功');
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )

        }
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }
    changeStationId = (event: any) => {
        this.editRecord.stationId = event;
        this.checkCanBeSaved();
    }
    changeVendorId = (event: any) => {
        this.editRecord.vendorId = event;
        this.checkCanBeSaved();
    }
    changeEntryDate = (date: any, dateString: string) => {
        this.editRecord.entryDate = dateString;
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.bracketNumber !== null && this.editRecord.bracketType !== '') {
            if (this.editRecord.stationId !== this.originalEditRecords.stationId ||
                this.editRecord.vendorId !== this.originalEditRecords.vendorId ||
                this.editRecord.bracketNumber !== this.originalEditRecords.bracketNumber ||
                this.editRecord.bracketType !== this.originalEditRecords.bracketType ||
                this.editRecord.entryDate !== this.originalEditRecords.entryDate ||
                this.editRecord.unitCost !== this.originalEditRecords.unitCost) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllBracketInventorys();
        this.getAllStations();
        this.getAllVendors();
    }
    getAllBracketInventorys() {
        axiosInstance.get('/logistics/getAllBracketInventorys').then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].bracketId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取支架失败');
            }
        )

    }
    getAllStations() {
        axiosInstance.get('/logistics/getAllStations').then(
            response => {
                this.sectionList = [];
                for(let i = 0; i < response.data.length; i++) {                    
                        this.sectionList.push({label:response.data[i].stationName, value: response.data[i].stationId});                    
                }
            },
            error => {
                displayErrorMessage(error, 'Get document list failed!');
            }
        )
    }
    getAllVendors() {
        axiosInstance.get('/logistics/getAllVendors').then(
            response => {
                this.vendorList = [];    
                for(let i = 0; i < response.data.length; i++) {
                    this.vendorList.push({label:response.data[i].vendorName, value: response.data[i].vendorId});                                        
                }
            },
            error => {
                displayErrorMessage(error, 'Get document list failed!');
            }
        )
    }
    getBracketInventoryHistoryById() {
        this.setState({historyDataSource: this.historyOriginalData, isHistoryLoaded: false});

        axiosInstance.get('/logistics/getBracketInventoryHistoryById', {params: {bracketId: this.editRecord.bracketId}}).then(
            response => {
                this.historyOriginalData = response.data;               
                for (let i = 0; i < this.historyOriginalData.length; i++) {
                    this.historyOriginalData[i].key = this.historyOriginalData[i].bracketId;
                }
                this.setState({historyDataSource: this.historyOriginalData, isHistoryLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取历史信息失败');
                this.setState({isHistoryLoaded: true});
            }
        )

    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 支架库存管理</span>
                </div>
                <div className="seperate">
                </div>
                
                
                {this.state.isEdit? 
                    <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">支架编号:</label>
                                <Input className="box-input" defaultValue={this.editRecord.bracketNumber} onChange={this.changeInput.bind(this, 'bracketNumber')}/>  
                                <label className="box-label">支架型号:</label>
                                <Input className="box-input" defaultValue={this.editRecord.bracketType} onChange={this.changeInput.bind(this, 'bracketType')}/>                             
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <label className="box-label">堆场:</label>
                                <Select className="box-input"  options={this.sectionList} onChange={this.changeStationId} defaultValue={this.editRecord.stationName}/> 
                                <label className="box-label">供应商:</label>
                                <Select className="box-input"  options={this.vendorList} onChange={this.changeVendorId} defaultValue={this.editRecord.vendorName}/>   
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <label className="box-label">入库时间:</label>
                                {(this.editRecord.entryDate + "") == ""?
                                    <DatePicker className="box-input" onChange={this.changeEntryDate}/>   
                                    :
                                    <DatePicker className="box-input" onChange={this.changeEntryDate} defaultValue={this.editRecord.dayJsEntryDate}/>   
                                }  
                                <label className="box-label">单位成本:</label>
                                <Input className="box-input" onChange={this.changeInput.bind(this, 'unitCost')} defaultValue={this.editRecord.unitCost}/>                                    
                            </div>
                        </div>        
                        <div className="row">
                            <div className="box">  
                                <label className="box-label">是否可用:</label>
                                <label >{this.editRecord.status === 'UnAvailable' ? '不可用' :'可用'}</label>                                  
                            </div>
                        </div>

                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                        </div>
                        <br/>
                        <br/>
                        {
                            this.editRecord.bracketId == 0? '' :
                            <div>
                                <div><span> 使用历史记录：</span></div>
                                <div style={{width: '100%'}}>
                                    <Table columns={this.historyColumns} dataSource={this.state.historyDataSource} bordered loading={!this.state.isHistoryLoaded}/>
                                </div>
                            </div>
                        }
                    </div>
                :
                    <div className="content">
                        <label className="box-label">支架号:</label>                        
                        <Input onChange={this.onSearchBracketNumber}  className="tableSearch" value={this.searchText.bracketNumber} /> 
                        <label className="box-label">堆场:</label>                        
                        <Input onChange={this.onSearchStationName}  className="tableSearch" value={this.searchText.stationName} /> 
                        <label className="box-label">供应商:</label>
                        <Input onChange={this.onSearchVendorName}  className="tableSearch" value={this.searchText.vendorName} />          
                        <label>是否可用:</label>    
                        <Select style={{ width: '172px' }} options={[{label:'--全部--', value: ''},...this.statusList]} onChange={this.changeSelectSearch.bind(this, 'status')}/> 
                        <label>&nbsp;入库时间:</label>
                        <DatePicker style={{width: '150px'}} onChange={this.changeEntryDateStart} />
                        <label>&nbsp;-&nbsp;</label>
                        <DatePicker style={{width: '150px'}} onChange={this.changeEntryDateEnd} />

                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>   
                        <div className="row">
                            <Button type="primary" className="header-left" onClick={this.deleteSelected} disabled={this.selectedRows.length==0}>批量删除</Button>  
                            <Button type="primary" className="header-left" onClick={this.changeStation} disabled={this.selectedRows.length==0}>批量转场</Button>  
                            {this.state.isChangeStation? 
                            <div className="box">
                                <label className="box-label">堆场:</label>
                                <Select className="box-input"  options={this.sectionList} onChange={this.changeStationId} defaultValue={this.editRecord.stationName}/> 
                                <Button type="primary" className="header-left" onClick={this.updateStation}>确定</Button>  
                            </div>
                            :''
                            }
                        </div> 
                        <Table  columns={this.columns}  rowSelection={{
                            type: 'checkbox',
                            ...this.rowSelection,
                            }} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded
                            }  title={() => '支架数量: ' + this.state.dataSource?.length} />
                    </div>
                }
            </div>
        )      
    }
}

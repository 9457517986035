import React from "react";
import Password from "./views/Password";
import Permission from "./views/Permission";
import User from "./views/User";
import ManageHome from "./views/Index"
import BracketInventory from "./views/BracketInventory";
import Station from "./views/Station";
import Customer from "./views/Customer";
import Vendor from "./views/Vendor";
import ConsumablesInventory from "./views/ConsumablesInventory";
import LoadingTeam from "./views/LoadingTeam";
import ShipmentPlan from "./views/ShipmentPlan";
import ConsumablesInventoryOneTimeBracket from "./views/ConsumablesInventoryOneTimeBracket";
import ReceiveCarPicture from "./views/ReceiveCarPicture";
import Picture from "./views/Picture";
import ReturnPlan from "./views/ReturnPlan";
import BatchUpload from "./views/BatchUpload";
import BracketOrder from "./views/BracketOrder";
import ConsumableOrder from "./views/ConsumableOrder";
import ShipmentPlanPayment from "./views/ShipmentPlanPayment";
import ProfitManagement from "./views/ProfitManagement";
import OrderPayment from "./views/OrderPayment";
import InvoiceManage from "./views/InvoiceManage";
import ReceiveShipmentInvoice from "./views/ReceiveShipmentInvoice";
import SendShipmentInvoice from "./views/SendShipmentInvoice";
import ExportRecycleInvoice from "./views/ExportRecycleInvoice";
import ReturnRecycleInvoice from "./views/ReturnRecycleInvoice";
import ExportPlan from "./views/ExportPlan";
import CommonProperties from "./views/CommonProperties";
import { TransactionOutlined,ProjectOutlined,SettingOutlined,UsergroupDeleteOutlined,HomeOutlined,FileJpgOutlined,ShopOutlined,SolutionOutlined } from '@ant-design/icons';
import ProductCode from "./views/ProductCode";
import CarSearch from "./views/CarSearch";

function getManageMenus() {

    const permissions = sessionStorage.getItem("permissions") || '';
  console.log('permissions',permissions)
  const manageRoutes = [
    {
      path: '/',
      key: '/',
      label: '首页',
      icon: <HomeOutlined />,
      element: <ManageHome />,
    },
    {
      path: '/myAction',
      key: '/myAction',
      label: '我的',
      icon:<UsergroupDeleteOutlined />,
      children: [
        {
          path: 'password',
          key: 'password',
          label: '密码修改',
          element: <Password />,
        },
      ],
    },
    {
      path: '/businessManagement',
      key: '/businessManagement',
      label: '业务处理',
      icon:<ProjectOutlined />,
      hidden:  !permissions.includes('admin'), 
      children: [
        {
          path: 'shipmentPlan',
          key: 'shipmentPlan',
          label: '装箱计划',         
          hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
          && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'), 
          element: <ShipmentPlan />,
        },   
        {
          path: 'settlement',
          key: 'settlement',
          hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
          && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'),
          label: '结算管理',          
          children: [        
            {
              path: 'receiveInvoice',
              key: 'receiveInvoice',
              label: '应付收票管理',
              hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
              && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'),
              element: <ReceiveShipmentInvoice />,
            },        
            {
              path: 'sendInvoice',
              key: 'sendInvoice',
              label: '应收开票管理',
              hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
              && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'),
              element: <SendShipmentInvoice />,
            },    
          ],          
        }, 
        {
          path: 'recycle',
          key: 'recycle',
          hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
          && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'),
          label: '支架循环',          
          children: [        
            {
              path: 'exportPlan',
              key: 'exportPlan',
              label: '出口计划',
              hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
              && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'),
              element: <ExportPlan />,
            },   
            {
              path: 'returnPlan',
              key: 'returnPlan',
              label: '回程计划',
              hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
              && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'),
              element: <ReturnPlan />,
            },         
            {
              path: 'js',
              key: 'js',
              hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
              && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'),
              label: '结算管理',          
              children: [        
                {
                  path: 'exportPlan2',
                  key: 'exportPlan2',
                  label: '出口收票',
                  hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
                  && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'),
                  element: <ExportRecycleInvoice />,
                },   
                {
                  path: 'returnPlan2',
                  key: 'returnPlan2',
                  label: '回程收票',
                  hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
                  && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'),
                  element: <ReturnRecycleInvoice />,
                },         
              ],
            },    
          ],
        },   
        {
          path: 'carSearch',
          key: 'carSearch',
          label: '车架搜索',         
          hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
          && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'), 
          element: <CarSearch />,
        },         
      ],
    },
    {
      path: '/propertyManagement',
      key: '/propertyManagement',
      label: '资产模块',
      icon:<ShopOutlined />,
      hidden: !permissions.includes('admin'),
      children: [               
        {
          path: 'bracket',
          key: 'bracket',
          label: '采购管理',
          hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
          && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'),
          children: [     
            {
              path: 'bracketOrderNew',
              key: 'bracketOrderNew',
              label: '支架采购',
              hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
              && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'), 
              element: <BracketOrder/>,
            },  
            {
              path: 'consumableOrder',
              key: 'consumableOrder',
              label: '耗材采购',
              hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
              && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'), 
              element: <ConsumableOrder />,
            },             
          ],
        },
        {
          path: 'inventory',
          key: 'inventory',
          label: '库存管理',
          hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
          && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'), 
          children: [     
            {
              path: 'bracketManage',
              key: 'bracketManage',
              label: '支架管理',
              hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
              && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'), 
              children: [     
                {
                  path: 'recyclableBracketInventory',
                  key: 'recyclableBracketInventory',
                  label: '循环支架',
                  hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
                  && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'), 
                  element: <BracketInventory />,
                },
                {
                  path: 'onetimeBracketInventory',
                  key: 'onetimeBracketInventory',
                  label: '一次性支架',
                  hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
                  && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'), 
                  element: <ConsumablesInventoryOneTimeBracket />,
                },            
              ],
            },
            {
              path: 'consumablesInventory',
              key: 'consumablesInventory',
              label: '一次性耗材',
              hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
              && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'), 
              element: <ConsumablesInventory />,
            },            
          ],
        },
      ],
    },  
    {
      path: '/pictureManagement',
      key: '/pictureManagement',
      label: '照片管理',      
      icon:<FileJpgOutlined />,
      hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('客户') && !permissions.includes('主机厂'), 
      children: [        
        {
          path: 'ReceiveCarPicture',
          key: 'ReceiveCarPicture',
          label: '收车照审核',
          hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('客户') && !permissions.includes('主机厂'),
          element: <ReceiveCarPicture />,
        },
        {
          path: 'picture',
          key: 'picture',
          label: permissions.includes('客户')  || permissions.includes('主机厂')?'查看装箱照':'装箱照审核',
          hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('客户') && !permissions.includes('主机厂'), 
          element: <Picture />,
        },
        // {
        //   path: 'uploadPicture',
        //   key: 'uploadPicture',
        //   label: '照片补传',
        //   hidden:  !permissions.includes('admin') && !permissions.includes('运营部'),
        //   element: <UploadPicture />,
        // },
        {
          path: 'batchUpload',
          key: 'batchUpload',
          label: '批量上传',        
          hidden:  !permissions.includes('admin') && !permissions.includes('运营部'),
          element: <BatchUpload />,
        },
      ],
    },

    {
      path: '/financeManagement',
      key: '/financeManagement',
      label: '财务模块',
      icon: <TransactionOutlined />,
      hidden: !permissions.includes('admin'),
      children: [        
        {
          path: 'revenue',
          key: 'revenue',
          label: '应收款管理',
          hidden:  !permissions.includes('admin') && !permissions.includes('运营部') 
          && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'), 
          element: <ShipmentPlanPayment />,
        },
        {
          path: 'payment',
          key: 'payment',
          label: '应付款管理',
          hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
          && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'), 
          element: <OrderPayment />,
        }, 
        {
          path: 'paymentInvoce',
          key: 'paymentInvoce',
          label: '发票管理',
          hidden:  !permissions.includes('admin') && !permissions.includes('运营部') && !permissions.includes('采购部') 
          && !permissions.includes('财务') && !permissions.includes('财务总监') && !permissions.includes('客服部') && !permissions.includes('商务部'), 
          element: <InvoiceManage />,
        }, 
        {
          path: 'ProfitManagement',
          key: 'ProfitManagement',
          label: '管理费用',
          hidden: !permissions.includes('财务总监') ,
          element: <ProfitManagement />,
        },       
        {
          path: 'customer',
          key: 'customer',
          label: '客户管理',
          hidden: !permissions.includes('admin') && !permissions.includes('财务总监'),
          element: <Customer />,
        },
        {
          path: 'vendor',
          key: 'vendor',
          label: '供应商管理',
          hidden: !permissions.includes('admin') && !permissions.includes('财务总监'),
          element: <Vendor />,
        },      
      ],
    },   
    {
      path: '/configuration',
      key: '/configuration',
      label: '配置模块',
      icon: <SettingOutlined />,
      hidden: !permissions.includes('admin'),
      children: [        
        {
          path: 'user',
          key: 'user',
          label: '用户管理',
          hidden: !permissions.includes('admin') && !permissions.includes('财务总监'),
          element: <User />,
        },
        {
          path: 'userRole',
          key: 'userRole',
          label: '角色管理',
          hidden: !permissions.includes('admin') && !permissions.includes('财务总监'),
          element: <Permission />,
        },
        {
          path: 'station',
          key: 'station',
          label: '堆场管理',
          hidden: !permissions.includes('admin') && !permissions.includes('财务总监'),
          element: <Station />,
        },
        {
          path: 'loadingTeam',
          key: 'loadingTeam',
          label: '装卸团队管理',
          hidden: !permissions.includes('admin') && !permissions.includes('财务总监'),
          element: <LoadingTeam />,
        },
        {
          path: 'configuration',
          key: 'configuration',
          label: '配置管理',
          hidden: !permissions.includes('admin') && !permissions.includes('财务总监'),
          element: <CommonProperties />,
        },
        {
          path: 'productCode',
          key: 'productCode',
          label: '商品编码管理',
          hidden: !permissions.includes('admin') && !permissions.includes('财务总监'),
          element: <ProductCode />,
        },
      ],
    },      
   
  ]

  return manageRoutes;

}

export default getManageMenus
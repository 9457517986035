import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';

export default class CommonProperties extends React.Component {
    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp; &nbsp;
                        <Popconfirm
                            title="删除车站"
                            description="是否确定删除?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除' className="actionIcon"/>
                        </Popconfirm>
                        
                    </div>
                )}
        },
    {
        title: '配置项',
        dataIndex: 'propertyKey',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.propertyKey > b.propertyKey? 1 : -1,
        render: (_: any, record: any)=> {  
            return (
                <div>
                    {record.showName}                    
                </div>
            )}
    },
    {
        title: '配置值',
        dataIndex: 'propertyValue',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.propertyValue > b.propertyValue? 1 : -1,
    },
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
    };
    allPermissions: any[] = [];
    
    editRecord = this.createCommonProperties();
    originalEditRecords: any = {};
    canBeSaved = false;
    propertyKeyList = [
        {
            label: '费用类型',
            value: 'bracketCostType',
        },
        {
            label: '耗材类型->名称',
            value: 'consumableType',
        },
        {
            label: '耗材订单->型号',
            value: 'consumableSubType',
        },
        {
            label: '装箱类型',
            value: 'containerLoadingType',
        },
        {
            label: '始发站(город отправдения)',
            value: 'deparStation',
        },
        {
            label: '目的站(станция назначения)',
            value: 'destinationStation',
        },
        {
            label: '汽车品牌(Брэнд)',
            value: 'carBrand',
        },
        {
            label: '出境口岸(Погранпереход)',
            value: 'exitPort',
        },
        {
            label: '装箱计划->基本费用',
            value: 'baseExpense',
        },
        {
            label: '装箱计划->异常费用',
            value: 'additionalExpense',
        },
        {
            label: '装箱计划->基本成本',
            value: 'baseCost',
        },
        {
            label: '装箱计划->异常成本',
            value: 'additionalCost',
        },
        {
            label: '采购订单->发往地',
            value: 'orderCity',
        },
    ];
    
    createCommonProperties() {
        return { 
            propertyId : 0,
            propertyKey : "",
            propertyValue : "",
        };
    }
    
    copyCommonProperties(commonProperties: any) {
        return { 
            propertyId : commonProperties.propertyId,
            propertyKey : commonProperties.propertyKey,
            propertyValue : commonProperties.propertyValue,
        };
    }

    onSearch = (value: any) => {
        this.setState({searchText: value.target.value})
        this.search(value.target.value);
    }
    search = (searchText: string) => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => ( 
                    item.showName.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.propertyValue.toLowerCase().includes(searchText.toLowerCase()))
            )
          })
    }
    onEdit = (record: any) => {
        this.originalEditRecords = this.copyCommonProperties(record);
        this.editRecord = this.copyCommonProperties(record);
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    onDelete = (record: any) => {
        const CommonProperties = {propertyId: record.propertyId};
        axiosInstance.post('/logistics/deleteCommonProperties', CommonProperties).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createCommonProperties();
        this.originalEditRecords = this.createCommonProperties();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    save = () => {
        const commonProperties = this.copyCommonProperties(this.editRecord);
        if (commonProperties.propertyId === 0) {
            axiosInstance.post('/logistics/insertCommonProperties', commonProperties).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateCommonProperties', commonProperties).then(
                response => {
                    this.init();
                    message.success('修改成功');
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )

        }
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event;
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.propertyKey !== null && this.editRecord.propertyKey !== '' ||
        this.editRecord.propertyValue !== null && this.editRecord.propertyValue !== '' ) {
            if (this.editRecord.propertyKey !== this.originalEditRecords.propertyKey ||
                this.editRecord.propertyValue !== this.originalEditRecords.propertyValue) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllCommonProperties();
    }
    getAllCommonProperties() {
        axiosInstance.get('/logistics/getAllCommonProperties').then(
            response => {
                this.originalData = response.data;
                
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].propertyId;        
                    
                    for (let j = 0; j < this.propertyKeyList.length; j++) {
                        if (this.propertyKeyList[j].value == this.originalData[i].propertyKey) {
                            this.originalData[i].showName = this.propertyKeyList[j].label
                        }
                    }
                }
                this.setState({dataSource: this.originalData, isLoaded: true});                
                if (this.state.searchText !== '') {
                    this.search(this.state.searchText);
                }
            },
            error => {
                displayErrorMessage(error, '获取配置失败');
            }
        )       
    }

    
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 配置管理</span>
                </div>
                <div className="seperate">
                </div>
                
                 
                {this.state.isEdit? 
                    <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">配置项:</label>
                                <Select className="box-input"  options={this.propertyKeyList} onChange={this.changeSelect.bind(this, 'propertyKey')} defaultValue={this.editRecord.propertyKey}/>   
                                <label className="box-label">配置值:</label>
                                <Input className="box-input" defaultValue={this.editRecord.propertyValue} onChange={this.changeInput.bind(this, 'propertyValue')}/>                        
                            </div>
                        </div>
                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                        </div>
                    </div>
                :
                    <div className="content">
                        <Input placeholder="输入要查询的字符" onChange={this.onSearch} className="tableSearch" value={this.state.searchText} /> 
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>
                        
                        <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined,PlusOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import TextArea from 'antd/es/input/TextArea';

const dateFormat = 'YYYY-MM-DD';

export default class BracketOrderBracketInventory extends React.Component {
    props = {orderId: 0, recyclable:'',orderQty: 0};
    constructor(props:any) {
        super(props);
    }

    columns = [
    {
        title: '支架号',
        dataIndex: 'bracketNumber',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.bracketNumber > b.bracketNumber? 1 : -1,
    },
  
    {
        title: '入库时间',
        dataIndex: 'entryDate',
        showSorterTooltip: false,
    },
    {
        title: '堆场',
        dataIndex: 'stationName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.stationName > b.stationName? 1 : -1,
    },
  
    ];

    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
        allInInventory: true,   
    };

    
    allPermissions: any[] = [];
    
    stationList: any[] = [];
    editRecord = this.createBracketOrderBracketInventory();
    canBeSaved = false;
    
    createBracketOrderBracketInventory() {
        return {
            stationId : 0,
            bracketNumber : '',
        };
    }
    onAdd = () => {
        this.editRecord = this.createBracketOrderBracketInventory();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    save = () => {
        const bracketInventoryList = [];
        const bracketNumberList = this.editRecord.bracketNumber.split('\n');
        for (let i = 0; i < bracketNumberList.length; i++) {
            bracketInventoryList.push({orderId: this.props.orderId, stationId: this.editRecord.stationId, bracketNumber: bracketNumberList[i]})
        }
        axiosInstance.post('/logistics/insertBracketInventoryForOrderId', bracketInventoryList).then(
            response => {
                this.init();
                message.success('入库成功');
            },
            error => {
                displayErrorMessage(error, '入库失败');
            }
        )
    }
    finish = () => {
        axiosInstance.post('/logistics/finishBracketOrderInInventory', {orderId: this.props.orderId}).then(
            response => {
                this.init();
                message.success('完成成功');
            },
            error => {
                displayErrorMessage(error, '完成失败');
            }
        )
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if(this.props.recyclable != 'recyclable' &&
           this.editRecord.stationId !== null && this.editRecord.stationId !== 0) {
            this.canBeSaved = true;
        } else if (this.editRecord.stationId !== null && this.editRecord.stationId !== 0 &&
            this.editRecord.bracketNumber !== null && this.editRecord.bracketNumber !== ''
        ) {
            this.canBeSaved = true;
        }
        this.setState({}); 
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getBracketOrderById();
        this.getBracketInventoryByOrderId();
        this.getAllStations();
    }
    getBracketOrderById() {
        axiosInstance.get('/logistics/getBracketOrderById', {params: {orderId: this.props.orderId}}).then(
            response => {
                this.setState({allInInventory: response.data.allInInventory});
            },
            error => {
                displayErrorMessage(error, '获取支架失败');
            }
        )

    }
    getBracketInventoryByOrderId() {
        axiosInstance.get('/logistics/getBracketInventoryByOrderId', {params: {orderId: this.props.orderId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].bracketId;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取支架失败');
            }
        )

    }
    getAllStations() {
        axiosInstance.get('/logistics/getAllStations').then(
            response => {
                this.stationList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.stationList.push({label:response.data[i].stationName, value: response.data[i].stationId});
                }
            },
            error => {
                displayErrorMessage(error, 'Get document list failed!');
            }
        )
    }

    onSearch = (value: any) => {
        this.setState({searchText: value.target.value})
        this.search(value.target.value);
    }
    search = (searchText: string) => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => (
                    item.bracketNumber.toLowerCase().includes(searchText.toLowerCase()) )
            )
          })
    }

    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="seperate">
                </div>       
                {this.state.isEdit? 
                    <div className="content">
                    <div className="row">
                        <div className="box">
                            <label className="box-label">堆场:</label> 
                            <Select className="box-input"  options={this.stationList} onChange={this.changeSelect.bind(this, 'stationId')}/>                             
                        </div>
                    </div>                    
                        <div className="row">
                            <div className="box">
                                <label className="box-label">请录入支架号:</label>                                
                                <TextArea disabled={this.props.recyclable != 'recyclable'} rows={10} className="box-input"  onChange={this.changeInput.bind(this, 'bracketNumber')} />
                                <br/>
                                <br/>
                                <label>
                                    格式如下:<br/>
                                    支架号1<br/>
                                    支架号2<br/>
                                    支架号3<br/>
                                </label>                      
                            </div>
                        </div>                        
                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>入库</Button>
                        </div>
                    </div>
                :     
                <div className="content">       
                        <Input placeholder="输入支架号" onChange={this.onSearch} className="tableSearch" value={this.state.searchText} /> 
                        {this.state.allInInventory?
                        ''
                        :
                        <Button type="primary" className="header-right" onClick={this.onAdd}>入库</Button>
                        }

                        {this.state.allInInventory?
                        ''
                        :
                        <Popconfirm
                            title="完成入库"
                            description="是否确定所有入库都已经完成?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.finish()}
                        >
                            <Button type="primary" className="header-right">完成入库</Button>
                        </Popconfirm> 
                        }
                         
                        
                        <Table  columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                </div>
                }    
            </div>
        )      
    }
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { ContainerOutlined,DeleteOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';

export default class SendShipmentInvoice extends React.Component { 
    permissions = sessionStorage.getItem("permissions") || '';
    columns = [   


    {
        title: '批次号',
        dataIndex: 'shipmentNumber',
        width:150,
        showSorterTooltip: false,      
        sorter: (a: any, b: any) => a.shipmentNumber > b.shipmentNumber? 1 : -1,
    },
    {
        title: '班列号',
        dataIndex: 'trainNumber',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.trainNumber > b.trainNumber? 1 : -1,
    },
    {
        title: '客户名称',
        dataIndex: 'customerName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.customerName > b.customerName? 1 : -1,
    },    
    {
        title: '发票号',
        width: 100,  
        dataIndex: 'invoice',
        showSorterTooltip: false,
    }, 
    {
        title: '开票日期',
        width: 110,  
        dataIndex: 'invoiceDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.invoiceDate > b.invoiceDate? 1 : -1,
    },    
    {
        title: '审核',
        dataIndex: 'invoiced',
        showSorterTooltip: false,        
        sorter: (a: any, b: any) => a.invoiced > b.invoiced? 1 : -1,
        render: (_: any, record: any)=> {
            return (
                <div>
                    {
                        record.invoiced == true ? '是 ' :'否 '
                    }
                </div>
            )}
    },   
    {
        title: '发运日',
        dataIndex: 'shipmentDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.shipmentDate > b.shipmentDate? 1 : -1,
    },
    {
        title: '应收款',
        dataIndex: 'totalPrice',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.totalPrice > b.totalPrice? 1 : -1,
        render: (_: any, record: any)=> {
            return (
                <div>                    
                    <a onClick={() => this.onSpending(record)}>{record.totalPrice}</a>                 
                </div>
            )}
    },
    
    {
        title: '已收款',
        dataIndex: 'paid',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.paid > b.paid? 1 : -1,
        render: (_: any, record: any)=> {
            return (
                <div>                    
                    <a onClick={() => this.onReceiving(record)}>{record.paid}</a>                 
                </div>
            )}
    },
    {
        title: '未收款',
        dataIndex: 'notPaid',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.notPaid > b.notPaid? 1 : -1,
    },
    ];
       

    columnsSpending = [       
    {
        title: '操作',
        key: 'operation',
        width: 100,
        render: (_: any, record: any)=> {
            return (
                <div> {
                    this.permissions.includes('财务总监')?
                    <Popconfirm
                        title="删除该记录" 
                        description="是否确定删除?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => this.onDelete(record)}
                    >
                        <DeleteOutlined title='删除' className="actionIcon"/>
                    </Popconfirm>
                    :''
                    }                                 
                </div>
            )
        }
    },  
    {
        title: '费用类型',
        dataIndex: 'expenseType',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.expenseType > b.expenseType? 1 : -1,
    },
    {
        title: '费用项目',
        dataIndex: 'subExpense',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.subExpense > b.subExpense? 1 : -1,
    },
    {
        title: '备注',
        dataIndex: 'comments',
    },
    {
        title: '是否客户支付',
        dataIndex: 'payByCustomer',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.payByCustomer > b.payByCustomer? 1 : -1,
        render: (_: any, record: any)=> {
            return (
                <div>
                    {
                        record.payByCustomer? '是' : '否'
                    }
                </div>
            )
        }
    },
    {
        title: '金额',
        dataIndex: 'amount',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.amount > b.amount? 1 : -1,
    },  
    ];
    columnsReceiving = [  
    {
        title: '收款时间',
        dataIndex: 'paymentDate',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.paymentDate > b.paymentDate? 1 : -1,
    },
    {
        title: '收款金额',
        dataIndex: 'paymentAmount',
        showSorterTooltip: false,
    },
    {
        title: '备注',
        dataIndex: 'comments',
        showSorterTooltip: false,
    },
    ];
    columnsCost = [       
        {
            title: '项目名称',
            dataIndex: 'item',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.item > b.item? 1 : -1,
        },
        {
            title: '金额',
            dataIndex: 'cost',
            showSorterTooltip: false,
        },
        ];
    originalData: any[] = [];
    originalDataSpending: any[] = [];
    originalDataReceiving: any[] = [];
    originalDataCost: any[] = [];
    shipmentNumberList: any[] = [];
    trainNumberList: any[] = [];
    customerNameList: any[] = [];
    searchText = {
        shipmentNumber: '',
        trainNumber: '',
        customerName: '',
        shipDateStart: '',
        shipDateEnd: '',
        invoiced: null,
    }

    onSpending = (record: any) => {             
        this.getAdditionalExpenseByPlanId(record.planId)        
        this.setState({isSpending: true});
        this.setState({isReceiving: false});
        this.setState({isCost: false});
    }
    onReceiving = (record: any) => {                     
        this.getShipmentReceivingByPlanId(record.planId)
        this.setState({isSpending: false});
        this.setState({isReceiving: true});
        this.setState({isCost: false});
    }
    onCost = (record: any) => {                     
        this.getShipmentCostByPlanId(record.planId)
        this.setState({isSpending: false});
        this.setState({isReceiving: false});
        this.setState({isCost: true});
    }
    goBack =()=>{
        this.setState({isSpending: false});
        this.setState({isCost: false});
        this.setState({isReceiving: false});
    }

    getShipmentReceivingByPlanId(planId:any) {
        this.totalReceiving = 0;
        axiosInstance.get('/logistics/getShipmentPaymentByPlanId', {params: {planId: planId}}).then(
            response => {
                this.originalDataReceiving = response.data;
                for (let i = 0; i < this.originalDataReceiving.length; i++) {
                    this.originalDataReceiving[i].key = this.originalDataReceiving[i].consumablesId;                    
                    this.totalReceiving = this.totalReceiving + this.originalDataReceiving[i].paymentAmount;
                }
                this.setState({dataSourceReceiving: this.originalDataReceiving, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取收款信息失败');
            }
        )
    }
    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {                
        this.selectedRows = selectedRows;        
        this.setState({});
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };

    updatedKeys = "";

    invoiceSelected = () => {  
        this.updatedKeys = ";";
        for(let i=0; i<this.selectedRows.length; i++)  {
          this.selectedRows[i].isUpdated = false;
          this.updatedKeys = this.updatedKeys + this.selectedRows[i].key + ";";
        }                       
          this.selectedRows.forEach(element => { 
              const ShipmentPlan = {planId: element.planId};        
              axiosInstance.post('/logistics/makeInvoiceByPlanId', ShipmentPlan).then(
                  response => {     
                      element.isUpdated = true;     
                      let isAllUpdated = true;   
                      for(let i=0; i<this.selectedRows.length; i++)  {
                          if(!this.selectedRows[i].isUpdated){
                              isAllUpdated = false;
                          }
                        }     
                      if(isAllUpdated){
                          const newDataSource = this.state.dataSource?.map(item => {
                              if (this.updatedKeys.includes(";" + item.key+ ";")) {
                                  return { ...item, invoiced:true }; 
                              }
                              return item; 
                          });                    
                          this.setState({dataSource:newDataSource});
                      }
                  
                  },
                  error => {
                      displayErrorMessage(error, '操作失败');
                  }
              )
          })               
          message.success('操作成功');
          // this.init();        
          // this.setState({isLoaded: true});    
      }
  
    onDelete = (record: any) => {
        const AdditionalExpense = {id: record.id, planId: record.planId};
        axiosInstance.post('/logistics/deleteAdditionalExpenseById', AdditionalExpense).then(
            response => {                
                this.onSpending(record);
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onInvoice = (record: any) => {
        const ShipmentPlan = {planId: record.planId};        
        axiosInstance.post('/logistics/makeInvoiceByPlanId', ShipmentPlan).then(
            response => {
                // this.init();
               // message.success('操作成功');                
                const newDataSource = this.state.dataSource.map(item => {
                        if (item.key === record.key) {
                            return { ...item, invoiced:true }; 
                        }
                        return item;
                    });                    
                    this.setState({dataSource:newDataSource});
            },
            error => {
                displayErrorMessage(error, '操作失败');
            }
        )
    }

    getShipmentCostByPlanId(planId:any) {
        this.totalCost = 0;
        axiosInstance.get('/logistics/getShipmentCostByPlanId', {params: {planId: planId}}).then(
            response => {
                this.originalDataCost = response.data;
                for (let i = 0; i < this.originalDataCost.length; i++) {                    
                    this.totalCost = this.totalCost + this.originalDataCost[i].cost;
                }
                this.setState({dataSourceCost: this.originalDataCost, isLoaded: true});
            },
            error => {
                displayErrorMessage(error, '获取成本信息失败');
            }
        )
    }

    getAdditionalExpenseByPlanId(planId:any) {        
        this.totalAmount = 0;
        axiosInstance.get('/logistics/getAdditionalExpenseByPlanId', {params: {planId: planId}}).then(
            response => {
                this.originalDataSpending = response.data;
                for (let i = 0; i < this.originalDataSpending.length; i++) {
                    this.originalDataSpending[i].key = this.originalDataSpending[i].id;
                    this.originalDataSpending[i].expenseType === 'baseExpense'? this.originalDataSpending[i].expenseType ='基本费用': this.originalDataSpending[i].expenseType ='异常费用';                                  
                    this.originalDataSpending[i].subTotal = this.originalDataSpending[i].amount;
                    this.totalAmount = this.totalAmount + this.originalDataSpending[i].amount;
                }
                this.setState({dataSourceSpending: this.originalDataSpending, isLoaded: true});                
            },
            error => {
                displayErrorMessage(error, '获取信息失败');
            }
        )

    }
    invoicedList: any[] = [{label:'是', value: true},{label:'否', value: false}];
    totalAmount = 0;
    totalReceiving = 0;
    totalCost = 0;
    paymentDate = "";
    invoice = "";    
    invoiceDate = "";    
    invoiceExpense = "";    
    invoiceCost = "";
    canBeSaved = false;
    canBePaid = false;
    canInvoiceChanged = true;
    state = {
        dataSource: this.originalData,
        dataSourceSpending: this.originalDataSpending,
        dataSourceReceiving: this.originalDataReceiving,
        dataSourceCost: this.originalDataCost,
        isLoaded: false,
        isSpending: false,
        isReceiving: false,
        isChangeInvoice:false,
        isChangePayment:false,
        isCost: false,
    };
    allPermissions: any[] = [];
  
    changeShipDateStart = (date: any, dateString: string) => {
        this.searchText.shipDateStart = dateString;
        this.search();
    }
    changeShipDateEnd = (date: any, dateString: string) => {
        this.searchText.shipDateEnd = dateString;
        this.search();
    }
    changeSelect = (field: string, event: any) => {        
        (this.searchText as any)[field] = event       
    }

    onSearchShipmentNumber = (value: any) => {
        this.searchText.shipmentNumber = value.target.value;
        this.search();
    }

    onSearchTrainNumber = (value: any) => {
        this.searchText.trainNumber = value.target.value;
        this.search();
    }

    onSearchCustomerName = (value: any) => {
        this.searchText.customerName = value.target.value;
        this.search();
    }
    changeSelectSearch = (field: string, event: any) => {
        (this.searchText as any)[field] = event
        this.search();
    }
    search = () => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => {
                if (this.searchText.shipmentNumber != '' && !item.shipmentNumber?.toLowerCase().includes(this.searchText.shipmentNumber?.toLowerCase())) {
                    return false;
                }
                if (this.searchText.trainNumber != ''  && !item.trainNumber?.toLowerCase().includes(this.searchText.trainNumber?.toLowerCase())) {
                    return false;
                }
                if (this.searchText.customerName != ''  && !item.customerName?.toLowerCase().includes(this.searchText.customerName?.toLowerCase())) {
                    return false;
                }            
                if (this.searchText.invoiced==false || this.searchText.invoiced==true) {                             
                    return this.searchText.invoiced  == item.invoiced ;
                }
                if (this.searchText.shipDateStart != '' && item.shipmentDate < this.searchText.shipDateStart) {
                    return false;
                }
                if (this.searchText.shipDateEnd != '' && item.shipmentDate > this.searchText.shipDateEnd) {
                    return false;
                }

                return true;

            }
            )
          })
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false});
        this.getAllShipmentPlanRevenue();              
    }

    getAllShipmentPlanRevenue() {
        axiosInstance.get('/logistics/getAllShipmentPlanRevenue').then(
            response => {
                this.originalData = response.data;
                let shipmentNumberList: any[] = [];
                let trainNumberList: any[] = [];
                let customerNameList: any[] = [];
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].planId;
                    if (!shipmentNumberList.includes(this.originalData[i].shipmentNumber)) {
                        shipmentNumberList.push(this.originalData[i].shipmentNumber);
                    }
                    if (!trainNumberList.includes(this.originalData[i].trainNumber)) {
                        trainNumberList.push(this.originalData[i].trainNumber);
                    }
                    if (!customerNameList.includes(this.originalData[i].customerName)) {
                        customerNameList.push(this.originalData[i].customerName);
                    }
                }
                this.shipmentNumberList = [];
                this.shipmentNumberList.push({label: '', value: ''});
                for(let i = 0; i < shipmentNumberList.length; i++) {
                    this.shipmentNumberList.push({label: shipmentNumberList[i], value: shipmentNumberList[i]});
                }
                this.trainNumberList = [];
                this.trainNumberList.push({label: '', value: ''});
                for(let i = 0; i < trainNumberList.length; i++) {
                    this.trainNumberList.push({label: trainNumberList[i], value: trainNumberList[i]});
                }
                this.customerNameList = [];
                this.customerNameList.push({label: '', value: ''});
                for(let i = 0; i < customerNameList.length; i++) {
                    this.customerNameList.push({label: customerNameList[i], value: customerNameList[i]});
                }
                this.setState({dataSource: this.originalData, isLoaded: true});                
            },
            error => {
                displayErrorMessage(error, '获取装箱计划收款信息失败');
            }
        )
    }
    changeInvoice = (event: any) => {
        this.invoice = event.target.value;
        this.checkCanBeSaved();
    }

    changeInvoiceExpense = (event: any) => {
        this.invoiceExpense = event.target.value;
        this.checkCanBeSaved();
    }
    changeInvoiceCost = (event: any) => {
        this.invoiceCost = event.target.value;
        this.checkCanBeSaved();
    }


    changeInvoiceDate = (date: any, dateString: string) => {
        this.invoiceDate = dateString;
        this.checkCanBeSaved();
    }    

    changePaymentDate = (date: any, dateString: string) => {
        this.paymentDate = dateString;
        this.checkCanBePaid();
    }  
    checkCanBePaid() {
        this.canBeSaved = false;
        if (this.paymentDate !== null && this.paymentDate !== '') {
            this.canBePaid = true;
        }
        this.setState({});
    }

    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.invoice !== null && this.invoice !== '' &&
            this.invoiceDate !== null && this.invoiceDate !== '') {
            this.canBeSaved = true;
        }
        this.setState({});
    }
    changeInvioce = () => { 
        this.setState({isChangeInvoice: !this.state.isChangeInvoice});        
    };

    changePayment = () => { 
        this.setState({isChangePayment: !this.state.isChangePayment});        
    };
    changeUserName = (event: any) => {
        this.invoice = event.target.value;
        this.checkCanBeSaved();
    }
    makeInvoice = () => {          
        this.updatedKeys = ";";
        for(let i=0; i<this.selectedRows.length; i++)  {
          this.selectedRows[i].isUpdated = false;
          this.updatedKeys = this.updatedKeys + this.selectedRows[i].key + ";";
        }                       
          this.selectedRows.forEach(element => {        
             element.id= element.planId
             element.invoice = this.invoice
             element.invoiceDate = this.invoiceDate
             element.invoiceExpense = this.invoiceExpense
             element.invoiceCost = this.invoiceCost
             element.type = 'shipmentPlan'
             axiosInstance.post('/logistics/makeInvoice', element).then(
                  response => {     
                      element.isUpdated = true;     
                      let isAllUpdated = true;   
                      for(let i=0; i<this.selectedRows.length; i++)  {
                          if(!this.selectedRows[i].isUpdated){
                              isAllUpdated = false;
                          }
                        }     
                      if(isAllUpdated){
                          const newDataSource = this.state.dataSource?.map(item => {
                              if (this.updatedKeys.includes(";" + item.key+ ";")) {
                                  return { ...item, verified:true }; 
                              }
                              return item; 
                          });                    
                          this.setState({dataSource:newDataSource});
                      }
                  
                  },
                  error => {
                      displayErrorMessage(error, '操作失败');
                  }
              )
          })               
          message.success('操作成功');
          // this.init();        
          // this.setState({isLoaded: true});    
      }

      makePayment = () => {         
        this.updatedKeys = ";";
        for(let i=0; i<this.selectedRows.length; i++)  {
          this.selectedRows[i].isUpdated = false;
          this.updatedKeys = this.updatedKeys + this.selectedRows[i].key + ";";
        }                       
          this.selectedRows.forEach(element => {                                  
             element.id = 0
             element.createdBy = sessionStorage.getItem("loginName")?.replaceAll('"', '')
             element.paymentDate = this.paymentDate
             element.paymentAmount = element.totalPrice       
             element.comments = '批量付款'          
             axiosInstance.post('/logistics/insertShipmentPayment', element).then(
                  response => {     
                      element.isUpdated = true;     
                      let isAllUpdated = true;   
                      for(let i=0; i<this.selectedRows.length; i++)  {
                          if(!this.selectedRows[i].isUpdated){
                              isAllUpdated = false;
                          }
                        }     
                      if(isAllUpdated){
                          const newDataSource = this.state.dataSource?.map(item => {
                              if (this.updatedKeys.includes(";" + item.key+ ";")) {
                                  return { ...item, paid:element.paymentAmount, notPaid:0 }; 
                              }
                              return item; 
                          });                    
                          this.setState({dataSource:newDataSource});
                      }
                  
                  },
                  error => {
                      displayErrorMessage(error, '操作失败');
                  }
              )
          })               
          message.success('操作成功');
          // this.init();        
          // this.setState({isLoaded: true});    
      }

    onExport = (record: any) => {
        this.setState({isLoaded: false});
        axiosInstance.get('/logistics/exportShipmentPlanRevenue', {params: {shipmentNumber: this.searchText.shipmentNumber,
            trainNumber: this.searchText.trainNumber, customerName: this.searchText.customerName, shipDateStart: this.searchText.shipDateStart, shipDateEnd: this.searchText.shipDateEnd},responseType: 'blob'}).then(
            response => {
                this.setState({isLoaded: true});
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const fileName = '应收帐款.xlsx';
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                message.success('导出成功!');
            },
            error => {
                displayErrorMessage(error, '导出失败!');
            }
        );

    }

    render() {
        const roles = sessionStorage.getItem("permissions") || '';     
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>                                     
                {this.state.isSpending==false && this.state.isReceiving==false && this.state.isCost ==false ? 
                    <div>                
                    <div className="title"> 
                        <span> 应收开票管理</span>
                    </div>
                    <div className="seperate">
                    </div>
                        <div className="content">
                            <label>批次号:</label>                        
                            <Input onChange={this.onSearchShipmentNumber}  className="tableSearch" value={this.searchText.shipmentNumber} /> 
                            <label>&nbsp;&nbsp;班列号:</label>                        
                            <Input onChange={this.onSearchTrainNumber}  className="tableSearch" value={this.searchText.trainNumber} /> 
                            <label>&nbsp;&nbsp;客户名称:</label>                                                    
                            <Input onChange={this.onSearchCustomerName}  className="tableSearch" value={this.searchText.customerName} /> 
                            <label className="box-label">审核:</label>
                            <Select style={{ width: '100px' }} options={[{label:'--全部--', value: 'all'},...this.invoicedList]} onChange={this.changeSelectSearch.bind(this, 'invoiced')}/>                                                               
                            <label>&nbsp;&nbsp;发运日:</label>
                            <DatePicker style={{width: '150px'}} onChange={this.changeShipDateStart} />
                            <label>&nbsp;-&nbsp;</label>
                            <DatePicker style={{width: '150px'}} onChange={this.changeShipDateEnd} />
                            <br/>
                            
                            <Button type="primary" className="header-left" onClick={this.changeInvioce} disabled={this.selectedRows.length==0 || this.canInvoiceChanged==false } >批量开票</Button>                                                          
                            <Button type="primary" className="header-left" onClick={this.changePayment} disabled={this.selectedRows.length==0 } >批量收款</Button>                                                          
                            {this.state.isChangeInvoice? 
                            <div className="box">
                                <label className="box-label">发票号:</label>
                                <Input className="box-input" style={{width: '150px'}} onChange={this.changeUserName} />
                                <label className="box-label">开票日期:</label>
                                <DatePicker className="box-input" style={{width: '150px'}}onChange={this.changeInvoiceDate} />     
                                <label className="box-label">摘要:</label>
                                <Input className="box-input" style={{width: '150px'}} onChange={this.changeInvoiceExpense}/>
                                <label className="box-label">异常收支:</label> 
                                <Input className="box-input" style={{width: '150px'}} onChange={this.changeInvoiceCost}/>                                
                                <Button type="primary" className="header-left" onClick={this.makeInvoice}  disabled={!this.canBeSaved}>确定</Button>                            
                            </div>
                            :''
                            }

                            {this.state.isChangePayment? 
                            <div className="box">                              
                                <label className="box-label">付款日期:</label>
                                <DatePicker className="box-input" style={{width: '150px'}} onChange={this.changePaymentDate} /> 
                                <Button type="primary" className="header-left" onClick={this.makePayment}  disabled={!this.canBePaid}>确定</Button>                            
                            </div>
                            :''
                            }
                                                      
                            <Table pagination={{ pageSize: 10 }} rowSelection={{ type: 'checkbox', ...this.rowSelection, }} columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}
                            summary={() => {
                                let allPrice = 0;
                                let totalPaid = 0;
                                let totalNotPaid = 0;

                                if(this.selectedRows.length>0){
                                    this.selectedRows?.forEach(({ totalPrice,paid,notPaid }) => {
                                        allPrice += totalPrice;
                                        totalPaid += paid;
                                        totalNotPaid += notPaid;                                    
                                    });  
                                }else{
                                    this.state.dataSource?.forEach(({ totalPrice,paid,notPaid }) => {
                                        allPrice += totalPrice;
                                        totalPaid += paid;
                                        totalNotPaid += notPaid;                                    
                                    });    
                                } 
                                return (
                                <Table.Summary.Row>
                                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>汇总(所有或选中)</Table.Summary.Cell>
                                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                <Table.Summary.Cell index={6}>{allPrice % 1 === 0 ? allPrice : allPrice.toFixed(2)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={7}>{totalPaid % 1 === 0 ? totalPaid : totalPaid.toFixed(2)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={8}>{totalNotPaid % 1 === 0 ? totalNotPaid : totalNotPaid.toFixed(2)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={9} />
                                </Table.Summary.Row>
                                );
                                }} />
                            
                        </div>
                </div>
                :          
                    this.state.isSpending == true?      
                    <div className="content">     
                    <div className="title">
                        <span> 应收款明细</span>
                    </div>         
                    <Button type="primary" className="header-right" onClick={this.goBack}>返回</Button>                       
                    <Table  title={() => '合计金额: ' + this.totalAmount} columns={this.columnsSpending} dataSource={this.state.dataSourceSpending} bordered loading={!this.state.isLoaded}/>
                    </div>
                    :
                        this.state.isCost == true?      
                        <div className="content">     
                        <div className="title">
                            <span> 成本明细</span>
                        </div>         
                        <Button type="primary" className="header-right" onClick={this.goBack}>返回</Button>                       
                        <Table  title={() => '合计金额: ' + this.totalCost} columns={this.columnsCost} dataSource={this.state.dataSourceCost} bordered loading={!this.state.isLoaded}/>
                        </div>
                        :
                        <div className="content">     
                        <div className="title">
                        <span> 收款明细</span>
                        </div>         
                        <Button type="primary" className="header-right" onClick={this.goBack}>返回</Button>                       
                        <Table  title={() => '合计金额: ' + this.totalReceiving} columns={this.columnsReceiving} dataSource={this.state.dataSourceReceiving} bordered loading={!this.state.isLoaded}/>
                        </div>
                }
            </div>
        )      
    }

   
}
